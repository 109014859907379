.form-button.form-button-main.globus{
    @media only screen and (min-width: 1025px) {
        right: 3%;
    }
    @media only screen and (min-width: 1200px) {
        right: 4%;
    }
    @media only screen and (min-width: 1300px) {
        right: 5%;
    }
    @media only screen and (min-width: 1400px) {
        right: 6%;
    }
    @media only screen and (min-width: 1500px) {
        right: 8vw;
    }
    .btn__container.btn--secondary-lilac{
        font-family: $good-head-regular;
        background-color: #CF1F16;
        border: 2px solid #FFFFFF;
        border-radius: 22px;
        background-image: none;
        width: 349px;
        padding: 10px 17px 8px 17px;
        font-weight: bold;
        &:before {
            background-color: #CF1F16;
        }
    }
}

.buzzer-netto.kaufland.edeka.globus {
    .title-container {
        padding-bottom: 42px;
        @media only screen and (min-width: 481px) {
            padding-bottom: 70px;
        }
        h2 {
            font-family: $gobold-bold;
            @media only screen and (min-width: 481px) {
                max-width: 929px;
                font-size: 60px;
                line-height: 90px;
            }
            @media only screen and (max-width: 480px) {
                max-width: 332px;
                font-size: 30px;
                line-height: 42px;
            }
        }
        h4 {
            font-family: $good-head-regular;
            font-size: 20px;
            line-height: 30px;
            @media only screen and (min-width: 481px) {
                max-width: 889px;
                margin-top: 24px;
                font-size: 24px;
                line-height: 34px;
            }
            @media only screen and (max-width: 481px) {
                margin-top: 25px!important;
            }
        }
        .legend-wrapper {
            width: 343px;
            margin: 0 auto;
            border: 2px solid #fff;
            border-radius: 4px;
            @media only screen and (min-width: 481px) {
                width: 490px;
            }
            .legend-img {
                .img-legend {
                    width: 100%;
                }
            }
            .legend-content {
                margin-top: 15px;
                h2 {
                    font-size: 30px;
                    line-height: 42px;
                    @media only screen and (min-width: 481px) {
                        font-size: 30px;
                        line-height: 32px;
                    }
                }
                p {
                    font-family: $good-head-regular;
                    margin: 0 auto;
                    margin-top: 20px;
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 15px;
                    @media only screen and (min-width: 481px) {
                        margin-top: 20px;
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }
    .step-container {
        padding-top: 40px;
        padding-bottom: 41px;
        @media only screen and (min-width: 481px) {
            padding-top: 90px;
            padding-bottom: 70px;
        }
        .step-title {
            font-family: $gobold-bold;
            line-height: 62px;
            @media only screen and (max-width: 481px) {
                font-size: 31px;
                line-height: 42px;
                max-width: 343px;
                margin-bottom: 0;
            }
        }
        .s-content-steps__item {
            img {
                @media only screen and (max-width: 768px) {
                    width: auto;
                }
                @media only screen and (max-width: 481px) {
                    width: auto;
                }
            }
            p {
                font-family: $good-head-regular;
                font-size: 20px;
                line-height: 30px;
                @media only screen and (min-width: 481px) {
                    max-width: 251px;
                }
                @media only screen and (max-width: 481px) {
                    width: 100%;
                }
            }
            &::after {
                display: none;
            }
        }
        .step-container--terms-link {
            max-width: 406px;
            margin: 0 auto;
            font-size: 16px;
            line-height: 24px;
            margin-top: 25px;
            font-family: $good-head-regular;
        }
        .btn__container.btn--secondary-lilac{
            background-color: #CF1F16;
            border: 2px solid #FFFFFF;
            border-radius: 22px;
            background-image: none;
            width: 349px;
            font-family: $good-head-regular;
            font-size: 19px;
            font-weight: bold;
            line-height: 15px;
            padding: 10px 17px 8px 17px;
            @media only screen and (max-width: 481px) {
                margin-top: 0;
            }
            &:before {
                background-color: #CF1F16;
            }
            a {
                font-family: $good-head-regular;
            }
        }
    }
    .card-container {
        padding-top: 31px;
        padding-bottom: 40px;
        @media only screen and (min-width: 481px) {
            padding-top: 65px;
            padding-bottom: 65px;
        }
        h2 {
            font-size: 24px;
            line-height: 34px;
            text-align: center;
            max-width: 572px;
            margin: 0 auto;
            padding-bottom: 25px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            text-align: center;
            max-width: 406px;
            margin: 0 auto;
            &.terms-link {
                margin-bottom: 25px;
                margin-top: 25px;
            }
            a {
                font-weight: normal;
            }
        }
        .btn__container.btn--secondary-lilac{
            background-color: #CF1F16;
            border: 2px solid #FFFFFF;
            border-radius: 22px;
            background-image: none;
            width: 349px;
            font-family: $good-head-regular;
            font-size: 19px;
            font-weight: bold;
            line-height: 15px;
            padding: 10px 17px 8px 17px;
            letter-spacing: 5px;
            &:before {
                background-color: #CF1F16;
            }
            a {
                font-family: $good-head-regular;
            }
        }
    }
    .prizeRewe {
        padding-bottom:60px;
        @media only screen and (max-width: 481px) {
            padding-top: 20px;
            padding-bottom:30px;
        }
        .terms-title {
            font-family: $gobold-bold;
            @media only screen and (max-width: 481px) {
                margin: 0 auto;
                margin-bottom: 12px;
            }
        }
        .terms-subtitle {
            font-family: $good-head-regular;
            margin: 0 auto;
            max-width: 337px;
            @media only screen and (min-width: 481px) {
                max-width: 617px;
                margin-bottom: 31px;
            }
            @media only screen and (max-width: 481px) {    
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 17px;
            }
        }
        .instruction-wraper {
            max-width: 645px;
            margin: 0 auto;
            text-align: center;
            .instruction {
                font-family: $good-head-regular;
                text-align: center;
                margin-bottom: 21px;
                font-size: 16px;
                line-height: 24px;
                a {
                    text-decoration: none;
                    font-weight: normal;
                }
            }
        }
    }
}

.s-content-end.globus-end {
    padding: 0;      
    margin-bottom: 0;
    h1 {
        font-family: $gobold-bold;
    }
    p {
        font-family: $good-head-regular;
        color: #fff;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        @media only screen and (max-width: 480px) { 
            padding-bottom: 30px;
        }
        @media only screen and (min-width: 480px) { 
            padding-bottom: 30px;
            max-width: 480px;
            margin: 0 auto;
            margin-bottom: 50px;
            text-align: center;
        }
    }
    .end-element {
        background-color: #7D69AC;
        padding: 0 20px;
        padding-top: 40px;
        margin: 0 auto;
        @media only screen and (min-width: 480px) { 
            max-width: unset;
        }
        p {
            color: #3B2772;
            text-align: left;
            padding-bottom: 0px;
        }
        .end-element-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 50px auto;
            max-width: 1175px;
            justify-content: center;
            .end-element__copy {
                h3 {
                    font-family: $gobold-bold;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: bold;
                }
                p {
                    font-family: $good-head-regular;
                    font-size: 20px;
                    line-height: 26px;
                }
                .btn__container {
                    font-family: $good-head-regular;
                    font-size: 19px;
                    font-weight: bold;
                    line-height: 15px;
                    padding: 10px 17px 8px 17px;
                }
            }
        }
    }
}

.penny.globus-holding {
    .content-holding__heading {
        .campaign-heading {
            h2 {
                font-family: $gobold-bold;
                font-size: 30px;
                line-height: 42px;
                @media only screen and (min-width: 480px) { 
                    font-size: 60px;
                    line-height: 90px;
                    max-width: 908px;
                }
            }
            p {
                font-family: $good-head-regular;
            }
        }
        .remindMe-container {
            p {
                font-family: $good-head-regular;
                @media only screen and (min-width: 480px) { 
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .custom-inputs {
                .text-content {
                    font-family: $good-head-regular;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .btn__container {
            font-family: $good-head-regular;
            font-size: 19px;
            font-weight: bold;
            line-height: 15px;
            padding: 10px 17px 8px 17px;
        }
    }
}