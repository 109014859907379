.campaign-footer {
    padding: 14px 10px 100px;

    @include breakpoint (medium) {
        padding-top: 50px;
    }

    * {
        color: $dark-lilac;
        text-align: center;
    }

    
    h2 {
        font-family: $recoleta-medium;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 15px;

        font-size: 30px;
        line-height: 32px;

        @include breakpoint(medium) {
            font-size: 40px;
            line-height: 46px;
            width: 80%;
        }

        img.tablet-up {
            margin-bottom: -20px;
        }

        span {
            font-family: inherit;
        }

        br {
            display: none;
            @include breakpoint(medium) {
                display: block;
            }
        }

        &.special {
            @include breakpoint (medium) {
                line-height: 60px;
            }

            img {
                width: 65px;
            }
        }
    }
    
    h3 {
        font-family: $recoleta;

        @include breakpoint(medium) {
            font-size: 25px;
            line-height: 28px;

            width: 60%;
            margin: 30px auto 50px;
        }
    }

    & > p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 15px;
        
        @include breakpoint(large) {
            width: 60%;
            margin: 0 auto;
        }
    }

    .btn--anchor {
        display: block;
        margin-top: 25px;
        @include breakpoint (medium) {
            margin-top: 45px;
        }
    }

    .content-separator {
        margin-top: 50px;
        @include breakpoint(medium) {
            margin-top: 100px;
        }
        
        img {
            width: 90%;
            
            @include breakpoint(medium) {
                width: 60%;
            }

        }
    }

    .cta-container {
        display: flex;
        flex-direction: column;
        
        @include breakpoint(medium) {
            width: 60%;
            margin: 0 auto;
        }
        @include breakpoint(large) {
            flex-direction: row;
            align-content: center;
            justify-content: space-around;
            max-width: 1024px;
        }

        button {
            padding: 0 25px;
            width: auto;
        }
    }

    h3 {
        &.special {
            font-family: $soft;
            
            @include breakpoint(medium) {
                font-family: $recoleta;
            }
        }
    }

    .campaign-mid {

        @include breakpoint(large) {
            width: calc(60% + 140px);
            margin: 0 auto;
        }

        img {
            width: 90%;
            margin: 10px auto;

            &.special {
                @include breakpoint(large) {
                    margin-top: 70px;
                }
            }
        }

        h3 {
            font-size: 26px;
            font-weight: bold;
            width: 80%;
            margin: 10px auto;
        }
    }
}