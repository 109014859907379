.nutrition-facts {

    &__description {
        color: $dark-lilac;
        font-family: $soft-alt-regular;
        padding: 0 15px;

        @include breakpoint(large) {
            flex-basis: 30%;
            font-size: 17px;
            padding: 0;
        }

        p {
            line-height: 1.3em;
            margin: 10px 0 30px;
            font-size: 15px;

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            @include breakpoint(medium) {
                font-size: 18px;
            }
        }

    }

    &__table {
        font-family: $soft-alt-regular;

        @include breakpoint(large) {
            flex-basis: 65%;
        }

        th {
            text-align: right;
            color: $dark-lilac;
            font-family: $soft-alt-medium;
            font-size: 16px;
            // text-align: left;

            @include breakpoint(medium) {
                font-size: 18px;
                line-height: 1.7em;
            }

            @include breakpoint(large) {
                letter-spacing: 1.3px;
            }
            
            &:last-child {
                padding-right: 15px;

                @include breakpoint(large) {
                    padding-right: 0;
                }
            }
        }

        td {
            text-align: right;
            min-width: 150px;
            color: $dark-lilac;
            font-family: $soft-alt-regular;
            // text-align: center;
            font-size: 16px;
            padding-top: 11px;

            @include breakpoint(medium) {
                font-size: 18px;
                line-height: 1.1em;
            }

            @include breakpoint(large) {
                letter-spacing: 1.7px;
            }

            &:first-of-type {
                text-align: left;
            }

            &:last-child {
                padding-right: 15px;
                
                @include breakpoint(large) {
                    padding-right: 0;
                }
            }
        }
    }

    // &__container {
    //     background-image: url($local-images+'/Shapes/mask.png');
    //     background-repeat: no-repeat;
    //     background-size: cover;

    //     display: none;

    //     margin-top: 15px!important;
    //     border-bottom: none;

    //     &.opened {
    //         display: block;
    //     }

    //     &:last-of-type {
    //         border-bottom: 1px solid $dark-lilac;
    //     }

    //     .product-accordion {
    //         &__content {
    //             border-top: none;
    //             margin-top: 0;
    //             padding-top: 0;
    //         }
    //     }
    // }

    &__container {
        background-image: url($local-images+'/Shapes/mask.png');
        background-repeat: no-repeat;
        background-size: cover;

        margin-top: 15px!important;
        border-bottom: none;

        &.opened {
            display: block;
        }

        &:last-of-type {
            border-bottom: 1px solid $dark-lilac;
        }

        .product-accordion {
            &__content {
                border-top: none;
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

@import "../../05_STATE/description/nutrition-facts";