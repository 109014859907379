.header-md {
    &__container {
        
        display: flex;

        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;

        align-items: center;
        justify-content: space-between;

        max-height: 102px;
        transition: 0.5s;

        z-index: 3;
        
        .menu-line {
            background: $white;
        }

        .menu-line:nth-child(1) {
            z-index: -1;
        }

        .menu-line:nth-last-child(1) {
            z-index: -1;
        }
    }

    &__logo {
        
        display: flex;
        // @include flex-long(0,0,15%);
        margin-left: 0;
        justify-content: center;
        height: 100%;

        img {
            height: 65%;
            width: auto;

            @include breakpoint(IE-only) {
                height: auto;
            }
        }

        .nav__item {
            display: flex; 
            align-items: center;

            span {
                color: $white;
                font-size: 60px;
            }
        }
    }

    &__content {
        
        position: relative;
        right: 0;
        transition: 0.5s;

        display: flex;
        // @include flex-long(1,0,60%);
        margin-right: 30px;
        justify-content: flex-end;
    }

    &__navigations {
        
        display: flex;
        align-items: center;
    }

    &__navigation {
        
        margin: 0 15px;
        font-family: $soft-alt-regular;
        text-transform: uppercase;
        color: $white;
        letter-spacing: 2.5px;

        @include breakpoint(medium) {
            font-size: 16px;
        }

        @include breakpoint (large) {
            font-size: 17px;
        }

        @include breakpoint (x-large) {
            font-size: 20px;
            margin: 0 30px;
        }
    }

    &__icons {
        
        display: flex;
        align-items: center;

        a {
            span {
                color: $white;
            }
        }
        
        .shopping-bag {
            position: relative;

            .icon-shopping-bag {
                font-size: 20px;
                position: relative;

                &:before {
                    font-size: 33px;
                }

                &:after {
                    content: attr(data-count);
                    text-align: center;
                    position: absolute;
                    font-family: $soft-alt-regular;
                    left: 0;
                    right: 0;
                    top: -2px;
                }
            }
        }
    }

    &__icon {
        
        margin: 0 15px;
        
        &:nth-last-child(1) {
            margin: 0;
            margin-right: 15px;
            margin-left:15px;
        }
        
        a {
            span {
                font-size: 33px;
                color: $white;
            }
            .image_globe{
                width:33px;
                height:33px;
            }
        }
       
          
  
    }

    &__menu {
        
        cursor: pointer;
        position: absolute;
        right: 50px;
        top: calc(50% - 15px);
        opacity: 0;
        transition: 0.5s;
        z-index: -1;
    }

    &__contact {
        
        margin: 0;
        margin-right: 2px;
        margin-left: 15px;

        a {
            span {
                font-size: 26px;
            }
        }
    }
    &__country {
        a {
            span {
                font-size: 26px;
            }
        }
    }

    &__in-cart {
        
        position: fixed;
        top: -800px;
        right: 0;
        transition: 0.5s;
        @include breakpoint (large) {
            right: 125px;
            width: 600px;
        }
    }

    &__in-cart.active {
        
        z-index: 999;
        top: 90px;

        @include breakpoint (large) {
            top: 85px;
        }
    }
}

@import "../../05_STATE/header/sticky";