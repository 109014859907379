.buzzer-mobile {
    background: rgba(125, 107, 170, 0.2);
    border-radius: 10px;
    padding: 30px 10px 45px;
    margin: 0 15px 70px;

    p {
        text-align: center;
        width: 100%;
        margin-bottom: 13px;
        font-size: 16px !important;
        line-height: 22px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    span {
        font-size: 25px;
        line-height: 38px;

        &.separator {
            display: inline-block;
            height: 1px;
            width: 5%;
            background-color: $dark-lilac;
            margin: 0 10px;
        }
    }

    select {
        background-color: white;
    }

    .buzzer-drop-down {
        .options {
            .out-stock {
                opacity: 0.3;
            }
        }
    }

    .buzzer-drop-down > *{
        margin:0;
        padding:0;
    }
    
    .buzzer-drop-down .selected a{
        background:#fff no-repeat scroll right center;
        display:block; 
        padding-right:40px; 
        padding-left:10px; 
        text-decoration:none;
        color:$dark-lilac;

        background: #FFFFFF;
        border: 1px solid #3B2774;
        box-sizing: border-box;
        border-radius: 22px;
    }
    
    .buzzer-drop-down .selected a span{
        cursor:pointer; 
        display:block; 
        padding: 5px 15px 5px 0;

        font-size: 16px;
        line-height: 22px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .buzzer-drop-down .option{
        position:relative; 
    }
    
    .buzzer-drop-down .options ul{
        background:#fff none repeat scroll 0 0;               display:none;
        list-style:none; 
        padding:0px 0px; 
        position:absolute;
        top:32px; 
        width:90%; 
        min-width:170px;
        //border:1px solid #d7d7d7;
        z-index: 9;
        left: 50%;
        transform: translate(-50%);

        li {
            padding: 5px;
            border-bottom: 1px solid rgba(141,126,181, 0.5);
        
            &[data-match-selected] {
                background: #AFA6D2;
            }
        }

        p {
            display: block;
            text-align: left;
            font-size: 15px;
        }
    }
    
    .buzzer-drop-down .selected span.value, .buzzer-drop-down .options span.value{
        display: none;
    }
    
    .buzzer-drop-down .options ul li a{
        display:block;
        text-decoration:none;
    }
    
    .buzzer-drop-down .options ul li a:hover{
        background: #EFEFEF;
        transition:0.2s ease;
    }

    .buzzer-drop-down {
        position: relative;
        &::after {
            content: '\e027';
            font-family: 'icomoon';
            font-size: 12px;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            pointer-events: none;
            color: #3b2774;
        }
    }
}