.container_title {
    padding-top: 6vh;
    width: 59%;
    margin: auto;
    margin-bottom: 40px;
    // margin-block: 50px;

    @media (max-width: 767px) {
        width: auto;
        margin-top: 0px;
        margin-inline: 20px;
        margin-bottom: 40px;
    }

    .title {
        color: var(--Fresh-White, #FFF);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Gobold Bold";
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 20px;

        @media (max-width: 767px) {
            font-size: 30px;
        }


    }

    .description {
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Good Headline Pro";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 20px !important;
        margin-top: 0 !important;
        max-width: 663px;
        justify-self: center;
        margin: auto;
    }

    /* Media query pour les écrans avec une largeur minimale de 768 pixels (écrans web) */
    @media only screen and (min-width: 768px) {
        .description {
            padding: 1vh;
            margin-top: 20px;
        }
    }

    @media only screen and (max-width: 767px) {
        .description {
            margin-bottom: 10px !important;
        }
    }
}