.simple-loader {
    &__container {
        z-index: 99999;
        display:  none;
        position: fixed;
        top: 0;
        height: 100vh;
        width: 100%;
        // background-color: rgba(59, 39, 116, 0.5);
        background-color: rgba(2, 2, 2, 0.548);

        &.active {
            display: block; 
        }
    }

    &--search {
        z-index: 9999;
    }

    &__indicator {
        position: absolute;
        top: 50%;
        left: calc(50% - 30px);
        transform: translate(-50%,-50%);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 4px solid $mid-lilac;
        border-right-color: transparent;
        transform-origin: center;
        animation: rotate 1s linear infinite;
    }

}

.partial-loader {
    &__container {
        z-index: 5;
        display:  none;
        height: 100px;
        width: 100%;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}