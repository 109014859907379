.campaign-heading {
    margin: 20px 0 50px;
    padding: 0 10px;
    
    @include breakpoint(medium) {
        margin: 25px 0 70px;
        padding: 0;
    }

    h2, h4, h3, p {
        text-align: center;
        color: $white;
    }

    h2 {
        font-size: 30px;
        line-height: 36px;
        font-family: $recoleta-medium;

        @include breakpoint(medium) {
            font-size: 45px;
            line-height: 50px;
        }

        @include breakpoint(large) {
            font-size: 60px;
            line-height: 65px;
        }
    }

    h3 {
        @include breakpoint(medium) {
            font-size: 35px;
            line-height: 45px;
            width: 60%;
            margin: 30px auto;
        }

        &.wider {
            @include breakpoint(small-only) {
                padding: 0 10px;
                margin: 15px 0;
            }

            @include breakpoint(medium) {
                width: 75%;
            }
        }
    }

    h4 {
        font-family: $recoleta;
        font-size: 20px;
        margin-top: 20px;
        
        @include breakpoint(medium) {
            font-size: 25px;
            margin: 70px 0 30px;
        }

        @include breakpoint(large) {
            font-size: 30px;
            margin-top: 50px;
        }

        &.intervogue {
            @include breakpoint(small-only) {
                font-family: $soft-alt-regular;
            }
        }
    }

    a {
        font-family: inherit;
    }

    & > img {
        width: 90%;
        margin: 0 auto;
        display: block;
    }
    
    & > p {
        font-size: 20px;
        line-height: 26px;
        margin: 20px  auto;
        
        @include breakpoint(medium) {
            width: 50%;
            margin: 30px auto;
            font-size: 24px;
            line-height: 27px;
        }
    }

    p {
        a {
            text-decoration: underline;
            font-weight: bold;

            &:hover {
                text-decoration: none;
            }
        }

        &.special {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .cta-container {
        @include breakpoint(medium) {
            margin-top: 20px;
        }

        & > p {
            margin-top: 30px;
        }
    }

    h2 {
        br {
            @include breakpoint(small-only) {
                display: none;
            }
        }
        span.mobile {
            font-family: inherit;
        }
        img.tablet-up {
            margin-bottom: -20px;

            &#rewe-logo {
                margin-bottom: -10px;
            }
        }
    }
}