
$icon-height: 40px;

.icon {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        height: 100%;
        width: $icon-height;

        border-radius: 50%;

        svg {
            height: 80%;

            @include breakpoint(IE-only) {
                height: 28px;
            }
            // height: auto;
        }
    }

    &__text {
        font-size: 12px;
        color: white;
        text-align: center;
        margin-top: 8px;
        font-family: $soft-regular;
        width: 100%;
    }
}

@import "../../05_STATE/buttons/icon";