.text-section-heading{
    background-color: rgb(231, 229, 242);
    text-align: center;
    font-family: $good-head-pro;
    margin: 0 auto;
    color: #3b2774;
    padding: 25px 0;
/*     @media only screen and (max-width: 481px) {
            width: 91%;
    } */
        &__paragraph,
        .btn__container  {
             display: block;
             margin: 0 auto;
         }
    
    &__paragraph{
            padding-bottom: 35px;
            font-size: 20px;
            line-height: 26px !important;
            font-family: $good-head-pro !important;
            color: #3b2774 !important;  
            font-weight: bold;       
            /* mobile Portrait */
            @media only screen and (max-width: 481px) {
                font-size: 20px;
                line-height: 20px;
                padding-top: 0px;
                width: 90%;
            }

            @media screen and (min-width:476px) and (max-width:1024px) {
                
                width: 90%;
                max-width: 500px;
            } 
            
            @include breakpoint(large) {
                width: 600px;
            }
    }
    .btn__container{
        font-weight: bold;
        font-size: 20px;
        line-height: 15px;
        letter-spacing: 5px;
        font-family: $good-head-pro;
        border : 2px solid white;
        background-color: #7D6BAA;
        @media only screen and (min-width: 481px) {
            width: 349px;
        }
    }
}