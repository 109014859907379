.match-card {
    max-width: 782px;
    animation: fadeIn .5s;
    
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    @-moz-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    @-o-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    @-ms-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    &.whitebg{
        background-color: #fff;
    }

    &__disabled {
        opacity: 0.5;
    }
    
    &__top {
        position: relative;

        &__image {
            img {
                width: 100%;
            }
        }
        
        &__team {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 65%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6%;
            
            &__left, &__right {
                width: 18%;
                
                @include breakpoint(small-only) {
                    width: 27%;
                }
            }

            img {
                height: 50px;
                margin-left: -20px;
                
                @include breakpoint(small-only) {
                    height: 60px;
                    margin-left: 0;
                }
            }
            
            &__divider {
                font-weight: 900;
                font-size: 35px;
                font-family: $gobold-bold;
                color: $white;
                
                @include breakpoint(large) {
                    font-weight: 900;
                    font-size: 40px;
                }
            }
        }
    }

    &__bottom {
        margin-top: 15px;

        @include breakpoint(large) {
            margin-top: 40px;
        }

        p {
            color: #3b2774;
            font-family: $gobold-bold;
            font-size: 20px;
            font-weight: 400;
            line-height: 38px;
            
            @include breakpoint(large) {
                font-size: 30px;
                line-height: 42px;
            }
        }
        
        &__info {
            p {
                font-family: $good-head-regular;
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                
                @include breakpoint(large) {
                    font-size: 24px;
                    line-height: 33px;
                }
            }

            &__time {
                margin-left: 15px;
            }
        }

        &__team {
            display: flex;
            padding-bottom: 5px;
            margin-bottom: 16px;
            
            &__divider {
                padding: 0 10px;
            }

            &__left, &__right {
                display: flex;
                text-align: center;
                word-wrap:break-word;
            }
        }
    }

    &__small {
        max-width: 384px;
        border: 1px solid #AFA6D2;
        border-radius: 12px;
        overflow: hidden;

        .match-card {
            &__top {
                position: relative;

                &__team {
                    width: 75%;

                    img {
                        height: 50px;
                        margin-left: 0;
                    }
                    
                    &__left, &__right {
                        width: 26%;
                    }
                    
                    &__divider {
                        font-weight: 900;
                        font-size: 40px;
                        color: $white;
                    }
                }
            }
                    
            &__bottom {
                padding: 20px 10px;
                margin-top: 0;

                p {
                    color: #3b2774;
                    font-family: $good-head-regular;
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 27px;
                }
                
                &__info {
                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                    }

                    &__time {
                        margin-left: 15px;
                    }
                }

                &__team {
                    display: flex;
                    padding-bottom: 5px;

                    &__left, &__right {
                        word-wrap:break-word;
                    }
                }
            }
            &__custom-text {
                p{
                    text-align: center;
                    font-family: $good-head-regular;
                    font-size: 16px;
                    font-weight: normal;
                }
            }
        }
    }

    &__cta {
        display: flex;
        justify-content: space-between;
    }
}