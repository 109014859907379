$offset-top-left: 8%;
$offset-top-right: 13%;
$offset-side: -12%;
$deco-width-left: 33%;
$deco-width-right: 46%;

.container {
    &--simple-deco {
        position: relative;

        &::before {
            display: none;
            position: absolute;
            z-index: -1;
            left: $offset-side;
            top: $offset-top-left;
            height: 105% + $offset-top-left;
            width: $deco-width-left;
            content: '';

            background-image: url($local-images+'/Pages/home/simple-deco-left.png');
            background-repeat: no-repeat;
            background-size: 50%;

            @include breakpoint(large) {
                display: block;
            }

            @include breakpoint(x-large) {
                left: $offset-side - 8;
            }

            @media (min-width: 1700px) {
                left: $offset-side - 12;
            }

            @media (min-width: 1800px) {
                left: $offset-side - 16;
            }

            @media (min-width: 1900px) {
                left: $offset-side - 22;
            }
        }

        &::after {
            display: none;
            position: absolute;
            z-index: -1;
            right: $offset-side;
            top: $offset-top-right;
            height: 105% + $offset-top-right;
            width: $deco-width-right;
            content: '';

            background-image: url($local-images+'/Pages/home/simple-deco-right.png');
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: top right;

            @include breakpoint(large) {
                display: block;
            }

            @include breakpoint(x-large) {
                right: $offset-side - 8;
                top: $offset-top-left;
            }

            @media (min-width: 1700px) {
                right: $offset-side - 12;
            }

            @media (min-width: 1800px) {
                right: $offset-side - 16;
            }

            @media (min-width: 1900px) {
                right: $offset-side - 22;
            }
        }
    }

    &--beach-deco {
        position: relative;

        &::before {
            display: none;
            position: absolute;
            z-index: -1;
            left: $offset-side;
            top: 2%;
            height: 105%;
            width: $deco-width-left;
            content: '';

            background-image: url($local-images+'/Pages/home/beach-deco-left.png');
            background-repeat: no-repeat;
            background-size: 50%;

            @include breakpoint(large) {
                display: block;
            }

            @include breakpoint(x-large) {
                left: $offset-side - 52;
            }
        }

        &::after {
            display: none;
            position: absolute;
            z-index: -1;
            right: $offset-side;
            top: 2%;
            height: 105%;
            width: 65%;
            content: '';

            background-image: url($local-images+'/Pages/home/beach-deco-right.png');
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: top right;

            @include breakpoint(large) {
                display: block;
            }

            @include breakpoint(x-large) {
                right: $offset-side - 52;
            }
        }
    }
}