.footer__container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 50px;
    padding-top: 50px;

    @media only screen and (max-width: 768px) {
        display: block;
    }
    
    &--content{
        width: 60%;
        @media only screen and (max-width: 768px) {
            width: 100%;
            margin: 0 auto;
        }
    }

    .footer__copyright{
        width: 40%;
        @media only screen and (max-width: 768px) {
            width: 100%;
            margin: 0 auto;
            margin-top: 50px;
        }
    }

    &--links{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        @media only screen and (max-width: 768px) {
            justify-content: center;

            h4:last-child{
                margin-right: 0;
            }
        }


        h4{
            margin-right: 60px;
            margin-bottom: 25px;
            font-family: $soft-regular;
            color: #3B2772;
            font-style: normal;
            font-weight: bold;
            font-size: 14.1745px;
            line-height: 17px;
            letter-spacing: 2.18069px;
            text-transform: uppercase;
            
            @include breakpoint(small-only){
                width: 50%;
                margin-right: 0;
                text-align: center;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 2.18069px;
/*                 &:first-child {
                    width: 65%;
                }
                &:nth-child(2) {
                    width: 30%;
                } */
            }
        }
    }
}

footer {
    background-color: #fff;
    
    .footer__legal{
        padding-left: 0;
        margin: 0;
        margin-top: 65px;
        width: 90%;
        max-width: 630px;
        line-height: 18px;
        @media only screen and (max-width: 768px) {
            margin: 0 auto;
            margin-top: 25px;
            
        }
        
        .legal__link {
            font-family: $soft-medium;
            text-transform: none;
            line-height: 18px;
            letter-spacing: 1px;
            border: none;
            padding-right: 0;
            margin-right: 0;

            &--separator{
                margin-left: 10px;
                margin-right: 10px;
                font-size: 16px;
                color: #afa6d2;
            }

            @include breakpoint(small-only) {
                font-size: 16px;
                text-transform: uppercase;
            }
            
            a{
                color: #3B2772;
                font-family: $soft-medium;
                display: inline;
                @include breakpoint(small-only){
                    display: inline-flex;
                }
               
            }
        }
    }

    .icon-mdlz:before{
        color: #3B2772;
    }
    .footer__copyright{
        margin-top: 0;
        max-width: 210px;
        span{
            font-size: 38px;
        }
        h4{
            font-size: 16px;
            color: #3B2772;
            text-transform: none;

            @include breakpoint(small-only){
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}

