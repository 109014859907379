.form-input {
    &__container {
        position: relative;
    }

    &__input {
        width: 100%;
        border: 1px solid #3b2774;
        box-sizing: border-box;
        border-radius: 22px;
        padding: 10px 25px;
        color: #3b2774;
        font-size: 16px;
        background-color: transparent;

        &::placeholder {
            color: #3b2774;
            font-family: $soft-alt;
            font-size: 16px;
            line-height: 22px;
        }
        &:focus::placeholder {
            color:transparent;
        }

        &.notvalid {
            border: 1px solid $error-red;
            color: $error-red;
    
            &::placeholder {
                color: $error-red;
            } 
        }
    }
    
    &__label {
        position: absolute;
        top: -10px;
        bottom: 0;
        left: 28px;
        background-image: url($local-images+'/Shapes/master-paper-texture_grey.jpg');
        padding: 0 5px 0 10px;
        max-height: 20px;
        display: none;
        transition: all .2s;

        font-family: $soft-alt-regular;
        font-size: 14px;
        line-height: 22px;
        // color: $mid-dark-lil;
        color: $dark-lilac;
    }

    &__error {
        text-decoration: none;
        font-family: $soft-alt;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3em;
        color: $error-red !important;
        margin: 0 auto;
        overflow-wrap: break-word;
        text-align: center;
        display: none;

        &.active {
            display: block;
        }
        &.active-success { 
            display: block;
            padding-top:15px;
            font-size: 18px;
            color: #3C2774;
        }

        &--global {
            font-family: $soft-alt;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 5px;
            color: $error-red;
            line-height: 19px;

            padding-left: 20px;
            display: none;
        text-align: left;
        }
        &--global.show {
            display: block;
        }
    }

    &__success {
        display: none;
        font-size: 16px;
        color: #aabe24;
        padding: 10px 0;
        margin: 0 auto;
        overflow-wrap: break-word;
        text-align: center;

        &.active {
            display: block;
        }
    }

    &__select {
        cursor: pointer;
        width: 100%;
        border: 1px solid $mid-dark-lil;
        box-sizing: border-box;
        border-radius: 22px;
        padding: 8px 25px;
        color: $mid-dark-lil;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    &__dropdown {
        select {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;

            cursor: pointer;

            &::-ms-expand {
                display: none;
            }
        }

        &:after {
            // content: url($local-images + '/icons/ArrowDown.svg');
            content: '\e027';
            font-family: 'icomoon';
            font-size: 12px;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            pointer-events: none;
            color:#3b2774;
        }
    }

    &__button {
        &.notvalid {
            opacity: 0.7;
            pointer-events: none;
        }
    }
}
.notvalid {
    .form-input {
        &__input {
            border: 1px solid $error-red;
    
            &::placeholder {
                color: $error-red;
            }
        }
    
        &__error {
            display: inline-block;
            line-height: 19px;
            width: 100%;
            text-align: left;
        }
    }
}
.form {
    
    &-line {
        display: flex;

        .form-container {
            margin-bottom: 0;
            max-width: 100%;
        }
    }

    &-mobile {

        @include breakpoint(small-only) {
            flex-wrap: wrap;
        
            .small-input-container {
                width: 100%;
                max-width: none !important;

                &.first {
                    margin-bottom: 22px;
                }
            }
        }
    }

    &-container {
        margin: 22px auto;
        position: relative;

        display: flex;
        justify-content: space-between;

        /* Internet Explorer 10 / 11 */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            justify-content: flex-start;
        }

        @media only screen and (max-width: 640px) { 
            // display: block;
        }

        &.centered {
            justify-content: center;
        }

        .input-container {
            width: 100%;
        }

        .small-input-container {
            max-width: 135px;

            @include breakpoint(small) {
                max-width: 165px;
            }
            @include breakpoint(medium) {
                max-width: 200px;
            }
        }
        .small-input-container {
            max-width: 200px;
            width: 100%;

            @include breakpoint(medium) {
                // min-width: 240px;
            }
            @include breakpoint(large) {
                min-width: 200px;
            }

            &.larger {
                width: 100%;
                min-width: 100%;
            }

            &.desktop {
                @media only screen and (min-width: 768px) {  
                    position: absolute;
                    top: -30px;
                    left: 108px;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        .btn__container.notvalid {
            opacity: 0.7;
            pointer-events: none;
        }
    }
}
.form-input__container.active {
    label {
        display: block;
        z-index: 2;
    }
}
.ui-autocomplete {
    z-index: 99;
}

.form-button {
    text-align: center;

    p {
        padding-top: 30px;
        color: #3b2774;
        font-size: 20px;
        line-height: 25px;

        span {
            @media only screen and (min-width: 768px) { 
                display: block;
            }
        }
    }
}

/* Custom Radio button */

/*
	From : https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
*/
/* The container */
.custom-inputs {
	display: block;
	position: relative;
	padding-left: 35px;
	// margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
    user-select: none;
    font-size: 20px;
    color: #3b2774;
}

.custom-inputs.notvalid .checkmark,
.custom-inputs.notvalid .radiobtn {
    border: 1px solid $error-red;
}

/* Hide the browser's default checkbox */
.custom-inputs input {
	position: absolute;
	opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 28px;
	width: 28px;
    //background-color: #eee;
    border: 1px solid #3C2774;
    border-radius: 9px;
}

.radiobtn {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
    //background-color: #eee;
    border: 1px solid #3C2774;
    border-radius: 50%;
}

.radiobtn:after {
	content: "";
	position: absolute;
	display: none;
}

.custom-inputs .radiobtn:after {
	top: 5px;
	left: 5px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #FFFFFF;
}

/* On mouse-over, add a grey background color */
/*.custom-inputs:hover input ~ .checkmark,  .custom-inputs:hover input ~ .radiobtn{
	background-color:transparent;
}*/

/* When the checkbox is checked, add a blue background */
/*.custom-inputs input:checked ~ .checkmark {
	background-color: #2196F3;
}*/

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom-inputs input:checked ~ .checkmark:after, .custom-inputs input:checked ~ .radiobtn:after {
	display: block;
}

.custom-inputs input:checked ~ .radiobtn { 
    background: #3b2774;
}

/* Style the checkmark/indicator */
.custom-inputs .checkmark:after {
	left: 4px;
	top: 6px;
    content: "\e019";
    font-family: icomoon;
    font-size: 16px;
    color: #3C2774
    /*
    width: 5px;
    height: 10px;
    border: solid #3C2774;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);*/
}

.custom-inputs .text-content {
    display: block;
    padding-left: 20px;
    font-size: 20px;
    line-height: 25px;
    
    a {
        text-decoration: underline;
        font-family: $good-head-pro;
    }
}

select.form-input__input {
    padding-right: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#error-product-code{
	color: #b32525 !important;
    padding-top: 2px;
}

@import "../../05_STATE/form/form";