.dynamic-button{
    display: flex;
    justify-content: center;
    max-width: 500px;
    flex-wrap: nowrap;
    cursor: pointer;

    &__child{
        cursor: pointer;
&__first {
        width: 140px !important;
        border: none;
        outline: none;
        background-color: $white;
        padding: 2px 15px;
        position: relative;
        border-radius: 8px;
        letter-spacing: 0.7px;
        color: $btn-milka-puruple;
        font-family: $good-head-pro;
        font-size: 20px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 2px solid $btn-milka-puruple;
        min-width: 160px;
        height: 48px;
        border-radius: 50px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-right: 15px;
    }

    &__second {
        width: 140px !important;
        border: none;
        outline: none;
        background-color: $dark-lilac;
        padding: 2px 15px;
        position: relative;
        border-radius: 8px;
        letter-spacing: 0.7px;
        color: $white !important;
        font-family: $good-head-pro;
        font-size: 20px;
        cursor: pointer;
        min-width: 160px;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 2px solid $dark-lilac;
        height: 48px;
        border-radius: 50px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-right: 15px;

   
      
    }
    &-active::after {
        width: 140px !important;
        content: "";
        position: relative;
        bottom: 9%;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $dark-lilac;
    }

    @include breakpoint(x-small){
        &__first{
            font-size:16px ;
        }
        &__second{
            font-size:16px ;
        }
    }

    }
    
}