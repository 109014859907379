.buzzer-desktop {
    background: rgba(125, 107, 170, 0.2);
    border-radius: 10px;
    padding: 20px 60px;
    margin: 50px 5% 90px;

    span {
        font-size: 25px;
        line-height: 38px;

        &.separator {
            display: block;
            height: 2px;
            width: 5%;
            max-width: 20px;
            background-color: $dark-lilac;
            margin: 0 30px;
        }

        &.checkmark[data-checked] {
            height: 29px;
            width: 29px;
            background: #FFFFFF;
            border: 1.5px solid $dark-lilac;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            margin-right: 25px;
            transition: 0s;
        }

        &.checkmark[data-checked="true"] {
            background: #FFFFFF;
            border: 10px solid $dark-lilac;
        }
    }

    div {
        display: flex;
        align-items: center;
    }

    img {
        width: auto;
        height: 85px;
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        &.date {
            width: 40%;
        }
        &.match {
            width: 60%;
            justify-content: center;

            div {
                width: 45%;
                justify-content: space-between;
            }
        }
    }

    div {
        &.date {
            width: 40%;
        }
    }

    .match-container {
        flex-wrap: wrap;
        padding: 10px 0;

        hr.separator {
            flex-basis: 100%;
            opacity: 0.5;
            border: 0.5px solid #8D7EB5;
            margin: 5px 0;
        }

        .team-container {
            justify-content: center;
        }

        .team-home {
            justify-content: flex-end;

            span {
                text-align: right;
            }
        }
        
        .team-away {
            justify-content: flex-start;
            span {
                text-align: left;
            }
        }

        &.out-stock {
            .match {
                pointer-events: none;
                opacity: 0.5;
            }

            div.date {
                p {
                    background-color: white;
                    padding: 20px 10px;
                    border-radius: 13px;
                    width: 80%;
                    font-weight: bold;
                    color: #3B2772;
                    margin-left: 0;
                }
            }
        }
    }
}