.cardblog{
    background:#d9d6ea;
    width: 500px;
    height: 195px;
    //opacity: 0.3;
    border-radius:0 24px 24px 0;
    margin-top:12px;
    &__content{
        margin-left:20px;
        padding:20px;
        &__titre{
            color:#3B2774;
            font-size:26px;
            font-style:normal;
            font-weight:400;
            font-family:Good Headline Pro;   
        }
        &__logotext{
            position:absolute;
            margin-top:10px;
            &__logo{
                display:block;
            }
            &__texte{
                margin:30px;
                //padding-top:15px;
                color:#3B2774;
                font-weight:bold;
                font-size:24px;
                position:relative;
                float:right;
                margin-top:-32px;
            }
        }
    }
    &__btnAnnuler{
        position:relative;
        border:3px solid #AFA6D2;
        border-radius:50px;
        background:#fff;
        font-weight:400;
        margin-top:60px;
        height:50px;
        width:150px;
        &__logoA{
            position:relative;
            margin:0;
           z-index:20;
           width:14px;
        }
        &__text{
            margin-left:10px;
            font-family: 'Good Headline Pro';
            color:#7D69AC;
            font-size:24px;
        }
       
    }
}