.container {
  @include breakpoint(large) {
    max-width: 1170px;
    margin: 0 auto;
    padding: 15px 0;
  }
}
.choix_milka_tuc{
  margin-top: 8px !important;
}
.intropage_menu {
  overflow-x: scroll;
  -ms-overflow-style: none;

  @include breakpoint(medium) {
    overflow: hidden;
  }
}
.intropage_nav {
  @media only screen and (max-width: 976px) {
    width: 150% !important;
  }

  @media only screen and (max-width: 768px) {
    width: 275% !important;
  }
}
.page {
  &__menu {
    // overflow-x: scroll;
    // -ms-overflow-style: none;
    margin: 0 0 30px 0;
    clear: both;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    font-family: $recoleta;
    color: $dark-lilac;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    padding-bottom: 15px;
    text-align: center;
    width: 100%;

    @include breakpoint(start-medium-tablet) {
      font-size: 60px;
      line-height: 65px;
    }

    * {
      font: inherit;
    }

    &--centered {
      font-family: $recoleta;
      color: $dark-lilac;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      padding-bottom: 15px;
      text-align: center;
      width: 100%;

      @include breakpoint(medium) {
        font-size: 60px;
        line-height: 65px;
        text-align: center;
      }
    }

    &.centered {
      text-align: center;
    }
    &.left {
      text-align: left;
    }

    &--small {
      font-family: $recoleta;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.4375px;
      color: $dark-lilac;

      @include breakpoint(medium) {
        font-size: 40px;
        line-height: 46px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 15px;
    width: 100%;
    font-family: $recoleta;
    color: $dark-lilac;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    text-align: center;
    width: 100%;

    @include breakpoint(medium) {
      font-size: 40px;
      line-height: 46px;
    }
    > * {
      font-family: $recoleta;
      color: $dark-lilac;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 28px;
      text-align: center;
      width: 100%;

      @include breakpoint(medium) {
        font-size: 40px;
        line-height: 46px;
      }
    }

    &-left {
      > * {
        text-align: left;
      }
    }

    .left {
      > * {
        text-align: left;
      }
    }

    &.center {
      text-align: center;
    }
  }

  &__intro {
    margin-bottom: 15px;
    width: 100%;
    > * {
      font-family: $recoleta;
      color: $dark-lilac;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      width: 98%;
      margin: 0 auto;

      @include breakpoint(medium) {
        font-size: 25px;
        line-height: 31px;
        width: auto;
      }
    }
    @include breakpoint(medium) {
      margin-bottom: 50px;
    }

    &.white {
      > * {
        color: $white;
      }
    }

    &.left {
      > * {
        @include breakpoint(medium) {
          text-align: left;
        }
      }
    }

    a {
      text-decoration: underline;
      color: $dark-lilac;

      &:hover {
        text-decoration: none;
      }
    }

    p.small {
      font-size: 18px;
      @include breakpoint(medium) {
        font-size: 20px;
      }
    }

    &--small {
      > * {
        font-family: $recoleta;
        color: $dark-lilac;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        width: 98%;
        margin: 0 auto;

        @include breakpoint(medium) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      a {
        text-decoration: underline;
        color: $dark-lilac;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__label {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;

    p {
      font-family: $recoleta;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 29px;
      text-align: center;
      color: $dark-lilac;
    }
  }

  &__paragraph {
    font-family: $soft-alt;
    color: $dark-lilac;
    font-style: normal;
    font-weight: normal;
    // padding: 0 20px;

    @include breakpoint(medium) {
      font-size: 20px;
      line-height: 26px;
    }

    a {
      text-decoration: underline;
      color: $dark-lilac;

      &:hover {
        text-decoration: none;
      }
    }

    &-m {
      max-width: 980px;
    }

    &.centered {
      text-align: center;
    }
    &.left {
      @include breakpoint(medium) {
        text-align: left;
      }
    }
  }

  &__image {
    img {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }

  &__lastparagraph {
    margin-top: 20px;
  }

  &__enumeration {
    > * {
      font-family: $recoleta;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      color: $dark-lilac;

      @include breakpoint(medium) {
        font-size: 30px;
        line-height: 32px;
      }
    }

    &.left {
      > * {
        text-align: left;
      }
    }
  }
}
.white__bg {
  background-image: url($local-images + "/Shapes/master-paper-texture_white.jpg");
  background-repeat: repeat;
}
.grey__bg {
  background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
  background-repeat: repeat;
}
.grey-bg {
  &--deco {
    main {
      display: block;
      background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
      background-size: cover;
      @include breakpoint(large) {
        background-image: url($local-images + "/Shapes/grey-bg-deco--long.jpg");
      }
    }
  }

  &--deco2 {
    main {
      display: block;
      background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
      background-size: cover;
      @include breakpoint(large) {
        background-image: url($local-images + "/Shapes/grey-bg-deco2.jpg");
      }
    }
  }

  &--deco3 {
    main {
      display: block;
      background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
      background-size: cover;
      @include breakpoint(large) {
        background-image: url($local-images + "/Shapes/grey-bg-deco3.jpg");
      }
    }
  }

  &--deco4 {
    main {
      display: block;
      background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
      background-size: cover;
      @include breakpoint(large) {
        background-image: url($local-images + "/Shapes/grey-bg-deco4.jpg");
      }
    }
  }

  &--deco5 {
    main {
      display: block;
      background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
      background-size: cover;
      @include breakpoint(large) {
        background-image: url($local-images + "/Shapes/grey-bg-deco5.jpg");
      }
    }
  }

  &--deco6 {
    main {
      display: block;
      background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
      background-size: cover;
      @include breakpoint(large) {
        background-image: url($local-images + "/Shapes/grey-bg-deco6.jpg");
      }
    }
  }

  &--deco7 {
    main {
      display: block;
      background-image: url($local-images + "/Shapes/master-paper-texture_grey.jpg");
      background-size: cover;
      @include breakpoint(large) {
        background-image: url($local-images + "/Shapes/grey-bg-deco7.jpg");
      }
    }
  }
}
a.linkbutton {
  text-decoration: none;

  div {
    margin: 20px auto 0 0;
  }
}

.pink {
  color: #e50071;
}
.navigation {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 10px 0;

  border-top: 4px double;
  border-bottom: 4px double;
  border-color: $white;
  padding: 20px;

  @media (min-width: 768px) {
    min-width: 600px;
    // max-width: 700px;
    margin: 10px auto 0px;
  }

  @media (min-width: 1024px) {
    max-width: 1212px;
    // padding: 20px 200px;
  }

  li {
    padding-right: 55px;

    @media only screen and (max-width: 640px) {
      padding-right: 20px;
    }

    &:last-of-type {
      padding-right: 0;
    }

    a {
      font-family: $soft-medium;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      color: $white;

      &:hover {
        font-weight: bold !important;
      }

      &.active {
        font-weight: bold !important;
      }
    }
  }
}
.navigation-newsletter {
  color: $white;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (min-width: 1200px) {
    max-width: 1175px;
    margin: 0 auto;
  }
  a {
    padding-right: 18px;
    font-size: 20px;
  }
}
.newsletter-lilac {
  color: #3b2772;
}
.none {
  display: none;
}
.noneImportant {
  display: none !important;
}
.heading_dark-lilac {
  border-color: #3b2772;
  li {
    a {
      color: #3b2772;
    }
  }
}
.s-content-title {
  // padding: 125px 12px;
  padding: 0px 25px;
  @media only screen and (min-width: 1200px) {
    padding: 0px 100px;
  }
  text-align: center;
  font-family: $recoleta-medium;
  color: $white;
  padding-top: 30px;
  h1 {
    padding-bottom: 30px;
    font-family: $recoleta-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 65px;
    text-align: center;
    word-break: break-word;

    /* iPad Portrait */
    @media only screen and (max-width: 768px) {
      font-size: 40px;
      line-height: 40px;
    }

    span {
      font-family: $recoleta-medium;

      @media only screen and (min-width: 768px) {
        display: block;
      }
    }
  }

  .subheading {
    padding-bottom: 35px;
    font-family: "Intervogue Soft Alt Regular";
    font-size: 40px;
    line-height: 40px;
    span {
      font-family: "Intervogue Soft Alt Regular";
      font-weight: normal;
    }

    a {
      font-family: "Intervogue Soft Alt Regular";
      font-weight: normal;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    strong {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    /* iPad Portrait */
    @media only screen and (max-width: 768px) {
      font-size: 28px;
      line-height: 28px;
    }
  }

  p {
    padding-bottom: 35px;
    font-size: 30px;
    line-height: 25px;

    span {
      display: block;
      font-weight: bold;
    }
  }
}
.noticeHolding {
  p {
    font-size: 16px !important;
  }

  div {
    @media only screen and (max-width: 425px) {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
.s-content-gift {
  padding: 0 15px;
  background: url($local-images + "/Shapes/alpine-bacground.png");
  background-size: cover;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: end;
  justify-content: center;

  @media only screen and (max-width: 640px) {
    background: none;
  }

  @media only screen and (min-width: 1200px) {
    height: 580px;
  }

  @media only screen and (min-width: 1600px) {
    align-items: flex-end;
    background: url($local-images + "/Shapes/alpine-bacground-large.png")
      no-repeat bottom;
  }

  picture {
    img {
      display: inline;
      width: auto;
      padding-bottom: 10px;

      @media only screen and (max-width: 640px) {
        padding-bottom: 0;
        display: block;
      }
    }
  }

  &.secondary-bg {
    margin-top: 50px;
    background: url($local-images + "/Shapes/alpine-background-2.png");
    background-size: cover;

    @media only screen and (min-width: 1200px) {
      height: 515px;
    }

    @media only screen and (max-width: 640px) {
      background: none;
    }

    @media only screen and (min-width: 1600px) {
      align-items: flex-end;
      background: url($local-images + "/Shapes/alpine-background-2-large.png")
        no-repeat bottom;
    }

    picture {
      img {
        @media only screen and (max-width: 640px) {
          width: 100%;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  &.third-bg {
    margin-top: 50px;
    background: url($local-images + "/Pages/bg-pattern/bg-paper-white.jpg");
    background-repeat: repeat-x;
    background-position: bottom;

    @media only screen and (max-width: 640px) {
      background: none;
    }

    picture {
      img {
        @media only screen and (max-width: 640px) {
          width: 100%;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}

.s-content-gift--container {
  margin-top: 80px;
  position: relative;

  .s-content-gift {
    padding-top: 50px;

    @media only screen and (min-width: 1200px) {
      padding-top: 70px;
    }
  }
}

.s-content-prize {
  @media only screen and (max-width: 450px) {
    padding: 0px;
  }
  .s-content-title {
    padding-top: 2px;
    h1 {
      color: #3b2774;
    }
  }
  padding: 33px 15px 53px 15px;
  text-align: center;

  @media only screen and (min-width: 1200px) {
    width: 1024px;
    margin: 0 auto;
    display: flex;
    text-align: left;
  }

  // &.first-item {
  //     margin-top: 50px;
  // }

  img {
    max-width: 100%;
    height: auto;
    padding-bottom: 30px;
  }

  h2 {
    padding-bottom: 25px;
    font-size: 28px;
    line-height: 32px;
    color: #3b2774;

    span {
      font-family: inherit;

      @media only screen and (min-width: 768px) {
        display: block;
      }
    }
  }
  .subtitle-prize {
    font-family: "Intervogue Soft Alt Regular";
    font-weight: 700;
    display: inline;
    @media only screen and (min-width: 768px) {
      text-align: left;
    }
  }
  .s-content-title {
    p {
      font-family: "Recoleta-Medium;";
    }
  }
  p {
    font-family: "Recoleta-Medium";
    padding-bottom: 25px;
    font-size: 20px;
    line-height: 24px;
    color: #3b2774;
  }

  .s-content-prize__item-image {
    padding: 0 10px;

    @media only screen and (min-width: 1200px) {
      padding: 0;
      width: 540px;
    }

    .prizehouse {
      @media only screen and (min-width: 1200px) {
        padding-top: 20px;
        padding-right: 50px;
      }
    }

    .prizecar {
      @media only screen and (min-width: 1200px) {
        margin-left: 70px;
      }
    }
    .tropheEdeka {
      width: unset !important;
      height: unset !important;
      display: block;
      margin: auto;
      @media only screen and (min-width: 1200px) {
        margin-left: 100px;
        margin-top: 50px;
      }
    }
    .samsungKaufland {
      width: unset !important;
      height: unset !important;
      display: block;
      margin: auto;
      @media only screen and (min-width: 1200px) {
        margin-left: 152px;
        margin-top: 50px;
      }
    }
    .prizevoucher {
      @media only screen and (min-width: 1200px) {
        margin-top: -22px;
        margin-left: -24px;
      }
    }
    .prizeMagasin {
      @media only screen and (min-width: 1200px) {
        width: 382px;
        margin-left: 100px;
      }
    }
    .prizeSport {
      width: unset;
      height: unset;
      margin: auto;
      @media only screen and (min-width: 1200px) {
        width: 246px;
        height: 363px;
        margin-left: 150px;
      }
    }
    .prizechocolate {
      width: 315px;
      margin-left: 25px;
      @media only screen and (min-width: 1200px) {
        margin-left: 29px;
      }
    }

    .prizetafel {
      @media only screen and (min-width: 1200px) {
        margin-left: -48px;
      }
    }

    &.priszevoucher,
    &.prizecash {
      @media only screen and (min-width: 1200px) {
        text-align: right;
      }
    }
  }

  .s-content-prize__item-text {
    padding: 0 10px;

    @media only screen and (min-width: 1200px) {
      padding: 0;
      width: 505px;
    }

    ul {
      li {
        padding-bottom: 5px;
        font-family: $soft-alt;
        font-size: 20px;
        line-height: 24px;
        color: #3b2774;

        span {
          @media only screen and (min-width: 1200px) {
            display: block;
            padding-left: 18px;
          }
        }
      }
    }

    &.prizecar-text {
      @media only screen and (min-width: 1200px) {
        padding-top: 40px;
        width: 508px;
      }
    }

    &.priszevoucher {
      @media only screen and (min-width: 1200px) {
        padding-top: 88px;
      }
    }

    &.prizechocolate {
      @media only screen and (min-width: 1200px) {
        padding-top: 50px;
        margin-left: -45px;
      }
    }

    &.prizecash {
      @media only screen and (min-width: 1200px) {
        padding-top: 130px;
      }
    }

    &.prizetafel {
      @media only screen and (min-width: 1200px) {
        padding-top: 140px;
      }
    }
  }

  &.position-reverse {
    flex-direction: row-reverse;
  }
}

.s-content-prize__participation {
  padding: 80px 20px;
  text-align: center;
  color: #3b2774;

  @media only screen and (min-width: 768px) {
    padding: 150px 20px 170px 20px;
  }

  h3 {
    padding-bottom: 30px;
    font-family: $recoleta-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    text-align: center;

    @media only screen and (min-width: 768px) {
      font-size: 40px;
      line-height: 45px;

      span {
        display: block;
        font-family: $recoleta-medium;
      }
    }
  }

  a {
    padding-bottom: 50px;
  }

  a,
  .btn__container {
    margin-bottom: 50px;

    @media only screen and (min-width: 768px) {
      width: 379px;
      display: block;
      margin: 0 auto;
    }
  }

  p {
    @media only screen and (min-width: 1200px) {
      width: 890px;
      margin: 0 auto;
    }
  }
}

.s-content-end {
  padding: 0 20px;
  margin: 90px auto;

  h1 {
    padding-bottom: 30px;
    font-family: $recoleta-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    color: $white;
    margin-top: 50px;

    @include breakpoint(medium) {
      width: 50%;
      margin: 0 auto;
      margin-top: 50px;
    }
  }

  h1 {
    padding-bottom: 30px;
    font-family: $recoleta-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    color: #3b2774;
  }

  a {
    text-align: center;

    .btn__container {
      font-weight: 800 !important;
      @media only screen and (min-width: 768px) {
        width: 379px;
      }
    }
  }
}

.s-content-product {
  @media only screen and (max-width: 450px) {
    padding: 100px 15px 0px 0px;
  }
  //btn

  @media only screen and (min-width: 768px) {
    .btn__text-mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .btn__text-desktop {
      display: none;
    }
  }
  padding: 100px 15px 0px 0px;
  text-align: center;
  .s-content-title {
    p {
      font-size: 25px;
      padding-bottom: 0px;
      @media only screen and (min-width: 1200px) {
        width: 1060px;
        margin: 0 auto;
      }
    }

    &.product {
      padding-top: 120px;

      p {
        @media only screen and (min-width: 768px) {
          span {
            display: block;
          }
        }
      }
    }
  }
  .s-content-title {
    @media only screen and (min-width: 768px) {
      .space {
        padding: 0;
        font-size: 25px !important;
        line-height: 32px;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    padding-top: 2%;
    margin-bottom: 25px;
  }

  a,
  .btn__container {
    margin-top: 76px;
    @media only screen and (min-width: 768px) {
      display: inline-block;
      margin-left: 30px;
    }
  }
  @media only screen and (min-width: 1025px) {
    .btn__container {
      padding: 0px;
    }
  }
  &.home {
    padding-top: 100px;
  }

  .image-caption {
    color: #3b2774;
    padding-bottom: 50px;
  }

  p {
    color: #3b2774;
    @media only screen and (min-width: 1024px) {
      width: 820px;
      margin: 0 auto;
    }
  }

  &.newsletter {
    padding: 40px 15px 80px 15px;
  }

  &.newsletter-lose {
    padding: 0 15px 30px 15px;
  }
  .heading_dark-lilac {
    color: #3b2774;
  }
}

.step_heading {
  padding-top: 5%;
  color: white;
  text-align: center;
}

.main-subheading {
  padding-bottom: 30px;
  font-family: $recoleta-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  color: #3b2774;

  @media only screen and (max-width: 640px) {
    font-size: 22px;
    line-height: 24px;
  }
}

.s-content-button {
  padding: 0 20px;
  text-align: center;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    width: 400px;
  }
}

.announcement-banner__container.content {
  a {
    font-family: $recol;
  }
}

.sm-title {
  .s-content-title h1 {
    font-size: 40px;
    line-height: 44px;
  }

  &.marge-top {
    padding-top: 50px;
  }
}

.medium-copy .s-content-copy {
  .s-content-title {
    h1 {
      color: #3b2772;
    }
  }

  .s-content-copy__item {
    @media only screen and (min-width: 768px) {
      width: 100%;
    }
  }
}

.s-content-copy {
  padding-bottom: 100px;
  clear: both;
  padding-top: 5px;
  .s-content-copy__item {
    padding-bottom: 25px;

    @media only screen and (min-width: 768px) {
      width: 590px;
      margin: 0 auto;
    }

    a {
      text-decoration: underline;
      font-family: $good-head-pro;
    }

    p {
      padding-bottom: 15px;
      font-size: 20px;
      line-height: 25px;
    }

    .headings {
      padding-bottom: 15px;
      font-size: 22px;
      font-weight: bold;
    }

    .small {
      padding-top: 20px;
      font-size: 16px;
      line-height: 20px;
    }

    ul,
    ol {
      li {
        padding-bottom: 10px;
        font-size: 20px;
        line-height: 25px;
        span {
          font-weight: 700;
        }
      }
    }

    ol {
      list-style: none;
      counter-reset: counterlist;

      li {
        position: relative;
        counter-increment: counterlist;
        padding-left: 30px;

        @media only screen and (max-width: 320px) {
          width: 275px !important;
        }

        @media only screen and (max-width: 640px) {
          width: 330px;
        }

        @media only screen and (min-width: 768px) {
          width: 550px;
        }

        &::before {
          content: counter(counterlist) ". ";
          font-weight: bold;
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
    }

    ul {
      list-style: none;

      li {
        position: relative;
        padding-left: 30px;

        @media only screen and (max-width: 320px) {
          width: 275px !important;
        }

        @media only screen and (max-width: 640px) {
          width: 330px;
        }

        @media only screen and (min-width: 768px) {
          width: 550px;
        }

        &:not(.no-list)::before {
          content: "●";
          position: absolute;
          left: 0;
        }
      }
    }

    &.footer {
      text-align: center;

      .headings {
        font-family: $recoleta-medium;
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 36px;
      }
    }

    em {
      text-decoration: underline;
    }
  }
}

.content-list-wrapper {
  text-align: left;

  @media only screen and (min-width: 1200px) {
    width: 598px;
  }

  .title-list {
    font-family: $recoleta-medium;
  }

  .content-list {
    display: flex;
    padding-bottom: 10px;

    @media only screen and (max-width: 1024px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    img {
      max-width: none;
    }

    .content-list__text {
      padding: 8px 0 0 25px;
      font-size: 20px;
      line-height: 24px;
      color: #3b2774;

      &.first {
        margin-top: -6px;
      }

      &.last {
        margin-top: -12px;
      }
    }
  }

  a {
    display: inline !important;
    margin: 0 !important;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p.small {
    padding-top: 50px;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #3b2774;

    @media only screen and (max-width: 640px) {
      padding-left: 60px !important;
    }

    @media only screen and (max-width: 1024px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media only screen and (min-width: 1200px) {
      padding-left: 50px;
    }
  }

  .participation-mobile {
    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
}

.s-content-partication {
  .active {
    border-bottom: none;
  }
  /* background-image: url($local-images + "/Pages/bg-pattern/bg-paper-white.jpg"); */
  padding: 0 20px;
  overflow: hidden;
  @media only screen and (min-width: 1200px) {
    margin: 0px auto 0px auto;
    width: 80%;
  }

  p:not(.s-content-partication__heading) {
    padding-bottom: 20px;
    font-size: 20px;
    color: #3b2774;
  }

  h2 {
    padding: 60px 0 0 0;
    font-family: $recoleta-medium;
    font-size: 40px;
    line-height: 44px;
    color: #3b2774;
    text-align: center;
    word-break: break-word;

    @media only screen and (max-width: 640px) {
      padding: 60px 0 10px 0;
    }
    @media only screen and (min-width: 654px) {
      font-size: 30px;
    }
    span {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      @media only screen and (min-width: 1200px) {
        display: block;
      }
    }
  }
  h4 {
    font-size: 25px;
    padding-left: 7%;
    color: #3b2774;
  }
  .form-button {
    margin-top: 50px;
    button {
      @media only screen and (max-width: 640px) {
        width: 100%;
      }
    }
  }
  .btn__container {
    background-color: #d61924;
  }

  .s-content-partication__heading {
    font-family: $recoleta-medium;
    font-size: 30px;
    line-height: 32px;
    color: #3b2774;
    text-align: end;

    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }

  input[type="file"] {
    text-indent: -1;
    visibility: hidden;
    position: absolute;
  }

  .custom-file-btn {
    position: relative;
  }

  .marge-bottom {
    margin-bottom: 70px;

    @media only screen and (max-width: 640px) {
      margin-bottom: 50px;
    }
  }

  .custom-inputs {
    @media only screen and (min-width: 768px) {
      margin-left: 18px;
    }
  }

  .space-right {
    @media only screen and (min-width: 768px) {
      padding-right: 20px;
    }
  }

  .form-line:first-of-type {
    @media only screen and (min-width: 769px) and (max-width: 1200px) {
      margin-top: 50px;
    }
  }

  .form-mobile:first-of-type .input-container {
    @media only screen and (max-width: 640px) {
      padding-bottom: 22px;
    }
  }

  /*.grid-6-m:first-of-type {
        @media only screen and (max-width: 640px) { 
            padding-bottom: 50px;
        }
    }*/

  .results {
    @media only screen and (max-width: 640px) {
      padding-bottom: 20px;
    }
  }

  .grecaptcha-badge {
    @media only screen and (max-width: 640px) {
      margin-left: 45px;
    }
  }

  &.title {
    p {
      text-align: center;
      line-height: 25px;
    }

    img {
      display: block;
      margin: 70px auto 40px auto;
      text-align: center;
      max-width: 100%;
      height: auto;
    }

    .btn__container {
      @media only screen and (min-width: 768px) {
        width: 379px;
      }
    }
  }

  .form-container.text-center {
    display: block;
    text-align: center;
    .small-input_box {
      margin-bottom: 25px;
      display: flex;
      justify-content: center;
    }
    .half-width {
      width: 390px;
    }
  }
  .text-down {
    padding-top: 15%;
    margin: 0px;
  }

  .form-container.results {
    margin: 5px auto 22px auto;
  }
  .chooseTeam {
    margin: 22px auto;
    width: 100%;

    @media screen and (min-width: 781px) {
    }
  }
}
.noPadding {
  padding: 0px;
  @media only screen and (min-width: 768px) {
    padding: 0 200px 70px 200px;
    padding-bottom: 0px;
  }
}
.spaceForm {
  padding-bottom: 75px;
  hr{
    color:#F0E9EA;
    @media only screen and (min-width: 768px) {
      width: 587px
    }
  }
}
.content-purchase {
  .radioBtn-text {
    display: inline;
  }
  p {
    text-align: center;
    line-height: 26px;

    span {
      @media only screen and (min-width: 1000px) {
        display: block;
      }
    }
  }

  .content-purchase__inputs {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    label:first-of-type {
      padding-right: 30px;
    }

    &.notvalid {
      .radiobtn {
        border: 1px solid $error-red;
      }
    }
  }
}

.content-confirmation {
  p,
  h3 {
    color: #3b2772;
  }
  .s-content-title {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 30px;
    @media only screen and (min-width: 1024px) {
      padding-left: 138px;
      padding-right: 170px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      font-family: "Recoleta Medium";
      @media only screen and (min-width: 768px) {
        font-size: 30px;
      }
    }
    p {
      font-size: 20px;
      @media only screen and (min-width: 768px) {
        font-size: 25px;
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 50px;
      }
      span {
        font-family: "Recoleta Medium";
        font-size: 25px;
        line-height: 32px;
        color: #3b2774;

        @media only screen and (max-width: 640px) {
          display: inline;
        }
      }
    }
  }
  .space-shop {
    padding-top: 61px;
    @media only screen and (min-width: 768px) {
      padding-top: 80px;
    }
  }

  .content-confirmation__video {
    padding: 0 20px 30px 20px;
    text-align: center;

    #video_lottery {
      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      @media only screen and (min-width: 1200px) {
        width: 900px;
      }
    }
  }

  .s-content-product {
    p {
      font-family: $recoleta;
      font-size: 22px;
      line-height: 24px;
      color: #3b2774;

      span {
        font-family: $recoleta;
        @media only screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    img {
      padding-top: 50px;
    }
  }

  .content-banner .btn__container {
    width: auto;
  }

  .btn__container {
    width: 379px;
  }

  .announcement-banner__container {
    position: relative;
    margin-top: -8px;
  }
}

.content-gifts {
  text-align: center;
}

.gift-popup {
  .review-overlay__container {
    @media only screen and (max-width: 640px) {
      width: 100%; // remove important  f=
    }

    @media only screen and (max-width: 768px) {
      width: 750px;
      padding: 0;
    }
  }

  .review-overlay__foot {
    @media only screen and (max-width: 768px) {
      position: static;
    }
  }

  .review-overlay__title {
    padding: 25px 0;
    border-bottom: 1px solid rgba(126, 105, 172, 0.2);
  }

  .review-overlay__sub-container {
    padding: 0;
    border-bottom: none;
  }

  #js-select-gift.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  input[type="button"] {
    border: none;
    outline: none;
    font-family: "Intervogue Soft Alt Medium";
    font-size: 20px;
    color: #3b2774;
  }
}

.content-winner {
  .s-content-title {
    p {
      font-family: $recoleta;
      font-size: 25px;
      line-height: 30px;

      @media only screen and (min-width: 1200px) {
        width: 970px;
        margin: 0 auto;
      }
    }
  }

  .content-winner__gifts {
    padding: 50px 20px 150px 20px;

    @media only screen and (min-width: 1024px) {
      width: 980px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .content-winner__gifts-selected {
    padding: 0 20px 120px 20px;
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .content-winner__gifts-item {
    text-align: center;

    @media only screen and (max-width: 768px) {
      padding-bottom: 80px;
    }

    img {
      max-width: 100%;
      height: auto;
      padding-bottom: 20px;

      &.first {
        @media only screen and (min-width: 1024px) {
          padding-top: 100px;
        }
      }
    }

    .link-btn {
      display: block;
      font-size: 20px;
      line-height: 24px;
      color: #3b2774;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      &.first {
        @media only screen and (min-width: 1024px) {
          padding-top: 80px;
        }
      }
    }
  }
}

.content-holding {
  .swiper-slide {
    @include breakpoint(large) {
      flex-direction: row;
    }
  }
  margin-top: 40px;
  .text-white {
    .s-content-title {
      h1,
      p {
        color: $white;
      }
    }
  }
  .noPadding {
    padding: 0px;
  }
  .strapline {
    padding-left: 5%;
    padding-right: 5%;
  }
  .s-content-title {
    h1 {
      @include breakpoint(medium) {
        padding-top: 30px;
      }
    }
    .heading_dark-lilac {
      color: $heading_dark-lilac;
    }

    p:not(.subheading) {
      font-size: 20px;
      line-height: 24px;

      @media only screen and (min-width: 1200px) {
        width: 940px;
        margin: 0 auto;
      }

      strong {
        font-family: inherit;
        font-size: inherit;
      }
    }
  }
  .notice {
    font-size: 16px;
    padding: 80px 12px;
    @media only screen and (min-width: 500px) {
      padding: 125px 12px;
    }
  }

  picture {
    img {
      @media only screen and (max-width: 640px) {
        width: 100%;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .content-slider-wrapper {
    height: auto;

    .swiper-container {
      @media only screen and (max-width: 425px) {
        height: auto;
      }
    }
  }

  .swiper-wrapper {
    @media only screen and (max-width: 1160px) and (min-width: 800px) {
      height: 370px;
    }
  }
  @media only screen and (max-width: 425px) {
    .swiper-container {
      height: auto;
    }

    .swiper-wrapper {
      background-color: #d61924;
      height: auto;
    }
    .content-slider {
      height: 300px;
    }

    .slider__background {
      height: 400px;
      background-image: none;

      div {
        height: 100%;
      }

      picture {
        display: block;
        height: 100%;
      }

      img {
        // position: unset;
        width: 100%;
        height: auto;
      }
    }

    .swiper-slide {
      height: 730px;
      @media only screen and (min-width: 425px) {
        height: 800px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .slider__background {
      height: 500px;
      background-image: none !important;

      div {
        height: 100%;
      }

      picture {
        display: block;
        height: 100%;
      }

      img {
        // position: unset;
        width: 100%;
        height: auto;
      }
    }
  }
}

.cookie-content {
  .s-content-copy .s-content-copy__item ul li {
    width: 100%;
  }
}

.content-banner {
  h3,
  p {
    font-family: $recoleta;
    font-style: normal;
    font-weight: 600;
    color: #3b2774;
    text-align: center;
  }

  h3 {
    padding: 20px 0;
    font-size: 48px;
    line-height: 32px;

    @media only screen and (max-width: 640px) {
      font-size: 40px;
    }
  }

  p {
    font-size: 30px;
    line-height: 32px;

    @media only screen and (max-width: 640px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .shop-card__content.banner-one {
    padding-top: 25px;
  }

  .shop-card__content.newsletter-one {
    padding-top: 44px;
  }

  .shop-card__content.newsletter-two {
    padding-top: 50px;
  }

  .shop-card__container {
    &.banner-content-one {
      @media only screen and (max-width: 640px) {
        height: 360px;
      }
    }

    &.banner-content-two {
      @media only screen and (max-width: 640px) {
        height: 254px;
      }

      .shop-card__footer img {
        @media only screen and (max-width: 640px) {
          padding: 20px 0;
        }
      }
    }

    &.newsletter-content {
      @media only screen and (max-width: 640px) {
        height: 456px;
      }
    }

    /* iPad landscape */
    @media only screen and (min-width: 769px) and (max-width: 1200px) {
      height: 425px;
    }

    .shop-card__footer {
      img {
        @media only screen and (max-width: 640px) {
          width: 300px;
        }
      }
    }
  }

  .shop-card__content.newsletter-one {
    img {
      @media only screen and (max-width: 640px) {
        width: 180px;
      }
    }
  }

  &.mosaique-row {
    @media only screen and (min-width: 1200px) {
      height: 410px;
    }
  }

  .btn__container.banner-two {
    margin-top: -20px;
  }
}

.content-faq {
  position: relative;
  padding-top: 100px;
  padding: 25px;
  &.s-content-copy {
    @media only screen and (max-width: 640px) {
      margin: 0 auto 80px auto;
    }

    &.marge-bottom {
      @media only screen and (max-width: 640px) {
        margin: 0 auto;
      }
    }
  }

  .content-faq-deco {
    display: none;

    @media only screen and (min-width: 1200px) {
      display: block;
    }

    img {
      @media only screen and (min-width: 1200px) {
        position: absolute;
      }
    }

    .one {
      @media only screen and (min-width: 1200px) {
        top: 7%;
        left: 12%;
      }

      @media only screen and (min-width: 1600px) {
        left: 20%;
      }
    }

    .two {
      @media only screen and (min-width: 1200px) {
        top: 25%;
        right: 12%;
      }

      @media only screen and (min-width: 1600px) {
        right: 20%;
      }
    }

    .three {
      @media only screen and (min-width: 1200px) {
        top: 45%;
        left: 12%;
      }

      @media only screen and (min-width: 1600px) {
        left: 20%;
      }
    }
    .four {
      @media only screen and (min-width: 1200px) {
        top: 65%;
        right: 12%;
      }

      @media only screen and (min-width: 1600px) {
        right: 20%;
      }
    }
    .five {
      @media only screen and (min-width: 1200px) {
        top: 83%;
        left: 12%;
      }

      @media only screen and (min-width: 1600px) {
        left: 20%;
      }
    }
  }
  .footer {
    padding-top: 50px;
  }
}

.content-link {
  padding: 0 20px 20px 20px;

  .btn__container {
    background-color: #d61924;
    span {
      font-family: "Intervogue Soft Alt Regular";
      font-size: 20px;
    }

    @media only screen and (min-width: 768px) {
      width: 460px;
      margin: 0 auto;
    }
  }
}

.content-prize {
  .s-content-title {
    p {
      font-family: $recoleta;
      font-weight: normal;
      font-size: 25px;
      line-height: 30px;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      br {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.content-gift-footer {
  padding: 60px 0;
  background-image: url($local-images + "/Pages/bg-pattern/bg-paper-white.jpg");
  background-repeat: repeat;

  @media only screen and (max-width: 640px) {
    padding: 60px 10px;
  }

  .btn__container {
    width: 379px;

    @media only screen and (max-width: 640px) {
      width: 379px;
    }

    span {
      text-transform: uppercase;
    }
  }

  .content-faq {
    @media only screen and (max-width: 640px) {
      padding-top: 50px;
    }
  }

  &.hr {
    .content-faq {
      @media only screen and (min-width: 768px) {
        width: 85%;
        margin: 0 auto;
        border-top: 1px solid #3b2774;
      }

      @media only screen and (min-width: 1200px) {
        width: 70%;
      }

      @media only screen and (min-width: 1600px) {
        width: 50%;
      }
    }

    .s-content-copy__item {
      @media only screen and (min-width: 768px) {
        padding-top: 80px;
      }
    }
  }
}

.content-home {
  .btn__text {
    font-size: 18px;
  }
  #home-terms {
    font-family: "Intervogue Alt Soft";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    padding-bottom: 60px;
    padding-left: 25px;
    padding-right: 25px;
    a {
      text-decoration: underline;
      font-weight: 900;
    }
    @media only screen and (min-width: 1024px) {
      padding-bottom: 100px;
      font-size: 23px;
    }
  }
  .title-mitmachen {
    .heading_dark-lilac {
      font-size: 30px;
      font-family: "Recoleta Medium";
    }
  }
  #edeka-terms {
    font-family: "Intervogue Alt Soft";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    padding-bottom: 60px;
    padding-left: 25px;
    padding-right: 25px;
    a {
      font-family: "Intervogue Alt Soft";
      text-decoration: underline;
      color: #3b2772;
      font-weight: 900;
    }
    @media only screen and (min-width: 1024px) {
      padding-bottom: 50px;
      font-size: 20px;
    }
  }
  h1 {
    font-family: "Recoleta Medium";
    color: $white;

    @include breakpoint(medium) {
      padding-top: 30px;
    }
  }
  p {
    color: $white;
    font-size: 18px;
    // padding-right:261px;
    //     padding-left:241px;
    //     font-size:24px;
  }
  @media only screen and (min-width: 768px) {
    line-height: 46px;
    padding: 5px;
    padding-top: 0px;
    .subheading {
      padding-right: 244px;
      padding-left: 257px;
    }
  }
  .s-content-title {
    padding: 5px;
    p {
      line-height: 35px;
      padding-bottom: 0px;
      font-size: 24px;
      font-family: "Recoleta Regular";
    }
    @media only screen and (min-width: 768px) {
      .space {
        padding-right: 90px;
        padding-left: 119px;
      }
    }
  }
  .s-content-title .subheading {
    font-family: "Recoleta Regular";
    font-weight: 400;
    font-size: 24px;
    line-height: 46px;
    padding-top: 0px;
    padding-bottom: 40px;

    @media only screen and (min-width: 768px) {
      font-size: 40px;
    }

    span {
      font-family: inherit;

      @media only screen and (max-width: 768px) {
        display: inline;
      }
    }
  }
  .btn__container {
    background-color: #7d69ac;
    margin-bottom: 2%;
    @media only screen and (min-width: 768px) {
      width: 379px;
      margin: 0 auto;
    }
  }
  .content-home__container {
    text-align: center;
    width: 100%;
    padding-top: 5%;
    @media only screen and (min-width: 952px) {
      padding: 80px 15px;
      width: 50%;
      margin: 0 auto;
    }

    p {
      padding-bottom: 30px;
      padding-left: 2%;
      font-family: "Intervogue Soft Alt";
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #3b2774;
      text-align: left;
      @media only screen and(min-width:952px) {
        font-size: 16px;
        line-height: 32px;
        padding: 5%;
      }

      span {
        font-family: inherit;
      }

      a {
        font-family: $recoleta;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      &.small {
        padding-top: 35px;
        font-size: 20px;
      }
    }
    h2 {
      color: #3b2774;
      font-size: 23px;
      text-align: start;
      padding-left: 20px;
      @media only screen and (min-width: 768px) {
        font-size: 32px;
      }
    }
    .btn__container {
      background-color: $white;
      color: #3b2772;
      margin-bottom: 2%;
      width: 379px;
      @media only screen and (min-width: 768px) {
        margin: 0 auto;
      }
    }
    .text-white {
      color: white;
    }
  }
  .step_container {
    background: unset;
    // padding-bottom:8%;
    .step-title {
      color: $white;
    }
    .step_heading {
      padding-top: 0px;
    }
    .btn__container {
      margin-top: 77px;
      margin-bottom: 81px;
    }
  }
  .notice {
    font-size: 16px;
    padding: 15px 2px 2px 2px;
    line-height: 20px;
    text-align: center;
    @media only screen and (min-width: 500px) {
      padding: 52px 87px 32px;
    }
    .heading_dark-lilac {
      color: #3b2774;
      line-height: 30px;
      font-size: 16px;
    }
  }
  .noticeHome {
    @media only screen and (min-width: 768px) {
      .headingSpace {
        padding: 0 100px;
      }
    }
  }
  .s-content-copy {
    .s-content-copy__item {
      p {
        color: #3b2774;
      }
    }
  }
  .content-home__image {
    // width:36%;
    // position: absolute;
    // right: 0;
    // top:13%;
    z-index: 0;
    position: relative;
    height: 119%;
    width: 100%;
    padding-top: 5%;
    img {
      height: 100%;
      width: 100%;
    }
    @media only screen and (min-width: 952px) {
      position: unset;
      right: unset;
      top: unset;
      padding-top: unset;
      img {
        top: -124px;
        z-index: 0;
        position: relative;
        height: 119%;
        width: 100%;
      }
    }
    @media only screen and (min-width: 1440px) {
      img {
        height: 882px;
      }
    }
  }

  picture {
    img {
      @media only screen and (max-width: 640px) {
        width: 100%;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .content-home__wrapper {
    background: url($local-images + "/Pages/bg-pattern/bg-red.png");
    @media only screen and (min-width: 652px) {
      display: flex;
      max-height: 600px;
    }
    @media only screen and (min-width: 1440px) {
      max-height: 760px;
      display: flex;
    }
    .milka_on_stadium {
      top: unset;
      height: 600px;
    }
  }

  .content-gift-footer {
    background: url($local-images + "/Pages/bg-pattern/bg-paper-lilac.jpg");
    background-repeat: no-repeat;
    p {
      color: $white;
    }
    @media only screen and (max-width: 640px) {
      padding: 0 10px 60px 10px;

      .content-faq {
        padding-top: 0;
      }
    }
  }

  .participation {
    @media only screen and (min-width: 768px) {
      .s-content-title {
        h1 {
          padding-right: 200px;
          padding-left: 200px;
          font-family: "Recoleta Medium";
        }
        .subheading {
          padding-left: 199px;
          padding-right: 205px;
          text-align: center;
        }
        p {
          font-family: "Recoleta Medium";
        }
      }
    }
  }
  // //for the intro page
  // .spaceTile-intropage{
  //     padding-bottom:113px;
  // }
  .intro-fcClub {
    text-align: left;
    font-size: 20px;
    line-height: 26px;
    padding-left: 25px;
    @media only screen and(min-width:1024px) {
      padding-left: 153px;
      // padding-top: 122px;
      padding-bottom: 122px;
    }
    @media only screen and (min-width: 1250px) {
      padding-top: 109px;
    }
    h2 {
      text-align: left;
      font-size: 40px;
      color: #3b2772;
      padding-bottom: 25px;
      font-family: "Recoleta Medium";
    }
    p {
      color: #3b2772;
      font-size: 20px;
      padding-bottom: 25px;
    }

    ul {
      color: #3b2772;
      list-style-type: disc;
      padding-bottom: 45px;
      list-style-position: inherit;
    }
    .btn__text {
      font-size: 15px;
    }
    // .btn__container{
    //     margin-left: unset;
    // }
  }
  .sticky-icon {
    position: fixed;
    top: 41%;
    right: 0;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.content-gift-wrapper {
  @media only screen and (min-width: 1200px) {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  picture {
    @media only screen and (min-width: 1200px) {
      padding: 90px 40px 0 0;
    }

    img {
      width: 100%;

      @media only screen and (max-width: 1024px) {
        display: block;
        margin: 0 auto;
        padding-top: 30px;
      }
    }
  }
}

.content-upload-receipt {
  text-align: center;

  .btn__container {
    @media only screen and (min-width: 768px) {
      width: 379px;
      margin: 0 auto;
    }
  }

  .btn--primary {
    > span {
      text-transform: uppercase;

      @media only screen and (min-width: 768px) {
        font-size: 22px;
      }
    }
  }
  .text-flex {
    display: flex;
    justify-content: center;
  }
  .radioBtn {
    margin-left: 10%;
  }
}

h2.heading {
  span {
    font-family: inherit;
  }

  @media only screen and (min-width: 768px) {
    font-size: 40px;
    line-height: 60px;

    span {
      display: block;
    }
  }
}

.para-description {
  padding: 15px 20px 70px 20px !important;
  font-family: $recoleta-medium;
  font-size: 30px;
  line-height: 35px;
  text-align: center;

  span {
    font-family: inherit;
  }

  @media only screen and (min-width: 768px) {
    font-size: 30px !important;
    line-height: 45px;

    span {
      display: block;
    }
  }
}

.content-participation-footer {
  .content-gift-footer {
    background-image: none;
  }
}
.content-slider-wrapper-small {
  display: flex !important;
  @media only screen and (min-width: 1690px) {
    height: 603px !important;
  }
}

.content-slider-wrapper {
  position: relative;
  // padding:20px 15px;
  height: 800px;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (min-width: 400px) and (max-width: 685px) {
    height: 850px;
  }
  @media (min-width: 682px) and (max-width: 766px) {
    height: 900px;
  }
  @media (min-width: 767px) and (max-width: 799px) {
    height: 900px;
  }
  @media only screen and (min-width: 800px) {
    display: block;
    flex-wrap: unset;
    flex-direction: unset;
    height: 371px;
    background-color: #d61924;
    width: 100%;
  }
  @media only screen and (min-width: 1200px) {
    height: 603px;
  }
  @media only screen and (min-width: 1690px) {
    height: 717px;
  }

  .swiper-container {
    height: 800px;
    @media (min-width: 400px) and (max-width: 685px) {
      height: 850px;
    }
    @media (min-width: 682px) and (max-width: 766px) {
      height: 900px;
    }
    @media (min-width: 767px) and (max-width: 799px) {
      height: 900px;
    }
    @media only screen and (min-width: 800px) {
      overflow: unset;
      height: unset;
    }

    /* Internet Explorer 10 / 11 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      overflow: visible;
    }
  }

  .swiper-pagination {
    /* Internet Explorer 10 / 11 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
      left: 135px;
      bottom: -50px;
    }
  }

  .swiper-slide.swiper-slide-active {
    z-index: 999;
    display: flex;
    flex-flow: row wrap;
    @media only screen and (min-width: 1200px) {
      display: unset;
      flex-flow: unset;
    }
  }

  //For fading effect
  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
    z-index: 0 !important;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid $white;
    background: transparent;
    opacity: 1;
    /* Internet Explorer 10 / 11 */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin: 0 4px;
    }
  }

  .swiper-pagination-bullet-active {
    background: $white;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    height: 25px;
    width: 150px;
    bottom: 50%;
    left: calc(50% - 75px);
    @media only screen and (min-width: 370px) {
      bottom: 55%;
    }
    @media only screen and (min-width: 425px) {
      bottom: 510px;
    }
    @media only screen and (min-width: 768px) {
      bottom: 700px;
    }
    @media only screen and (min-width: 769px) {
      bottom: 80px;
      left: auto;
      right: calc(15% - 75px);
    }
    @media (min-width: 600px) and (max-width: 1199px) {
      bottom: 250px;
    }
    @media only screen and (min-width: 1440px) {
      bottom: 80px;
    }
    @media only screen and (min-width: 1700px) {
      bottom: 100px;
    }
  }

  .swiper-button-next {
    width: 52px;
    height: 52px;

    @media only screen and (max-width: 799px) {
      display: none;
    }
    @media (min-width: 800px) and (max-width: 1199px) {
      top: auto;
      bottom: 250px;
      right: 35%;
    }
    @media only screen and (min-width: 1200px) {
      top: 83%;
      left: 75%;
    }
    @media only screen and (min-width: 1400px) {
      left: 65%;
    }
    &:after {
      content: "";
      background: url($local-images + "/Carousel Arrow_right-white.png");
      width: inherit;
      height: inherit;
    }
  }

  .swiper-button-prev {
    width: 52px;
    height: 52px;

    @media only screen and (max-width: 799px) {
      display: none;
    }
    @media (min-width: 800px) and (max-width: 1199px) {
      top: auto;
      bottom: 250px;
      left: 50%;
    }
    @media only screen and (min-width: 1200px) {
      top: 83%;
      left: 70%;
    }
    @media only screen and (min-width: 1400px) {
      left: 60%;
    }
    &:after {
      content: "";
      background: url($local-images + "/Carousel Arrow_left-white.png");
      width: inherit;
      height: inherit;
    }
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;
  }

  .content-slider {
    position: relative;
    background-color: #d61924;
    width: 100%;
    order: 1;
    @media (min-width: 800px) and (max-width: 1199px) {
      order: 2;
      flex: 0 0 48%;
    }
    @media only screen and (min-width: 1199px) {
      order: 2;
      flex: 0 0 60%;
    }
    @media only screen and (min-width: 1200px) {
      position: absolute;
      right: 0;
      top: 10%;
      order: 2;
      width: 38%;
      padding-left: 33px;
      background-color: unset;
    }
    @media (min-width: 1200px) and (max-width: 1310px) {
      padding-left: 47px;
    }
    @media only screen and(min-width:1440px) {
      padding-right: 75px;
      left: 61%;
      // top:12%;
      float: none;
    }
    @media only screen and (min-width: 1800px) {
      width: 35%;
    }
    @media only screen and (min-width: 2175px) {
      // width:45%;
      top: 20%;
    }
  }

  .content-slider__header {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 75px;
    padding-bottom: 10px;
    font-family: $recoleta;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    color: $white;
    text-align: center;
    @media only screen and(min-width:800px) {
      text-align: left;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 40px;
      line-height: 42px;
      flex-direction: column;
      display: block;
      padding-left: 0px;
      text-align: left;
    }
    @media only screen and (min-width: 1965px) {
      padding-left: 0px;
      float: left;
    }
    .slider_text-bold {
      font-family: $recoleta;
      font-weight: 900;
    }

    strong {
      font-family: $recoleta-medium;
    }
  }

  .content-slider__description {
    font-family: "Intervogue Soft Alt Regular";
    padding-bottom: 100px;
    font-size: 20px;
    line-height: 20px;
    color: $white;
    text-align: center;
    padding-right: 5px;
    padding-left: 5px;
    @media only screen and(min-width:800px) {
      text-align: left;
    }
    @media only screen and (min-width: 1200px) {
      padding-bottom: 25px;
      font-size: 20px;
      line-height: 28px;
      float: left;
      padding-right: 0px;
      padding-left: 0px;
      text-align: left;
    }
    @media only screen and (min-width: 1965px) {
      padding-left: 0px;
      float: left;
    }
    .slider_text-bold {
      font-family: "Intervogue Soft Alt Regular";
      font-weight: 700;
    }

    p {
      font-size: 20px;
    }
  }

  .content-slider__image {
    text-align: center;
    // img {
    //     max-width: 100%;
    //     height: auto;
    //     @media only screen and (min-width:800px){
    //         height:100%;
    //         order:-1;
    //     }
    // }
    .slider__image {
      position: absolute;
      z-index: 1;
      bottom: 0;
      @media (min-width: 482px) {
        top: 26%;
      }
      @media (min-width: 550px) and (max-width: 752px) {
        top: 24%;
      }
      @media only screen and (min-width: 800px) {
        position: inherit;
        order: -1;
        height: 370px;
        background-color: #d61924;
      }
      @media only screen and (min-width: 1200px) {
        height: 100%;
        min-height: 603px;
      }
    }

    // @media only screen and (min-width: 1200px) {
    //     // position: absolute;
    //     // top: 0;
    // }

    // &.slider-1 {
    //     @media only screen and (min-width: 1200px) {
    //         right: 0;
    //     }
    // }

    // &.slider-2, &.slider-3, &.slider-4, &.slider-6 {
    //     @media only screen and (min-width: 1200px) {
    //         left: 38px;
    //     }
    // }
  }

  .content-slider__btn {
    display: inline-block;
    @media only screen and (max-width: 1199px) {
      width: 280px;
      margin: 0 auto;
      display: block;
    }

    @media only screen and (min-width: 768px) {
      .btn__container {
        width: 379px;
        margin-left: 0;
      }
    }
    @media only screen and (min-width: 1200px) {
      display: block;
      clear: both;
      float: right;
      margin-right: 85px;
    }
  }
  .slider__background-intro {
    @media only screen and (min-width: 1200px) {
      flex: unset !important;
    }
  }
  .slider__background {
    background-image: url($local-images + "/pages/bg-pattern/Master Paper Texture_White.png");
    order: 2;
    flex: 1 0 100%;
    @media (min-width: 800px) and(max-width:1199px) {
      flex: 0 0 50%;
    }
    @media only screen and (min-width: 1199px) {
      order: 1;
      flex: 0 0 40%;
      background-color: #cf1316;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 850px;
    }
    @media only screen and (min-width: 1690px) {
      max-width: 1058px;
    }
    @media (min-width: 1200px) and (max-width: 1310px) {
      max-width: 788px;
    }
  }
  // .content-slider-wrapper :nth-child(1) { order: 1; }
  // .content-slider-wrapper :nth-child(2) { order: 2; }
}
.noSpace {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.step_container {
  margin-bottom: 2%;
  // background: url($local-images+'/MilkaFC_Background.png');
  background-repeat: no-repeat;
  .btn__container {
    width: 75%;
    background-color: #cf1316;
    margin-top: 2%;
    margin-bottom: 2%;
    @media only screen and (min-width: 768px) {
      width: 379px;
    }
  }
  p {
    font-size: 20px;
    line-height: 30px;
    color: $white;
    font-family: "Intervogue Soft Medium";
  }
  .text--wheat {
    color: wheat;
    font-family: "Intervogue Soft Alt Bold";
  }
}

h2 {
  font-family: "Intervogue Soft";
  font-weight: 500;
  font-style: normal;
  size: 25px;
  line-height: 35px;
  text-align: center;
  color: $white;
}
.footer__social-container {
  height: 350px;
  background-color: #d61924;

  &.page {
    .footer__social {
      max-width: none;
      margin: 30px auto 0;

      @include breakpoint(medium) {
        margin: 80px auto 0;
      }
      .icon-tiktok::before {
        content: url('/resources/imagesBundesliga/icons/tiktok.png');
        width: 40px; /* Adjust width as needed */
        height: 20px; /* Adjust height as needed */
        display: inline-block; /* Adjust display property as needed */
      }
    }

    a {
      font-size: 50px;
      margin: 0 12.5px;

      @include breakpoint(medium) {
        margin: 0 25px;
      }

      span::before {
        color: #3b2772 !important;
      }

      span.icon-play::before {
        color: #afa6d2 !important;
      }
    }

    h2 {
      color: white;
    }
  }
}
.btn__container {
  background-color: #d61924;
}
.footer__social-text-container {
  padding-top: 60px;
  @media only screen and (max-width: 768px) {
    padding-left: 5px;
    padding-right: 4px;
  }

  h2 {
    font-size: 20px;

    @media only screen and (min-width: 768px) {
      font-size: 25px;
    }
  }
}

.bg-white {
  background-image: url($local-images + "/pages/bg-pattern/Master Paper Texture_White.png");
  .heading_dark-lilac {
    color: $dark-lilac;
  }
  .text--wheat {
    color: #7d69ac;
    font-weight: 700;
  }
}
.streetLbl {
  width: 47%;
  @media only screen and (min-width: 768px) {
    width: 64%;
  }
}
.numberLbl {
  width: 53%;
  @media only screen and (min-width: 768px) {
    width: 36%;
  }
}
.streetLbl_info {
  width: 100%;
  display: inline-block;
  @media only screen and (min-width: 768px) {
    width: 58%;
  }
  @media only screen and (max-width: 768px) {
    // margin-top: 20px;
  }
}
.numberLbl_info {
  width: 100%;
  display: inline-block;
  padding-left: 0px !important;
  @media only screen and (min-width: 768px) {
    margin-left: 6%;
    width: 36%;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.cityLbl_info {
  width: 100%;
  display: inline-block;
  @media only screen and (min-width: 768px) {
    width: 58%;
        margin-left: 6%;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
}
.zipLbl_info {
  width: 100%;
  display: inline-block;
  padding-left: 0px !important;
  @media only screen and (min-width: 768px) {
    width: 36%;
  }
  @media only screen and (max-width: 768px) {
    // margin-top: 20px;
  }
}

.zipLbl {
  width: 45%;
}
.cityLbl {
  width: 52%;
}
.content-participation-footer {
  color: $white;
  p {
    color: $white;
  }
}
.bg-lilac {
  padding-top: 60px;
  background-image: url($local-images + "/pages/bg-pattern/Master Paper Texture_White.png");
  .form-input__input {
    background-color: rgba(76, 53, 131, 0.1);
  }
}
.full-width {
  width: 100%;
}
.full-width-milka {
  @media only screen and (min-width: 1024px) {
    width: 352px;
  }
}
.team-picture {
  width: 100%;
  margin: auto;
  margin-top: 25px;
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
  }
}
.team-picture2 {
  width: 100px;
  height: 125px;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.display-col {
  display: flex;
  flex-direction: column;
}
.space-bottom {
  padding-bottom: 45px;
}

.choose_team {
  .space-bottom {
    padding-bottom: 25px;
  }
}
.btn-bottom {
  margin-top: 80px;
}
.activeMilka {
  border-bottom: 1px solid white;
}

.heading__image {
  display: flex;
  justify-content: center;
  .logo__image1 {
    padding: 11px;
    padding-top: 0px;
    @media only screen and (min-width: 768px) {
      padding-right: 52px;
    }
  }
}
.s-content-copy__item {
  color: #3b2772;
  ul li::before {
    content: "\2022";
    color: #3b2772;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
.thankYou {
  @media only screen and (min-width: 1024px) {
    .s-content-title {
      padding-left: 105px;
      padding-right: 128px;
    }
  }

  p {
    font-size: 25px;
    line-height: 32px;
  }
  .image {
    margin: auto;
    height: 202px;
    width: 275px;
    @media only screen and(min-width:768px) {
      width: 445px;
      height: 441px;
      padding-bottom: 117px;
    }
  }
  .imageLose {
    margin: auto;
    width: 400px;
    height: 467px;
    padding-bottom: 117px;
  }
  h3,
  h1 {
    color: #3b2772;
  }
  .confetti {
    position: absolute;
    bottom: 852px;
  }
}
.prize {
  p {
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    font-size: 20px;
    color: #3b2772;
    line-height: 26px;
  }
  @media only screen and (min-width: 1200px) {
    .subheading,
    p {
      font-family: "Recoleta Medium";
      font-size: 40px;
      padding-left: 300px;
      padding-right: 300px;
    }
  }
  @media only screen and (min-width: 1800px) {
    .subheading,
    p {
      font-family: "Recoleta Medium";
      font-size: 40px;
      padding-left: 440px;
      padding-right: 460px;
    }
  }
  .btn__container {
    width: 324px;
    margin: 0 auto;
    background-color: #cd1823;
    margin-top: 50px;
    @media only screen and(min-width:768px) {
      width: 379px;
    }
  }
  .s-content-title {
    padding: 0px;
  }
  .notice_prize {
    font-family: "Intervogue Soft Alt Regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: center;
    padding-bottom: 100px;
  }
}
.btn_thankyou {
  .btn__container {
    width: 179px;
    height: 46px;
    background-color: #cf1316;
    padding: 15px 25px 15px 25px;
  }
}
.game_title {
  .heading-hr-space {
    @media only screen and (min-width: 1024px) {
      padding-bottom: 100px;
    }
  }
  .s-content-title {
    color: #3b2772;
    font-family: "Recoleta";
    line-height: 46px;
    h1 {
      font-size: 60px;
      line-height: 65px;
      padding-top: 37px;
      @media only screen and(min-width:768px) {
        padding-top: 22px;
      }
    }
    p {
      font-size: 40px;
      line-height: 46px;
      font-family: "Recoleta Medium";
      @media only screen and (min-width: 1024px) {
        padding: 0px 120px;
      }
    }
    h3 {
      font-family: "Recoleta Medium";
    }

    .game-smallText {
      font-size: 30px;
      font-family: "Recoleta Medium";
      padding-top: 50px;
      @media only screen and (min-width: 1024px) {
        padding-left: 262px;
        padding-right: 261px;
        padding-top: 100px;
      }
    }
  }
}
.game_title {
  .s-content-title {
    h1 {
      @media only screen and(max-width:768px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
    p {
      @media only screen and(max-width:768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
.iframe_game {
  height: 250px;
  width: 100%;
  @media only screen and(min-width:900px) {
    height: 653px;
    width: 100%;
  }
}
.football-game {
  padding-top: 70px;
}
.game-space {
  display: block;
  justify-content: center;
  padding-bottom: 100px;
  @media only screen and( min-width:768px) {
    display: flex;
    padding-top: 100px;
    padding-bottom: 150px;
  }
  img {
    height: 100%;
    width: 100%;
  }
  .ball {
    width: 143px;
    height: 143px;
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      width: 290px;
      height: 290px;
      margin-left: 75px;
      margin-right: 75px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
  @keyframes wobble {
    0% {
      transform: none;
    }
    15% {
      transform: translate3d(0, -20%, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
      transform: translate3d(0, 15%, 0) rotate3d(0, 0, 1, -3deg);
    }
    45% {
      transform: translate3d(0, -10%, 0) rotate3d(0, 0, 1, 3deg);
    }
    60% {
      transform: translate3d(0, 5%, 0) rotate3d(0, 0, 1, -2deg);
    }
    75% {
      -webkit-transform: translate3d(0, -5%, 0) rotate3d(0, 0, 1, -1deg);
      -ms-transform: translate3d(0, -5%, 0) rotate3d(0, 0, 1, -1deg);
      transform: translate3d(0, -5%, 0) rotate3d(0, 0, 1, -1deg);
    }
    100% {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
  .ball:hover {
    animation-duration: 1s;
    // animation-fill-mode: both;
    animation-name: wobble;
    transform: translateY(0px);
  }
  .s-content-title {
    color: #3b2772;
  }
}
// .page__menu{
//     .heading_dark-lilac{
//         color:red;
//     }
// }
.notice {
  font-size: 16px;
  padding: 52px 2px 2px 2px;
  text-align: center;
  margin-bottom: 30px;
  clear: both;
  @media only screen and (min-width: 1200px) {
    padding: 50px 281px 50px 265px;
  }
  .heading_dark-lilac {
    color: $white;
    font-size: 16px;
  }
}
.spaceTeamButtom {
  padding-bottom: 70px;
}

.account-layout {
  .account{
    @media only screen and (min-width: 481px){
      padding-top: 60px;
    }
  }
}
.account {
  background-color: #7D69AC;
  .error-msg {
    color: red;
    display: flex;
    justify-content: center;
  }
  .errorMsg {
    color: red!important;
    margin-bottom: 10px;
    display: block;
  }
  .s-content-title {
    @media only screen and (max-width: 782px) {
      padding: 0;
    }
  }
  h1,
  h2,
  p {
    color: $white;
  }
  h2.title {
    font-family: $recoleta-medium;
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    letter-spacing: 1px;
  }
  p{
    font-family:$recoleta;
    font-weight: 400;
    font-size: 22px;
    line-height: 33.5px;
  }
  .required-field{
    font-size: 16px;
    text-align: right;
    font-family: "Intervogue Soft Alt Regular";
    padding-right: 11px;
    padding-bottom: 8px;
  }
  .btn__container {
    background: #3B2772;
    font-family: Intervogue Soft;
    font-weight: 400;
    letter-spacing: 4px;
    width: 304px;
    @include breakpoint(small-only) {
      width: 95%;
  }
    &:before,&:hover{
      background: #3B2772;
    }
  }
  .half-width {
    @media only screen and(min-width:1335px) {
      padding-left: 200px;
      padding-right: 76px;
    }
  }

  .space-left {
    @media only screen and(min-width:1200px) {
      padding-right: 200px;
      padding-left: 76px;
    }
  }
  .linkPassword {
    border-bottom: 0px solid #3b2772;
    width: 158px;
    text-decoration: underline;
    text-align: center;
    margin: 0 auto;
  }
  .space-bottom {
    padding-top: 20px;
    .team-picture {
      width: 60%;
      margin-top: 88px;
    }
  }
  .title {
    padding-bottom: 5px;
  }
  .subtitle {
    padding-bottom: 20px;
  }
  .form-button {
    padding-bottom: 35px;
  }
  .input-container {
    @media only screen and (max-width: 782px) {
      padding-top: 22px;
    }
    @include breakpoint(small-only) {
      width: 95%!important;
      margin: 0 auto;
    }
    .form-input__input{
      background-color: $white;
    }
    .space-bottom {
      padding-top: 10px;
    }
    .justify {
      text-align: justify;
      font-size: 13.5px;
    }
  }
  .team-picture {
    margin-top: 21px;
  }
  .linkPassword {
    a{
      color: $white;
    }
    padding-bottom: 42px;
    padding-top: 15px;
    /* padding-left: 12px; */
  }
  .noPadding {
    padding-bottom: 0px;
    text-align: center;
  }
  @media only screen and (min-width: 782px) {
    .space-bottom {
      padding-top: 50px;
    }
    .title {
      padding-bottom: 15px;
    }
    .subtitle {
      padding-bottom: 30px;
    }
    .form-button {
      padding-bottom: 40px;
    }
    .input-container {
      padding-top: 22px;
    }
    .linkPassword {
      padding-bottom: 27px;
      padding-top: 15px;
    }
    .noPadding {
      padding-bottom: 0px;
    }
  }
  .space-title {
    padding-top: 43px;
    @media only screen and (max-width: 1335px) {
      padding-top: 20px;
    }
    .s-content-title {
      .fancenterCopy {
        font-size: 20px !important;
      }
    }
  }
  .space-title-jersey {
    padding-top: 43px;
    margin-bottom: 50px !important;
    @media only screen and (max-width: 1335px) {
      padding-top: 20px;
    }
    .s-content-title {
      .fancenterCopy {
        font-size: 20px !important;
      }
    }
  }
  .picture_register {
    display: none;
    @media only screen and (min-width: 481px) {
      display: inline-block;
    }
  }
  .picture_register-mobile {
    @media only screen and (min-width: 481px) {
      display: none;
    }
  }
  .input-container .choose_team {
    width: 374px;
  }
  .choose_team {
    padding-top: 70px;
    @media only screen and(min-width:676px) {
      margin: 0 auto;
    }
    .space-bottom {
      padding-top: 10px;
    }
    p {
      text-align: center;
      @media only screen and (min-width: 760px) {
        text-align: left;
      }
    }
    .heading-choose_team {
      font-weight: bold;
      color: rgba(81, 60, 121, 1);
      font-size: 19px;
      line-height: 23px;
    }
    .profile_picture {
      width: 110px;
      height: 110px;
      border-radius: 100%;
      @media only screen and (max-width: 768px) {
        margin: auto;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        @media only screen and (min-width: 760px) {
          float: left;
        }
      }
    }
    .checkbox_register {
      clear: both;
      padding-top: 40px;
      .input-container.zero_width{
        width: 100%!important;
      }
    }
  }
  .dropdown_space {
    padding-left: 5px;
    padding-right: 5px;
    .dropdown-input__container {
      @media only screen and(max-width:676px) {
        width: 85%;
        margin: 0 auto;
      }
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .choose_team {
      .zero_width {
        width: unset;
      }
    }
  }
  .form-line .form-container{
    &:last-child{
      @media only screen and (max-width:854px) {
        border-top: 1px solid #fff;
        padding-bottom: 10px;
      }
    }
  }
/*   .input-container{
    &.space-left{
      .title{
        @include breakpoint(small-only) {
          padding-top: 43px;
        }
      }
    }
  } */
  .register-button{
    .btn__container{
      width: 424px;
      @include breakpoint(small-only) {
        width: 95%;
      }
    }
  }
}
.vl {
  border-bottom: 1px solid rgba(126, 105, 172, 0.2);
}
@media only screen and (min-width: 856px) {
  .vl {
    border-left: 1px solid $white;
    height: 572px;
    margin-bottom: 50px;
  }
}
.register-layout{
 .banner__container{
   display: none;
 }
}
.register {
  background-color: #3B2772;
  h1,
  h2,
  p {
    color: $white;
  }
  .s-content-partication {
    padding: 0;
    background-color: #3B2772;
    @media only screen and (min-width: 800px) {
      padding: unset;
    }
    @media only screen and (min-width: 1200px) {
      width: 100%;
    }
    .title-seperator{
      width: 95%;
      border-color: #E5E5E5;
      @include breakpoint(large) {
        width: 90%;
      }
    }
    .title-data{
      color: $white;
      padding-bottom: 0;
      padding-top: 30px;
      font-family: "Intervogue Soft Alt Regular";
      font-weight: 700; 
      font-size: 16px;
      letter-spacing: 1px;
      @media only screen and (min-width: 768px) {
        text-transform: uppercase;
        width: 587px;
        margin: 0 auto;
      }
    }
    .spaceForm{
      padding: 0 10px;
    }
  }
  .team-selection{
    background-color: $white;
  }
  .space-title{
    padding-top:50px;
  }
  .space-title-jersey{
    padding-top:50px;
  }
  .s-content-title {
    @include breakpoint(small-only) {
      padding: 0 10px;
    }
    h1{
      font-family: "Recoleta";
      @include breakpoint(small-only) {
        font-size: 30px;
      }
    }
    p {
      font-size: 21px;
      padding-bottom: initial;
      &:first-of-type{
        padding: 0;
        padding-bottom: 35px;
        max-width: 840px;
        margin: 0 auto;
      }
    }
    h2 {
      font-size: 40px;
    }
  }
  .account{
    background-color: #3B2772;
  }
  .account.spaceTeamButtom{
    @include breakpoint(small-only) {
      padding-bottom: 4px;
    }
    & + .title-seperator{
      @media only screen and (min-width: 481px){
        display: none;
      }
    }
    .space-bottom{
      .form-input__input{
        color: white;
      }
    }
    .chooseTeam {
      background-color: #fff;
      max-width: none;
      padding-bottom: 50px;
      margin-bottom: 49px;
      @media only screen and (min-width: 481px){
        margin-bottom: 35px;
      }
      @media only screen and (min-width: 1025px){
        &.buzzer-form {
          margin: 100px 0 44px;
        }
      }
      
      h2.heading {
        padding-bottom: 50px;
        padding-top: 50px;
        color:#3b2772;
        @include breakpoint(small-only) {
          font-size: 30px;
        }
      }
  
      &--team-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        .team {
          padding: 5px;
          width: 175px;
          border-radius: 10px;
          margin: 5px;
          cursor: pointer;
  
          @include breakpoint(small-only) {
            width: 130px;
          }
  
          &-logo {
            margin-bottom: 20px;
            img {
              height: 90px;
              width: auto;
              display: block;
              margin: 0 auto;
            }
          }
          p {
            font-family: $soft-alt;
            font-size: 20px;
            line-height: 22px;
            text-align: center;
          }
        }
        .team.active,
        .team:hover {
          background-color: #c9c9c9;
          text-decoration: none;
          p {
            //color: #fff;
          }
        }
      }
      #favorite-team-error {
        color: #d93333;
        display: none;
        text-align: center;
        margin-top: 50px;
      }
      .swiper-container {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
      }
      .swiper-pagination {
        display: none;
      }
  
    }
  }
  .form_registration {
    @media only screen and (min-width: 1300px) {
      padding-top: 40px;
      padding-left: 428px;
      padding-right: 428px;
    }
  }
  .form-container {
    // padding-right: 5%;
    .input-container {
      margin: 0 auto;
      @media (min-width: 481px) and (max-width: 767px) {
        width: 478px;
      }
      @media only screen and (min-width: 768px) {
        width: 587px;
      }
      .form-input__input{
        background-color:$white;
      }
      .text-content{
        color:$white;
      }
    }
    .streetLbl {
      display: inline-block;
    }
    .numberLbl {
      display: inline-block;
      padding-left: 23px;
    }
    .cityLbl {
      width: 66%;
      display: inline-block;
      padding-left: 23px;
    }
    .zipLbl {
      width: 34%;
      display: inline-block;
    }
    .space_hr {
      padding-bottom: 24px;
    }
    .choose_team {
      @media only screen and (min-width: 676px) {
        width: 343px;
      }
      p,.heading-choose_team{
        color:$white;
      }
    }
    &.profile{
      @include breakpoint(small-only) {
        padding: 0 10px;
      }
      .choose_team{
        p{
          text-align: center;
          font-family: "Intervogue Soft Alt Regular";
          padding: 0;
        }
        .heading-choose_team{
          font-weight: normal;
        }
        p.img-type{
          font-size: 14px;
          margin-bottom: 30px;
        }
        .profile_picture{
          margin: 0 auto;
        }
        .checkbox_register{
          padding-top: 0;
        }
        .checkbox_register .form-container{
          margin-top: 5px;
          margin-bottom: 0;
          .input-container.zero_width{
            padding-top:0;
            .text-content{
              line-height: 19px;
              font-size: 13.5px;
              text-align: left;
            }
          }
        }
        .checkbox_register .desktop-only{
          letter-spacing: normal;
          font-size: 14px;
          text-transform: initial;
          font-family: "Intervogue Soft Alt Regular";
        }
        .heading__image{
          margin-top: 50px;
        }
      }
    }
    .input-container_newsletter {
      @media only screen and (min-width: 767px) {
        width: 700px;
        margin: 0 auto;
      }
    }
    .checkmark{
      border: 1px solid $white;
      background-color: $white;
    }
    .btn__container span {
      color:$white;
    }
  }
  .s-content-newsletter {
    padding: 0 10px;
    .text-content{
      color:$white;
      font-size: 14px;
      font-weight: 800;
      a {
        color:$white;
      }
    }
    .checkmark{
      border: 1px solid $white;
    }
    .btn__container {
      background-color: #7D69AC;
      margin-top: 35px;
      margin-bottom: 120px;
      text-decoration: none;
      width: 343px;;
      font-size: 18px;
      letter-spacing: 4px;
      font-weight: normal;
      &:hover{
        background-image: none;
      }
      @media only screen and (min-width: 481px){
        max-width: 523px;
      }

      @media only screen and (min-width: 598px) {
        width: 523px;
      }
      .active {
        border-bottom: 0px;
      }
    }
  }
  .form-input__input.js-event-type-in.js-event-ab{
    &::-ms-reveal {
      display: none;
    }
  }
  .s-content-partication .form-button {
    margin-top: 0;
  }
  .black-border{
    border: 0.860289px solid #000000;
    opacity: 0.2;
    width: 700px;
    @include breakpoint(small-only) {
      display: none;
    }
  }
}

#error-member {
  padding-left: 20px;
}
.error-declaration {
  padding-left: 21px;
}
.confirmation_account {
  margin: 0 auto;
  border-radius: 30px;
  background-color: #ffffff;
  margin-bottom: 41px;
  height: 950px;
  width: 90%;
  @media only screen and (min-width: 793px) {
    width: 783px;
    height: 655px;
  }
  .confirmation_account-text {
    .s-content-title {
      padding: 40px 38px;
      @include breakpoint(small-only) {
        padding: 17px 13px;
      }
    }
    p {
      color: #3b2772;
    }
    img {
      margin: 0 auto;
      display: flex;
      padding-bottom: 35px;
    }
    .btn__container {
      background-color: #cc1823;
      width: 272px;
      height: 44px;
    }
  }
}
.main-confirmation_account {
  background-image: url("/resources/imagesBundesliga/fcAccount/konfetti-vordergrund.png");
  background-color: #3B2774;
  .s-content-title h1{
    font-size: 30px;
    padding-bottom:0;
    @include breakpoint(small-only) {
      padding-bottom: 9px;
    }
    @media only screen and (min-width: 481px) {
      font-size: 45px;
      padding-top: 100px;
    }
  }
  .s-content-title p{
    font-size: 20px;
  }
  .heading__image {
    margin-bottom: 100px;
  }
  .confirmation_account{
    /* max-height: 666px; */
    height: auto;
    border-radius: 10px;
    @include breakpoint(small-only) {
      border-top: 1px solid #fff;
      padding-bottom: 17px;
    }
    @media only screen and (min-width: 481px) {
      max-height: 615px;
      margin-bottom: 181px;
      padding-bottom: 40px;
    }
  }
  .confirmation_account-text{
    .space{
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 10px;
    }
    .s-content-title{
      padding-bottom: 0;
    }
    .btn__container{
      background-color: #7D69AC;
      margin: 0 auto;
      span{
        letter-spacing: 4px;
        font-size: 20px;
      }
    }
  }
}

.game {
  .btn__container {
    background-color: #cc1823;
    margin-bottom: 41px;
  }
}
.form-container {
  .error-msg {
    color: red;
  }
}

.error-msg p {
  color: red;
}
.navigationAccount-sm-list {
  display: block !important;
  text-decoration: none !important;
  margin-top: 25px;
  margin-bottom: 25px;
}
.logout-sm {
  clear: both;
  float: right;
  margin-top: 25px !important;
  margin-right: 25px !important;
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
}
.navigationAccount-sm {
  @media only screen and (min-width: 768px) {
    display: none!important;
  }
  .left-arrow {
    float: left;
    margin-left: 8px;
  }
  .right-arrow {
    float: right;
    margin-right: 5px;
  }
}

.hide {
  left: 0 !important;
  clear: both;
}
.close {
  display: none !important;
}

#navigationAccount {
  display: none;
  background: #e5e5e5;
  height: 100%;
  width: 100%;
}

.navigationAccount {
  @media only screen and(max-width:768px) {
    display: none;
  }
  width: 327px;
  border-right: 1px solid rgba(241, 235, 234, 1);
  li {
    padding-top: 24px;
    padding-left: 121px;
    padding-right: 34px;
    a {
      font-size: 16px;
      font-family: Intervogue Soft Alt;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: rgba(164, 164, 164, 1);
    }
    .active {
      font-weight: 900;
      color: black;
    }
  }
}

.holding {
  .navigation-newsletter {
    display: none;
  }
  // .page__menu{
  //     display:none;
  // }
  .s-content-title.notice {
    padding: 50px 5%;
  }
  .page__menu {
    overflow: hidden;

    .navigation {
      min-width: 0;
    }
  }
}
//account details
.main-content.main-content--white.login-detail_layout{
  background-color: #3b2772;
  background-image: none;
}
.login-detail_layout{
  .banner__container{
    display:none;
  }
  .profile_detail {
    display: flex;
    flex-direction: column;
    background-color: #3b2772;
    @include breakpoint(medium) {
      flex-direction: row;
    }
    .left{
      float: none;
      width: initial;
      @include breakpoint(small-only) {
        
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 10px 0;
      margin: 0 10px;
      }
      @media only screen and (max-width: 768px) {
        float: none;
        width: initial;
      }
      @include breakpoint(medium) {
        border-right: 1px solid #f1ebea;
      }
      button.btnLogout.logout-lg {
        margin-top: 24px;
        margin-left: 121px;
        margin-right: 34px;
        height: 32px;
        width: 100px;
        border-radius: 6px;
        @include breakpoint(small-only) {
          display: none;
        }
        .btn__logout{
          text-transform: uppercase;
          font-size: 13px;
        }
      }
    }
    .logout-sm{
      display: none;
      @include breakpoint(small-only) {
        display: block;
      }
      .btnLogout{
        height: 30px;
        padding: 0 10px;
        border-radius: 5px;
      }
      .btn__logout{
        text-transform: uppercase;
        font-size: 12px;
        line-height: 17px;
      }
    }
    .right{
      width: 100%;
      @media only screen and (min-width: 768px) {
        float: none;
        width: 100%;
        padding-left: 50px;
      }
      .choose_team{
        min-width: 343px;
        @include breakpoint(medium) {
          width: 60%;
        }
        h2,p{
          color: $white;
          @include breakpoint(small-only) {
            text-align: center;
          }
        }
        .space-bottom{
          @include breakpoint(small-only) {
            max-width: 294px;
            margin: 0 auto;
          }
          p{
            @include breakpoint(small-only) {
              font-weight: normal;
            }
          }
        }
        .notice-regardlessTeam{
          @include breakpoint(small-only) {
            max-width: 294px;
            margin: 0 auto;
          }
        }
        .profile_picture{
          width: initial;
          height: initial;
          display: flex;
          @include breakpoint(small-only) {
            flex-direction: column-reverse;
            align-items: center;
          }
          img{
            width: 110px;
          }
          .img-type{
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 40px;
            @include breakpoint(medium) {
              margin-left: 28px;
            }
            p{
              font-size: 17px;
              padding-bottom: 10px;
              &:last-child{
              font-size: 14px;
              }
            }
            .heading-choose_team{
              font-size: 18px;
              font-weight: normal;
            }
          }
          .profile-img_container{
            display: flex;
            flex-direction: column;
            max-width: 119px;
            img#profileImage {
              width: 110px;
              height: 110px;
              object-fit: cover;
              overflow: hidden;
            }
            .btn__container{
              width: initial;
              padding: 0;
              margin: 0 auto;
            }
          }
        }
        .team-picture{
          display: flex;
          background-color: #E5E1EE;
          border-radius: 10px;
          @include breakpoint(small-only) {
            max-width: 210px;
            min-height: 203px;
            padding: 30px;
          }
          img{
            @include breakpoint(small-only) {
              max-width: 90px;
            }
          }
        }
      }
      .heading-choose_team{
        color: $white;
      }
      p.text-regardlessTeam{
        background-color: #E5E1EE;
        color: #3b2774;
      }
      #text-underTeam{
        font-weight: normal;
        padding: 8px 15px;
      }
      label.btn__container.custom-file-btn.pictureUpload {
        background: #3B2772;
        font-family: Intervogue Soft;
        font-weight: 400;
        letter-spacing: 4px;
      }
      span.desktop-only {
        letter-spacing: normal;
        font-size: 14px;
        text-transform: initial;
        font-family: "Intervogue Soft Alt Regular";
        color: #fff;
      }
      input#file_to_upload {
        visibility: hidden;
        position: absolute;
      }
      .zero_width{
        @media only screen and (min-width: 1024px){
          margin: 0;
          margin-top: 30px;
        }
        .text-content{
          color:$white;
          font-size: 12px;
          line-height: 16px;
          max-width: 294px;
          text-align: left;
        }
        .checkmark{
          background-color: $white;
          width: 28px;
          height: 28px;
          border-radius: 10px;
          &:after{
            left: 2px;
          }
        }
        .heading__image{
          margin-top: 70px;
        }

      }
    }
    .navigationAccount{
      border-right: initial;
      width: 312px;
      li,a{
        color:$white;
        font-family: 'Intervogue Soft Thin';
      }
      li{
        padding-right: initial;
        &:first-of-type{
          font-weight: 700;
        }
      }
      a{
        &.active{
          color: #fff;
          font-family: 'Intervogue Soft';
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
    .navigationAccount-sm{
      .left-arrow{
        color: #fff;
      }
    }
    #navigationAccount{
      background: none;
    }
    .navigationAccount-sm-list{
      @media only screen and (min-width: 769px){
        display: none!important;
      }
      margin-top: 0;
      li{
        padding-left: initial;
        margin-left: 25px;
        &:not(:first-child){
          padding-top:10px;
        }
        a{
          text-transform: uppercase;
          @include breakpoint(small-only) {
            font-size: 14px;
          }
        }
        &:first-of-type {
          text-transform: uppercase;
          font-weight: normal;
        }
      }
    }
    .choose_team{
      h2{
        text-align: left;
        font-family: $recoleta;
        font-size: 30px;
      }
      select.form-input__input{
        background-color: #fff;
      }
      .form-input__dropdown:after{
        font-size: 9px;
      }
    }
    /* Account deletion */
    &.account-delete {
      @media only screen and (min-width: 481px) {
        padding-bottom: 70px;
      }
      .left {
        @media only screen and (min-width: 481px) {
          padding-bottom: 30px;
        }
      }
      .right {
        @media only screen and (min-width: 481px) {
          padding-left: 50px;
          padding-right: 37px;
        }
      }
      .account-delete__wrapper {
        padding-top: 23px;
        @media only screen and (max-width: 481px) {
          padding-bottom: 40px;
        }
        
        * {
          color:#fff;
        }
        h1 {
          font-family: $gobold-bold;
          font-size: 30px;
          line-height: 45px;
          text-transform: uppercase;
          @media only screen and (max-width: 481px) {
            text-align: center;
          }
        }
        p {
          font-family: $good-head-pro;
          font-size: 20px;
          line-height: 34px;
          padding-top: 10px;
          max-width: 490px;
          @media only screen and (max-width: 481px) {
            text-align: center;
          }
        }
        .btn__container {
          background-color: #7d69ac;
          font-family: $good-head-pro;
          font-size: 20px;
          font-weight:700;
          @media only screen and (min-width: 481px) {
            margin-left: 0;
            width: 285px;
          }
        }
      }
    }
  }
}
.profile_detail {
  padding-top: 60px;
  .choose_team {
    .heading-choose_team {
      color: #513c79;
      font-size: 19px;
      font-weight: 700;
      padding-bottom: 7px;
      line-height: 22px;
    }
    p {
      padding-top: 2px;
      color: #3b2774;
    }
  }
  .btn__container {
    background-color: #cd1823;
    width: 527px;
  }
  .form-container {
    text-align: justify;
  }
  .border-line {
    clear: both;
    margin: 0 22px;
  }
  .milka_de_account {
    text-align: left;
    @media only screen and (min-width: 768px) {
      padding-left: 171px;
    }

    h1 {
      font-weight: 400;
      font-size: 54px;
      line-height: 54px;
      padding-bottom: 24px;
      color: #3b2772;
    }
    p {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      color: #3b2772;
      padding-bottom: 21px;
    }
    ol {
      list-style: revert;
      color: #3b2772;
      padding-left: 15px;
    }
  }
}
/* Password Change Email */
.password-change-email_layout{
  .banner__container{
    display: none;
  }
}
.profile_detail.password_change_email{
  padding-top: 0;
  background-color: #7D69AC;
  .content-upload-receipt{
    margin-bottom: 12px;
    @media only screen and (min-width: 481px){
      margin-bottom: 40px;
    }
  }
  .form-container{
    &:last-child{
      margin-top: 15px;
      border-top:none;
      padding-bottom: 0;
      @media only screen and (min-width: 481px){
        margin-top: 22px;
        margin-bottom: 0;
      }
    }
  }
  .title{
    @include breakpoint(small-only) {
      padding-bottom: 17px;
    }
  }
  p{
    font-size: 20px;
    font-family: "Intervogue Soft Alt Regular";
    text-align: center;
    max-width: 527px;
    line-height: 26px;
  }
  p + .input-container{
    max-width: 360px;
    margin: 0 auto;    
    p{
      font-size: 15px;
      text-align: left;
      padding-left: 24px;
      padding-bottom: 4px;
    }
  }
  .form-button{
    padding-top: 6px;
    padding-bottom: 0;
    @media only screen and (min-width: 481px){
      padding-top: 23px;
      padding-bottom: 35px;
    }
  }
  button {
    background: none;
    border: none;
    &.btn__wrapper {
      padding: 0;
      @media only screen and (max-width: 481px){
        width: 95%;
        .btn__container{
          width: 100%;
        }
      }
    }
  }
  .btn__container{
    background-color: #3B2772;
    width: 357px;
    @media only screen and (min-width: 481px){
      width: 504px;
    }
    span{
      color:$white;
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-family: "Intervogue Soft Alt Regular";
      font-weight: 400;
      @include breakpoint(small-only) {
        font-size: 18px;
        white-space: nowrap;
      }
    }
  }
}
/* Password Reset*/
.reset-password_layout{
  background-color: #3b2772;
  background-image: none!important;
  .banner__container,.menuAccount{
    display: none;
  }
}
.profile_detail.reset_password{
  .account{
    background-color: #3b2772;
    @include breakpoint(small-only) {
      justify-content: center;
    }
  }
  .form-container{
    &:last-child{
      border-top: 0;
      padding-bottom: 0;
    }
    .title{
      color:$white;
      text-align: left;
      font-weight: 400;
      line-height: 24px;
      padding-bottom: 20px;
      @include breakpoint(small-only) {
        text-align: center;
      }
    }
    p{
      font-family: "Intervogue Soft Alt Regular";
      font-weight: 700;
      font-size: 18px;
      padding-bottom: 0;
      &.small{
        font-weight: 400;
        font-size: 16px;
      }
    }
    .input-container.resetPassword{
      width: 392px;
      .input-container{
        width: 100%!important;
      }
    }
    .form-button{
      margin-top: 26px;
      padding-bottom: 0;
      button {
        background: none;
        border: none;
      }
    }
    .btn__container{
      background-color: #7D69AC;
      width: 357px;
      @media only screen and (min-width: 481px){
        width: 504px;
      }
      span{
        color:$white;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-family: "Intervogue Soft Alt Regular";
        font-weight: 400;
        @include breakpoint(small-only) {
          font-size: 18px;
          white-space: nowrap;
        }
      }
    }
  }
  .form-input__input.js-event-type-in.js-event-ab{
    &::-ms-reveal {
      display: none;
    }
  }
}
.profile_detail.reset_password + .clear {
  padding-top: 50px;
}
/* Login Detail Account Upgrade */
.profile_detail.account_upgrade{
  display: block;
  #frm_updateProfile{
    justify-content: center;
    .form-line:last-child {
      @include breakpoint(small-only) {
        padding-top: 0px;
      }
    }
  }
  .separator{
    width: 90%;
    margin-top: 20px;
    @media only screen and (min-width: 481px) {
      display: none;
    }
    hr{
      color: #8D7EB5;
      border-color: #8D7EB5;
    }
  }
  .content-upload-receipt{
    @media only screen and (min-width: 481px) {
      margin-bottom: 130px;
    }
    @media only screen and (min-width: 481px) and (max-width: 920px){
      justify-content: center;
    }
    .form-container:last-child {
      border-top: 0;
      @media only screen and (max-width: 480px) {
        padding-bottom: 0;
      }
    }
    .grid-4-m{
      @media only screen and (min-width: 639px) and (max-width: 1020px){
        flex-basis: calc(47.33% );
      }
    }
    .grid-8-m{
      @media only screen and (min-width: 481px) and (max-width: 920px){
        flex-basis: 100%;
      }
      .milka_de_account{
        @media only screen and (min-width: 481px) and (max-width: 920px){
          padding-left: 0;
        }
      }
    }
  }
  .form-button{
    .btn__container{
      @media only screen and (min-width: 481px) and (max-width: 1045px){
        width: 397px!important;
        margin-left: auto!important;
      }
    }
  }
  .milka_de_account{
    *{
      color:$white;
    }
    h1{
      font-family: $recoleta;
      color:$white;
      @include breakpoint(small-only) {
        font-size: 30px;
        text-align: center;
        padding-bottom: 6px;
      }
      @media only screen and (min-width: 481px) and (max-width: 920px){
        text-align: center;
      }
    }
    p{
      font-size: 20px;
      @media only screen and (min-width: 481px) {
        font-size: 22px;
      }
    }
    ol{
      font-size: 20px;
      line-height: 30px;
      padding-left: 22px;
      @media only screen and (min-width: 481px) {
        font-size: 22px;
        font-weight: 700;
      }
      li{
        font-family: "Intervogue Soft Alt Regular";
      }
    }
  }
  .milka-logo{
    @include breakpoint(small-only) {
      display:none;
    }
  }
  .account.spaceTeamButtom{
    background-color: inherit;
    padding-bottom: 0;
    .content-upload-receipt{
      @media only screen and (min-width: 481px) {
        margin-bottom: 0;
      }
    }
  }
  .account-milka-text{
    margin: 0 auto;
    margin-bottom: 120px;    
    max-width: 95%;
    @media only screen and (min-width: 481px)  {
      max-width: 792px;
    }
    *{
      color:$white;
    }
    h4{
      @media only screen and (min-width: 1024px){
        margin-top: 0;
      }
    }
    ul{
      @media only screen and (min-width: 1024px){
        padding-left: 0;
        padding-right: 0;
      }
      li{
        font-family: "Intervogue Soft";
        font-size: 20px;
        @media only screen and (min-width: 481px)  {
          font-size: 22px;
        }
      }
    }
  }
  .choose_team.dropdown_space{
    max-width: 80%;
    margin: 0 auto;
    @media only screen and (min-width: 481px)  {
      max-width: 302px;
    }
    .heading-choose_team{
      font-size: 14px;
      font-weight: normal;
      @media only screen and (min-width: 481px) {
        display: none;
      }
    }
  }
  .choose_team{
    padding-top: 0;
    p,
    .heading-choose_team,
    .custom-file-inputUpload::before,
    .text-content{
      color:#fff;
    }
    p{
      font-family: "Intervogue Soft";
      font-size: 18px;
      @media only screen and (min-width: 481px) {
        font-weight: 700;
      }
    }
    .notice-regardlessTeam{
      background-color: #E5E1EE;
      #text-underTeam.text-regardlessTeam{
        font-family: "Intervogue Soft Alt Thin";
        padding: 7px 15px;
        color: #3B2774;
        font-size: 13px;
        text-align: left;
      }
    }
    .team-picture{
      display: flex;
      background-color: #E5E1EE;
      width: 100%;
      min-height: 292px;
      max-width: 302px;
      margin-top: 38px;  
      border-radius: 10px;
      @media only screen and (min-width: 481px) {
        margin-top: 18px;
      }      
      @include breakpoint(small-only) {
        max-width: 210px;
        min-height: 203px;
        padding: 30px;
      }
      img{
        @include breakpoint(small-only) {
          max-width: 90px;
        }
      }
    }
    .form-input__container{
      margin-top: 22px;
      width: initial;
    }
    
    .checkmark{
      border: 1px solid $white;
      background-color: $white;
    }
    .profile_picture{
      width: initial;
      height: initial;
      display: flex;
      @include breakpoint(small-only) {
        flex-direction: column-reverse;
        align-items: center;
      }
      @media only screen and (min-width: 719px) {
        padding-top: 100px;
      }
      img{
        width: 110px;
      }
      .img-type{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 40px;
        @include breakpoint(medium) {
          margin-left: 28px;
        }
        p{
          font-size: 17px;
          padding-bottom: 10px;
          line-height: 10px;
          white-space: nowrap;
          &:last-child{
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          }
        }
        .heading-choose_team{
          font-size: 18px;
          font-weight: normal;
        }
      }
      .profile-img_container{
        display: flex;
        flex-direction: column;
        .form-container{
          &:last-child{
            border-top: none;
            padding-bottom: 0;
          }
        }
        .btn__container{
          width: initial;
          padding: 0;
          margin: 0 auto;
        }
      }
    }
    label.btn__container.custom-file-btn.pictureUpload {
      background: #3B2772;
      font-family: Intervogue Soft;
      font-weight: 400;
      letter-spacing: 4px;
    }
    span.desktop-only {
      letter-spacing: normal;
      font-size: 14px;
      text-transform: initial;
      font-family: "Intervogue Soft Alt Regular";
      color: #fff;
    }
    input#file_to_upload {
      visibility: hidden;
      position: absolute;
    }
    .zero_width{
      width: initial;
      max-width: 820px;
      @include breakpoint(small-only) {
        width: 80%!important;
        margin: 0 auto;
      }
      @media only screen and (max-width: 782px){
        padding-top: 0;
      }
      @media only screen and (min-width: 782px){
        padding-top: 0;
      }
      @media only screen and (min-width: 1024px){
        margin: 0;
      }
      .custom-inputs{
        @media only screen and (min-width: 720px) and (max-width: 1024px) {
          max-width: 350px;
        }
      }
    }
  }
}

.show-password{
  &::before{
    content: url("/resources/images/icons/hide-eye_2.png");
    position: absolute;
    top: 13px;
    right: 18px;
    cursor: pointer;
  }
}
.hide-password{
  &::before{
    content: url("/resources/images/icons/eye.png");
    position: absolute;
    top: 13px;
    right: 18px;
    cursor: pointer;
  }
}

.account-confirmation_layout{
  .banner__container{
    display: none;
  }
}

/* Account Menu Reskin */
.profile_detail{
  display: flex;
  flex-direction: column;
  background-color: #3b2772;
  @include breakpoint(medium) {
    flex-direction: row;
  }
  .navigationAccount{
    border-right: initial;
    width: 299px;
    li,a{
      color:$white;
      font-family: 'Intervogue Soft Thin';
    }
    li{
      padding-right: initial;
      &:first-of-type{
        font-size: 23px;
        font-family: $recoleta;
      }
    }
    a{
      &.active{
        color: $white;
        font-family: 'Intervogue Soft';
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .navigationAccount-sm{
    .left-arrow{
      color: #fff;
    }
  }
  #navigationAccount{
    background: none;
  }
  .navigationAccount-sm-list{
    @media only screen and (min-width: 769px){
      display: none!important;
    }
    margin-top: 0;
    li{
      padding-left: initial;
      margin-left: 25px;
      &:not(:first-child){
        padding-top:10px;
      }
      a{
        text-transform: initial;
      }
      &:first-of-type {
        text-transform: uppercase;
        font-weight: normal;
    }
    }
  }
  .left{
    float: none;
    width: initial;
    @include breakpoint(small-only) {
      
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 10px 0;
    margin: 0 10px;
    }
    @media only screen and (max-width: 768px) {
      float: none;
      width: initial;
    }
    @include breakpoint(medium) {
      border-right: 1px solid #f1ebea;
    }
    button.btnLogout.logout-lg {
      margin-top: 24px;
      margin-left: 121px;
      margin-right: 34px;
      height: 32px;
      width: 100px;
      border-radius: 6px;
      @include breakpoint(small-only) {
        display: none;
      }
      .btn__logout{
        text-transform: uppercase;
        font-size: 13px;
      }
    }
  }
  .logout-sm{
    display: none;
    @include breakpoint(small-only) {
      display: block;
    }
    .btnLogout{
      height: 30px;
      padding: 0 10px;
      border-radius: 5px;
    }
    .btn__logout{
      text-transform: uppercase;
      font-size: 12px;
      line-height: 17px;
    }
  }
  .right{
    @media only screen and (min-width: 768px) {
      float: none;
      width: 100%;
      padding-left: 90px;
    }
  }
}

/* Account Information */
.account-info_layout{
  .banner__container,.menuAccount{
    display: none;
  }
  .account-information{
    padding-bottom: 71px;
    .right{
      h2{
        color:$white;
        text-align: left;
        font-family: $recoleta;
        font-size: 30px;
        @include breakpoint(small-only) {
          text-align: center;
        }
      }
      .account-info {
        width: 100% !important; 
        margin-left: 0;
        @include breakpoint(small-only) {
          width: 95% !important;
          margin: 0 auto;
        }
      }
    }
  }
}

#frm_updateProfile {
  margin: 14px 5% 0;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 768px) {
    margin: 13px 0 0 22px;
  }
  .logout-md {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .form-line{
    flex-grow: 1;
    &.row{
      width: 100%;
      @include breakpoint(medium) {
        width: initial;
      }
    }
    &.last{
      padding-top: 30px;
      @media only screen and (min-width: 768px) {
        padding-top: 160px;
      }
    }
  }
  .form-line:last-child{
    padding-top: 30px;
  }
  .form-button{
    flex-basis: 100%;
    margin-top: 50px;
    margin-bottom: 75px;
    @media only screen and (min-width: 481px){
      max-width: initial;
      flex-basis: 90%;
    }
    .btn__container{
      background-color: #7D69AC;
      max-height: 70px;
      margin-top: 0;
      font-weight: normal;
      @media only screen and (min-width: 481px){
        max-width: initial;
        width: 527px;
      }
      @media only screen and (max-width: 1045px) and (min-width: 481px){
        width: 397px;
        margin-left: 0;
      }
      &:after{
        background-image: none;
      }
      &:hover{
        background-image: none;
      }
    }
  }
}
.left {
  @media only screen and (max-width: 768px) {
    float: none;
    width: 100%;
  }
  float: left;
  width: 30%;
  .form-container {
    margin: 0px;
  }
}
.right {
  @media only screen and (min-width: 768px) {
    float: right;
    width: 70%;
  }

  .form-container {
    margin: 0px;
  }
  p {
    padding-bottom: 20px;
  }
  h2 {
    color: #3b2772;
    padding-bottom: 19px;
  }
  .space-bottom {
    p {
      font-size: 19px;
      line-height: 22px;
      font-weight: 700;
    }
    .btn-bottom{
      margin-top: 30px;
    }
  }
  .btn__logout {
    color: #765ba8;
    background-color: #f3f3f3;
    font-weight: 700;
    font-size: 15px;
  }
}
.btn__logout {
  color: #765ba8;
  background-color: #f3f3f3;
  font-weight: 700;
  font-size: 15px;
}
.form-container .space-up {
  margin-top: 2px;
}
.btn__container {
  span {
    text-transform: uppercase;
  }
}
.linkPassword {
  border-bottom: 1px solid #3b2774;
  width: fit-content;
  margin-left: 32%;
}
.noticeFaq {
  font-size: 20px;
  line-height: 26px;
  padding-top: 50px;
  padding-left: 25px;
}

@media only screen and(max-width:430px) {
  .intro-slider__image {
    display: none;
  }
  .intro-slider__image-sm {
    display: block;
  }
}
@media only screen and(min-width:430px) {
  .intro-slider__image {
    display: block;
  }
  .intro-slider__image-sm {
    display: none;
  }
}
.image__container {

  .form-button {
    width: 100%;
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 5%;
    z-index: 4;

    @include breakpoint(large) {
      justify-content: flex-end;
      bottom: 10%;
      right: 3%;
    }

    .white {
      background-color: #ffffff;
    }

    button {
      @include breakpoint(medium) {
        min-width: 400px;
      }
    }

    &--center {
      justify-content: center;
    }
  }
  .form-button-main {
    .white {
      background-color: #ffffff;
    }
  }
  //button for edeka and kaufland left side
  .form-button-edeka {
    bottom: 0;

    @media only screen and (min-width: 700px) {
      bottom: 110px;
      left: 100px;
      width: 340px;
    }
    @media (min-width: 1024px) and(max-width:1199px) {
      bottom: 82px !important;
      left: 50px !important;
    }
    @media only screen and(min-width:1500px) {
      bottom: 125px;
    }
    @media only screen and(min-width:1900px) {
      bottom: 150px;
      // left:250px
    }
  }
}
.account-milka-text {
  h4 {
    font-size: 30px;
    line-height: 32px;
    color: #513c79;
    text-align: center;
    font-family: "recoleta medium";
    margin-top: 20px;
  }
  li {
    font-size: 20px;
    line-height: 26px;
    color: #7e7e7e;
    list-style: disc;
  }
  ul {
    padding-left: 36px;
    padding-top: 20px;
  }
  @media only screen and (min-width: 1024px) {
    h4 {
      font-size: 40px;
      line-height: 46px;
      margin-top: 114px;
    }
    ul {
      padding-left: 368px;
      padding-right: 300px;
      padding-top: 30px;
    }
    li {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
header {
  .header-md__container {
    z-index: 11;
  }
}
.btn__container {
  height: 45px;
}
.faqTitle {
  padding-top: 50px;
}
.errorMsg {
  color: red;
}
.errorMsg-Register {
  color: red;
  margin: auto;
}
.pictureUpload {
  font-size: 14px;
  font-weight: 400;
  font-family: "Intervogue Soft Alt";
  line-height: 19px;
  color: #3b2774;
  text-decoration: 1px solid #3b2774;
  text-decoration: underline;
  @media only screen and (min-width: 900px) {
    margin-right: 231px;
  }
}
.custom-file-inputUpload::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-inputUpload::before {
  content: "Bild hochladen";
  font-size: 14px;
  font-weight: 400;
  font-family: "Intervogue Soft Alt";
  line-height: 19px;
  color: #3b2774;
  text-decoration: 1px solid #3b2774;
  text-decoration: underline;
}
.custom-file-inputUpload:hover::before {
  border-color: black;
}
.custom-file-inputUpload:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.profileLabel {
  padding-top: 17px;
}
.btnLogout {
  border: 0;
}
.clear {
  clear: both;
}
.profile_detail .btn__container {
  width: 327px;
}
.resetPassword {
  h2 {
    text-align: left;
  }
}
.menuAccount {
  @media only screen and (max-width: 768px) {
    top: 65px;
    left: 5px;
  }
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 2px;
  font-family: "Intervogue Solf Alt";
  color: #3b2774;
  position: absolute;
  top: 157px;
  left: 129px;
  z-index: 2;
  span {
    font-weight: 900;
  }
}
.separatorNewsletter {
  padding-top: 75px;
}
.logout-address {
  float: right;
  @media only screen and (min-width: 768px) {
    padding-right: 25%;
  }
}
.address_info {
  width: 881px;
  height: 103px;
  background: #f3f3f3;
  border-radius: 12px;
  padding: 50px 100px;
  margin-left: 25%;
  margin-top: 4%;
  font-family: "Intervogue Soft Alt";
  font-size: 18px;
}
.address-bold {
  font-weight: 700;
}
.profile_picture {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  @media only screen and(max-width:768px) {
    margin: auto;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}
.updateProfile {
  .btn__container {
    background-color: #cd1823;
    margin-top: 100px;
    width: 315px;
  }
}
.noticeParticipation {
  a {
    text-decoration: underline;
  }
}
.paddingFormat {
  padding-top: 35px;
  text-align: center;
}
.terms {
  h1 {
    color: #3b2772;
  }
}
//btn for intropage
.small-width {
  .btn__container {
    width: 254px !important;
  }
}

.account-info {
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    margin-left: 0px;
  }
  width: 50% !important;
  margin-left: 92px;
}

.padding-down {
  padding-bottom: 30px;
}
#whiteBtn {
  margin-top: 25px;
  color: #3b2772;
  background-color: white;

  @media only screen and (max-width: 500px) {
    width: 80%;
    margin: 25px auto;
  }
}
.bulletLi {
  padding-left: 29px;
}
.strapline {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}
.space-top {
  padding-top: 40px;
}
#intropage-text {
  .subheading {
    font-size: 30px;
  }
}
#intropage-intervogueFont {
  .heading_dark-lilac {
    font-family: "Intervogue Soft Alt Regular";
  }
  .subheading {
    @media only screen and (min-width: 768px) {
      padding-right: 163px;
      padding-left: 163px;
    }
  }
}
.more-info-cta {
  display: inline-block;
  padding-left: 50px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  color: #3b2774;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    padding-left: 70px;
  }
}

.content-visibility {
  display: none;

  &.show {
    display: block;
  }
}
//header
.active {
  text-decoration: underline;
}
.style-introvogue {
  font-family: $soft-alt;
  margin-top: 25px;
}
.zero_width {
  @media only screen and (min-width: 1024px) {
    width: 378px;
    margin-left: 22%;
    margin-top: 25px;
  }
}
.small-btn {
  @media only screen and (max-width: 768px) {
    width: 263px;
  }
}
.small-btn-resetPassword {
  @media only screen and (max-width: 768px) {
    width: 263px;
  }
}
/**rewe**/
.banner-rewe {
  img {
    display: block;
    margin: auto;
    width: 100%;
  }
  @media only screen and (min-width: 1018px) {
    display: none;
  }
}
//banner for 'edeka'
.banner-edeka {
  // TODO: Fix this for all pages
  // padding-bottom: 50px;
  // margin-top: -250px;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
  img {
    display: block;
    // margin: auto;
    width: 100%;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}
.heading-rewe {
  padding-left: 5px;
  padding-right: 5px;

  @media only screen and (min-width: 668px) and (max-width: 720px) {
    display: block !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 60px;
    text-align: center;
    // padding-left: 83px;
    // padding-right: 83px;
    line-height: 71px;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  @media only screen and (min-width: 1480px) and (max-width: 1660px) {
    padding-left: 15%;
  }

  h1 {
    display: inline;
  }
  img {
    display: inline;
  }
}
.rewe-startPage {
  padding-top: 20px;
  p {
    font-size: 20px;
    @media only screen and (min-width: 768px) {
      font-size: 25px;
    }
  }
  .heading-rewe-prize {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  h1 {
    .title-mobile {
      display: inline;
      font-size: 30px;
      line-height: 36px;
      font-weight: 600;
      @media only screen and (min-width: 768px) {
        display: none;
      }
    }
    .title-desktop {
      display: none;
      @media only screen and (min-width: 768px) {
        display: inline;
      }
    }
  }
  .reweLogo {
    display: none;
    @media only screen and (min-width: 768px) {
      display: inline;
      vertical-align: baseline;
    }
  }
  .titles-rewe {
    h1 {
      display: inline;
      font-family: "Recoleta Medium";
    }
    @media only screen and (min-width: 999px) {
      padding-right: 119px;
      padding-left: 119px;
    }
    a {
      text-decoration: underline;
    }
    .paragraph-rewe {
      .s-content-title {
        .space {
          @media only screen and (min-width: 768px) {
            padding-left: 50px;
            padding-right: 50px;
            // text-align:justify;
          }
        }
      }
    }
  }
  .headingImage {
    padding-bottom: 25px;
    text-align: center;
    @media only screen and (min-width: 1024px) {
      line-height: 70px;
    }
    .title-with-image {
      font-size: 30px;
      display: inline;
      @media only screen and (min-width: 768px) {
        padding-bottom: 50px;
        font-size: 60px;
        padding-left: 168px;
      }
    }
    .logo-container {
      display: inline;
      vertical-align: middle;
    }
  }
  .second-text-padding-top {
    padding-top: 30px;
  }
  .space-rewe-text {
    padding-top: 50px;
    padding-bottom: 20px;
    .subheading {
      text-align: center;
      font-size: 22px;
      @media only screen and (min-width: 768px) {
        font-size: 30px;
      }
    }
  }
  .step-title {
    font-size: 17px;
    @media only screen and (min-width: 1024px) {
      font-size: 20px;
    }
  }
  .notice-rewe {
    clear: both;
    padding-top: 30px;
    margin-bottom: 25px;

    @media only screen and (min-width: 768px) {
      padding-right: 170px;
      padding-left: 170px;
    }
    .s-content-title .space {
      font-size: 20px;
    }
  }
  .rewe-button {
    margin-top: 30px;
    margin-bottom: 25px;
    @media (max-width: 765px) and (min-width: 568px) {
      width: 50%;
    }
  }
  .content-slider__header {
    font-family: "Recoleta Medium";
  }
  .content-slider-wrapper .content-slider__header {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
  }
  .content-slider-wrapper .content-slider__description {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 20px;
  }
}
.prizeRewe {
  background-image: url("/resources/images/pages/bg-pattern/Master Paper Texture_White.png");
  p,
  h1 {
    color: #3b2774;
  }
  p {
    font-size: 20px;
    @media only screen and (min-width: 768px) {
      font-size: 25px;
    }
  }
  h1 {
    display: inline;

    .title-mobile {
      display: inline;
      font-weight: 600;
      @media only screen and (min-width: 768px) {
        display: none;
      }
    }
    .title-desktop {
      display: none;
      @media only screen and (min-width: 768px) {
        display: inline;
      }
    }
  }
  .reweLogo {
    display: none;
    @media only screen and (min-width: 768px) {
      display: inline;
      vertical-align: middle;
    }
  }
  .headingImage {
    padding-bottom: 50px;
    text-align: center;
    // @media only screen and (min-width:1024px){
    //     line-height:60px;
    // }
    @media only screen and (min-width: 1024px) {
      line-height: 60px;
    }
    .title-with-image {
      font-size: 30px;
      display: inline;
      @media only screen and (min-width: 768px) {
        font-size: 60px;
        padding-left: 168px;
      }
    }
    .logo-container {
      display: inline;
    }
  }
  .titlePrize {
    padding-top: 70px;
    text-align: center;
    line-height: 32px;
    padding-left: 5px;
    padding-right: 5px;
    @media (min-width: 1199px) and (max-width: 1298px) {
      padding-top: 100px;
      padding-left: 147px;
      padding-right: 147px;
    }
    @media only screen and (min-width: 1299px) {
      padding-top: 100px;
      padding-left: 220px;
      padding-right: 220px;
    }
    @media only screen and (min-width: 1800px) {
      padding-left: 340px;
      padding-right: 340px;
    }
    .space-prize-title {
      padding-left: 10px;
    }
    h1 {
      font-size: 30px;
      line-height: 30px;
      font-family: "Recoleta Medium";
      @media only screen and (min-width: 768px) {
        font-size: 40px;
        line-height: 46px;
      }
    }
    p {
      padding-top: 15px;
      font-size: 20px;
      @media only screen and (min-width: 768px) {
        font-size: 26px;
        padding-top: 30px;
        font-family: $recoleta;
      }
    }
  }
  .prize-section-rewe {
    padding-top: 40px;
    @media only screen and (min-width: 768px) {
      padding-top: 103px;
    }
    p {
      font-size: 20px;
      line-height: 26px;
    }
    .rewe-prize1 {
      @media only screen and (min-width: 768px) {
        margin-left: 62px;
      }
    }
    // .rewe-prize-heading{
    //     padding-top:50px;
    // }
  }
  .notice-prize-section {
    padding-left: 15px;
    padding-right: 15px;

    p {
      font-size: 15px;
      text-align: center;
      line-height: 19px;
      font-weight: 400;
      .bold {
        font-weight: 700;
      }
    }
    a {
      text-decoration: underline;
    }
    span {
      font-weight: 700;
    }
    @media only screen and (min-width: 768px) {
      padding-left: 210px;
      padding-right: 210px;
    }
  }
  .participate-rewe {
    padding-top: 70px;
    text-align: center;
    font-size: 30px;
    padding-bottom: 50px;
    @media only screen and (min-width: 768px) {
      font-size: 40px;
      padding-top: 100px;
      padding-left: 217px;
      padding-right: 231px;
    }
    .btn-prize-container {
      margin-top: 20px;
      @media only screen and (min-width: 768px) {
        margin-top: 30px;
      }
    }
  }
}
.endpage-rewe {
  .navigation-newsletter {
    color: #3b2772;
    padding-bottom: 30px;
    @media only screen and (min-width: 768px) {
      padding-bottom: 53px;
    }
  }
  a {
    text-decoration: none;
    margin-top: 0px;
  }
  h4 {
    color: #3b2772;
    font-weight: 500;
    line-height: 28px;
    font-size: 22px;
    text-align: center;
    @media only screen and (min-width: 768px) {
      padding-left: 110px;
      padding-right: 110px;
      font-size: 30px;
      line-height: 32px;
    }
  }
  .paragraph-end-rewe {
    padding-top: 40px;
    padding-bottom: 25px;
    padding-right: 10px;
    padding-left: 10px;
    @media only screen and (min-width: 768px) {
      padding-top: 80px;
      padding-bottom: 25px;
    }
  }
  .titles-rewe {
    margin-bottom: 28px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 140px;
    }
  }
  .s-content-product {
    padding: 0px;
    .btn__container {
      margin-top: 0px;
    }
  }
}
//styling for kaufland
.content-slider-subheading {
  font-family: "Intervogue Soft Alt Regular";
  font-weight: 500;
  font-size: 25px;
  line-height: 26px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  @media only screen and (min-width: 768px) {
    text-align: unset;
    font-size: 30px;
    line-height: 32px;
    padding-bottom: 10px;
    padding-left: 38px;
  }
}
.formParticipation {
  .btn__container {
    background: #93d1f4;
    position: absolute;
    z-index: 3;
    color: #3b2774;
    width: 310px;
    top: 325px;
    left: 25px;
    @media only screen and (min-width: 768px) {
      top: 572px;
      width: 400px;
      left: 172px;
    }
    @media (min-width: 568px) and (max-width: 800px) {
      top: 539px;
    }
    @media (min-width: 801px) and (max-width: 1023px) {
      top: 676px;
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
      top: 422px;
      left: 47px;
    }
    @media (min-width: 1201px) and (max-width: 1440px) {
      top: 609px;
      left: 118px;
    }
    @media (min-width: 1441px) and (max-width: 1528px) {
      top: 690px;
    }
    @media only screen and (min-width: 1800px) {
      top: 829px;
      left: 314px;
    }
    //33% zoom
    @media (min-width: 2733px) and (max-width: 4102px) {
      top: 25%;
      left: 17%;
    }
    //50% zoom
    @media (min-width: 2052px) and (max-width: 2732px) {
      top: 19%;
      left: 17%;
    }
    //25% zoom
    @media (min-width: 4103px) and (max-width: 5464px) {
      top: 31%;
      left: 21%;
    }
  }
}
.navigation-supermarket {
  .navigation-newsletter {
    @media only screen and (max-width: 768px) {
      padding-bottom: 30px;
    }
  }
}

@media (min-width: 1199px) and (max-width: 1299px) {
  .kaufland-background {
    max-width: 750px !important;
  }
  .kaufland-slider {
    width: 41% !important;
    padding-left: 37px !important;
  }
  .content-text {
    padding-top: 30px !important;
  }
}
@media (min-width: 1300px) and (max-width: 1482px) {
  .kaufland-slider {
    display: block;
  }
}

.kaufland-slider {
  @media screen and (max-width: 481px) {
    padding-top: 15%;
  }
}
// fan center
.fanCenter {
  h1 {
    font-size: 30px;
    @media only screen and (min-width: 68px) {
      font-size: 60px;
    }
  }
  .fancenter_navigation {
    background-color: #afa6d2;
    overflow-x: scroll;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @media only screen and (min-width: 1024px) {
      width: 100%;
      height: 66px;
      margin-top: 50px;
      margin-bottom: 60px;
    }
    .fancenter_navigation-center {
      display: inline-block;
      text-align: center;
      min-width: 650px;
      width: 100%;
      // @media only screen and (min-width:768px){

      // }
    }
    .fancenter_navigation-steps {
      color: #3b2772;
      font-size: 26px;
      line-height: 32px;
      font-weight: 600;
      text-align: center;
      width: 119px;
      padding-top: 14px;
      display: inline-block;
      @media (min-width: 768px) and (max-width: 1024px) {
        width: 188px;
      }
      @media only screen and (min-width: 1024px) {
        width: 250px;
      }
    }
    .navigation-image {
      display: inline-block;
      vertical-align: middle;
    }
    .navigation-text {
      display: inline-block;
      padding-left: 5px;
      color: white;
    }
    .navigation-text-completed {
      display: inline-block;
      padding-left: 5px;
      color: #3b2772;
    }
    .step-text {
      color: white;
      font-family: "RECOLETA ALT";
      font-size: 27.42px;
      line-height: 29.25px;
      text-align: center;
      padding-top: 1px;
      position: absolute;
      left: 9px;
      top: 3px;
    }
    .text-inactive {
      left: 11px;
      top: 2px;
    }
    .step-inprogress {
      background-color: #3b2772;
      border-radius: 50%;
      height: 37px;
      width: 37px;
      position: relative;
      .step-text1 {
        left: 15px;
      }
      .step-text2 {
        left: 11px;
      }
      .step-text3 {
        top: 3px;
      }
      .step-text4 {
        top: 3px;
      }
    }
    .step-inactive {
      background-color: transparent;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      border: 3px solid white;
      position: relative;
      .step-text {
        left: 9px;
      }
    }
    .text-completed {
      background: url("/resources/imagesBundesliga/fanCenter/icons/vector.png");
      background-repeat: no-repeat;
      left: 10px;
      top: 11px;
      width: 25px;
      height: 25px;
      color: transparent;
    }
    .step-completed {
      background-color: #aabe24;
      border-radius: 50%;
      height: 37px;
      width: 37px;
      position: relative;
    }
    .separator {
      display: none;
      @media only screen and (min-width: 1104px) {
        display: inline-block;
      }
    }
    .separator-line-white {
      background: url("/resources/imagesBundesliga/fanCenter/icons/line.png");
      width: 31px;
      height: 2px;
      background-repeat: no-repeat;
    }
    .separator-line-lilac {
      background: url("/resources/imagesBundesliga/fanCenter/icons/line-lilac.png");
      width: 31px;
      height: 2px;
      background-repeat: no-repeat;
    }
    .text-explanation {
      font-family: "Recoleta-Medium";
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        font-size: 13.9px;
      }
    }
  }

  .space-title {
    h1 {
      font-size: 30px;
      @media only screen and (min-width: 768px) {
        font-size: 60px;
      }
    }
  }
  .space-title-jersey {
    h1 {
      font-size: 30px;
      @media only screen and (min-width: 768px) {
        font-size: 60px;
      }
    }
  }
  .fancenter-thankyou {
    .noSpace {
      padding-top: 0px;
    }
    .breakline {
      padding-bottom: 50px;
      padding-top: 0px;
      margin: auto;
      @media only screen and (min-width: 768px) {
        padding-bottom: 100px;
        padding-top: 100px;
      }
    }
  }
  .fancenter-new {
    h2 {
      font-weight: 400px;
      font-size: 30px;
      line-height: 32px;
      letter-spacing: 0.44px;
      color: #3b2772;
      font-family: "Recoleta medium";
      padding-left: 5px;
      @media only screen and (min-width: 768px) {
        font-size: 40px;
        line-height: 46px;
      }
    }
    h3 {
      font-weight: 400;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.44px;
      text-align: center;
      font-family: "recoleta medium";
      color: #3b2772;
      padding-top: 15px;
      padding-bottom: 30px;
      @media only screen and (min-width: 768px) {
        font-size: 30px;
        line-height: 32px;
        padding-top: 20px;
        padding-bottom: 40px;
      }
    }
  }

  .btn__container {
    background-color: #d61924;
  }
  .fan-center-btn-thankYou {
    margin-top: 30px;
    margin-bottom: 50px;
    @media only screen and (min-width: 768px) {
      margin-top: 40px;
      margin-bottom: 100px;
    }
  }
}
.ticket-choice {
  padding-top: 27px;
}
.image-center {
  display: block;
  margin: auto;
}
.image-space {
  padding-bottom: 25px;
  @media only screen and (min-width: 768px) {
    width: 1205px;
    padding-top: 88px;
  }
}
.slider-fanCenter {
  padding-top: 98px !important;
}
.wincode__section {
  padding-top: 50px;
  p {
    line-height: 32px;
    font-size: 25px;
    font-weight: 400;
    @media only screen and (min-width: 768px) {
      padding-left: 230px;
      padding-right: 230px;
    }
  }
  .subheading {
    font-weight: 500;
    font-size: 40px;
    line-height: 46px;
    font-family: "Recoleta";
  }
  .heading_dark-lilac {
    font-family: "Recoleta Regular";
  }
  .btn__container {
    width: 346px;
    @media only screen and (min-width: 768px) {
      width: 427px;
    }
  }
}
.fanCenter-menu {
  overflow-x: scroll;
  -ms-overflow-style: none;

  @include breakpoint(medium) {
    overflow: hidden;
  }

  .page__menu {
    li {
      a {
        color: #3b2772;
      }
      .active {
        text-decoration: none;
      }
    }
    .menu-lilac {
      border-color: #3b2772;

      li a {
        font-size: 16px;
      }
    }
  }

  .main-nav {
    width: 150%;
  }
}
.fancenter {
  .centered-fancenter {
    display: flex;
    justify-content: center;
  }
}

.code-section {
  width: 345px;
  height: 495px;
  background-color: rgba(125, 107, 170, 0.2);
  border-radius: 10px;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
  @media only screen and (min-width: 768px) {
    width: 520px;
    margin: auto;
  }
}
.code-section-team {
  height: 255px;
}
.fancenter-code {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 768px) {
    margin-bottom: 150px;
  }
  .btn-bottom {
    margin-top: 0px;
    padding-left: 7px;
  }
  .full-width {
    width: 100%;
    @media only screen and (min-width: 768px) {
      width: 95%;
      padding-left: 27px;
    }
  }
  .form-input__dropdown select {
    background-color: white;
  }
}
.fancenter_code-textbox {
  padding: 16px 27px 33px 27px;
  input {
    background-color: #fff;
  }
}
.fan-center-button {
  border: none;
  background: transparent;
  display: block;
  margin: auto;
  width: 243px;
}
.fanCenter-label {
  padding-top: 30px;
  padding-left: 50px;
  color: #3b2772;
}
.jersey-choice-fanCenter {
  margin-bottom: 60px;
  padding-top: 27px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 150px;
    padding-top: 70px;
  }
}
.jersey-size {
  @media only screen and (max-width: 768px) {
    padding-top: 27px;
  }
  .subheading {
    font-family: "RECOLETA MEDIUM" !important;
    font-weight: 500 !important;
    line-height: 30px;
    font-size: 30px !important;
    @media only screen and (min-width: 768px) {
      line-height: 46px;
    }
  }
  .heading_dark-lilac {
    font-family: "Recoleta Regular";
    font-weight: 300;
    line-height: 30px;
    font-size: 25px;
  }
}
.block {
  display: block;
}
.codeEntry {
  @media only screen and (max-width: 768px) {
    padding-top: 27px;
  }
  .subheading {
    font-family: "RECOLETA MEDIUM";
    font-weight: 500;
    line-height: 30px;
    font-size: 22px;
    padding-left: 5px;
    padding-right: 5px;
    @media only screen and (min-width: 768px) {
      padding-left: 138px;
      padding-right: 135px;
      line-height: 46px;
      font-size: 40px;
    }
  }
}

.jersey-choice {
  margin-bottom: 50px;
  @media only screen and (min-width: 1100px) {
    margin-bottom: 135px;
    display: flex;
    margin-left: 220px;
    margin-right: 100px;
  }
  @media only screen and (min-width: 1600px) {
    margin-left: 375px;
    margin-right: 225px;
  }
}
.jersey-image {
  display: block;
  justify-content: center;
  margin-bottom: 25px;
  @media only screen and (min-width: 950px) {
    display: block;
    margin: auto;
    width: 366px;
  }
  #jerseyImg {
    display: block;
    margin: auto;
  }
  p {
    margin-top: 30px;
    text-align: center;
    padding-bottom: 25px;
    font-size: 20px;

    @include breakpoint(medium) {
      margin-top: 50px;
      font-size: 25px;
    }
  }
}
.ticket-choice {
  p {
    padding: 2px;
    line-height: 30px;
    font-size: 20px;
    @media only screen and (min-width: 768px) {
      padding-left: 271px;
      padding-right: 252px;
    }
  }
}
.ticket-radioBtn {
  background-color: RGBA(125, 107, 170, 0.2);

  display: block;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 75px;
  .content-purchase .content-purchase__inputs {
    @media only screen and (max-width: 768px) {
      padding-left: 25px;
      display: block;
      padding-bottom: 25px;
    }
  }
  .custom-inputs {
    margin-bottom: 28px;
  }
  @media only screen and (min-width: 768px) {
    width: 664px;
  }
}
.breakline {
  width: 75%;
}
.ticket-jersey-choice {
  padding-top: 75px;
  margin-bottom: 50px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 150px;
  }
}
.section-ticket-jersey {
  height: 104px;
}
.space-ticket-jersey {
  margin-bottom: 65px;
}

.form_for_me {
  .fancenter-form-text {
    margin-bottom: 25px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 95px;
    }
  }

  p {
    padding-bottom: 20px;
    font-size: 20px;
  }
  a {
    text-decoration: underline;
  }
  .label-asterix {
    padding: 0px;
  }

  .fancenter-form-container {
    margin-bottom: 75px;
    margin-left: 10px;
    margin-right: 10px;
    // .form-container .small-input-container{
    //     max-width:365px;
    // }
    // .form-container{
    //     max-width:365px;
    // }
    @media only screen and (min-width: 1175px) {
      padding-left: 300px;
      padding-right: 300px;

      .space-right {
        width: 144%;
      }
      .form-container .small-input-container.desktop {
        left: 19px;
      }
      .street-number-label {
        position: absolute;
        right: 0px;
      }
      // .address-label{
      //     width:728px;
      // }
      .zipcode-label {
        width: 191px;
      }
      .city-label {
        min-width: 100%;
      }
      .anrede-label {
        margin-left: 18px;
        padding-bottom: 10px;
      }
    }
    .input-container {
      padding-top: 0px;
    }
    .sm-left {
      @media only screen and (max-width: 678px) {
        margin-left: 2px;
      }
    }
    .legal-text {
      margin-top: 25px;
      @media only screen and (min-width: 768px) {
        margin-top: 75px;
      }
    }
    .breakline {
      margin-top: 25px;
      @media only screen and (min-width: 768px) {
        margin-top: 75px;
      }
    }
    .button-form-ticket {
      margin-top: 25px;
      width: 360px;
      @media only screen and (min-width: 768px) {
        width: 420px;
        margin-top: 75px;
      }
    }
  }
}
//fan center for match selection
.matchSelection {
  width: 80%;
  margin: auto;
  background-color: RGBA(125, 107, 170, 0.2);
  height: auto;
  @media only screen and (min-width: 768px) {
    padding-bottom: 100px;
    margin-left: 133px;
    margin-right: 130px;
  }
}
.matchSelection-information {
  margin-top: 50px;
  margin-left: 25px;
  @media only screen and (min-width: 768px) {
    margin-left: 59px;
  }
}
.month {
  padding-top: 25px;
  text-align: center;
  @media only screen and (min-width: 768px) {
    text-align: unset;
  }
  span {
    text-transform: capitalize;
    font-family: "Recoleta";
    font-weight: 600;
    color: #3b2772;
    font-size: 24px;
    padding-top: 50px;
    @media only screen and (min-width: 768px) {
      font-size: 30px;
      line-height: 32px;
    }
  }
}
.matchSelection-text {
  label {
    font-weight: 400;
    font-family: "Intervogue Solf Alt";
    color: #3b2774;
    font-size: 16px;
    @media only screen and (min-width: 768px) {
      font-size: 25px;
    }
  }
}
.matchSelection-date {
  padding-top: 46px;
  @media only screen and (min-width: 768px) {
    float: left;
  }
}
.separator-teams {
  padding-left: 25px;
  padding-right: 50px;
}
.matchSelection-teams {
  .label {
    display: inline;
  }
  img {
    display: inline-block;
    vertical-align: middle;
  }
  @media screen and (min-width: 768px) {
    padding-top: 13px;
    padding-right: 62px;
    float: right;
  }
}
.clear {
  clear: both;
}
//match selection
.btnMatchSelection {
  margin-top: 35px;
  margin-bottom: 75px;
  @media only screen and (min-width: 768px) {
    margin-top: 70px;
    margin-bottom: 150px;
  }
}
.teamSelection {
  margin-top: 25px;
  @media only screen and (min-width: 768px) {
    margin-top: 52px;
    margin-bottom: 84px;
  }
}
.teamSelection-image {
  display: block;
  margin: auto;
  width: 65px;
  height: 98px;
  margin-bottom: 20px;
}
.teamSelection-label {
  display: flex;
  justify-content: center;
}
.edeka-terms {
  font-size: 30px;
  line-height: 32px;
  font-weight: 400;
  color: #3b2772;
  text-align: center;
  padding-bottom: 20px;
  font-family: "Recoleta Medium";
}

.subtitle-edeka {
  font-family: "Recoleta Medium";
  color: #3b2772;
  font-size: 30px;
  padding-left: 16px;
  padding-right: 15px;
  @media only screen and (min-width: 1025px) {
    font-size: 40px;
  }
}
.edeka-prize-heading {
  padding-top: 50px;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
  text-align: left;
  .subtitle-prize {
    font-family: "Intervogue Soft Alt Regular";
  }
}
#edeka-prize--heading {
  padding-top: 25px;
  @media only screen and (min-width: 1024px) {
    padding-top: 50px;
  }
}
.edeka-prize1 {
  @media only screen and (min-width: 1024px) {
    margin-left: 35px;
  }
}
.edeka-prize2 {
  margin-top: 25px;
}
.prize-space {
  padding-bottom: 0px;
}

#edeka-paragraph {
  font-size: 20px;
  font-family: "Intervogue Soft Alt Regular";
}
.removeMargin {
  margin-bottom: 0px !important;
}
.whiteText {
  color: white !important;
  font-family: "Intervogue Soft Alt Regular" !important;
  font-size: 24px;
  a {
    text-decoration: underline;
    font-weight: 900;
  }
  padding-bottom: 100px !important;
}
.edeka-copy {
  @media only screen and (max-width: 1023px) {
    line-height: 26px;
    text-align: center;
  }
}
.space {
  @media only screen and (max-width: 1023px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 1024px) {
  .edekaQ2-copy {
    padding-top: 30px;
    line-height: 32px;
    font-family: "Recoleta";
  }
  .edeka-copy {
    font-family: "Recoleta Regular";
    line-height: 35px;
    padding-bottom: 0px;
    text-align: center;
  }
  .space {
    padding-right: 75px;
    padding-left: 75px;
  }
  .edeka-copy-medium {
    font-family: "Recoleta Medium" !important;
  }
}
@media only screen and (min-width: 1800px) {
  .space {
    padding-right: 118px;
    padding-left: 81px;
  }
}
.edeka-copy-medium-sm {
  font-family: "Recoleta Regular" !important;
  letter-spacing: 1px;
  padding-bottom: 0px !important;
  @media only screen and (min-width: 1024px) {
    letter-spacing: 0px;
    font-family: "Recoleta Medium" !important;
    padding-bottom: 40px !important;
  }
}

.edeka {
  &-space {
    &-up {
      margin-top: 60px;
    }
  }
}

#error-emailname {
  text-decoration: none;
}
#error-winningcode {
  text-decoration: none;
}
.fancenterMath-sm {
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}
.matchSelection-sm {
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
.btnMatchSelection-sm {
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
.fancenterHide {
  display: none !important;
}
.fancenterShow {
  display: flex;
}
#frm_chooseTeamDate {
  width: 100%;
}
.matchChoosenSm {
  padding-top: 25px;
  text-align: center;
}
#error-fancenter {
  .active {
    text-decoration: none !important;
  }
}
#fancenter-confirmation {
  .subheading,
  .heading_dark-lilac {
    font-family: "Recoleta Medium";
  }
}
//Scratch card

.scratch-card {
  @media only screen and (max-width: 1024px) {
    // margin-bottom: 150px;
    display: block;
    margin: auto;
  }
  #scratchLeft {
    margin-bottom: 50px;
    @media only screen and (min-width: 1024px) {
      // margin-bottom: 150px;
      margin-left: 25px;
    }
    .container {
      cursor: pointer;
      position: relative;
      width: 258px;
      height: 316px;
      margin: 0 auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      // background-image: url('/resources/imagesBundesliga/game/scratch-left-sm.png');
      @media only screen and (min-width: 1024px) {
        width: 339px;
        height: 416px;
        // background-image: url('/resources/imagesBundesliga/game/scratch-left.png');
      }
    }
  }
  #scratchRight {
    @media only screen and (max-width: 1024px) {
      margin-left: 25px;
      margin-bottom: 80px;
    }
    margin-left: 80px;
    .container {
      cursor: pointer;
      position: relative;
      width: 258px;
      height: 316px;
      margin: 0 auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      // background-image: url('/resources/imagesBundesliga/game/scratch-right-sm.png');
      @media only screen and (min-width: 1024px) {
        width: 339px;
        height: 416px;
        // background-image: url('/resources/imagesBundesliga/game/scratch-right.png');
      }
    }
    .canvas {
      @media only screen and (min-width: 1024px) {
        top: -3px;
        left: 4px;
      }
    }
  }

  .canvas {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    @media only screen and (min-width: 1024px) {
      top: 0px;
      left: 3px;
    }
  }

  .form {
    padding: 20px;
  }
  h2 {
    position: absolute;
    top: 164px;
    left: 108px;
    font-size: 16px;
    color: #3b2772;
  }

  // #js-container2{
  //     background-image: url('/resources/imagesBundesliga/game/New\ Project2.png');
  // }
}

.logoShield {
  height: unset !important;
  width: unset !important;
  margin-top: 100px;
  @media only screen and (max-width: 768px) {
    display: block;
    margin: auto;
    margin-top: 25px;
  }
}
.scratchRightUnavailable {
  display: none;
}
.scratchLeftUnavailable {
  display: none;
}
.scratchRightUnavailableDisplay {
  position: relative;
  width: 252px;
  height: 317px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-image: url("/resources/imagesBundesliga/game/unscratch-right-sm.png");
  display: block;
  margin: auto;
  @media only screen and (min-width: 1024px) {
    margin: unset;
    width: 339px;
    height: 416px;
    background-image: url("/resources/imagesBundesliga/game/unscratch-right.png");
  }
}
#scratchRightUnavailable {
  @media only screen and( min-width:1024px) {
    margin-left: 50px;
  }
}
.scratchLeftUnavailableDisplay {
  position: relative;
  width: 252px;
  height: 317px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-image: url("/resources/imagesBundesliga/game/unscratch-left-sm.png");
  display: block;
  margin: auto;
  @media only screen and (min-width: 1024px) {
    margin-left: 0px;
    width: 339px;
    height: 416px;
    background-image: url("/resources/imagesBundesliga/game/unscratch-left.png");
  }
}
.gameScratch {
  @media only screen and (min-width: 1024px) {
    justify-content: center;
    display: flex;
  }
  .none {
    display: none;
  }
  .show {
    display: block;
  }
}
.gameText {
  color: #7d69ac;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
}
#imageScratchRight {
  position: absolute;
  top: 8px;
  left: 10px;
  height: 292px;
  width: 230px;
  @media only screen and(min-width:1024px) {
    top: 9px;
    left: 0px;
    height: 410px;
    width: 325px;
  }
}
#imageScratch {
  position: absolute;
  top: 8px;
  left: 10px;
  height: 292px;
  width: 230px;
  @media only screen and(min-width:1024px) {
    top: 9px;
    left: 0px;
    height: 410px;
    width: 325px;
  }
}
.paragraphScratch {
  margin-top: 125px;
  .s-content-title {
    font-family: "Recoleta";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
  }
  p {
    @media only screen and (min-width: 1025px) {
      padding: 0 120px;
      line-height: 32px;
    }
    span {
      font-weight: 600;
      display: contents;
    }
  }
  #imageWin {
    height: unset;
    width: unset;
    display: block;
    margin: auto;
    margin-top: 30px;
  }
}
.bottomSpace {
  margin-bottom: 100px;
}
//participation page  bugs fixed spacing
.checkbox__space {
  position: absolute;
  top: 80px;
  @media only screen and (min-width: 768px) {
    top: 40px;
  }
}
//faq step content bug fixed font family
.faqStep {
  p {
    font-family: "Intervogue Soft Medium";
  }
}
#slider-text-introduction {
  padding-top: 23px;
}
#btn--lilac {
  background-color: #7d69ac;
}
#btn--blue {
  background-color: #00a2dd;
  margin-top: 20px;
}
#btn--bluePale {
  background-color: #93d1f4;
  margin-top: 20px;
}
.radio-family {
  padding-bottom: 75px;
}
#error-msg-family {
  color: red;
  padding-top: 30px;
  text-align: left;
}
.family-space-top {
  padding-top: 75px;
  a {
    text-decoration: underline;
  }
}
.family-space {
  padding-top: 22px;
}
.family-radio-bold {
  font-weight: 700;
}
//only for edeka slider height
#slider-height-edeka {
  @media only screen and (max-width: 768px) {
    height: 750px;
  }
}
.s-content-partication--scroll {
  height: 50px;
}
.top-toshow {
  @media only screen and (min-width: 768px) {
    height: 50px;
  }
}
#endpage-Text {
  font-family: "Recoleta Medium";
}
.title-game {
  padding-top: 30px;
  .heading_dark-lilac, #endpage-Text {
    color: #fff;
  }
}
#messageTshirt {
  padding-top: 13px;
}
.intropage_fairplay {
  .navigation {
    border-color: #3b2772;
  }
  li {
    a {
      color: #3b2772;
    }
  }
}
.fairplay-text {
  padding-bottom: 50px;
  padding-top: 40px;

  h1 {
    color: #3b2772;
  }
  p {
    color: #3b2772;
    line-height: 32px;
  }
  .s-content-title {
    @media only screen and (min-width: 1200px) {
      padding: 0 200px;
    }
  }
}
#wallsio-iframe {
  border: 0;
  height: 150vh;
  width: 100%;
  display: block;
  overflow-y: scroll;
  margin-bottom: 100px;

  @media only screen and (min-width: 781px) {
    width: 80%;
    margin: 0 10%;
    height: 100vh;
    margin-bottom: 50px;
  }
}
.text-regardlessTeam {
  background-color: rgba(229, 225, 239, 0.8);
  max-width: 100%;
  width: 400px;
  text-align: center;
  margin: auto;
}
#text-underTeam {
  text-align: center;
  font-weight: bold;
  padding: 25px;
  line-height: 1.5em;
  font-size: 16px;
  @media only screen and (min-width: 481px) {
    text-align: left;
  }
}
#pennyTicket {
  width: unset !important;
  height: unset !important;
  display: block;
  margin: auto;
  @media only screen and (min-width: 768px) {
    margin-left: 0px;
  }
}
.section-rewe {
  padding-bottom: 100px;
}
#intervogue-paragraph {
  p {
    font-family: "Intervogue Soft Alt Regular";
  }
}
#penny-mitmachen {
  p {
    font-size: 40px;
  }
}

.intervogue-soft-alt {
  font-family: "Intervogue Soft Alt Regular" !important;
}

.netto-slider {
  .swiper-button-disabled,
  .swiper-pagination-bullets {
    display: none;
  }
}

.navigation-newsletter {
  padding-bottom: 25px;
  padding-right: 10px;
  position: relative;
  z-index: 2;
  // padding: 0 20px;
}

.banner__container--white {
  @media only screen and (max-width: 1023px) {
    background-color: #f6f6f8;
    .image__container {
      padding-bottom: 0;
    }
  }
}
.content-holding__heading {
  .content-holding__p {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 80px;
    font-family: $recol;
    max-width: 604px;
    width: 99%;
    @include breakpoint(small-only) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 60px;
    }
  }

  .campaign-heading {
    max-width: 1129px;
    margin: 0 auto;
    margin-top: 50px;
    .match-select{
      font-size: 20px !important;
      font-family: $good-head-pro !important;
    }
    p {
      max-width: 868px;
      width: 95%;
      
    }
    h2 {
      @media only screen and (max-width: 768px) {
        width: 95%;
        margin: 0 auto;
      }
    }
  }

  .navigation-newsletter {
    @media only screen and (max-width: 1023px) {
      background-color: #f6f6f8;
      color: #3b2772;
    }
  }
  .banner-edeka {
    @media only screen and (max-width: 1023px) {
      background-color: #f6f6f8;
      //background: rgb(246,246,248);
      //background: linear-gradient(180deg, rgba(246,246,248,1) 19%, rgba(209,205,230,1) 106%);
    }
    img {
      width: auto;
      margin: 0 auto;
    }
  }
}

.slider__background {
  .slider__image {
    background-color: #d61924;
  }
}

.remindMe-container {
  min-height: 500px;
  max-width: 882px;
  background-color: #fff;
  margin: 0 auto;
  margin-bottom: 100px;
  border-radius: 15px;
  padding: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .custom-inputs .text-content {
    font-size: 16px;
    line-height: 16px;
    color: #3b2772;
    @media only screen and (min-width: 767px) {
      max-width: 657px;
    }
  }
  .notvalid .text-content {
    color: #d93333;
  }
  .custom-inputs .checkmark:after {
    top: -8px;
    @media only screen and (max-width: 767px) {
      top: 6px;
    }
  }
  @include breakpoint(small-only) {
    margin-bottom: 50px;
    padding: 25px;
  }
  @media only screen and (max-width: 882px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .btn-desabled {
    background-color: rgb(207, 207, 207) !important;
    background: none;
  }
  .btn-desabled:hover {
    cursor: not-allowed;
    background-color: rgb(207, 207, 207) !important;
    background: none;
  }

  .email-sent {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3em;
    margin: 20px auto;
    overflow-wrap: break-word;
    text-align: center;
  }

  p {
    color: #3b2772;
    font-size: 24px;
    line-height: 27px;
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
    text-align: left;

    @include breakpoint(medium) {
      width: 95%;
    }
    @include breakpoint(small-only) {
      width: 100%;
    }
  }

  #email-container {
    max-width: 359px;
    margin-left: 15px;
    margin-bottom: 30px;
    @include breakpoint(small-only) {
      margin-left: 0;
    }
  }

  .btn__container {
    max-width: 359px;
    font-size: 20px;
    line-height: 15px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 500;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .form__container {
    width: 60%;
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }
  .img__container {
    width: 40%;
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
    img {
      max-height: 264px;
      object-fit: cover;
      max-width: 230px;
      margin: 0 auto;
      display: block;
    }
  }
}
#main-buzzer {
  .navigation-newsletter {
    @media only screen and (max-width: 1023px) {
      background-color: #f4f4f7;
      color: #3b2772;
    }
  }
  .banner-edeka {
    padding-bottom: 0;
    @media only screen and (max-width: 1023px) {
      background-color: #f4f4f7;
      margin-bottom: 0;
    }

    img {
      width: auto;
      margin: 0 auto;
    }
  }

  .campaign-footer h3.special {
    width: 60%;
    font-size: 25px;
    line-height: 32px;
    font-weight: 400;
    @include breakpoint(small-only) {
      width: 90%;
      font-size: 20px;
      line-height: 26px;
    }
  }
  .campaign-footer {
    padding: 14px 10px 50px;
  }

  .campaign-heading__p {
    font-family: $recol;
  }
  .legal-text {
    font-family: $good-head-pro;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    @media only screen and (min-width: 768px) {
      margin-top: 20px;
      margin-bottom: 70px;
    }
  }
}
.navigation {
  border: none;
  background-color: #fff;
  margin: 0 auto;
  @media only screen and (min-width: 1212px) {
    border-radius: 10px;
  }
  li a {
    color: #3b2772;
  }
}
.s-content-steps__item img {
  width: auto;
}
.s-content-steps__item::before {
  content: none !important;
}

.content-home .btn__container,
.banner__container .btn__container,
.campaign-footer .btn__container {
  font-weight: 500;
  font-size: 20px;
  line-height: 15px;
  letter-spacing: 5px;
}
.content-home .btn__container,
.campaign-footer .btn__container {
  @include breakpoint(small-only) {
    font-size: 18px;
  }
}
.s-content-steps {
  p {
    margin-top: 25px;
  }
}
.campaign-footer .btn__container {
  max-width: none;
  margin-top: 50px;
  @include breakpoint(small-only) {
    margin-top: 25px;
  }
}
.campaign-heading {
  &__p {
    font-size: 25px !important;
    line-height: 32px !important;
    margin-bottom: 80px !important;
    font-weight: 400;
    @include breakpoint(small-only) {
      font-size: 20px !important;
      line-height: 26px !important;
      margin-bottom: 60px !important;
    }
  }
}
#buzzer-participation {
  margin-top: 150px;
  p.special {
    max-width: 1175px;
    color: #fff;
    text-align: center;
    margin: 50px auto;
    margin-bottom: 150px;
    width: 70%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    @include breakpoint(small-only) {
      margin-top: 25px;
      margin-bottom: 100px;
      width: 90%;
    }
  }
  #frm_participation {
    margin-bottom: 0 !important;
    font-family: $good-head-pro;

    #phone {
      @media only screen and (min-width: 1025px) {
        width: 47%;
        margin: 12px;
      }
    }
  }
  #moreInfo {
    color: #3b2774;
  }
  .buzzer-tcs {
    color: #3b2774;
  }
  .campaign-heading {
    h2 {
      margin-top: 100px;
      @include breakpoint(small-only) {
        margin-top: 50px;
        padding-bottom: 25px;
      }
    }
    p {
      font-family: $recol;
    }
  }
}

#main-participation {
  margin-top: 150px;
  p.special {
    max-width: 1175px;
    color: #fff;
    text-align: center;
    margin: 50px auto;
    margin-bottom: 150px;
    width: 70%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    @include breakpoint(small-only) {
      margin-top: 25px;
      margin-bottom: 100px;
      width: 90%;
    }
  }
  #frm_participation {
    margin-bottom: 0 !important;
    font-family: $good-head-pro;

    #phone {
      @media only screen and (min-width: 1025px) {
        width: 47%;
        margin: 12px;
      }
    }
  }
  #moreInfo {
    color: #3b2774;
  }
  .buzzer-tcs {
    color: #3b2774;
  }
  .campaign-heading {
    h2 {
      margin-top: 100px;
      @include breakpoint(small-only) {
        margin-top: 50px;
        padding-bottom: 25px;
      }
    }
    p {
      font-family: $recol;
    }
  }
}
.content-participation {
  &__form {
    background-color: white;
    border-radius: 20px;
    max-width: 1175px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 25px;

    @media only screen and (max-width: 1175px) {
      border-radius: 0;
    }

    .buzzer-form .form-container {
      width: 50%;
      @include breakpoint(small-only) {
        width: 90%;
      }
    }
    .buzzer-form input,
    .buzzer-form select {
      background-color: #fff;
    }
    .form-input__label {
      background-image: none;
      background-color: #fff;
    }
  }
}

#buzzer-participation {
  .s-content-steps__item {
    p {
      font-family: $soft-alt-regular;
    }
  }
  .btn__container {
    font-weight: 500;
    font-size: 20px;
    line-height: 15px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-top: 20px;
    &.buzzer-button{
      font-weight: normal;
      font-family: $good-head-pro-bold;
      @include breakpoint(small-only){
        margin-top: 20px;
      }
    }
    @include breakpoint(small-only) {
      margin-top: 40px;
    }
  }
  hr.separator {
    width: 85%;
  }
  .buzzer-form .form-container #dob-container {
    @media only screen and (max-width: 1024px) {
      order: 9;
    }
  }
  .buzzer-form .form-container .button__code {
    width: 100%;
    @media only screen and (max-width: 1024px) {
      order: 10;
    }
  }
  .space-bottom {
    .full-width {
      width: 65%;
      @include breakpoint(small-only) {
        width: 90%;
      }
    }
  }
  #dob-container {
    #day {
      width: 20%;
    }
    #month {
      width: 30%;
      margin-left: 5%;
    }
    #year {
      width: 40%;
      margin-left: 5%;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 35px;
      label {
        display: block;
        top: -24px;
        left: 14px;
      }
    }
  }
}

#main-participation {
  .s-content-steps__item {
    p {
      font-family: $soft-alt-regular;
    }
  }
  .btn__container {
    font-weight: 500;
    font-size: 20px;
    line-height: 15px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-top: 20px;
    @include breakpoint(small-only) {
      margin-top: 40px;
    }
  }
  hr.separator {
    width: 85%;
  }
  .buzzer-form .form-container #dob-container {
    @media only screen and (max-width: 1024px) {
      order: 9;
    }
  }
  .buzzer-form .form-container .button__code {
    width: 100%;
    @media only screen and (max-width: 1024px) {
      order: 10;
    }
  }
  .space-bottom {
    .full-width {
      width: 65%;
      @include breakpoint(small-only) {
        width: 90%;
      }
    }
  }
  #dob-container {
    #day {
      width: 20%;
    }
    #month {
      width: 30%;
      margin-left: 5%;
    }
    #year {
      width: 40%;
      margin-left: 5%;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 35px;
      label {
        display: block;
        top: -24px;
        left: 14px;
      }
    }
  }
}

.team-retailer__form {
  display: flex;
  @media only screen and (max-width: 768px) {
    display: block;
  }
  .team-container__form,
  .retailer-container__form {
    width: 50%;
    float: left;
    @media only screen and (max-width: 768px) {
      width: 100%;
      float: none;
    }
    
    .form-container {
      width: 100%;
      @media only screen and (max-width: 768px) {
        width: 90%;
      }
      .products-checkbox{
        display: flex;
        width: 278px;
        .form-container{
          .text-content{
            color: #fff;
          }
          .checkmark{
            border: 1px solid #fff;
            background-color: #fff;
          }
        }
      }
    }
  }

  .team-container__form {
    h2.heading {
      font-family: $gobold-bold;
      font-weight: 400;
      font-size: 30px;
      line-height: 32px;
    }
    #text-underTeam {
      font-weight: 400;
    }
  }

  .retailer-container__form {
    h6.centered {
      font-family: $good-head-pro;
      font-size: 25px;
      line-height: 28px;
      font-weight: 400;

      @include breakpoint(small-only) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .product-code__p {
      font-size: 20px;
      line-height: 26px;
      @media only screen and (max-width: 768px) {
        text-align: center;
      }
    }
    .product-code__img {
      width: 311px;
      height: auto;
      margin: 20px auto;
    }

    .product-code__h6 {
      @media only screen and (max-width: 768px) {
        font-size: 26px !important;
        line-height: 31px !important;
        text-align: center;
        width: 90%;
      }
    }
    .notvalid#product-code {
      border: 1px solid #d93333;
    }

    #product-code,
    #product-code::placeholder {
      font-family: "Futura";
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 37px;
      text-align: center;
      color: #7d69ac;
      background: rgba(76, 53, 131, 0.1);
      border-radius: 35.5px;
      border: none;
    }
    #product-code::placeholder {
      background: none;
    }
  }
}
#buzzer-prizes {
  .navigation-newsletter {
    @media only screen and (max-width: 1023px) {
      background-color: #f4f4f7;
      color: #3b2772;
    }
  }

  .banner-edeka {
    padding-bottom: 0;
    @media only screen and (max-width: 1023px) {
      background-color: #f4f4f7;
      margin-bottom: 0;
    }

    img {
      width: auto;
      margin: 0 auto;
    }
  }

  color: #fff;
  .content-home {
    max-width: 1175px;
    margin: 0 auto;
  }
  .campaign-mid {
    max-width: 1175px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 1175px) {
      padding-right: 25px;
      padding-left: 25px;
    }

    div:nth-child(3) {
      margin-left: 4%;
      @media only screen and (max-width: 870px) {
        margin-left: 0;
      }
    }
  }
  .campaign-footer * {
    color: #fff;
  }

  .prize-element {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 50px;
    color: #3b2772;
    background-color: #fff;
    border-radius: 10px;

    img {
      width: 100%;
      object-fit: cover;
    }

    .prize-element__copy {
      width: 100%;
      padding: 50px;

      @media only screen and (max-width: 1175px) {
        padding: 25px;
      }

      h6 {
        font-family: "Recoleta";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 46px;
        margin-bottom: 20px;

        @media only screen and (max-width: 870px) {
          font-family: "Recoleta";
          font-weight: 600;
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 0.4375px;
        }
      }
      p {
        font-size: 20px;
        line-height: 26px;
      }
    }

    &--other {
      width: 48%;
      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      @media only screen and (max-width: 870px) {
        width: 100%;
      }
    }

    &--first {
      img {
        width: 60%;
        max-width: 671px;
        object-fit: cover;

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @media only screen and (max-width: 870px) {
          width: 100%;
          max-width: none;
          border-bottom-left-radius: 0;
          border-top-right-radius: 10px;
        }
      }
      .prize-element__copy {
        width: 40%;
        padding-left: 50px;
        padding-left: 50px;
        padding-top: 40px;

        @media only screen and (max-width: 1175px) {
          padding: 25px;
        }

        @media only screen and (max-width: 870px) {
          width: 100%;
        }
      }
    }
  }
}
#buzzer-faqs {
  .navigation-newsletter {
    @media only screen and (max-width: 1023px) {
      background-color: #f4f4f7;
      color: #3b2772;
    }
  }

  .banner-edeka {
    padding-bottom: 0;
    @media only screen and (max-width: 1023px) {
      background-color: #f4f4f7;
      margin-bottom: 0;
    }

    img {
      width: auto;
      margin: 0 auto;
    }
  }

  .content-faq {
    max-width: 1175px;
    background-color: #fff;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 100px;
    padding: 50px 100px 50px 100px;

    @media only screen and (max-width: 1175px) {
      margin-bottom: 0;
      border-radius: 0;
    }
    @include breakpoint(small-only) {
      padding: 25px;
    }

    &__item {
      position: relative;
      border-top: 2px solid #46337a;

      padding-bottom: 25px;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      color: #3b2772;

      p {
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        color: #3b2772;
        padding-bottom: 10px;
      }
      ul {
        list-style: outside;
        li {
          margin-bottom: 25px;
        }
      }
      h6 {
        max-width: 90%;
        font-style: normal;
        font-weight: bold;
        padding: 25px 0px 0px 0px;
      }
      a {
        text-decoration: underline;
      }
      .item-content {
        display: none;
        padding: 25px;
      }
      .item-content.show {
        display: block;
      }
    }
    &__item:last-child {
      border-bottom: 2px solid #46337a;
    }
    &__img {
      &.shown {
        top: 35px;
      }
      top: 25px;
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
}
.s-content-end {
  h1 {
    color: #fff;
    font-size: 60px;
    line-height: 65px;
    text-align: center;

    @include breakpoint(small-only) {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 0.4375px;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    color: #fff;
    @include breakpoint(small-only) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .end-element {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 50px auto;
    max-width: 1175px;
    justify-content: center;

    &__item {
      width: 31%;
      background-color: #fff;
      padding: 5px;
      border-radius: 8px;
      position: relative;
      margin-bottom: 50px;

      @media only screen and (max-width: 962px) {
        width: 40%;
      }

      @media only screen and (max-width: 730px) {
        width: 100%;
      }

      img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    &__item:nth-child(2),
    &__item:nth-child(3) {
      margin-left: 3%;
      @media only screen and (max-width: 730px) {
        margin-left: 0;
      }
    }
    &__copy {
      padding: 0 0 10px 10px;

      h3 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: $gobold-bold;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #3b2772;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #3b2772;
        margin: 0;
        width: 100%;
        font-family:$good-head-pro;
      }
      button {
        position: absolute;
        bottom: 20px;
        width: 95%;
        max-width: none;
        left: 10px;

        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-family: $good-head-pro;
      }
    }
  }
}
#game-buzzer {
  margin-top: 150px;

  @include breakpoint(small-only) {
    margin-top: 74px;
  }
  .banner-edeka {
    padding-bottom: 0;
  }
  .content-home {
    margin-bottom: 100px;
    h1 {
      width: 90%;
      font-family: Recoleta;
      font-style: normal;
      font-weight: normal;
      font-size: 60px;
      line-height: 65px;
      text-align: center;
      margin: 0 auto;

      @include breakpoint(small-only) {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.4375px;
      }
    }
    @media only screen and (min-width: 481px) {
      .campaign-heading > p {
        width: 55%;
      }
    }
    p {
      font-family: Recoleta;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 32px;
      text-align: center;

      @include breakpoint(small-only) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
#selfie-content {
  margin-top: 150px;
  .none {
    display: none !important;
  }
  #webcam-container {
    background-color: gray;
    width: 90%;
    height: auto;
    margin: 25px auto;
    margin-bottom: 100px;
    padding-bottom: 10px;
    background-color: #2c253c;
    max-width: 768px;

    @include breakpoint(small-only) {
      width: 100%;
    }
  }

  #webcam-capture {
    width: 90%;
  }
  #webcam {
    //width: 100%;
  }

  @include breakpoint(small-only) {
    margin-top: 74px;
  }
  @media only screen and (min-width: 481px) {
    .campaign-heading > p {
      width: 55%;
    }
  }
  .content-home {
    h1 {
      width: 90%;
      font-family: Recoleta;
      font-style: normal;
      font-weight: normal;
      font-size: 60px;
      line-height: 65px;
      text-align: center;
      margin: 0 auto;

      @include breakpoint(small-only) {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.4375px;
      }
    }
    p {
      font-family: Recoleta;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 32px;
      text-align: center;

      @include breakpoint(small-only) {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
  .campaign-selfie {
    background-color: #fff;
    max-width: 1175px;
    margin: 0 auto;
    border-radius: 15px;
    color: #3b2774;
    padding: 50px;
    padding-top: 100px;
    margin-bottom: 100px;
    margin-top: 50px;

    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 1175px) {
      border-radius: 0;

      padding: 25px;
      padding-top: 50px;
      margin-bottom: 0px;
    }
    @media only screen and (max-width: 418px) {
      padding-bottom: 25px;
      padding-left: 0;
      padding-right: 0;
    }

    .separator {
      width: 90%;
    }

    h3 {
      font-family: Recoleta;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 46px;
      color: #3b2774;
      text-align: none;

      @include breakpoint(small-only) {
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.4375px;
      }
    }
    &__text {
      width: 50%;
      margin-bottom: 100px;
      @media only screen and (max-width: 949px) {
        width: 100%;
      }
      @include breakpoint(small-only) {
        margin-bottom: 50px;
      }
      p {
        width: 90%;
        font-family: Recoleta;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 28px;
        color: #3b2774;
        margin-top: 25px;
        @include breakpoint(small-only) {
          font-family: "Intervogue Soft Alt";
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          margin: 25px auto;
        }
      }
    }
    &__create {
      width: 50%;
      margin-bottom: 100px;
      @media only screen and (max-width: 949px) {
        width: 100%;
      }
      .star-selfie--image {
        max-width: 468.82px;
        display: block;
        margin: 0 auto;
        @include breakpoint(small-only) {
          width: 331px;
        }
      }
      .image-notvalid {
        width: 100%;
        text-align: center;
        color: #d93333 !important;
        padding: 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3em;
      }
      .star-selfie__container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .star-selfie--webcam {
          cursor: pointer;
          margin-top: 15px;
          transform-origin: 0.5s;
        }
        .star-selfie--gallery {
          cursor: pointer;
          margin-left: 10%;
          margin-top: 15px;
          transform-origin: 0.5s;
          @include breakpoint(small-only) {
            margin-left: 5%;
          }
        }
        .star-selfie--webcam:hover,
        .star-selfie--gallery:hover {
          transform: scale(1.1);
        }
      }
    }
    &__news {
      width: 100%;
      margin-top: 100px;
      margin-bottom: 50px;
      @include breakpoint(small-only) {
        margin-top: 50px;
        padding-left: 25px;
        padding-right: 25px;
      }
      h3 {
        font-family: Recoleta;
        font-style: normal;
        font-weight: normal;
        font-size: 60px;
        line-height: 65px;
        text-align: center;
        color: #3b2772;

        @include breakpoint(small-only) {
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 0.4375px;
        }
      }

      p {
        font-family: Recoleta;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        color: #3b2772;
        margin-top: 25px;
        margin-bottom: 25px;
        @include breakpoint(small-only) {
          font-size: 22px;
          line-height: 28px;
        }
      }
      button {
        width: 488px;
        max-width: none;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 5px;
        text-transform: uppercase;
        color: #fff;
        @include breakpoint(small-only) {
          width: 100%;
          font-size: 18px;
          line-height: 15px;
        }
      }
    }
    .campaign-selfie__btn {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 5px;
      text-transform: uppercase;
      max-width: none;
      margin: 0;
      margin-top: 25px;

      @media only screen and (max-width: 895px) {
        margin: 25px auto;
        font-size: 18px;
      }

      @media only screen and (max-width: 768px) {
        margin-bottom: 100px;
      }
    }
    .campaign-selfie__submit {
      width: 50%;
      margin-bottom: 50px;
      @media only screen and (max-width: 895px) {
        width: 100%;
        margin-bottom: 25px;
      }
      p {
        margin-top: 25px;
        font-family: Recoleta;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 32px;
        color: #3b2774;
        width: 90%;
        @media only screen and (max-width: 895px) {
          font-size: 22px;
          line-height: 28px;
          text-align: center;
          margin: 25px auto;
        }
      }
    }

    #star-selfie-image {
      width: 50%;
      max-width: 468.82px;
      margin: 0 auto;
      margin-bottom: 50px;
      @media only screen and (max-width: 895px) {
        width: 100%;
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
}
.cookie-page__content {
  padding: 25px;
  h2,
  h3 {
    padding-bottom: 25px;
  }
  #cookie-policy-title {
    margin-top: 25px;
  }
  ul {
    list-style: outside;
    li {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 50px;
    }
  }
  #ot-sdk-btn {
    margin-top: 25px;
  }
}
.campaign-selfie__validation {
  margin-bottom: 100px;
  @include breakpoint(small-only) {
    margin-bottom: 50px;
  }
  h3 {
    font-family: Recoleta;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    color: #3b2774;
    width: 90%;
    margin-bottom: 25px;
    margin: 0 auto;

    @include breakpoint(small-only) {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 0.4375px;
      color: #3b2772;
    }
  }
  .form-container__checkbox {
    width: 80%;
    padding: 25px;
    margin: 0 auto;
    @include breakpoint(small-only) {
      width: 95%;
      padding: 25px 0px 25px 0px;
    }
  }
  #selfie-image {
    max-width: 902px;
    min-width: 331px;
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .action-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    #redo,
    #next {
      margin-left: 25px;
    }
    #edit,
    #redo,
    #next {
      cursor: pointer;
      transition-duration: 0.5s;
    }
    #edit:hover,
    #redo:hover,
    #next:hover {
      transform: scale(1.2);
    }

    #redo,
    #next,
    #edit {
      img {
        display: block;
        margin: 10px auto;
      }
      p {
        color: #3b2772;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  .notvalid span.text-content {
    color: #dd4747 !important;
  }
}

.campaign-selfie__validation.edit {
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;
}

#image-edited {
  position: relative;
  width: 100%;
  background-color: #fff;

  @media only screen and (max-width: 949px) {
    width: 550px;
  }
  @media only screen and (max-width: 593px) {
    width: 375px;
  }
}

//cropit
#cropit-preview-local {
  background-color: #f8f8f8;
  background-size: cover;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 7px;

  width: 248px;
  height: 350px;
  position: absolute !important;
  top: 115px;
  left: 347px;

  -webkit-clip-path: path(
    "M2.9,158C-2.3,122.6,0.8,6.8,111.8,4.5C235.1,2,216.9,146.7,214.2,164.3c-0.2,1.1-33,157.5-106.4,157.5 C51.9,321.8,10.5,209.4,2.9,158z"
  );
  clip-path: path(
    "M2.9,158C-2.3,122.6,0.8,6.8,111.8,4.5C235.1,2,216.9,146.7,214.2,164.3c-0.2,1.1-33,157.5-106.4,157.5 C51.9,321.8,10.5,209.4,2.9,158z"
  );

  transform: scale(0.89);
  @media only screen and (max-width: 949px) {
    top: 8px;
    left: 167px;
    transform: scale(0.6);
  }
  @media only screen and (max-width: 593px) {
    top: -54px;
    left: 75px;
    transform: scale(0.4);
  }
}

.cropit-preview-image-container {
  cursor: move;
}

.image-editor-container {
  width: 100%;
}

.actions-container {
  width: 100%;
  margin-top: 50px;
  &__btn {
    display: flex;
    flex-direction: row;
    button {
      max-width: 208px !important;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 5px;
      text-transform: uppercase;

      @include breakpoint(small-only) {
        max-width: 160px !important;
        font-size: 14px;
      }
    }
  }
  &__zoom {
    display: block;
    margin: 25px auto;
    width: 80%;
    -webkit-appearance: none;
    appearance: none;
    height: 5px;
    background: #7d69ac;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    @media only screen and (max-width: 949px) {
      width: 60%;
    }
  }
  &__zoom:hover {
    opacity: 1;
  }
  &__zoom::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #7d69ac; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 15px;
  }

  &__zoom::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #7d69ac; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 15px;
  }

  .rotation-container {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
  }
  .btn-rotate {
    width: 30px;
    height: 30px;
    margin: 20px;
    transition-duration: 0.5s;
  }
  .btn-rotate:hover {
    transform: scale(1.2);
  }
}

.intropage_menu {
  @media only screen and (max-width: 976px) {
    overflow: auto;
  }
}

.intropage_nav {
  @media only screen and (max-width: 840px) {
    justify-content: flex-start;
    padding-left: 50px;
  }
}

.banner__container--red {
  .image__container {
    @media only screen and (max-width: 1023px) {
      background-color: #d20515;
    }
  }
  .image__container {
    padding-bottom: 0;
  }
}
.navigation-newsletter.penny,
.navigation-newsletter.edeka {
  @media only screen and (max-width: 1023px) {
    background-color: #d20515;
    a {
      color: #fff;
    }
  }
}

.banner-edeka.penny,
.banner-edeka.edeka {
  @media only screen and (max-width: 1023px) {
    background-color: #d20515;
    img {
      width: auto;
      margin: 0 auto;
    }
  }
}
.banner-edeka.main {
  @media only screen and (max-width: 1023px) {
    margin-bottom: 0;
  }
}

.penny,
.edeka {
  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination {
    display: none;
  }
}

.image__container.start {
  padding-bottom: 25px;
  .participate-section {
    @media only screen and (min-width: 1024px) {
      margin-left: 150px;
    }
  }
}
.campaign-heading {
  h4 {
    max-width: 1129px;
    margin: 50px auto;
    width: 80%;
    @media only screen and (max-width: 768px) {
      width: 95%;
    }
  }
}
.prizeRewe {
  padding-top: 100px;
  @include breakpoint(small-only) {
    padding-top: 50px;
  }
}
.slidernew-container {
  background-color: #d61924;
  color: #fff;
  .swiper-slide {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 1059px) {
      display: table;
    }
  }

  .slidernew {
    &-image {
      width: 60%;
      @media only screen and (max-width: 1200px) {
        width: 50%;
      }
      @media only screen and (max-width: 1059px) {
        width: 100%;
        display: table-footer-group;
      }
      img {
        height: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    &-copy {
      width: 40%;
      padding: 50px;
      padding-left: 0;
      padding-top: 0;

      @media only screen and (max-width: 1200px) {
        padding: 20px;
        padding-left: 20px;
        padding-top: 50px;
        width: 50%;
      }

      @media only screen and (max-width: 1059px) {
        order: 1;
        width: 100%;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
        padding-bottom: 50px;
      }

      &__header {
        font-family: $recol;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 46px;
        @include breakpoint(small-only) {
          font-size: 30px;
          line-height: 32px;
        }
      }
      &__description {
        margin-top: 25px;
        font-family: $soft-alt;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        max-width: 500px;

        @include breakpoint(small-only) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}

.buzzer-netto {
  background-color: #7d69ac;
  .campaign-heading h2 {
    max-width: 825px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 40px;
    max-width: 551px;
    line-height: 46px;
  }
  .campaign-heading h4 {
    font-family: "Intervogue Soft Alt Thin";
    font-size: 20px;
    a {
      font-family: "Intervogue Soft Alt Thin";
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .content-home.endpage-rewe {
    padding: 0;
    .campaign-heading {
      padding: 0;
      margin-bottom: 0;
      p{
        width: 90%;
        margin-top: 30px;
      }
    }
  }
  .netto-section_one {
    h4 {
      margin-top: 0 !important;
      &:first-of-type {
        margin-bottom: 20px !important;
      }
    }
  }
  .netto-section_two {
    background-color: #3b2772;
    padding: 35px 0;
    @media only screen and (min-width: 1025px) {
      padding: 60px 0;
    }
    .btn__container {
      margin-bottom: 13px;
    }
    .participation-condition {
      font-size: 24px;
    }
  }
  .prizeRewe {
    background-color: #3b2772;
    background-image: none;
    * {
      color: #fff;
    }
    .btn__container.btn--red {
      background-color: #7d69ac;
    }
    .participation-contidion{
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 28px;
      & + p{
        font-size: 20px;
      }
    }
    .content-faq{
      padding: 25px 25px 15px;
    }
    .s-content-copy .s-content-copy__item.footer {
      padding-top: 0;
      padding-bottom: 0;
      @media only screen and (min-width: 768px) {
        width: 833px;
        margin: 0 auto;
      }
      .headings{
        @media only screen and (max-width: 450px) {
          font-size: 29px;
        }
      }
      .netto-competition{
        font-size: 20px;
        line-height: 29px;
        @media only screen and (min-width: 768px) {
          font-size: 24px;
        }
      }
    }
    #instructions {
      text-align: justify;
    }
  }
}

.campaign-footer h2 {
  max-width: 550px;
}

.zoom-btn-container {
  position: relative;
  .minus {
    left: 25px;
    top: -8px;
    position: absolute;
    cursor: pointer;
    transition-duration: 0.5s;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .plus {
    right: 25px;
    top: -10px;
    position: absolute;
    cursor: pointer;
    transition-duration: 0.5s;
  }
  .plus:hover,
  .minus:hover {
    transform: scale(1.2);
  }
}

.buzzer-jersey.lottery#confirmation{
  margin-top: -57px;

  @include breakpoint(small-only){
        padding-top: 20px;
  }

  img#ticket-img{
    width: 100%;
  }
  
  .spaceTile-intropage {
    @media only screen and (min-width: 481px)  {
      margin-bottom: 70px;
    }
  }
  .campaign-heading{
    padding: 0;
    margin: 0;

    h2 {
      font-family: $recoleta-medium;
      font-size: 40px;
      font-weight: 700;
      line-height: 54px;
      color: #fff;
      max-width: 939px;
      margin: 0 auto;
      padding-top: 70px;
      width: 90%;
      @include breakpoint(small-only){
        font-size: 30px;
        line-height: 41px;
        margin-top: 0px;
      }
    }
    h4 {
      font-family: $recol;
      font-weight: 500;
      font-size: 30px;
      line-height: 32px;
      color: #fff;
      margin: 0 auto !important;
      margin-top: 25px !important;
      max-width: 939px;
      width: 90%;
      @include breakpoint(small-only){
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 0px !important;
      }
    }
    .section-heading{
      margin: 60px 0 0 0;
    }
    .section-text-button__text {
      @media only screen and (min-width: 481px)  {
        margin-top: 49px;
      }
    }
    .btn-simple {
      @media only screen and (min-width: 481px)  {
        margin-top: 0 !important;
        width: 290px!important;
        letter-spacing: 4px;
      }
    }
  }
  .s-content-end, .end-element{
    margin-top: 0;
    &__copy{
      &__title{
        font-family: $gobold-bold;
        font-size: 24px;
      }
    }
  }
  .s-content-end .end-element .end-element__item {
    height: 480px;
    @media only screen and (min-width: 731px) {
      height: 460px;
    }
  }
  .s-content-end .end-element__copy button {
    @media only screen and (min-width: 731px) {
      bottom: 10px;
    }
  }
}

.q3-game{
  //border-bottom: 10px solid#e8cf75;
  .image__img{
    @media only screen and (max-width:1025px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .game-text__container {
    @media only screen and (max-width:1023px) {
      position: absolute;
      z-index: 3;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 100%;
      padding-bottom: 70px;
    }
  }
}
.image__container .game-text__container .form-button {
  margin-top: 20px;
  position: unset;
  justify-content: center;
  @media only screen and (min-width: 1025px) {
    justify-content: center;
  }
}
.live-game-text__container {
/*   @media only screen and (min-width:481px) {
    position: absolute;
    width: 80%;
    
    left: 50%;
    transform: translate(-50%, -23%);
    bottom: 0;
    z-index: 3;
  } */
  
  @media only screen and (min-width: 1025px) {
    position: absolute;
    transform: translate(-50%, -16%);
    width: 43%;
    left: initial;
    right: 0;
    transform: none;
    top: 0;
    z-index: 3;
  }
  @media only screen and (max-width: 1025px) {
    border-bottom: 10px solid #e8cf73;
  }
  @media only screen and (max-width: 481px) {
    padding-bottom: 25px;
  }

  @media only screen and (min-width: 481px) and (max-width: 1025px) {
    padding-bottom: 40px;
  }

/*   & + picture img {
    @media only screen and (min-width: 1025px) {
      z-index: 4;
    }
  }
 */
  .live-game-title__content {
/*     @media only screen and (min-width: 1300px) and (max-width: 1440px) {
      margin-top: 70px;
    } */
    @media only screen and (min-width: 1440px) {
      margin-top: 70px;
    }
    @media only screen and (min-width: 1566px) {
      margin-top: 22%;
    }
    @media only screen and (min-width: 2070px) {
      margin-top: 31%;
    }
    h1 {
      @media only screen and (min-width: 1025px) and (max-width: 1240px)  {
        font-size: 34px;
        line-height: 31px;
      }
    }
    h2 {
      font-family: "Recoleta";
      font-size: 22px;
      line-height: 30px;
      max-width: 289px;
      margin: 0 auto;
      margin-bottom: 15px;
      @media only screen and (min-width: 1025px) {
        font-size: 29px;
        line-height: 43px;
        max-width: max-content;
      }
      
      @media only screen and (min-width: 481px) and (max-width: 1025px) {
        font-size: 32px;
        line-height: 38px;
        max-width: 609px;
        margin: 0 auto;
        margin-bottom: 8p
      }
      @media only screen and (min-width: 1025px) and (max-width: 1240px)  {
        font-size: 26px;
        line-height: 31px;
      }
    }
    
    p {
      @media only screen and (min-width: 481px) and (max-width: 1025px) {
        max-width: 535px;
      }
    }
  }

  .form-button-participate{
    .btn__container {
      background-color: #CF1F16;
      border: 2px solid #FFFFFF;
      width: 314px;
      @media only screen and (min-width: 1025px) {
        width: 325px;
      }
      &::before {
        background-color: #CF1F16;
      }
    }
  }
}
.coutdown-dark__wrapper{
  @media only screen and (max-width: 1024px) {
    background-image: url('/resources/imagesBundesliga/90_min/banner/counter-background.png');   
    background-repeat: no-repeat;
    background-size: cover;

  }
  @media only screen and (max-width: 481px) {
    max-height: 130px;
    min-height: 130px;
  }
}
.live-game-title__content{
  margin-top: 50px;
  text-align: center;
  @media only screen and (min-width: 1025px) {
    margin-top: 20px;
    max-width: 540px;
    margin: 0 auto;
    margin-top: 30px;
  }
  h1{
    font-family: "Recoleta Medium";
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 10px;
    @media only screen and (min-width: 1025px) {
      font-size: 44px;
      line-height: 61px;
    }
  }
  h2 {
    font-family: "Recoleta";
    @media only screen and (min-width: 1025px) {
      font-size: 29px;
      line-height: 47px;
    }
  }
  p{
    color: #fff;
    font-size: 19px;
    line-height: 22px;
    margin-top: 11px;
    max-width: 297px;
    margin: 0 auto;
    @media only screen and (min-width: 1025px) {
      max-width: fit-content;
    }
  }
}
.form-button-participate{
  margin-top: 30px;
}
.timer-dark{
  text-align: center;
  height: 251px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 481px) {
    height: auto;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1066px) {
    height: 187px;
  }
  @media only screen and (min-width: 1066px) and (max-width: 1126px) {
    height: 221px;
  }
  @media only screen and (min-width: 1126px) and (max-width: 1180px) {
    height: 251px;
  }
  @media only screen and (min-width: 1180px) and (max-width: 1240px) {
    height: 261px;
  }
}
.timer-dark > span{
  color: #fff;
  font-family: 'Digital-7 Regular';
  font-size: 170px;
  @media only screen and (max-width: 481px) {
    font-size: 129px;
  }
}

.game-text__container {
  @media only screen and (min-width: 1023px) {
    position: absolute;
    width: 40%;
    left: initial;
    right: 50px;
    bottom: 6%;
    z-index: 3;
  }
}
.game-text__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.game-title__content{
  margin-bottom: 10px;
  text-align: center;
  h1{
    font-family: "Recoleta Medium";
    color: #fff;
    @media only screen and (min-width: 1025px) {
      font-size: 36px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      font-size: 72px;
      margin-bottom: 44px;
    }
  }
  h2 {
    font-family: "Recoleta";
    @media only screen and (min-width: 1025px) {
      font-size: 25px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {   
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 30px;
    }
  }
}
.coutdown-wrapper {
  .timer{
    display: flex;
    font-family: "Recoleta Medium";
    color: #DDC6A2;
    font-size:45px;
    letter-spacing: 5px;
  }
  span{
    font-family: "Recoleta Medium";
    color: #DDC6A2;
    font-size:45px;
    letter-spacing: 4px;
    text-align: center;
  }
  p{
    font-family: "Intervogue Soft";
    text-transform: uppercase;
    font-size:12px;
    color: #fff;
    letter-spacing: 1px;
  }
} 

//90min Q3 Anouncement page

.content-holding__heading.q3-announment {
  .campaign-heading{
    max-width: 820px;
    @media only screen and (min-width: 481px) {
      margin-bottom: 70px;
      margin-top: 61px;
    }
    h1 {
      font-family: $gobold-bold;
      /* text-transform: uppercase; */
      line-height: 58px;
      font-size: 48px;
      max-width: unset;     
      margin-bottom: 28px;
      @media only screen and (min-width: 768px) {
        line-height: 65px;
        margin-bottom: 0;
        font-size: 60px;
      }
      @media only screen and (max-width: 481px) {
        line-height: 32px;
        font-size: 30px;
      }
      @media only screen and (min-width: 481px) {
        padding-top: 0;
      }
    }
    p{ 
      /* font-family: "Intervogue Soft Alt Regular"; */
      font-family: $good-head-regular;
      font-family: $good-head-pro-regular;
      font-size: 24px;
      line-height: 32px;
      width: 100%;
      @media only screen and (max-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        /* font-family: "Intervogue Soft Alt Regular"; */
        font-family: $good-head-regular;
        max-width: 335px;
      }
      &:first-of-type{
        @media only screen and (max-width: 481px) {
          margin-bottom: 45px;
        }
      }
      span{
        font-weight: bold;
        font-family: $recol;
      }
    }
  }
  .spaceTile-intropage_v1{
    padding-top: 10px;

    @media screen and (min-width:481px){
      padding-top: 30px;
    }
  }
  .remindMe-container {
    margin-bottom: 140px;
    padding-bottom: 6px;      
    @media only screen and (min-width: 481px) {
      padding-bottom: 61px;
    }
    .form__container {
      width: 62%;
      @media only screen and (max-width: 650px) {
        width: 100%;
      }
    }
    .img__container {
      width: 38%;
      @media only screen and (max-width: 650px) {
        width: 100%;
      }
    }
    p{
      font-family: $good-head-regular;
      width: 100%;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      @media only screen and (min-width: 481px) {
        line-height: 30px;
        text-align: left;
        margin-bottom: 24px;
      }
    }
    .email-sent {
      max-width: 375px;
      margin: -50px 0 0 0px;      
      @media only screen and (max-width: 481px) {
        margin: 0;  
      }
      @media only screen and (min-width: 481px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media only screen and (min-width: 481px) and (max-width: 816px) {
        font-size: 16px;
        line-height: 24px; 
        margin-top: 10px;
      }
    }
    #email-container, #firstName{
      margin-left: 0;
      @media only screen and (min-width: 481px) {
        max-width: 445px;
      }
    }
    #firstName {
      margin-bottom: 10px;
    }
    .custom-inputs .text-content {
      @media only screen and (min-width: 481px) {
        margin-right: 90px;
      }
    }
    .btn__container{
      font-family: $good-head-regular;
      margin-top: 20px;
    }
    .checkmark{
      height: 28px;
      width: 28px;
      border-radius: 10px;
      &:after {
        left:2px;
      }
    }
    .text-content{
      padding-left: 10px;
    }
    .form-container {
      margin-top: 0;
      margin-bottom: 4px;
      @media only screen and (min-width: 481px) {
        width: 100%;
        margin-bottom: 30px;
      }
      .input-container {
        @media only screen and (min-width: 650px) {
          max-width: 736px;
        }
      }
    }
    #name-terms {
      .text-content {
        font-family: $good-head-regular;
        line-height: 24px;
        @media only screen and (min-width: 481px) {
          margin-right: 0;
          max-width: unset;
        }
      }
    }
  }
}

.main-content.main-content--dark-lilac.gewinnen-background {
  background-color: #7D69AC;
}
.content-holding__heading.q4-announment {
  background-color: #7D69AC;
  .campaign-heading{
    @media only screen and (min-width: 481px) {
      margin-bottom: 70px;
      margin-top: 61px;
    }
    h1 {
      font-family: $gobold-bold;
      /* text-transform: uppercase; */
      line-height: 58px;
      font-size: 48px;
      max-width: unset;     
      margin-bottom: 28px;
      @media only screen and (min-width: 768px) {
        line-height: 65px;
        margin-bottom: 0;
        font-size: 60px;
        max-width: 820px;
        margin: 0 auto;
      }
      @media only screen and (max-width: 481px) {
        line-height: 32px;
        font-size: 30px;
      }
      @media only screen and (min-width: 481px) {
        padding-top: 0;
      }
    }
    p{ 
      /* font-family: "Intervogue Soft Alt Regular"; */
      font-family: $good-head-regular;
      font-family: $good-head-pro-regular;
      font-size: 24px;
      line-height: 32px;
      width: 100%;
      max-width: 783px;
      @media only screen and (max-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        /* font-family: "Intervogue Soft Alt Regular"; */
        font-family: $good-head-regular;
        max-width: 347px;
      }
      &:first-of-type{
        @media only screen and (max-width: 481px) {
          margin-bottom: 45px;
        }
      }
      span{
        font-weight: bold;
        font-family: $recol;
      }
    }
  }
  .spaceTile-intropage_v1{
    padding-top: 10px;

    @media screen and (min-width:481px){
      padding-top: 30px;
    }
  }
  .remindMe-container {
    margin-bottom: 140px;
    padding-bottom: 6px;      
    @media only screen and (min-width: 481px) {
      padding-bottom: 61px;
    }
    .form__container {
      width: 62%;
      @media only screen and (max-width: 650px) {
        width: 100%;
      }
    }
    .img__container {
      width: 38%;
      @media only screen and (max-width: 650px) {
        width: 100%;
      }
    }
    p{
      font-family: $good-head-regular;
      width: 100%;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      @media only screen and (min-width: 481px) {
        line-height: 30px;
        text-align: left;
        margin-bottom: 24px;
      }
    }
    .email-sent {
      max-width: 375px;
      margin: -50px 0 0 0px;      
      @media only screen and (max-width: 481px) {
        margin: 0;  
      }
      @media only screen and (min-width: 481px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media only screen and (min-width: 481px) and (max-width: 816px) {
        font-size: 16px;
        line-height: 24px; 
        margin-top: 10px;
      }
    }
    #email-container, #firstName{
      margin-left: 0;
      @media only screen and (min-width: 481px) {
        max-width: 445px;
      }
    }
    #firstName {
      margin-bottom: 10px;
    }
    .custom-inputs .text-content {
      @media only screen and (min-width: 481px) {
        margin-right: 90px;
      }
    }
    .btn__container{
      font-family: $good-head-regular;
      margin-top: 20px;
    }
    .checkmark{
      height: 28px;
      width: 28px;
      border-radius: 10px;
      &:after {
        left:2px;
      }
    }
    .text-content{
      padding-left: 10px;
    }
    .form-container {
      margin-top: 0;
      margin-bottom: 4px;
      @media only screen and (min-width: 481px) {
        width: 100%;
        margin-bottom: 30px;
      }
      .input-container {
        @media only screen and (min-width: 650px) {
          max-width: 736px;
        }
      }
    }
    #name-terms {
      .text-content {
        font-family: $good-head-regular;
        line-height: 24px;
        @media only screen and (min-width: 481px) {
          margin-right: 0;
          max-width: unset;
        }
      }
    }
  }
}

//90min Q3 start page
.content-home.game-90-min {
  .campaign-heading h1{
    font-size: 30px;
    line-height: 32px;
    max-width: 297px;  
    margin-bottom: 10px;
    margin-top: 45px;
    @media only screen and (min-width: 481px) {
      font-size: 48px;
      line-height: 53px;
      max-width: 1013px;
      margin: 0 auto;
      margin-bottom: 60px;
    }
  }
  .s-content-steps__item{
    min-width: 289px;
    @media only screen and (min-width: 481px) {
      min-width: 263px;
    }
    @media only screen and (min-width: 1025px) {
      padding: 0;
    }
    &::after{
      content: none;
    }
    picture {
      @media only screen and (min-width: 1025px) {
        height: 141px;
        display: flex;
      }
    }
    img {
      @media only screen and (min-width: 1025px) {
        height: auto;
        width: auto;
        display: flex;
        align-self: center;
      }
      @media only screen and (max-width: 768px) {
        width: auto;
      }
    }
    p {
      max-width: 253px;
      margin: 0 auto;
      margin-top: 40px;
      font-size: 20px;
      line-height: 26px;
      a{
        font-weight: normal;
      }
    }
  }
  .text--wheat{
    color:#E8CF73!important;;
  }
  .note-promo-code {
    max-width: 345px;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    @media only screen and (min-width: 481px) {
      max-width: 587px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  #edeka-terms {
    font-size: 20px;
    line-height: 24px;
    @media only screen and (max-width: 481px) {
      padding-bottom: 0;
    }
  }
}

#main-buzzer{
  .reward_90min {
    .prizeRewe-card__primary{
      padding-bottom: 31px;
      @media only screen and (min-width: 481px) {
        max-width: 489px;
        width: unset;
        margin-top: 93px;
        padding-bottom: 25px;
      }
      @media only screen and (min-width: 1226px) {
        margin-right: 30px;
      }
      @media only screen and (max-width: 1226px) {
        margin-right: 0;
      }
      h3{
        font-size: 30px;
        line-height: 32px;
        margin: 0 auto 20px;
        @media only screen and (min-width: 481px) {
          margin-bottom: 20px;
        }
      }
    }
    .prizeRewe-card__wrapper {
      @media only screen and (min-width: 1194px) {
        margin-top: 93px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .prizeRewe-card__secondary{
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 30px 0px;
      @media only screen and (max-width: 480px) {
        min-height: 430px;
        padding: 30px 13px;
        padding-top: 10px;
        margin-top: 31px;
        margin-bottom: 30px;
      }
      @media only screen and (min-width: 480px) {
        margin-top: 49px;
      }
      @media only screen and (min-width: 585px) {
        justify-content: space-between;
        flex-direction: row;
        max-width: 656px;
        width: unset;
        min-height: unset;
      }
      @media only screen and (min-width: 1194px) {
        margin-top: 0;
      }
      &:last-child img {
        @media only screen and (min-width: 585px) {
          margin-left: 30px;
        }
      }
      h3{
        margin-top: 0!important;
      }
      .prizeRewe-card__text{
        max-width: 343px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media only screen and (min-width: 585px) {
          margin-right: 30px;
        }
        *{
          text-align: center;
          margin: 0;
          width: 100%;
          @media only screen and (min-width: 585px) {
            text-align: left;
          }
        }
        p {
          color:#fff;
          span {
            color:#fff;
          }
          .bold-text {
            font-weight: bold;
          }
        }
        h3{
          font-size: 25px;
          line-height: 28px;
          margin-bottom: 13px;
        }
      }
      .prizeRewe-card__img{
        display: flex;
        flex-direction: column;
        img{
          margin-top: 0;
          align-self: center;
        }
        p {
          @media only screen and (max-width: 481px) {
            margin-bottom: 10px;
          }
        }
      }
      &.secon {
        .prizeRewe-card__img{
          p {
            margin-left: 25px;
            @media only screen and (max-width: 481px) {
              margin: 0 auto;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

#main-buzzer {
  .prizeRewe {
    &.reward_90min {
      @media only screen and (max-width: 481px) {
        padding-bottom: 20px !important;
      }
    }
  }
}

//90 min participation
#buzzer-participation.promo_90min{
  .s-content-steps{
    /* flex-wrap: nowrap; */
    @media only screen and (min-width: 1025px) {
      width: 100%;
      max-width: 1310px;
    }
  }
  .s-content-steps__item{
/*     min-width: unset;
    @media only screen and (max-width: 481px){
      width: 140px;
      padding: 0;
      display: flex;
      align-self: center;
      justify-content: center;
    }
    padding: 25px 27px; */
    &::after{
      content:none;
    }
    &:nth-child(1) {
      p {
        max-width: 253px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 253px;
        }
      }
    }
    &:nth-child(2) {
      margin-top: -40px;
      @media only screen and (max-width: 768px){
        margin-top: 0;
      }
      img{
        @media only screen and (max-width: 481px){
          width: 230px;
        }
      }
      p {
        max-width: 250px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 250px;
        }
      }
    }
    &:nth-child(3) {
      p {
        max-width: 250px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 250px;
        }
      }
    }
    &:nth-child(4) {
/*       img{
        @media only screen and (max-width: 481px){
          width: 60px;
        }
      } */
      p {
        max-width: 250px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 260px;
        }
      }
    }
    p{
      font-size: 20px;
      line-height: 26px;
      margin: 0 auto;
      margin-top: 30px;
      @media only screen and (min-width: 481px){
        margin-top: 60px;
      }
    }
  }
  .content-home picture img{
    @media only screen and (max-width: 481px){
      width: 150px;
    }
  }
  
  .buzzer-form{
    #salutation p {
      font-size: 16px;
    }
  }
  .content-participation__form{
    .buzzer-form{
      h2 {
        @media only screen and (max-width: 481px) {
          font-size: 30px;
        }
      }
      h3 {
        font-family: "Recoleta";
        text-align: center;
        @media only screen and (min-width: 1025px) {
          font-size: 25px;
        }
      }
    }
    #dob-container.desktop{
      .custom-inputs{
        padding-right: 0;
        .text-content{
          padding-left: 50px;
          white-space: nowrap;
        }
      }
      .checkmark{
        right: unset;
        left: 0;
        top: -7px;
      }
    }
    
    #participant-info{
      @media only screen and (max-width: 480px) {
        margin-bottom: 20px;
      }
    }
    

    .campaign-condition{
      color: #fff;
      text-align: center;
      @media only screen and (min-width: 481px) {
          width: 80%;
          margin: 0 auto;
      }
      .campaign-condition-container{
        padding: 0 10px;
        .condition-content{
          max-width: 597px;
          margin: 0 auto;
        }
        h3 {
          font-family: 'Recoleta';
          font-size: 30px;
          line-height: 32px;
          margin-bottom: 20px;
          @media only screen and (min-width: 481px) {
            font-size: 40px;
            margin-bottom: 30px;
          }
          &.condition-title{
            margin-top: 70px;
            @media only screen and (min-width: 481px) {
              margin-top: 90px;
            }
          }
        }
        p {
          font-size: 20px;
          line-height: 26px;
          &.condition-text{
            margin-bottom: 20px;
            @media only screen and (min-width: 481px) {
              margin-bottom: 30px;
            }
          }
          span{
            font-weight: 700;
          }
        }
        hr{
          margin-top: 40px;
          @media only screen and (min-width: 481px) {
            margin-top: 90px;
          }
        }
      }
    }
    .checkmark{
      border-radius: 9px;
    }

    #name-terms {
      margin-bottom: 10px;
      padding-bottom: 0;
    }
    a#moreInfo {
      margin-left: 53px;
    }
    .campaign-consent{
      @media only screen and (min-width: 481px) {
        width: 80%;
        margin: 0 auto;
      }
    }
    .campaign-consent {
      padding: 20px 20px 0;
    }
    .product-code__text{
      max-width: 502px;
      margin: 0 auto;
      margin-top: 100px;
      color: #fff;
      text-align: center;
      h2{
        font-family: 'Recoleta';
        font-size: 40px;
        line-height: 32px;
        margin-bottom: 33px;
      }
      h5{
        font-family: 'Recoleta';
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 23px;
      }
      p {
        font-family: "Intervogue Soft Alt Regular";
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 40px;
      }
      picture {
        img {
          width: 224px;
          margin: 0 auto;
          @media only screen and (min-width: 481px) {
            width: 260px;
          }
        } 
      }
    }
    .buzzer-form.retailer-container__form {
      margin-top: 34px;
      margin-bottom: 40px;
      @media only screen and (min-width: 481px) {
        width: 100%;
        margin-top: 51px;
        margin-bottom: 40px;
      }
      .form-container{
        @media only screen and (min-width: 481px) {
          width: 100%;
        }
      }
      .input-container{
        @media only screen and (min-width: 481px) {
          width: 100%;
        }
        &:first-child {
          margin-bottom: 42px;
          margin-top: 0;
          @media only screen and (min-width: 481px) {
            margin-bottom: 65px;
          }
        }
      }
      #product_type{
        &::-webkit-input-placeholder { /* Edge */
          font-family: 'Intervogue Soft';
          font-weight: 700;
          color: rgba(125, 105, 172, 0.5);
          font-size: 22px;
          line-height: 37px;
          text-align: center;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-family: 'Intervogue Soft';
          font-weight: 700;
          color: rgba(125, 105, 172, 0.5);
          font-size: 22px;
          line-height: 37px;
          text-align: center;
        }
        
        &::placeholder {
          font-family: 'Intervogue Soft';
          font-weight: 700;
          color: rgba(125, 105, 172, 0.5);
          font-size: 22px;
          line-height: 37px;
          text-align: center;
        }
      }
    }
    .chooseTeam.buzzer-form.team-container__form {
      margin-top: 0px;
    }
  }
  p.special{
    margin-top: 80px;
    @media only screen and (max-width: 481px) {
      margin-bottom: 59px;
      margin-top: 30px;
    }
  }
  .btn__container {
    margin-top: 50px;
    @media only screen and (max-width: 481px) {
      width: 90%;
      margin-top: 0;
    }
  }
  .chooseTeam--team-container .team.active, .chooseTeam--team-container .team:hover {
    background-color: rgba(59, 39, 114, 0.1);
  }
}

#main-participation.promo_90min{
  .s-content-steps{
    /* flex-wrap: nowrap; */
    @media only screen and (min-width: 1025px) {
      width: 100%;
      max-width: 1310px;
    }
  }
  .s-content-steps__item{
/*     min-width: unset;
    @media only screen and (max-width: 481px){
      width: 140px;
      padding: 0;
      display: flex;
      align-self: center;
      justify-content: center;
    }
    padding: 25px 27px; */
    &::after{
      content:none;
    }
    &:nth-child(1) {
      p {
        max-width: 253px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 253px;
        }
      }
    }
    &:nth-child(2) {
      margin-top: -40px;
      @media only screen and (max-width: 768px){
        margin-top: 0;
      }
      img{
        @media only screen and (max-width: 481px){
          width: 230px;
        }
      }
      p {
        max-width: 250px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 250px;
        }
      }
    }
    &:nth-child(3) {
      p {
        max-width: 250px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 250px;
        }
      }
    }
    &:nth-child(4) {
/*       img{
        @media only screen and (max-width: 481px){
          width: 60px;
        }
      } */
      p {
        max-width: 250px;
        @media only screen and (min-width: 481px){
          margin: 0 auto;
          margin-top: 60px;
          max-width: 260px;
        }
      }
    }
    p{
      font-size: 20px;
      line-height: 26px;
      margin: 0 auto;
      margin-top: 30px;
      @media only screen and (min-width: 481px){
        margin-top: 60px;
      }
    }
  }
  .content-home picture img{
    @media only screen and (max-width: 481px){
      width: 150px;
    }
  }
  
  .buzzer-form{
    #salutation p {
      font-size: 16px;
    }
  }
  .content-participation__form{
    .buzzer-form{
      h2 {
        @media only screen and (max-width: 481px) {
          font-size: 30px;
        }
      }
      h3 {
        font-family: "Recoleta";
        text-align: center;
        @media only screen and (min-width: 1025px) {
          font-size: 25px;
        }
      }
    }
    #dob-container.desktop{
      .custom-inputs{
        padding-right: 0;
        .text-content{
          padding-left: 50px;
          white-space: nowrap;
        }
      }
      .checkmark{
        right: unset;
        left: 0;
        top: -7px;
      }
    }
    
    #participant-info{
      @media only screen and (max-width: 480px) {
        margin-bottom: 20px;
      }
    }
    

    .campaign-condition{
      color: #fff;
      text-align: center;
      @media only screen and (min-width: 481px) {
          width: 80%;
          margin: 0 auto;
      }
      .campaign-condition-container{
        padding: 0 10px;
        .condition-content{
          max-width: 597px;
          margin: 0 auto;
        }
        h3 {
          font-family: 'Recoleta';
          font-size: 30px;
          line-height: 32px;
          margin-bottom: 20px;
          @media only screen and (min-width: 481px) {
            font-size: 40px;
            margin-bottom: 30px;
          }
          &.condition-title{
            margin-top: 70px;
            @media only screen and (min-width: 481px) {
              margin-top: 90px;
            }
          }
        }
        p {
          font-size: 20px;
          line-height: 26px;
          &.condition-text{
            margin-bottom: 20px;
            @media only screen and (min-width: 481px) {
              margin-bottom: 30px;
            }
          }
          span{
            font-weight: 700;
          }
        }
        hr{
          margin-top: 40px;
          @media only screen and (min-width: 481px) {
            margin-top: 90px;
          }
        }
      }
    }
    .checkmark{
      border-radius: 9px;
    }

    #name-terms {
      margin-bottom: 10px;
      padding-bottom: 0;
    }
    a#moreInfo {
      margin-left: 53px;
    }
    .campaign-consent{
      @media only screen and (min-width: 481px) {
        width: 80%;
        margin: 0 auto;
      }
    }
    .campaign-consent {
      padding: 20px 20px 0;
    }
    .product-code__text{
      max-width: 502px;
      margin: 0 auto;
      margin-top: 100px;
      color: #fff;
      text-align: center;
      h2{
        font-family: 'Recoleta';
        font-size: 40px;
        line-height: 32px;
        margin-bottom: 33px;
      }
      h5{
        font-family: 'Recoleta';
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 23px;
      }
      p {
        font-family: "Intervogue Soft Alt Regular";
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 40px;
      }
      picture {
        img {
          width: 224px;
          margin: 0 auto;
          @media only screen and (min-width: 481px) {
            width: 260px;
          }
        } 
      }
    }
    .buzzer-form.retailer-container__form {
      margin-top: 34px;
      margin-bottom: 40px;
      @media only screen and (min-width: 481px) {
        width: 100%;
        margin-top: 51px;
        margin-bottom: 40px;
      }
      .form-container{
        @media only screen and (min-width: 481px) {
          width: 100%;
        }
      }
      .input-container{
        @media only screen and (min-width: 481px) {
          width: 100%;
        }
        &:first-child {
          margin-bottom: 42px;
          margin-top: 0;
          @media only screen and (min-width: 481px) {
            margin-bottom: 65px;
          }
        }
      }
      #product_type{
        &::-webkit-input-placeholder { /* Edge */
          font-family: 'Intervogue Soft';
          font-weight: 700;
          color: rgba(125, 105, 172, 0.5);
          font-size: 22px;
          line-height: 37px;
          text-align: center;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-family: 'Intervogue Soft';
          font-weight: 700;
          color: rgba(125, 105, 172, 0.5);
          font-size: 22px;
          line-height: 37px;
          text-align: center;
        }
        
        &::placeholder {
          font-family: 'Intervogue Soft';
          font-weight: 700;
          color: rgba(125, 105, 172, 0.5);
          font-size: 22px;
          line-height: 37px;
          text-align: center;
        }
      }
    }
    .chooseTeam.buzzer-form.team-container__form {
      margin-top: 0px;
    }
  }
  p.special{
    margin-top: 80px;
    @media only screen and (max-width: 481px) {
      margin-bottom: 59px;
      margin-top: 30px;
    }
  }
  .btn__container {
    margin-top: 50px;
    @media only screen and (max-width: 481px) {
      width: 90%;
      margin-top: 0;
    }
  }
  .chooseTeam--team-container .team.active, .chooseTeam--team-container .team:hover {
    background-color: rgba(59, 39, 114, 0.1);
  }
}

//Account code verification
.account.code-verification{
  .code-verification__text {
    max-width: 778px;
    text-align: center;
    margin: 0 10px;
    padding-top: 40px;
    margin-bottom: 40px;  
    @media only screen and (min-width: 481px) {
      margin: 0 auto;    
      padding-top: 60px;
      margin-bottom: 90px;
    }
    h2{
      font-size: 30px;
      line-height: 32px;
      font-family: 'Recoleta';
      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
      @media only screen and (min-width: 481px) {
        font-size: 48px;
        max-width: 736px;
        line-height: 58px;
      }
    }
    p{
      font-family: "Intervogue Soft Alt Regular";
      font-size: 20px;
      margin-bottom: 15px;
      line-height: 26px;
      @media only screen and (min-width: 481px) {
        line-height: 28px;
        font-size: 25px;
        font-family: 'Recoleta';
        line-height: 32px;
        margin-bottom: 30px;
      }
    }
  }
  .content-upload-receipt{
    background-color: #3B2772;
    @media only screen and (min-width: 481px) {
      padding-top: 60px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 15px;
      padding-top: 60px;
    }
  }
  .btn__container{
    background-color: #7D69AC;
    border: 2px solid #fff;
  }
  h2.title {
    font-family: 'Recoleta';
    font-size: 30px;
    line-height: 32px;
    font-weight: 500;
    max-width: 300px;
    display: inline-block;
    margin-bottom: 12px;
    @media only screen and (min-width: 481px) {
      font-size: 40px;
      line-height: 46px;
      max-width: 460px;
    }
  }
  .subtitle{
    font-family: "Intervogue Soft Alt Regular";
    font-size: 20px;
    line-height: 28px;
  }
  .required-field{
    text-align: left;
    padding-left: 22px;
  }
  .grid-6-m {
    @media only screen and (min-width: 481px) and (max-width: 919px) {
      flex-basis: calc(100% - 30px);
    }    
  }

  .space-left{    
    @media only screen and (min-width: 1200px) {
      padding: 22px 30px 0 30px;  
    }
    .input-container{
      margin-bottom: 30px;
      @media only screen and (min-width: 481px) {
        max-width: 360px;
        margin: 0 auto;
        margin-bottom: 37px;
      }
    }
    .form-button {
      padding-bottom: 35px;
    }
    .title{
      @media only screen and (min-width: 481px) {
        max-width: 490px;
      }
    }
    .subtitle{
      padding-bottom: 0;
    }
  }
  .form-line .form-container:last-child {
    @media only screen and (max-width: 480px) {
      padding-bottom: 0;
    }
    @media only screen and (max-width: 919px) {
      padding-bottom: 0;
      border-top: 1px solid #fff;
    }
  }
  .form-button {
    padding-bottom: 12px;
  }
  .gameover-errorMsg {
    color: #fff;
    background-color: #D61924;
    width: 489px;
    height: 96px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 26px;
    padding: 11px;
    padding-top: 8px;
    margin-bottom: 70px;
    @media only screen and (max-width: 520px) {
      width: 356px;
      height: auto;
    }
  }
}

main.main-content.no-product-code_layout{
  background-color:#3B2772;
  background-image: none;
}

.no-product-code__wrapper{
  text-align: center;
  max-width: 778px;
  margin: 0 12px;
  color: #fff;
  background-color:#3B2772;
  @media only screen and (min-width: 481px) {
    max-width: 778px;
    margin: 0 auto;
  }
  h2{
    font-family: 'Recoleta';
    font-size: 30px;
    line-height: 32px;    
    margin-top: 32px;
    margin-bottom: 13px;
    @media only screen and (min-width: 481px) {
      font-size: 48px;
      line-height: 58px;
      margin-top: 72px;    
      margin-bottom: 23px;    
    }
  }
  p{
    font-family: "Intervogue Soft Alt Regular";
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 23px;    
    @media only screen and (min-width: 481px) {
      font-family: 'Recoleta';    
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
  .form-button{
    margin-bottom: 50px;
  }
  .btn--red{
    width: 345px; 
    border: 2px solid;
    letter-spacing: 5px; 
    @media only screen and (min-width: 481px) {
      width: 404px;    
      margin-bottom: 100px;
    }
  }
}

//Confirmation page

.confirmation-wrapper{
  padding: 0 17px;
  margin-top: 30px;
  margin-bottom: 50px;
  @media only screen and (min-width: 481px) {
    margin-top: 70px;
    margin-bottom: 90px; 
  }

  .confirmation-text {
    text-align: center;
    color: #fff; 
     @media only screen and (min-width: 481px) {
      max-width: 778px;
      margin: 0 auto;
    }
    margin: 0 auto;
    h2 {
      font-family: $recol;
      font-size: 30px;
      line-height: 32px;    
      margin-bottom: 16px;
      @media only screen and (min-width: 481px) {
        font-size: 48px;
        line-height: 58px;   
      }
    }
    p {
      font-family: "Intervogue Soft Alt Regular";
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 8px;      
      @media only screen and (min-width: 481px) {
        font-family: $recol;
        font-size: 25px;
        line-height: 32px;   
      }
      &:first-of-type {    
        margin-bottom: 10px;   
        @media only screen and (min-width: 481px) {
          font-family: $recol;
          font-size: 25px;
          line-height: 32px; 
          margin-bottom: 30px;  
        }
      }
    }
  }
}

//No win page
.no-win-wrapper {
  margin-top: 26px;
  margin-bottom: 42px;
  @media only screen and (min-width: 481px) { 
    margin-top: 66px;
    margin-bottom: 82px;
  }
  .no-win-text{
    text-align: center;
    padding: 0 10px;
    h2 {
      font-size: 32px;
      font-family: 'Intervogue Soft Alt Medium';
      color: #F1CC74;
      font-weight: 700;
      margin: 0 auto;
      text-transform: uppercase;
      line-height: 38px;
      margin-bottom: 16px;  
      max-width: 345px;      
      @media only screen and (min-width: 481px) {
        font-size: 42px;
        line-height: 58px;
        max-width: 578px;
        margin-bottom: 21px;
      }
    }
    
    p {
      font-family: "Intervogue Soft Alt Regular";
      font-size: 20px;
      line-height: 26px;
      color: #fff;      
      @media only screen and (min-width: 481px) {
        line-height: 24px;
        max-width: 603px;
        margin: 0 auto;
      }
    }
  }
}

#game-buzzer{
  .s-content-end{
    background-color: #7D69AC;
    margin: 0;
    .end-element{
      margin: 0 auto;
      padding: 45px 0 0;
      @media only screen and (min-width: 481px) {
        padding: 85px 0 32px;
      }
    }
    .end-element__item {
      margin-bottom: 30px;      
      @media only screen and (min-width: 481px) {    
        margin-bottom: 50px;
      }
    }
  }
}

#buzzer-prizes {
  .campaign-prize-header{
    margin-top: 47px;
    margin-bottom: 36px;
    @media only screen and (min-width: 481px) {     
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 80px;
      max-width: 784px;
    }
    p {
      font-family: "Intervogue Soft Alt Regular";
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 15px;    
      text-align: center;
      padding: 0 25px;      
      @media only screen and (min-width: 481px) {
        font-family: $recol;
        font-size: 25px;
        line-height: 32px;
        padding: 0;
      }
    }
  }
  .reward_90min {
    .prizeRewe-card__primary{
      padding-bottom: 31px;
      @media only screen and (min-width: 481px) {
        max-width: 489px;
        width: unset;
        margin-top: 93px;
        padding-bottom: 25px;
      }
      @media only screen and (min-width: 1226px) {
        margin-right: 30px;
      }
      @media only screen and (max-width: 1226px) {
        margin-right: 0;
      }
      h3{
        font-size: 30px;
        line-height: 32px;
        margin: 0 auto 20px;
        @media only screen and (min-width: 481px) {
          margin-bottom: 20px;
        }
      }
    }
    .prizeRewe-card__wrapper {
      @media only screen and (min-width: 1194px) {
        margin-top: 93px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .prizeRewe-card__secondary{
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 30px 0px;
      @media only screen and (max-width: 480px) {
        min-height: 430px;
        padding: 30px 13px;
        padding-top: 10px;
        margin-top: 31px;
        margin-bottom: 30px;
      }
      @media only screen and (min-width: 480px) {
        margin-top: 49px;
      }
      @media only screen and (min-width: 585px) {
        justify-content: space-between;
        flex-direction: row;
        max-width: 656px;
        width: unset;
        min-height: unset;
      }
      @media only screen and (min-width: 1194px) {
        margin-top: 0;
      }
      &:last-child img {
        @media only screen and (min-width: 585px) {
          margin-left: 30px;
        }
      }
      h3{
        margin-top: 0!important;
      }
      .prizeRewe-card__text{
        max-width: 343px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media only screen and (min-width: 585px) {
          margin-right: 30px;
        }
        *{
          text-align: center;
          margin: 0;
          width: 100%;
          @media only screen and (min-width: 585px) {
            text-align: left;
          }
        }
        h3{        p {
          color:#fff;
          span {
            color:#fff;
          }
          .bold-text {
            font-weight: bold;
          }
        }
          font-size: 25px;
          line-height: 28px;
          margin-bottom: 13px;
        }
        p {
          color:#fff;
          span {
            color:#fff;
          }
          .bold-text {
            font-weight: bold;
          }
        }
      }
      .prizeRewe-card__img{
        display: flex;
        flex-direction: column;
        img{
          margin-top: 0;
          align-self: center;
        }
        p {
          @media only screen and (max-width: 481px) {
            margin-bottom: 10px;
          }
        }
      }
      &.secon {
        .prizeRewe-card__img{
          p {
            margin-left: 25px;
            @media only screen and (max-width: 481px) {
              margin: 0 auto;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .campaign-footer.campaign-prize-footer{
    max-width: 741px;
    margin: 0 auto;
    p{
      width: 100%;
      margin: 0 auto;
      &:nth-of-type(2){
        margin-bottom: 20px; 
        @media only screen and (min-width: 481px) {
          margin-bottom: 40px; 
        }
      }
    }
    .btn__container.btn--lilac{
      background-color: #7D69AC;
      border: 2px solid #fff;
      @media only screen and (max-width: 480px) {
        margin-top: 32px;
      }
      @media only screen and (min-width: 481px) {
        width: 398px; 
      }
    }
  }
}
.arende{
  width:40%;
  @media only screen and (min-width: 768px) {
      width: 36%;
  }
}
  .s-content-partication.account-info .form-button .btn__container.btn--lilac {
    background-color: #7D69AC;
    margin-top: 32px;
    color: var(--Fresh-White, #FFF);
      text-align: center;
      font-family: Good Headline Pro;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
      /* 75% */
      letter-spacing: 4px;
      text-transform: uppercase;
    @media only screen and (max-width: 480px) {
      margin-top: 32px;
    }

    @media only screen and (min-width: 481px) {
      width: 398px;
    }
  }


#buzzer-faqs {
  .content-faq {
    margin-bottom: 23px; 
    padding-top: 75px;     
    @media only screen and (min-width: 481px) {
      margin-bottom: 50px;
    }
    @media only screen and (max-width: 480px) {
      padding-top: 35px;
    }
    @media only screen and (max-width: 580px) {
      padding: 35px 50px 50px 50px;
    }
  }
  .content-faq__item {
    max-width: 783px;
    margin: 0 auto;
    padding-bottom: 15px;
    @media only screen and (min-width: 481px) {
      padding: 0;
    }
    h6{
      padding: 15px 0px 17px 0px;
    }
    ul{
      li {
      max-width: 560px;
      margin-bottom: 10px;
      &:last-of-type {
        @media only screen and (min-width: 481px) {
          margin-bottom: 17px;
        }
      }
      }
    }
    p {
      @media only screen and (min-width: 481px) {
        padding-bottom: 20px;
      }
    }
    .content-faq__img {
      top: 16px;
    }
    .item-content {
      @media only screen and (min-width: 481px) {
        padding: 0 25px;
      }
    }
  }
}

.content-holding__heading.closing{
  .content-end__text {
    margin-top: 50px;    
    margin-bottom: 48px;
    @media only screen and (min-width: 481px) {
      margin: 0 auto;
      margin-top: 90px;
      max-width: 783px;
    }
    h2 {
      font-family: $recol;
      font-size: 30px;
      line-height: 32px;
      @media only screen and (min-width: 481px) {
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 80px;
      }
    }
  }    
  .s-content-end{
    margin: 0;
    padding: 0 20px;
    background-color: #7D69AC;
    padding-top: 54px;
    @media only screen and (min-width: 481px) {
      padding: 83px 0 40px 0;
    }
    .end-element{
      margin: 0 auto;
      @media only screen and (min-width: 481px) {
        padding-bottom: 20px;
      }
    }
    .end-element__item{
      margin-bottom: 31px;
    }
  } 
}

.winner-wrapper{
  background-image: url('/resources/imagesBundesliga/90_min/prizes/layout/konfetti-sm.png');    background-repeat: no-repeat;
  background-position: 0px -58px;
  background-size: 100%;
  padding-bottom: 50px;
  @media only screen and (min-width: 481px) {
    padding-bottom: 100px;
  }
  @include breakpoint(large) {
    background-image:url('/resources/imagesBundesliga/90_min/prizes/layout/konfetti.png');    
    background-position: 0px -32px;
    background-size: 100%;
  }
  .winner-wrapper__content {
    padding: 0 31px;
    padding-top: 33px;
    text-align: center;
    color: #fff;
    @media only screen and (min-width: 481px) {
      max-width: 460px;
      margin: 0 auto;
    }
  }
  .winner-title {
    margin-bottom: 12px;
    h1 {
      font-family: 'Intervogue Soft Alt Medium';
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #F1CC74;
      text-transform: uppercase;
      @media only screen and (min-width: 481px) {
        font-size: 64px;
        line-height: 77px;
      }
    }
  }
  .winner-subtitle {
    margin-top: 15px;
    margin-bottom: 11px;
    @media only screen and (min-width: 481px) {
      margin-top: 45px;
      margin-bottom: 17px;
    }
    h2 {
      font-family: 'Intervogue Soft';
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      @media only screen and (min-width: 481px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
  img {
    width: 50%;      
    @media only screen and (min-width: 481px) {
      width: auto;
    }
  }
  .winner-text-one{
    margin-bottom: 30px;
    p {
      font-family: 'Intervogue Soft';
      font-size: 20px;
      line-height: 24px;
      @media only screen and (min-width: 481px) {
        font-size: 20px;
        line-height: 24px;
      }
      span {
        font-weight: bold;
      }
    }
  } 
  .winner-text-two p {
    font-family: 'Intervogue Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;      
  }
}

//product code page
.content-holding__heading.closing.product-wrapper {
  .content-end__text {
    max-width: 342px;
    margin: 40px auto 60px;
    @media only screen and (min-width: 481px) {
      margin: 70px auto 90px;
      max-width: 784px;
    }
    h2 {
      margin-bottom: 20px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 20px;
      }
    }
    p{
      color: #fff;
      font-family: "Intervogue Soft Alt Regular";
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 23px;    
      text-align: center;
      @media only screen and (min-width: 481px) {
        font-family: 'Recoleta';    
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 40px;
      }
    }
  }
  .s-content-end {
    .end-element {
      .end-element__item{
        &:last-child {
          .btn__container.btn--secondary-lilac{
            @media only screen and (min-width: 1025px) {
              padding: 10px 17px 8px 13px;
            }
          }
        }
        .btn__container.btn--secondary-lilac{
          @media only screen and (min-width: 481px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

//no-pariticipation page
.no-participation__wrapper {
  background-color: #7D69AC;
  padding-top: 30px;
  padding-bottom: 40px;
  @media only screen and (min-width: 481px) {
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 90px;
  }
  h2 {
    font-family: 'Recoleta';
    font-size: 30px;
    line-height: 32px;
    color: #fff;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 345px;
    @media only screen and (min-width: 481px) {
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      margin-bottom: 30px;
      max-width: max-content;
    }
  }
  p {
    color: #fff;
    font-family: $soft-alt;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    max-width: 345px;
    margin: 0 auto;
    @media only screen and (min-width: 481px) {
      font-family: 'Recoleta';
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 32px;
      max-width: 778px;
    }
  }
}
//kip
.content-Lucas-Podolski {
  color: #fff;
  text-align: center;
  @media only screen and (min-width: 481px) {
    margin: 0 auto;
  }
}
.headings__Lucas-Podolski h2 {
  font-family: $recoleta;
  font-size: 30px;
  line-height: 32px;
  max-width: 360px;
  margin: 0 auto;
  margin-bottom: 18px;
  @media only screen and (min-width: 481px) {
    font-size: 40px;
    line-height: 48px;
    max-width: unset;
  }
}
.headings__Lucas-Podolski p {
  font-family: $soft-alt;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 40px;
  max-width: 341px;
  display: inline-block;
  @media only screen and (min-width: 481px) {
    line-height: 28px;
    max-width: 835px;
  }
}
.Lucas-Podolski__img {
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 70%;
    align-content: center;
    align-self: center;
    margin: 0 auto;
  }
  @media only screen and (min-width: 1044px) {
    justify-content: space-between;
    max-width: 1100px;
  }
}
.img-wrapper__Lucas-Podolski {
  margin-bottom: 31px;

}
.img-wrapper__Lucas-Podolski img {
  width: 264px;
  margin: 0 auto;
  @media only screen and (min-width: 481px) {
    width: auto;
  }
}
.max-donation__text {
  margin-top: 50px;
  margin-bottom: 30px;
  @media only screen and (min-width: 481px) {
    margin-top: 19px;
  }
}

//kip Rewe
.kaufland {
  &.rewe, &.penny {
    .title-container__v2{
      background-color: #7d69ac;
      padding: 50px 15px 50px 15px;
      @media only screen and (min-width: 481px) {
        padding: 90px 15px 90px 15px;
      }
      a {
        font-weight: normal;
        text-decoration: none;
      }
      h2 {
        font-family: 'Recoleta';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        max-width: 345px;
        @media only screen and (min-width: 481px) {
          font-size: 40px;
          line-height: 45px;
          max-width: 989px;
        }
        @media only screen and (min-width: 780px) {
          font-size: 60px;
          line-height: 65px;
          max-width: 989px;
        }
      }
      p {
        font-family: 'Intervogue Soft';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
        @media only screen and (min-width: 481px) {
          font-family: 'Recoleta';
          font-size: 25px;
          line-height: 32px;
          max-width: 779px;
        }
      }
    }
    .step-container.step-container__v2 {
      padding-bottom: 40px;
      @media only screen and (max-width: 481px) {
        padding-top: 55px;
        padding-bottom: 30px;
      }
      .step-title.step-title__v2 {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 6px;
        max-width: 283px;
        @media only screen and (min-width: 481px) {
          font-size: 40px;
          line-height: 32px;
          margin-bottom: 29px;
          max-width: 645px;
        }
      }
      .s-content-steps{
        margin-top: 70px;
        @media only screen and (max-width: 481px) {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .s-content-steps__item {
        @media only screen and (min-width: 481px) {
          min-width: unset;
          max-width: 307px;
        }
        p {
          max-width: 206px;
          text-align: center;
          @media only screen and (max-width: 481px) {
            font-size: 20px;
            line-height: 24px;
          }
        }
        &.first {
          p {
            max-width: 188px;
          }
        }
        &.second {
          @media only screen and (max-width: 481px) { 
            padding-top: 0;
          }
        }
        &.third {
          p {
            max-width: 240px;
          }
        }
        &::after {
          @media only screen and (max-width: 481px) {
            background-size: 48px 51px;
            width: 43px;
            height: 44px;
          }
        }
        picture img {
          width: auto;
        }
      }
      p.step-container--terms-link {
        max-width: 305px;
        margin-top: 27px;
        @media only screen and (min-width: 481px) {
          font-size: 20px;
          margin-top: 31px;
          max-width: unset;
        }
      }
      .btn--secondary-lilac {
        @media only screen and (max-width: 481px) {
          margin-top: 0;
        }
      }
    }
    .prizeRewe {
      h3.terms-title {
        margin-top: 100px;
        @media only screen and (min-width: 481px) {
          margin-top: 130px;
        }
      }
      .prizeRewe-subtitle {
        @media only screen and (max-width: 481px) {
          font-family: $soft-alt;
          font-size: 20px;
          line-height: 26px;
          max-width: 347px;
        }
      }
      h4.terms-subtitle{
        font-family: $recol;
        font-size: 25px;
        line-height: 32px;
        @media only screen and (max-width: 481px) {
          font-family: $soft-alt;
          font-size: 20px;
          line-height: 26px;
          max-width: 360px;
        }
        a {
          font-family: $recol;
          text-decoration: none;
        }
      }
      p.terms-link {
        @media only screen and (max-width: 481px) {
          font-size: 20px;
          margin: 0 auto;
          max-width: 340px;
          margin-top: 25px;
        }
      }
      p.instruction {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 45px;
        @media only screen and (min-width: 481px) {
          margin-bottom: 40px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          font-family: $soft-alt;
          text-decoration: none;
          font-weight: 500;
        }
        span.hide {
          @media only screen and (min-width: 481px) {
            display: none;
          }
        }
      }
    }
  }
}

//fancenter scenarios C team selection
.tickets-choice-wrapper {
  margin-bottom: 45px;
  @media only screen and (min-width: 481px) {
    margin-bottom: 80px;
  }
  h2 {
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  @media only screen and (min-width: 481px) {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 30px;
  }
  }
  .tickets-choice__warning {
    color: #fff;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    text-align: center;
  }
} 
.tickets-select {
  max-width: 338px;
  display: flex;
  margin: 0 auto;
  text-align: center;
  justify-content: space-between;
  .custom-inputs{
    width: 158px;
    background: #AFA6D2;
    border-radius: 10px;
    /* padding: 8px 16px 8px 36px; */
    padding: 8px 19px 8px 36px;
    line-height: 25px;  
    @media only screen and (min-width: 481px) {
      /* padding: 8px 16px 8px 36px; */
      padding: 8px 19px 8px 36px;
    }

    &.disabled{
      background-color: #7a7a7a;
      color: #8f8f8f;
      cursor: not-allowed;
    }
  }
  label {
    .radiobtn {
      left: 20px;
      top: calc(50% - 10px);
      background: #fff;
    }
  }
}


.foundation-container {
  padding: 0 14px;
  background-color:#7D69AC;
  .foundation-title{
    color: #fff;
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    padding: 25px 0;
    @media only screen and (min-width: 481px) {
      font-size: 40px;
      line-height: 46px;
      padding-top: 81px;
      padding-bottom: 40px;
    }
  }
  .foundation-content {
    @media only screen and (min-width: 481px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .foundation-img__container {
    picture img {
      height: auto;
      display: block;
      margin: 0 auto;
      @media only screen and (min-width: 481px) {
        width: auto;
      }
    }
  }
  .foundation-text__right {
    margin-top: 23px;
    @media only screen and (min-width: 481px) {
      text-align: center;
    }
    @media only screen and (min-width: 1180px) {
      max-width: 525px;
      padding-left: 40px;
      margin-top: 0;
    }
    p {
      text-align: center;
      @media only screen and (min-width: 1180px) {
        text-align: left;
      }
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  p.foundation-text__left {
    max-width: 1157px;
    margin: 0 auto;
    text-align: center;
    margin-top: 28px;
    margin-bottom: 40px;
    @media only screen and (min-width: 1180px) {
      text-align: left;
    }
  }

  p.foundation-text__bottom {
    margin: 0 auto;
    margin-top: 38px;
    padding-bottom: 40px;
    max-width: 754px;
    @media only screen and (min-width: 481px) {
      padding-bottom: 90px;
    }
    a {
      font-weight: normal;
      text-decoration: none;
    }
  }
  p.more-about__text {
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    font-family: $soft;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    @media only screen and (min-width: 481px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  .more-about__content {
    img {
      margin: 0 auto;
      @media only screen and (min-width: 481px) {
        width: auto;
      }
    }
  }
}


//fancenter scenarios C ticket choice

.buzzer-jersey.tickets-choice {
  .campaign-heading {
    margin-bottom: 0;
    .recoleta {
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      max-width: 340px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 15px;
      @media only screen and (min-width: 481px) {
        margin-top: 80px;
        font-size: 40px;
        line-height: 46px;
        max-width: 888px;
      }
    }
    .lilac {
      max-width: 358px;
      margin: 0 auto;
      line-height: 26px;
      margin-bottom: 11px;
      &:nth-of-type(2) {
        @media only screen and (min-width: 481px) {
          max-width: 760px;
        }
      }
      &:nth-of-type(3) {
        max-width: 347px;
        @media only screen and (min-width: 481px) {
          max-width: 760px;
        }
      }
      @media only screen and (min-width: 481px) {
        max-width: 699px;
        margin-bottom: 10px;
      }
    }
    .vip-text {
      .lilac {
        max-width: 368px;
        @media only screen and (min-width: 481px) {
          max-width: 724px;
        }
      }
    }
  }
  .prizeRewe.campaign-footer{
    background-image: none;
    padding: 14px 10px 40px;
    @media only screen and (min-width: 481px) {
      padding: 35px 10px 71px;
    }
    #frm_gift_choice {
      .selection-options {
        @media only screen and (min-width: 481px) {
          width: fit-content;
        }
        label {
          background-color: #AFA6D2;
          padding-left: 48px;
          @media only screen and (min-width: 481px) {
            padding-left: 58px;
          }
          text-align: left;
          .radiobtn{
            background-color: #fff;
          }
        }
        .custom-inputs input:checked ~ .radiobtn {
          background: #3b2774;
        }
      } 
    }
    .btn__container {
      margin-top: 40px;
      background-color: #3B2772;
    }
  }
}

.buzzer-jersey {
  .btn__container.btn--red{
    background-color: #3B2772;
    width: 243px;
  }
  .campaign-footer.fancenter-main-footer {
    @media only screen and (min-width: 481px) {
      padding-top: 110px;
      padding-bottom: 127px;
    }
    @media only screen and (max-width: 481px) {
      padding: 54px 10px 20px;
    }
    h3 {
      @media only screen and (max-width: 481px) {
        font-family: $soft-alt;
        max-width: 330px;
        margin: 0 auto;
        padding-top: 9px;
      }
    }
    .btn__container.btn--red{
      background-color: #7D69AC;
      width: 249px;
      @media only screen and (min-width: 481px) {
        width: 363px;
      }
    }
  }
  #frm_team_selection {
    .chooseTeam--team-container .team.active, .chooseTeam--team-container .team:hover {
      background-color: rgba(59, 39, 114, 0.1);
      position: relative;
    }
  }
  
  .fancenter-main {
    .campaign-heading {
      p.lilac {
        @media only screen and (min-width: 481px) {
          width: 100%;
          max-width: 943px;
          margin-top: 10px;
        }
      }
      h3.lilac.recoleta-medium {
        @media only screen and (max-width: 481px) {
          display: block;
          font-size: 22px;
          padding-top: 38px;
        }
      }
      .ticket-wrapper {
        @media only screen and (max-width: 481px) {
          padding-top: 0;
        }
      }
    }
  }
}

//kip End page
.kaufland {
  &.end-penny, &.end-rewe {
    .s-content-end {
      @media only screen and (min-width: 481px) {
        margin-bottom: 25px;;
      }
      h1 {
        font-size: 30px;
        line-height: 32px;
        @media only screen and (min-width: 481px) {
          font-size: 60px;
          line-height: 65px;
          width: 100%;
        }
      }
      .end-element__item {
        @media only screen and (max-width: 481px) {
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        
        &:nth-child(2), &:nth-child(3) {
          @media only screen and (min-width: 481px) {
            margin-left: 2%;
          }
        }
      }
    }

    .s-content-end__subtitle {
      font-family: $recol;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #FFFFFF;
      @media only screen and (min-width: 481px) {
        font-size: 25px;
        line-height: 32px;
        max-width: 781px;
        margin: 0 auto;
      }
    }
    .end-element {
      @media only screen and (min-width: 481px) {
        margin-top: 100px;
      }
    }
  }
}

.buzzer-jersey.team-selection{
  .campaign-heading {
    @media only screen and (max-width: 481px) {
      margin: 0px 0 37px;
    }
    .text{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
        margin-top: 50px;
      h2{
        font-family: $gobold-bold;
        @include breakpoint(small-down){
          margin-bottom: 30px;
        }
        @include breakpoint(large){
          max-width: 980px;
          font-size: 40px;
        }
      }
      p{
        line-height: 140%;
        @media only screen and (min-width: 481px) {
          max-width: 819px;
        }
      }
    }
  }
  p.lilac {
    max-width: 381px;
    @media only screen and (min-width: 481px) {
      margin-top: 26px;
      max-width: 721px;
      font-size: 20px;
      font-family: $good-head-pro;
    }
  }
}

.buzzer-jersey.ticket-user-details {
  p {
    width: 100%;
    &.lilac.recoleta-medium.ticket{
      max-width: 754px;
    }
    &.lilac {
      max-width: 710px;
    }
  }

  .paragraph-heading{
    margin-top: 100px;
    font-size: 40px;
    @media only screen and (max-width: 481px) {
      line-height: 36px;
      font-size: 24px;
    }
    @media only screen and (min-width: 481px) {
      margin-top: 90px;
    }
  }
  .paragraph{
    margin-top: 20px;
    /* max-width: 700px !important; */
  }

  #frm_user_details_ticket {
    .custom-inputs {
      .text-content {
        font-size: 15px;
        line-height: 19px;
      }
      .checkmark {
        background-color: #fff;
        height: 28px;
        width: 28px;
        top: -5px;
        &::after {
          color: #3C2774!important;
          left: 2px;
        }
      }
    }
    .buzzer-form {
      .details{
        .matchcard__container{
          .match-card{
            &__bottom{
              &__team{
                p{
                  font-family:  $good-head-pro-bold;
                }
              }
            }
          }
        }
      }
      @media only screen and (min-width: 1025px) {
        margin-top: 0;
      }
    }
    .form-input__dropdown:after {
      right: 35px;
    }
    #address-container {
      @media only screen and (min-width: 1025px) {
        width: 100%;
      }
    }
    #street {
      @media only screen and (min-width: 1025px) {
        width: 70%;
      }
    }
    #houseNumber {
      @media only screen and (min-width: 1025px) {
        width: 30%;
      }
    }
    #zipCode {
      @media only screen and (min-width: 1025px) {
        width: 30%;
      }
    }    
    #city-container {
      @media only screen and (min-width: 1025px) {
        width: 70%;
      }
    }
    .resize {
      margin: 0 auto;
      max-width: 490px;
      @media only screen and (max-width: 481px) {
        padding: 0 30px;
      }
      #name-terms {
        width: 100%;
      }
    }
    #name-terms {
      width: 90%;
      @media only screen and (min-width: 481px) {
        margin-top: 6px;
      }
      @media only screen and (min-width: 1025px) {
        width: 45%;
      }
      div {
        @media only screen and (min-width: 481px) {
          width: 100%;
          padding-left: 13px;
        }
      }
    }
    .btn__container.btn--red {
      @media only screen and (max-width: 481px) {
        margin-bottom: 120px;
      }
    }
  }
}

.buzzer-jersey.ticket-companion-details {
  .campaign-heading {
    @media only screen and (min-width: 481px) {
      margin: 25px 0 50px;
      margin-top: 0;
    }
    .lilac.recoleta-medium.ticket {
      @media only screen and (min-width: 481px) {
        max-width: 750px;
      }
    }
    .lilac {
      max-width: 380px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 0;
        max-width: 710px;
      }
    }
    > p {
      @media only screen and (min-width: 481px) {
        width: 100%;
      }
    }
  }
  #frm_user_details_ticket{
    .buzzer-form {
      @media only screen and (min-width: 1025px) {
        margin: 0 0 70px;
      }
    }
    #companionUserDetails{
      .form-input__input{
        border: 1px solid #AFA6D2;
      }
      #street {
        @media only screen and (min-width: 1025px) {
          width: 70%;
        }
      }
      #houseNumber {
        @media only screen and (min-width: 1025px) {
          width: 30%;
        }
      }
      #zipCode {
        @media only screen and (min-width: 1025px) {
          width: 35%;
        }
      }
      #city-container {
        @media only screen and (min-width: 1025px) {
          width: 65%;
        }
      }
      #address-container{
        @media only screen and (min-width: 1025px) {
          width: 100%;
        }
      }
    }
    #name-terms {
      @media only screen and (min-width: 1025px) {
        width: 45%;
        margin-top: 12px;
      }
      @media only screen and (max-width: 481px) {
        margin-top: 33px;
      }
      .input-container {
        margin: 0;
        @media only screen and (min-width: 1025px) {
          width: 100%;
          padding: 0 10px;
        }
        .custom-inputs {
          .text-content {
            font-size: 15px;
            line-height: 19px;
          }
          .checkmark {
            background-color: #fff;
            height: 28px;
            width: 28px;
            top: -5px;
            border-radius: 9px;
            &::after {
              color: #3C2774!important;
              left: 2px;
            }
          }
        }
      }
    }  
  }
}

.buzzer-jersey.size-selection {
  .jersey-sizes--link {
    @media only screen and (max-width: 481px) {
      margin-top: 10px;
    }
  }
}

.buzzer-jersey.favorite-club {
  .campaign-heading {
    .contenu {  
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .titre{ 
        font-size: 40px;
        margin-top: 5% !important;
        width: 100%; 
        @media only screen and (min-width: 768px) {
          margin-bottom: 10px !important;
          max-width: 970px;
        }
        @media (max-width: 767px) {
          padding: 20px;
          margin: 15px 0 0 5px !important;
          display: flex;
          font-size: 24px;
          line-height: 33.6px;
          font-weight: 400;
          justify-content: center;
        }
        @media (max-width: 481px) {    
          max-width: 413px;
        }
        &.ticket {
          @media (max-width: 481px) {    
            max-width: 343px;
          }
        }
        &.ticket-vip {
          @media (max-width: 481px) {    
            max-width: 353px;
          }
        }
        br {
          @media (max-width: 767px) {
            display: none;
          }
        }
      } 
      .zuruck-button{
        background:#fff !important;
      }
      
    }
    .p-fav{
      font-family: $good-head-pro;
      @media (max-width: 481px) {    
        max-width: 333px;
      }
    }
    h3 {
      @media only screen and (max-width: 480px) {
        font-size: 30px;
      }
    }
    p.lilac {
      @media only screen and (min-width: 481px) {
        width: 100%;
        max-width: 840px;
      }
    }
    .fancenter__steps{
      span{
        @media only screen and (max-width: 480px) {
          font-size: 16px;
          font-family: $good-head-pro;
        }
        font-family: $gobold-bold;
        font-size: 24px;
      }
    }
  }
  .form-team{
    margin-bottom: 0!important;
    .chooseTeam {
      .group-bouton{
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 480px) {
          justify-content: space-evenly;
        }
        .dynamic-button{
          margin-left: 20px;
          height: 100px;
          @media only screen and (max-width: 480px) {
            margin-left: 0;
          }
        }
      }
      .swiper-container {
        max-width: 1200px!important;
        .swiper-button-next, .swiper-button-prev{
          display: none;
        }
        .swiper-wrapper{
          .swiper-slide{
            width:100%!important;
            .chooseTeam--team-container{
              .team{
                height: 278px;
                @media screen and (max-width: 767px){
                  margin: auto;
                }
                &:hover{
                  padding-bottom: 5px!important;
                  background-color: $mid-lilac!important;
                }
                .card-team{
                  height: 160px;
                  .team-logo{
                    margin-top: 20px;
                  }
                  .team-name{
                    font-family: $good-head-pro;
                    font-size: 20px !important;
                  }
                }
                .button-team{
                  font-family: $good-head-pro-bold;
                  line-height: 20.8px;
                }
                .team-selected{
                  display: none;
                  position: relative;
                  &__active{
                    display: flex;
                    float: right;
                    img{
                      width: 20px;
                      height: auto;
                    }
                  }
                }
              }
              .active{
                background-color: $mid-lilac!important;
                .btn__container {
                  height: 41px;
                }
              }
            }
          }
        }
      }
      .heading{
        padding-top: 70px;
        font-size: 40px;
        @media (max-width: 767px) {
          padding: 25px;
          margin: 0;
          display: flex;
          font-size: 30px;
          line-height: 42px;
          font-weight: 400;
          justify-content: center;
        }
      }
    }
  }
  #frm_team_selection {
    margin-bottom: 53px;
    @media only screen and (min-width: 481px) {
      margin-bottom: 93px;
    }
    .chooseTeam {
      @media only screen and (max-width: 481px) {
        margin-bottom: 31px;
      }
    }
    #submit-team {
      display: none;
      &__active{
        margin-top: 2px;
        display: flex;
        font-size: 16px;
        width: 150px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        @media only screen and (max-width: 496px) {
          width: 120px;
          font-size: 14px;
        }
      }
      @media only screen and (min-width: 481px) {
        margin-top: 20px;
      }
    }
  }
}

.user-details-table {
  max-width: 961px;
  background: #E5E1EE;
  border-radius: 12.3239px;
  margin: 0 13px;
  min-height: 274px;
  margin-bottom: 70px;
  @media only screen and (min-width: 481px) {
    margin: 0 auto;
    margin-bottom: 70px;
  }
  .user-details-table__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding:26px;
    @media only screen and (min-width: 481px) {
      padding: 46px;
    }
  }
  .my-info {
    width: 100%;
    min-width: 295px;
    margin-bottom: 40px;
    @media only screen and (min-width: 582px) {
      margin-bottom: 0;      
      width: 60%;
    }
  }
  .shipping-address {
    width: 100%;
    @media only screen and (min-width: 582px) {
      width: 40%;
    }
  }
  h4 {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    padding-bottom: 5px;
    border-bottom: 1px dashed rgba(59, 39, 114, 0.5);
    max-width: 295px;
    margin-bottom: 10px;
    @media only screen and (min-width: 582px) {
      max-width: unset;
      width: 300px;
    }
  }
  p{
    font-size: 20px;
    line-height: 32px;
  }
}

//confirmation-friend 
.buzzer-jersey.lottery#confirmation {
  .confirmation-friend {
    padding-top: 70px;        
    @media only screen and (max-width: 481px) {
      padding-top: 40px;
    }
  
    .campaign-heading {
      h2 {
        padding-top:0;
        @media only screen and (max-width: 481px) {
          font-size: 22px;
          line-height: 28px;
        }
      }
      p {
        font-family: $soft-regular;
        font-size: 20px;
        line-height: 26px;
        @media only screen and (min-width: 481px) {
          margin-top: 22px;
          font-size: 20px;
          line-height: 26px;
          max-width: 586px;
        }
      }
    }
  }
}

.container.medium-copy.terms.fancenter-terms {
  .s-content-copy .s-content-copy__item {
    ul {
      margin-left: 50px;
      li {
        width: unset;
        padding-left: 15px;
      }
    }
    ol.list-type-reset {
      margin-left: 50px;
      li {
        width: unset;
        list-style: lower-latin;
        padding-left: 15px;
        &::before {
          content:none;
        }
      }
    }
  }
}

//popup rewe
.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .popup-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 4;
}
.modal-v1, .popup-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 11;

  @media screen and (max-width:1024px) {
    overflow-y: scroll;
  }
}

.popup-overlay {
  background: #000;
  opacity: 0.6;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  border-radius: 3px;
  
/*   max-width: 600px;
  min-width: 300px; */
  z-index: 5;
/*   width: 881px;
  height: 491px; */
  background-color: #3b2772;
  border: 8.31132px solid #F1CC74;
  border-radius: 15px;
  @media only screen and (max-width: 1024px) {
    top:70%;
  }
  @media only screen and (max-width: 481px) {
    top:46%
  }

  .modal-img__container {
    min-width: 260px;
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  cursor: pointer;
  img {
    width: 23px;
    height: 24px;
  }
}

#buzzer-error {
  .s-content-title {
    h1,span {
      color:#fff;
    }
  }
}

.banner__container.q3-game {
  background-color: #3b2774;
}

#main-buzzer.weltmeister-main {
  .campaign-heading {
    margin: 50px 0 0px;
    @media only screen and (min-width: 481px) {
      margin: 50px 0 50px;
    }
    h1 {
      font-family: $gobold-bold;
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      /* text-transform: uppercase; */
      max-width: 353px;
      @media only screen and (min-width: 481px) {
        max-width: 980px;
        font-size: 60px;
        line-height: 90px;
        margin-bottom: 44px;
      }
    }
  }
  .s-content-steps.step-weltmeister {
    @media only screen and (min-width: 1024px) {
      width: 100%;
    }
    .s-content-steps__item {
      max-width: 342px;
      @media only screen and (min-width: 1024px) and (max-width: 1200px){
        max-width: 245px;
        min-width: unset;
      }
      &::after {
        content: none;
      }
      p {
        font-family: $good-head-regular;
        margin: 0 auto;
        margin-top: 41px;
        text-align: center;
        font-size: 20px;
        line-height: 26px;
        @media only screen and (min-width: 481px) {
          line-height: 30px;
        }
        a {
          text-decoration: underline;
          font-weight: bold;
        }
      }
      &:nth-child(1) {
        p {
          margin-top: 25px;
          @media only screen and (max-width: 481px) {
            max-width: 250px;
          }
        }
      }
      &:nth-child(2) {
        p {
          @media only screen and (max-width: 481px) {
            max-width: 240px;
          }
        }
      }
      &:nth-child(3) {
        @media only screen and (min-width: 481px) {
          margin-top: 56px;
        }
      }
      &:nth-child(4) {
        margin-top: 21px;
        @media only screen and (min-width: 481px) {
          margin-top: 12px;
        }
      }
      img {
        @media only screen and (max-width: 481px) {
          width: auto;
        }
      }
    }
  }
  #product-code-link {
    cursor: pointer;
  }
  .note-promo-code {
    font-family: $good-head-regular;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 31px;
    @media only screen and (min-width: 481px) {
      max-width: 609px;
      margin-top: 44px;
    }
  }
  .btn__container {
    border: 2px solid #fff;
    font-family: $good-head-regular;
    font-size: 20px;
    font-weight: bold;
    line-height: 15px;
    padding: 10px 17px 8px 17px;
    @media only screen and (max-width: 481px) {
      width: 314px;
    }
  }
  #edeka-terms {
    margin-top: 40px;
    font-family: $good-head-regular;
    @media only screen and (min-width: 481px) {
      font-size: 18px;
      line-height: 27px;
      margin-top: 22px;
    }
    @media only screen and (min-width: 1024px) {
      padding-bottom: 0;
      margin-bottom: 70px;
    }
  }
  
}

.weltmeister.campaign-footer {
  background-color: #7D69AC;
  @media only screen and (min-width: 481px) { 
    padding-top: 70px!important;
    padding-bottom: 80px;
  }
  .card-heading {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 40px;
    @media only screen and (min-width: 481px) {
      margin-top: 0px;
      max-width: 978px;
    }
    h1 {
      color: #FFFFFF;
      font-family: $gobold-bold;
      font-size: 30px;
      line-height: 39px;
      @media only screen and (min-width: 481px) {
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 84px;
      }
    }
  }
  .weltmeister-card {
    display: flex;
    justify-content: center;
    max-width: 1070px;
    margin: 0 auto;
    flex-wrap: wrap;
    @media only screen and (min-width: 1007px) {
      justify-content: space-between;
    }
  }
  .weltmeister-card__content {
    border: 3px solid #fff;
    border-radius: 4px;
  
    @media only screen and (max-width: 481px) {
      max-width: 373px;
      margin: 0 auto;
    }
    @media only screen and (max-width: 1007px) {
      margin-bottom: 41px;
    }
    
    &.primary {
      img {
        @media only screen and (max-width: 481px) {
          width: 373px;
        }
      }
      @media only screen and (min-width: 481px) {
        max-width: 491px;
      }
    }
    &.secondary {
      img {
        @media only screen and (max-width: 481px) {
          max-width: 360px;
        }
      }
      @media only screen and (min-width: 481px) {
        max-width: 498px;
      }
      h3 {
        @media only screen and (min-width: 481px) {
          max-width: 450px;
        }
      }
    }
    h3 {
      font-family: $gobold-bold;
      font-style: normal;
      font-weight: 400;
      color: #fff;
      margin-top: 21px;
      margin-bottom: 13px;
      font-size: 30px;
      line-height: 42px;
      width: 100%;
      @media only screen and (min-width: 481px) {
        padding-top: 10px;
      }
    }
    p {
      
      font-family: $good-head-regular;
      color: #fff;
      font-size: 20px;
      line-height: 30px;
      max-width: 327px;
      margin: 0 auto;
      margin-bottom: 20px;
      @media only screen and (min-width: 481px) {
        font-weight: 400;
        max-width: 442px;
        margin-bottom: 20px;
      }
    }
  }
}

.penny-participation {
  #name-brand-tuc, #name-brand-milka, #name-brand-wunderbar {
    * {
      color: #fff;
    }
    .input-container {
      @media only screen and (min-width: 481px) {
        width: 100%;
      }
      span#error-brand-tuc {
        display: none;
      }
      span#error-brand-milka{
        display: none;
      }
      span#error-brand-wunderbar{
        display: none;
      }
    }
    .checkmark {
      border-color: #fff;
      &::after {
        color: #fff;
      }
    }
  }
}

.modal.penny {
  .modal-content {
    background-color: #7D69AC;
    border: none;
    border-radius: 0;
    width: 90%;
    top:50%;
    @media only screen and (min-width: 481px) {
      top:53%;
      height: 653px;
    }
    @media only screen and (min-width: 854px) {
      width: 784px;
      height: 620px;
    }
    @media only screen and (min-height: 844px) {
      top:55%;
    }
    @media only screen and (max-width: 481px) {
      top:55%;
/*       overflow: scroll;
      max-height: 560px; */
    }

    @media screen and (min-width: 1024px) and (max-width: 1600px) {
      top: 53%;
      width: 756px;
      /* height: 572px; */
      /* height: min-content; */
    }
    .modal-wrapper {
      @media only screen and (max-width: 481px) {
        // overflow: scroll;
        max-height: 688px;
      }
      @media only screen and (min-width: 481px) {
        // overflow-y: scroll;
        max-height: 100%;
      }
    }
    .form-container {
      display: flex;
      justify-content: center;
      margin: 0;
      position: initial;
      width: 100%;
      margin-top: 0px;
      @media only screen and (min-width: 481px) {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .buzzer-form {
        margin: 0;
        @media only screen and (max-width: 481px) {
          width: 300px;
          // max-height: 60px;
        }
        input {
          background-color: #fff;
          width: 343px;
          @media only screen and (max-width: 481px) {
            width: 100%;
          }
        }
        picture img {
          width: auto;
          margin: 0 auto;
          height: 140px;
          @media only screen and (max-width: 481px) {
            height: 100px;
          }
        }
      }
      .input-container {
        @media only screen and (min-width: 1025px) {
          width: 100%;
        }
        @media only screen and (max-width: 481px) {
          width: 100%;
          margin: 7px auto;
        }
      }
    }
    .btn__container {
      font-family: $good-head-pro;
      font-weight: bold;
      width: 343px;
      margin-bottom: 40px;
      border: 2px solid #fff;
      font-size: 20px;
      line-height: 15px;
      letter-spacing: 5px;
      @media only screen and (max-width: 481px) {
        width:300px;
      }
    }
    .close-modal {
      background-color: #7D69AC;
      border-radius: 50%;
      border: 2px solid #fff;
      width: 52px;
      height: 52px;
      text-align: center;
      top: -25px;
      right: -17px;
      img {
        width: 31px;
        height: 35px;
      }

    }
    .error-code {
      text-align: center;
      color: #B32525;
      font-size: 15px;
      margin-bottom: 14px;
    }
  }

  .modal-heading {
    text-align: center;
    h1 {
      font-family: 'Gobold Bold';
      text-transform: uppercase;
      font-size: 24px;
      line-height: 39px;
      margin-bottom: 11px;
      max-width: 303px;
      color: #fff;
      @media only screen and (min-width: 481px) {
        max-width: initial;
        font-size: 30px;
        line-height: 56px;
        padding-top: 8px;
      }
      @media only screen and (max-width: 768px) {
        margin: 0 auto;
        margin-top: 21px;
        margin-bottom: 11px;
      }
    }
    p {
      font-family: $good-head-regular;
      margin: 0 auto;
      font-size: 20px;
      line-height: 25px;
      max-width: 313px;
      margin-bottom: 10px;
      color: #fff;
      @media only screen and (min-width: 481px) {
        text-align: center;
        max-width: 588px;
        margin-bottom: 10px;
      }
      &:last-of-type {
        margin-top: 9px;
        @media only screen and (min-width: 481px) {
          margin-bottom: -16px;
        }
      }
    }
    .product-code-instruction {
      width: auto;
      height: 65px;
        @media only screen and (max-width: 481px) {
          height: 50px;
        }
    }
  }
}
.modal-v1.penny {
  .modal-content {
    background-color: #7D69AC;
    border: none;
    border-radius: 0;
    width: 90%;
    top:50%;
    z-index: 12;
    background-image: url("/resources/imagesBundesliga/buzzer_23/Start-page-rudi-tafel.png") ;
    background-repeat: no-repeat;
    background-position-y: 102%;
      background-size: calc(max(30%, 200px)) auto;
    @media only screen and (min-width: 481px) {
      top:70%;
      // height: 572px;
    }
    @media only screen and (min-width: 854px) {
      width: 784px;
      // height: 602px;
      height: auto;
    }
    @media only screen and (min-height: 844px) {
      top:55%;
    }
    @media only screen and (max-width: 481px) {
      top:55%;
/*       overflow: scroll;
      max-height: 560px; */
    }

    @media screen and (min-width: 1024px) and (max-width: 1600px) {
      top: 53%;
      width: 756px;
       height: 560px; 
      /* height: min-content; */
    }
    .modal-wrapper {
      @media only screen and (max-width: 481px) {
        // overflow: scroll;
        max-height: 608px;
      }
      @media only screen and (min-width: 481px) {
        // overflow-y: scroll;
        max-height: 100%;
      }

    }
    .form-container {
      display: flex;
      justify-content: center;
      margin: 0;
      position: initial;
      width: 100%;
      margin-top: 10px;
      @media only screen and (min-width: 481px) {
        // margin-top: 20px;
        // margin-bottom: 10px;
      }
      .buzzer-form {
        margin: 0;
        @media only screen and (max-width: 481px) {
          width: 300px;
          max-height: 170px;
        }
        input {
          background-color: #fff;
          width: 343px;
          @media only screen and (max-width: 481px) {
            width: 100%;
          }
          &::placeholder {
            font-family: $good-head-pro
          }
        }
        picture img {
          width: auto;
          margin: 0 auto;
          @media only screen and (max-width: 481px) {
            height: auto;
          }
        }
      }
      .input-container {
        @media only screen and (min-width: 1025px) {
          width: 100%;
          margin: 5px auto;
        }
        @media only screen and (max-width: 481px) {
          width: 100%;
        }
      }
        .form-input__error {
          font-family: $good-head-pro;
        }
    }
    .btn__container {
      font-family: $good-head-regular;
      font-weight: bold;
      width: 343px;
      // margin-bottom: 40px;
      margin-bottom: 124px;
      border: 2px solid #fff;
      @media only screen and (max-width: 481px) {
        width:300px;
      }
            @media only screen and (max-width: 620px) {
              margin-bottom: 217px;
            }
    }
    .close-modal {
      background-color: #7D69AC;
      border-radius: 50%;
      border: 2px solid #fff;
      width: 52px;
      height: 52px;
      text-align: center;
      top: -25px;
      right: -27px;
      img {
        width: 31px;
        height: 35px;
      }
      @media screen and (max-width:1024px) {
      width: 44px;
        height: 43px;
        text-align: center;
        top: -12px;
        right: -17px;
        img{
          width: 27px;
            height: 27px;
          }
        
      }
    }
    .error-code {
      text-align: center;
      color: #B32525;
      font-size: 15px;
      margin-bottom: 7px;
      font-family:$good-head-pro;
    }
  }

  .modal-heading {
    text-align: center;
    h1 {
      font-family: 'Gobold Bold';
      text-transform: uppercase;
      font-size: 30px;
      line-height: 39px;
      margin-bottom: 20px;
      max-width: 303px;
      color: #fff;
      @media only screen and (min-width: 481px) {
        max-width: initial;
        font-size: 34px;
        line-height: 56px;
        padding-top: 30px;
      }
      @media only screen and (max-width: 768px) {
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 20px;
      }
    }
    p {
      font-family: $good-head-regular;
      margin: 0 auto;
      font-size: 24px;
      line-height: 34px;
      max-width: 313px;
      margin-bottom: 20px;
      color: #fff;
      @media only screen and (min-width: 481px) {
        text-align: center;
        max-width: 588px;
        margin-bottom: 21px;
      }
      &:last-of-type {
        margin-top: 20px;
        @media only screen and (min-width: 481px) {
          margin-bottom: 31px;
        }
      }
    }
    .product-code-instruction {
      width: 201px;
      height: 70px;
    }
  }
  .modal-body{
    display: flex;
    justify-content: center;
    align-items: start;
    max-width: 560px;
      margin: auto;
    @media screen and (max-width:1024px){
      display: block;
    }
    @media screen and (max-width:480px){
      padding:0 ;
    }
    .modal-text-section{
          flex: 0 0 66%;
            // padding: 0 0 0 68px;
      p{
        font-size: 20px;
        font-family: $good-head-pro;
        color:#ffff;
        text-align: left;
        @media screen and (max-width:1024px) {
          text-align: center;
            display: block;
          }
      }
      p:first-child{
        margin-bottom: 18px;
      }
    }
    .modal-image-section{
      flex: 0 0 37%;
      picture img{
        width: 78%;
        margin:0 auto;
        max-width: 200px;
        @media screen and (max-width:1024px){
          margin: 15px auto 0 auto;
        }
      }
    }
  }
}

.content-home.endpage-buzzer-q3 {
  @media only screen and (min-width: 768px) {
    padding: 0;
  }
  .campaign-heading {
    @media only screen and (min-width: 481px) {
      max-width: 783px;
      margin: 0 auto;
    }
    p {
      color: #fff;
      font-family: 'Recoleta';
      font-size: 30px;
      line-height: 32px;
      margin: 60px 0 0px auto;
      @media only screen and (min-width: 481px) {
        width: 100%;
        margin: 70px auto;
        font-size: 48px;
        line-height: 27px;
        line-height: 58px;
      }
    }
  }
  .s-content-end {
    background-color: #7D69AC;
    margin: 0 auto;
    padding-top: 60px;
    @media only screen and (min-width: 481px) {
      padding: 90px 0 40px 0;
    }
    .end-element{
      margin: 0 auto;
    }
  }
}

/* Weltmeister Prizes */
#buzzer-prizes.weltmeister-prizes {
  .campaign-prize-header {
    @media only screen and (min-width: 481px) {
      max-width: 980px;
    }
    h1 {
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      max-width: 353px;
      text-align: center;
      margin-bottom: 19px;
      @media only screen and (min-width: 481px) {
        font-size: 48px;
        margin-bottom: 14px;
        line-height: 58px;
        max-width: 980px;
      }
    }
    p {
      font-family: $good-head-pro;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        max-width: 802px;
        margin: 0 auto;
      }
    }
  }
  .weltmeister.campaign-footer {
    padding: 0 10px 40px;
    @media only screen and (min-width: 481px) {
      padding: 44px 10px 58px;
    }
  }
}

/* Weltmeister faqs */
#buzzer-faqs.weltmeister-faqs{
  .content-faq{
    padding: 35px 25px 50px 25px;
    @media only screen and (max-width: 481px) {
      margin-bottom: 0;
    }
    .content-faq__item {
      @media only screen and (max-width: 481px) {
        padding-bottom: 0;
      }
      h6 {
        font-family: $good-head-regular;
      }
      li {
        font-family: $good-head-regular;
      }
      p {
        font-family: $good-head-regular;
      }
    }
  }
  .prizeRewe {
    @media only screen and (max-width: 481px) {
      padding-bottom: 40px !important;
    }
  }
  .content-separator {
    margin-top: 0;
  }
  .content-separator h2 {
    @media only screen and (max-width: 481px) {
      max-width: 300px;
      margin-bottom: 25px;
    }
  }
}

/* weltmeister confirmation */
.weltmeister-confirmation {
  .confirmation-wrapper {

    .confirmation-text {
      @media only screen and (min-width: 481px) {
        max-width: 980px;
      }
      h1 {
        font-family: 'Gobold Bold';
        font-size: 30px;
        line-height: 42px;
        margin: 0 auto;
        text-transform: uppercase;
        max-width: 353px;
        text-align: center;
        margin-bottom: 19px;
        @media only screen and (min-width: 481px) {
          font-size: 48px;
          margin-bottom: 14px;
          line-height: 58px;
          max-width: 980px;
        }
      }
      p {
        font-family: $good-head-regular;
        @media only screen and (min-width: 481px) {
          font-size: 24px;
          line-height: 34px;
          max-width: 782px;
          margin: 0 auto;
        }
        &:first-of-type {
          margin-bottom: 30px;
        }
      }
    }
  }
  .s-content-end {
    background-color: #7D69AC;
    padding: 0;
    margin: 0;
    padding: 40px 20px 0 20px;
    @media only screen and (min-width: 481px) {
      padding: 71px 0 30px 0;
    }
    .end-element {
      margin: 0 auto;
      .end-element__item {  
        margin-bottom: 33px;
        &:not(:first-child) {
  /*           @media only screen and (min-width: 481px) {
              max-height: 500px;
            } */
          .end-element__copy {
            p {
              @media only screen and (min-width: 481px) {
                margin-bottom: 0!important;
              }
            }
          }
        }
        .end-element__copy {
          h3 {
            font-family:"Good Head W01 Medium";
            text-transform: uppercase;
            font-size: 20px;
            line-height: 28px;
            font-weight: bold;
          }
          p {
            font-family: $good-head-regular;          
            font-size: 20px;
            line-height: 26px;
          }
          .btn__container {
            font-family: $good-head-regular;
            font-size: 19px;
            font-weight: bold;
            line-height: 15px;
            padding: 10px 17px 8px 17px;
          }
        }
      }
    }
  }
}

.tickets-confirmation {
  .main-confirmation {
    color: #fff;
    background-color: #7D69AC;
    padding: 30px 17px;
    .confirmation-wrapper{
      margin-bottom: 0;
      @media only screen and (min-width: 481px) {
        margin-bottom: 50px;
      }
      .confirmation-text {
        .confirmation-image {
          margin-bottom: 30px;
        }
        @media only screen and (min-width: 481px) {
          max-width: 980px;
        }
        h2 {
          font-family: "Gobold Bold";
          font-size: 28px;
          line-height: 42px;
          margin: 0 auto;
          text-transform: uppercase;
          max-width: 353px;
          text-align: center;
          margin-bottom: 19px;
          @media only screen and (min-width: 481px) {
            font-size: 60px;
            margin-bottom: 44px;
            line-height: 56px;
            max-width: 980px;
          }
        }
        p {
          font-family: $good-head-pro;
          font-size: 24px;
          line-height: 34px;
          @media only screen and (min-width: 481px) {
            max-width: 663px;
            margin: 0 auto;
          }
          &:first-of-type {
            margin-bottom: 0;
          }
          span{
            font-family: $good-head-pro;
            font-weight: bold;
          }
        }
      }
    }
    
  }
  .s-content-end {
    @media only screen and (min-width: 481px) {
      padding:0 0 30px 0;
    }
  }
  .s-content-end .end-element {
    max-width: unset;
  }
  .s-content-end .end-element__item {
    width: 380px;
    @media only screen and (min-width: 759px) {
      height: 620px;
    }
    @media only screen and (max-width: 759px) {
      height: 570px;
    }
    &:first-child {
      @media only screen and (max-width: 759px) {
        height: 620px;
      }
    }
  }
  .s-content-end .end-element__item:nth-child(2),
  .s-content-end .end-element__item:nth-child(3) {
    margin-left: 0;
    @media only screen and (min-width: 797px) {
      margin-left: 20px;
    }
  }
  .s-content-end .end-element .end-element__item .end-element__copy {
    h3 {
      font-family: $gobold-bold;
      text-transform: inherit;
      font-size: 24px;
      line-height: 34px;
    }
    p {
      font-family: $good-head-regular;
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.content-home.game-90-min.extended-promo {
  .campaign-heading {
    @media only screen and (max-width: 481px) {
      margin: 20px 0 70px;
    }
    h1 {
      max-width: 337px;
      @media only screen and (min-width: 481px) {
        max-width: 803px;
        margin-bottom: 0;
      }
    }
    p.sub {
      max-width: 345px;
      @media only screen and (min-width: 481px) {
        max-width: 587px;
        font-family: 'Recoleta';
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 70px;
      }
    }
    #edeka-terms {
      @media only screen and (min-width: 481px) {
        margin-top: 70px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .s-content-steps {
      @media only screen and (min-width: 1025px) {
        max-width: 897px;
      }
    }
    .s-content-steps__item:nth-child(2) {
      p {
        max-width: 231px;
      }
    }
  }
}

/* weltmeister End */
.content-holding__heading.closing.weltmeister-end {
  .content-end__text {
    color: #fff;
    @media only screen and (min-width: 481px) {
      margin-bottom: 80px;
    }
    h1 {
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      max-width: 353px;
      text-align: center;
      margin-bottom: 19px;
      @media only screen and (min-width: 481px) {
        font-size: 40px;
        margin-bottom: 14px;
        line-height: 58px;
        max-width: 980px;
      }
    }
    p {
      font-family: $good-head-regular;
      text-align: center;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        max-width: 802px;
        margin: 0 auto;
      }
    }
  }
  .content-end__text-buzzer {
    color: #fff;
    @media only screen and (min-width: 481px) {
      margin-bottom: 40px;
      margin-top: 0;
    padding-top: 80px;
    padding-bottom: 91px;
    }
    h1 {
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      max-width: 353px;
      text-align: center;
      margin-bottom: 19px;
      @media only screen and (min-width: 481px) {
        font-size: 40px;
        margin-bottom: 14px;
        line-height: 58px;
        max-width: 980px;
      }
    }
    p {
      font-family: $good-head-regular;
      text-align: center;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        max-width: 802px;
        margin: 0 auto;
      }
    }
  }

  .s-content-end {
    .end-element__item {
      .end-element__copy {
        h3 {
          font-family: "Good Head W01 Medium";
          text-transform: uppercase;
          font-size: 20px;
          line-height: 28px;
          font-weight: bold;
        }
        p {
          font-family: $good-head-regular;
          font-size: 20px;
          line-height: 26px;
        }
        .btn__container {
          font-family: $good-head-regular;
          font-size: 19px;
          font-weight: bold;
          line-height: 15px;
          padding: 10px 17px 8px 17px;
        }
      }
    }
  }
    .content-home.endpage-buzzer-q3 {
      @media only screen and (min-width: 768px) {
        padding: 0;
      }
  
      .campaign-heading {
        @media only screen and (min-width: 481px) {
          max-width: 783px;
          margin: 0 auto;
        }
  
        p {
          color: #fff;
          font-family: 'Recoleta';
          font-size: 30px;
          line-height: 32px;
          margin: 60px 0 0px auto;
  
          @media only screen and (min-width: 481px) {
            width: 100%;
            margin: 70px auto;
            font-size: 48px;
            line-height: 27px;
            line-height: 58px;
          }
        }
      }
  
      .s-content-end {
        background-color: #7D69AC;
        margin: 0 auto;
        padding-top: 60px;
  
        @media only screen and (min-width: 481px) {
          padding: 90px 0 40px 0;
        }
  
        .end-element {
          margin: 0 auto;
  
          &__copy {
            h3 {
              font-size: 24px;
              font-family: $gobold-bold;
            }
  
            p {
              font-family: $good-head-pro;
            }
  
            .btn__container {
              font-family: $good-head-pro;
              padding: 10px 17px 10px 17px;
            }
          }
        }
      }
    }
}

/* weltmeister product page */
.content-holding__heading.closing.product-wrapper.weltmeister-product {
  .content-end__text {
    max-width: 373px;
    color: #fff;
    @media only screen and (min-width: 481px) {
      margin-bottom: 80px;
      max-width: 784px;
    }
    h1 {
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      max-width: 353px;
      text-align: center;
      margin-bottom: 19px;
      @media only screen and (min-width: 481px) {
        font-size: 60px;
        line-height: 90px;
        /* font-size: 48px;
        line-height: 58px; */
        margin-bottom: 14px;
        max-width: 980px;
      }
    }
    p {
      font-family: $good-head-regular;
      text-align: center;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        max-width: 588px;
        margin: 0 auto;
      }
    }
  }
  .s-content-end {
    .end-element__item {
      &:nth-child(2) {
        margin-left: 0;
        @media only screen and (min-width: 731px) {
          margin-left: 20px;
        }
      }
      .end-element__copy {
        h3 {
          font-family: "Good Head W01 Medium";
          text-transform: uppercase;
          font-size: 20px;
          line-height: 28px;
          font-weight: bold;
        }
        p {
          font-family: $good-head-regular;
          font-size: 20px;
          line-height: 26px;
        }
        .btn__container {
          font-family: $good-head-regular;
          font-size: 19px;
          font-weight: bold;
          line-height: 15px;
          padding: 10px 17px 8px 17px;
        }
      }
    }
  }
}

/* weltmeister participation */
#buzzer-participation.weltmeister-participation {
  .campaign-heading {
    @media only screen and (min-width: 481px) {
      margin: 0 0 70px;
    }
    h1 {
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      text-transform: uppercase;
      max-width: 353px;
      text-align: center;
      margin-bottom: 19px;
      padding-top: 0;
      max-width: unset;
      @media only screen and (min-width: 481px) {
        font-size: 60px;
        /* font-size: 48px; */
        line-height: 58px;
        margin-bottom: 14px;
        line-height: 90px;
        max-width: 980px;
      }
    }
    p {
      font-family: $good-head-regular;
      text-align: center;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        max-width: 621px;
        margin: 0 auto;
      }
    }
  }
  .content-participation__login {
    .buzzer-form {
      #frm_login {
        .form-container {
          h2 {
            font-family: "Good Head W01 Black";
            @media only screen and (max-width: 481px) {
              font-size: 30px;
              line-height: 39px;
            }
          }
          h3 {
            font-family: $good-head-regular;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 23px;
            @media only screen and (max-width: 481px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
          .btn__container {
            font-family: $good-head-regular;
            font-weight: bold;
            letter-spacing: 5px;
            text-transform: uppercase;
          }
        } 
      }
    }
  }
  #participant-info.buzzer-form {
    
    @media only screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
    @media only screen and (min-width: 481px) {
      margin: 40px 0 75px;
    }
    @media only screen and (min-width: 1025px) {
      margin: 40px 0 75px;
    }
    h2{
      /* text-transform: uppercase; */
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 39px;
      @media only screen and (min-width: 481px) {
        font-size: 40px;
        margin-bottom: 14px;
        line-height: 52px;
      }
    }
    h3 {
      font-family: $good-head-regular;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
      }
    }
    #userDetails.form-container {
      #salutation {
        p {
          @media only screen and (max-width: 481px) {
            font-size: 17px;
          }
        }
      }
    }

    #dob-container {
      .form-container {
        @media only screen and (min-width: 481px) {
          max-width: initial;
        }
        .input-container {
          @media only screen and (min-width: 481px) {
            margin-top: 0;
          }
          .custom-inputs {
            @media only screen and (min-width: 481px) {
              padding-right: 0;
              padding-left: 50px;
              padding-top: 4px;
            }
            .checkmark {
              @media only screen and (min-width: 481px) {
                right: auto;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
  
  .btn__container {
    font-family: $good-head-regular;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 20px;
    @media only screen and (max-width: 480px) {
      margin-top: 0;
    }
  }

  .retailer-container__form {
    @media only screen and (max-width: 481px) {
      margin-bottom: 50px;
    }
    h6.centered {
      font-family: $good-head-regular;
      margin:0;
      width: 100%;
      font-size: 24px;
      @media only screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
    .brand-wrapper {
      display: flex;
      margin-bottom: 40px;
      .form-container {
        width: 160px;
        margin: 0;
        margin-right: 0;
        .input-container {
          @media only screen and (min-width: 1025px) {
            width:100%;
          }
          .custom-inputs {
            span.text-content {
              color:#fff;
              font-family: $good-head-regular;
            }
            span.checkmark {
              border: 1px solid #fff;
              &::after {
                color: #fff;
                left: 4px;
                top: 6px;
                content: "\e019";
                font-family: icomoon;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    #product-purchase-error {
      padding-top: 5px;
    }
  }
  .team-container__form {
    @media only screen and (max-width: 480px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .campaign-consent {
    .form-container {  
      .text-content {
        font-family: $good-head-regular;
      }
    }
    #name-terms.form-container {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
    a#moreInfo {
      padding-left: 55px;
      strong {
        font-size: 15px;
      }
    }
    span#error-terms {
      padding-top: 6px;
    }
  }
  .content-participation__form {
    p.special {
      font-family: $good-head-regular;
      @media only screen and (min-width: 480px) {
        margin-bottom: 30px;
        max-width: 603px;
      }
    }
    .chooseTeam {
      h2.heading {
        font-family: $gobold-bold;
        text-transform: uppercase;
        padding-bottom: 20px;
      }
      .sub-heading {
        font-family: $good-head-regular;
        text-align: center;
        margin-bottom: 30px;
        font-size: 20px;
        @media only screen and (min-width: 480px) {
          font-size: 24px;
        }
        span {
          font-weight: bold;
        }
      }
      .chooseTeam--team-container {
        .team {
          p {
            font-family: "Good Head W01 Medium";
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

#main-participation.weltmeister-participation {
  .campaign-heading {
    @media only screen and (min-width: 481px) {
      margin: 0 0 70px;
    }
    h1 {
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      text-transform: uppercase;
      max-width: 353px;
      text-align: center;
      margin-bottom: 19px;
      padding-top: 0;
      max-width: unset;
      @media only screen and (min-width: 481px) {
        font-size: 60px;
        /* font-size: 48px; */
        line-height: 58px;
        margin-bottom: 14px;
        line-height: 90px;
        max-width: 980px;
      }
    }
    p {
      font-family: $good-head-regular;
      text-align: center;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
        max-width: 621px;
        margin: 0 auto;
      }
    }
  }
  .content-participation__login {
    .buzzer-form {
      #frm_login {
        .form-container {
          h2 {
            font-family: "Good Head W01 Black";
            @media only screen and (max-width: 481px) {
              font-size: 30px;
              line-height: 39px;
            }
          }
          h3 {
            font-family: $good-head-regular;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 23px;
            @media only screen and (max-width: 481px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
          .btn__container {
            font-family: $good-head-pro;
            font-weight: bold;
            letter-spacing: 5px;
            text-transform: uppercase;
            @media only screen and (min-width: 481px) {
              margin-bottom: 41px;
            }
          }
        } 
      }
    }
  }
  #participant-info.buzzer-form {
    
    @media only screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
    @media only screen and (min-width: 481px) {
      margin: 40px 0 75px;
    }
    @media only screen and (min-width: 1025px) {
      margin: 40px 0 75px;
    }
    h2{
      /* text-transform: uppercase; */
      font-family: 'Gobold Bold';
      font-size: 30px;
      line-height: 39px;
      @media only screen and (min-width: 481px) {
        font-size: 40px;
        margin-bottom: 14px;
        line-height: 52px;
      }
    }
    h3 {
      font-family: $good-head-regular;
      @media only screen and (min-width: 481px) {
        font-size: 24px;
        line-height: 34px;
      }
    }
    #userDetails.form-container {
      #salutation {
        p {
          @media only screen and (max-width: 481px) {
            font-size: 17px;
          }
        }
      }
    }

    #dob-container {
      .form-container {
        @media only screen and (min-width: 481px) {
          max-width: initial;
        }
        .input-container {
          @media only screen and (min-width: 481px) {
            margin-top: 0;
          }
          .custom-inputs {
            @media only screen and (min-width: 481px) {
              padding-right: 0;
              padding-left: 50px;
              padding-top: 4px;
            }
            .checkmark {
              @media only screen and (min-width: 481px) {
                right: auto;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
  
  .btn__container {
    font-family: $good-head-regular;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 20px;
    @media only screen and (max-width: 480px) {
      margin-top: 0;
    }
  }

  .retailer-container__form {
    @media only screen and (max-width: 481px) {
      margin-bottom: 50px;
    }
    h6.centered {
      font-family: $good-head-regular;
      margin:0;
      width: 100%;
      font-size: 24px;
      @media only screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
    .brand-wrapper {
      display: flex;
      margin-bottom: 40px;
      .form-container {
        width: 160px;
        margin: 0;
        margin-right: 0;
        .input-container {
          @media only screen and (min-width: 1025px) {
            width:100%;
          }
          .custom-inputs {
            span.text-content {
              color:#fff;
              font-family: $good-head-regular;
            }
            span.checkmark {
              border: 1px solid #fff;
              &::after {
                color: #fff;
                left: 4px;
                top: 6px;
                content: "\e019";
                font-family: icomoon;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    #product-purchase-error {
      padding-top: 5px;
    }
  }
  .team-container__form {
    @media only screen and (max-width: 480px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .campaign-consent {
/*     .form-container {  
      .text-content {
        font-family: $good-head-regular;
      }
    } */
    #name-terms.form-container {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
    a#moreInfo {
      padding-left: 55px;
      strong {
        font-size: 15px;
      }
    }
    span#error-terms {
      padding-top: 6px;
    }
  }
  .content-participation__form {
    p.special {
      font-family: $good-head-regular;
      @media only screen and (min-width: 480px) {
        margin-bottom: 30px;
        max-width: 603px;
      }
    }
    .chooseTeam {
      h2.heading {
        font-family: $gobold-bold;
        text-transform: uppercase;
        padding-bottom: 20px;
      }
      .sub-heading {
        font-family: $good-head-regular;
        text-align: center;
        margin-bottom: 30px;
        font-size: 20px;
        @media only screen and (min-width: 480px) {
          font-size: 24px;
        }
        span {
          font-weight: bold;
        }
      }
      .chooseTeam--team-container {
        .team {
          p {
            
          }
        }
      }
    }
  }
}

/* Q4 selection page */
.campaign-selection-header-mob {
  padding: 40px 30px;
  text-align: center;
  @media only screen and (min-width: 481px) {
    display: none;
  }
  p {
    font-family: $good-head-regular;
    color: #fff;
    font-size: 20px;
    line-height: 28px;
  }
}
.selection-wrapper {
  background-image: url("/resources/imagesBundesliga/weltmeister/stadium-image.png");
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 481px) {
    background-position: center;
  }
  .campaign-selection-header {
    max-width: 819px;
    margin: 0 auto;
    padding-top: 50px;
    margin-bottom: 50px;
    @media only screen and (max-width: 481px) {
      max-width: 310px;
    }
    h1 {
      text-align: center;
      color: #3B2772;
      font-family: 'Gobold Bold';
      text-transform: uppercase;
      font-size: 23px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 56px;
      }
    }
    p {
      font-family: $good-head-regular;
      text-align: center;
      color: #3B2772;
      @media only screen and (min-width: 481px) {
        font-size: 20px;
        line-height: 140%;
        max-width: 659px;
        margin: 0 auto;
      }
      @media only screen and (max-width: 481px) {
        display: none;
      }
    }
  }
  .mate-selection-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    margin: 0 auto;
    @media only screen and (max-width: 481px) {
      max-width: 400px;
    }
    .mate {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 600px;
      justify-content: end;
      height: 208px;
      @media only screen and (min-width: 481px) {
        height: 404px;
      }
      &.lightUp {
        background-image: url("/resources/imagesBundesliga/weltmeister/selection-amimation/uplight.png");
        background-repeat: no-repeat;
        /* background-position: 50% -160px; */
        background-position: 50% -44px;

        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
        @media only screen and (max-width: 481px) {
          background-position: 50% 23px;
          background-size: 209px;
        }
        }
      img {
        width: 160px;
        @media only screen and (min-width: 481px) {
          width: 280px;
        }
        &.unselect {
          width: 130px;
          @media only screen and (min-width: 481px) {
            width: 210px;
          }
        }
      }
      img.plinth {
        width: 115px;
        @media only screen and (min-width: 481px) {
          width: 215px;
        }
      }
      .stars:hover {
        cursor: pointer;
      }
    }
  }
  .cta-container {
    padding-top: 30px;
    padding-bottom: 40px;
    .btn__container {
      font-family: $good-head-regular;
      margin: 0 auto;
      border: 2px solid #fff;
      text-transform: uppercase;
      letter-spacing: 5px;
      width: 345px;
      @media only screen and (min-width: 481px) {
        width: 486px;
      }
      @media only screen and (max-width: 481px) {
        padding: 0;
      }
      &.unselect {
        background-color: #CEC7DF;
        width: 345px;
        @media only screen and (min-width: 481px) {
          width: 486px;
        }
        &:before {
          background-color: #CEC7DF;
        }
      }
    }
  }
}
.user-details-wrapper{
  .campaign-heading {
      .contenu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

  
        .zuruck-button {
           position:relative;
           left:-20%;
           margin:0 auto 15px auto;
          @include breakpoint(small-only){
            left: 0;
          }

          .btn-icon{
            border:2px solid $white ;
            &__text{
              color:$white;
            }
          }
        }
  
      }
  
  
    }
}
  
@import "../../05_STATE/general/page";
