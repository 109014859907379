.checkboxTeamRetailer-container{
    width: 65%;
    margin: auto;

    @media only screen and (max-width: 767px) {
        width: 90%;
        margin-top: -50px;
    }

    .checkboxTeamRetailer{
        margin: auto;
        margin-top: 40px;
        width: 40%;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }

        .custom-radio {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            margin-bottom: 20px;

            .labelcustom{
                font-family: "Good Headline Pro";
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
    
            input[type="radio"] {
                position: absolute;
                opacity: 0;
            }
    
            .radio {
                position: relative;
                display: inline-block;
                width: 21px;
                height: 20px;
                background-color: #ccc;
                border: 0px;
                top: 4px;
                border-radius: 50%;
                margin-right: 10px;
            }
    
            input[type="radio"]:checked+.radio {
                border: 7px solid #7D69AC;
            }
            
        }
    }

    p{
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Good Headline Pro";
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-align: center;

        span{
            font-weight: 700;
        }
    }
}

.errorProduitChoix{
    color: rgb(217, 51, 51) !important;
    margin-top: -20px;
    margin-bottom: 35px;
} 

.reiheBtn{
    @media only screen and (max-width: 767px) {
        margin-top: 55px !important;
    } 
}

.passWordtitle{
    font-family: "Good Headline Pro" !important;
}

.reiheBuzzerForm{
    @media only screen and (max-width: 767px) {
        margin-bottom: 0px !important;
    }
}

.buzzer-info{
    strong{
        font-family: "Good Headline Pro Bold"
    }
}

.reiheInputContainer{
    @media only screen and (max-width: 767px) {
        padding-top: -35px !important;
    }
}