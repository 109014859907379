.content-holding__heading.trikot-promo {
  background-color: #7D69AC;
  .form-input__input {
    font-family: $good-head-pro;
    &::placeholder{
      font-family: $good-head-pro;
    }
    &::-moz-placeholder{
      font-family: $good-head-pro;
    }
    &:-ms-input-placeholder{
      font-family: $good-head-pro;
    }
  }
}
span.good-headline-gold {
  font-family: $good-head-pro;
  display: unset!important;
  font-weight: bold;
  color: #E8CF73!important;
}

.image__container .form-button.form-button-main.weltmeister.trikot {
  @media only screen and (min-width: 1025px) {    
    right: calc(100% / 20);
  }
  @media only screen and (min-width: 1100px) {    
    right: calc(100% / 17);
  }
  @media only screen and (min-width: 1200px) {    
    right: calc(100% / 13);
  }
  @media only screen and (min-width: 1400px) {    
    right: calc(100% / 12);
  }
  @media only screen and (max-width: 1023px) {    
    bottom:12%;
  }
  button {
    background-color: #3B2774;
    font-family: $good-head-pro;
    display: inline-block;
    @media only screen and (max-width: 481px) {
      width: 295px;
    }
    @media only screen and (min-width: 481px) {
      font-size: 22px;
      padding: 8px 40px 8px 40px;
    }
    @media only screen and (min-width: 1024px) {
      width: 378px;
      min-width: unset;
    }
    &::before {
      background-color: #3B2774;
    }
    &:hover {
      background-color: #3B2774;
    }
    span {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-align: inherit;
        letter-spacing: inherit;
        display: inline-block;
        @media only screen and (max-width: 481px) {
          display: none;
        }
    }
  }
}

#main-buzzer.trikot-main {
  background-color: #7D69AC;
  .campaign-heading {
    margin-top: 0;
    padding-top: 50px;
    p {
      font-family: $good-head-pro;
    }
  }
  .note-promo-code {
    font-family: $good-head-pro;
  }
  .s-content-steps.step-weltmeister .s-content-steps__item p {
    font-family: $good-head-pro;
    .text--wheat {
      font-family: $good-head-pro;
    }
  }
  #edeka-terms {
    font-family: $good-head-pro;
  }
  .ticket-promo-container h3 {
    @media only screen and (min-width: 481px) {
      font-size: 40px;
    }
  }
  .btn--secondary-lilac{
    font-family: $good-head-pro;
    background-color: #3B2774;
    padding: 8px 17px 8px 17px;
    @media only screen and (min-width: 481px) {
      font-size: 22px;
    }
    &::before {
      background-color: #3B2774;
    }
    &:hover {
      background-color: #3B2774;
      background-image:unset;
    }
  }
  span.good-headline-gold {
    font-family: inherit;
    display: unset!important;
    font-weight: bold;
    color: #E8CF73!important;
  }

  .note-promo-code {
    @media only screen and (min-width: 481px) {
      margin-top: 64px;
    }
  }
  #product-code-link{
    display: inline-block;
    text-decoration: underline;
  }
}

.trikot-promo-container {
  h3 {
    font-family: "Gobold Bold";
    font-size: 30px;
    line-height: 42px;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    @media only screen and (min-width: 481px) {
      font-weight: 400;
      margin-top: 32px;
      font-size: 60px;
      margin-bottom: 60px;
    }
  }
  .trikot-promo-container-wrapper {
    max-width: 1175px;
    /* max-height: 376px; */
    background: #3B2774;
    border: 6px solid #E8CF73;
    border-radius: 14px;
    justify-content: center;
    padding: 32px 0;
    margin: 0 30px;
    margin-bottom: 31px;
    @media only screen and (min-width: 1167px) {
    margin: 0 auto;
    margin-bottom: 31px;
    }
    @media only screen and (max-width: 481px) {
      height: 753px;
      max-width: 300px; 
      margin: 0 auto;
      margin-bottom: 24px;
    }
    .trikot-promo-card {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap-reverse;
      .trikot-promo__content {
        display: flex;
        flex-direction: column;
        max-width: 444px;
        max-height: 296px;
        align-items: center;
        gap: 8px;
        img {
          @media only screen and (max-width: 481px) {
            width: 186px;
          }
        }
        h4 {
          font-family: 'Gobold Bold';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          text-transform: uppercase;
          color: #FFFFFF;
          /* max-width: 340px; */
          @media only screen and (max-width: 481px) {
            max-width: 297px;
          }
        }
        p {
          font-family: 'Good Headline Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #FFFFFF;
          @media only screen and (max-width: 481px) {
            max-width: 270px;
          }
        }
      }
    }
  }

  .trikot-promo-container-wrapper-secondary {
    display: flex;
    max-width: 1175px;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 31px;
    flex-wrap: wrap;
    
    @media only screen and (min-width: 1169px) {
      margin-bottom: 36px;
    }
    @media only screen and (min-width: 1167px) {
      justify-content: space-between;
    }
    @media only screen and (max-width: 629px) {
      margin: 0 30px;
    }
    @media only screen and (max-width: 481px) {
      margin-bottom: 0;
    }
    .trikot-promo-card {
      background: #3B2774;
      border: 6px solid #E8CF73;
      border-radius: 14px;
      width: 575px;
      max-height: 538px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 19px 0 27px 0;
      @media only screen and (max-width: 1167px) {
        margin-bottom: 30px;
      }
      @media only screen and (max-width: 481px) {
        width: 300px;
        margin-bottom: 0;
      }

      img {
        width: 262px;
        padding-bottom: 33px;
        @media only screen and (max-width: 481px) {
          width: 186px;
        }
      }
      &.primary {
        @media only screen and (max-width: 481px) {
          margin-bottom: 30px;
        }
      }
      &.secondary {
        @media only screen and (max-width: 481px) {
          max-height: unset;
        }
        img {
          width: 275px;
        }
        h4 {
          @media only screen and (max-width: 481px) {
            max-width: 270px;
          }
        }
      }
      h4 {
        font-family: 'Gobold Bold';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-bottom: 6px;
        @media only screen and (max-width: 481px) {
          max-width: 150px;
        }
      }
      p {
        font-family: 'Good Headline Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        max-width: 444px;
        @media only screen and (max-width: 481px) {
          max-width: 280px;
        }
      }
      .trikot-promo__content {
        max-width: 444px;
      }
    }
  }
  button.btn__container {
    font-family: 'Good Headline Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
    width: 295px;
    margin-bottom: 70px;
    @media only screen and (min-width: 481px) {
      width: 378px;
    }
    @media only screen and (max-width: 481px) {
      margin-top: 25px;
      margin-bottom: 39px;
    }
  }
}

.modal.penny.trikot-promo {
  .modal-content {
    @media only screen and (min-width: 844px) {
      height: 580px;
    }
    @media only screen and (min-width: 695px) {
      height: 603px;
    }
    @media only screen and (min-width: 750px) {
      height: 553px;
    }
    .form-container {
      @media only screen and (min-width: 481px) {
        margin-bottom: 0;
        margin-top: 0;
      }
      @media only screen and (max-width: 481px) {
        width: 315px;
        margin: 0 auto;
      }
      .input-container {
        @media only screen and (min-width: 481px) and (max-width: 695px) {
          margin-bottom: 0;
        }
      }
      .buzzer-form {
        @media only screen and (max-width: 481px) {
          width: unset;
        }
        input {
          ::placeholder {
            font-family: $good-head-pro;
          }
        }
      }
    }
  }
  h1 {
    font-size: 30px;
    @media only screen and (min-width: 481px) {
      font-size: 40px;
      padding-top: 17px;
      margin-bottom: 21px;
    }
    @media only screen and (min-width: 768px) {
      padding-top: 37px;
    }
    @media only screen and (max-width: 481px) {
      margin-bottom: 21px;
    }
  }
  .heading-content {
    display: flex;
    justify-content: center;
    max-width: 650px;
    margin: 0 auto;
    flex-wrap: wrap;
    @media only screen and (max-width: 481px) {
      margin-bottom: 21px;
    }
    @media only screen and (min-width: 674px) {
      justify-content: space-between;
    }
    @media only screen and (min-width: 674px) and (max-width:790px) {
      padding: 0 10px;
      justify-content: center;
    }
    @media only screen and (min-width: 696px) {
      margin-bottom: 16px;
    }
    p {
      font-family: 'Good Headline Pro';
      margin-bottom: 27px;
      text-align: center;
      @media only screen and (min-width: 696px) {
        text-align: left;
      }
      &:last-child {
        @media only screen and (max-width: 696px) {
          margin-bottom: 7px;
        }
      }
    }
    .secondary-text {
      @media only screen and (min-width: 481px) {
        max-width: 373px;
      }
    }
    .product-code-instruction {
      
      @media only screen and (min-width: 481px) {
        height: auto;
      }
    }
  }
  .btn--secondary-lilac{
    &::before {
      background-color: #7D6BAA!important;
    }
    &:hover {
      background-color: #7D6BAA!important;
    }
  }
}

.content-holding__heading.closing.product-wrapper.weltmeister-product.ticket-product.trikot-product {
  background-color: #7D69AC;
  .content-end__text {
    @media only screen and (min-width: 481px) {
      padding-bottom: 0;
    }
    margin-top: 0;
    h1 {
      text-transform: uppercase;
    }
    p {
      font-family: $good-head-pro;
    }
  }
  .chocolate {
    @media only screen and (min-width: 481px) {
      margin-bottom: 80px;
    }
    @media only screen and (max-width: 1025px) {
      padding-bottom: 70px;
    }
  }
  .s-content-end {
    .end-element {    
      @media only screen and (min-width: 1024px) {
        max-width: 981px;
        max-height: 365px;
      }
    }
    .end-element__item{
      justify-content: space-between;
      padding: 0;
      @media only screen and (min-width: 481px) {
        max-width: unset;
      }
      @media only screen and (min-width: 1024px) {
        max-height: 365px;
        flex-direction: row;
      }
      img {
        object-fit: unset;
      }
    }
    .end-element__copy {
      margin: unset;
      max-width: unset;
      @media only screen and (min-width: 1024px) {
        padding-top: 30px;
        padding-left: 60px;
      }
      h3 {
        @media only screen and (min-width: 481px) {
          max-width: 350px;
        }
      }
      p {
        @media only screen and (min-width: 481px) {
          margin-bottom: 0;
          max-width: 335px;
          padding-bottom: 34px;
        }
      }
      .btn__container.btn--secondary-lilac {
        position: unset;
        margin: unset;
      }
    }
    img  {
      width: auto;
    }   
  }
  .s-content-end ~ .s-content-end {
    padding-top: 0;
    .end-element__item {
      @media only screen and (min-width: 1024px) { 
        flex-direction: row-reverse;
      }
      .end-element__copy {
        @media only screen and (min-width: 1024px) {
          padding-top: 30px;
          padding-right: 60px;
        }
      }
    }
  }
/*   .overview-slider-wrapper .swiper-wrapper .swiper-slide {
    max-width: 674px;
    background-color: unset;
  }
  .overview-slider-wrapper .swiper-container {
    @media only screen and (max-width:481px) { 
      width: 300px;
    }
  }

  .Slider__wrapper {
    width: 350px;
    border-radius: 20px;
    @media only screen and (min-width:859px) { 
      width: 674px;
    }
    @media only screen and (max-width:481px) { 
      width: 300px;
    }
    h1.article-headline {
      font-family: $gobold-bold;
      font-size: 40px;
      color: inherit;
    }
    .image_container {
      display: flex;
      justify-content: center;
      .banner__image {
        width: 300px;
        @media only screen and (min-width:859px) { 
          width: 490px;
        }
      }
    }
    .Slider__excerpt {
      padding: 50px 0px 30px 0;
      @media only screen and (min-width:859px) { 
        padding: 30px 0px 10px 0;
      }
    }
    button.btn__container {
      background-color: var(--alpine-blue, #8BD1F0);
      font-family: $good-head-pro;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 4px;
      text-transform: uppercase;
      color: var(--deep-purple, #3B2774);
      &::before {
        background-color: var(--alpine-blue, #8BD1F0);
      }
      &:hover {
        background-color: var(--alpine-blue, #8BD1F0);
        background-image: unset;
      }
      a {
        color: var(--deep-purple, #3B2774);
      }
    }
  } */
}

.overview-slider-wrapper.trikot {
  @media only screen and (max-width:481px) { 
    padding: 50px 0 70px 0;
  }
  @media only screen and (min-width:859px) { 
    padding: 80px 0;
  }
  .swiper-wrapper .swiper-slide {
    max-width: 674px;
    background-color: unset;
  }
  .swiper-container {
    @media only screen and (max-width:481px) { 
      width: 300px;
    }
    @media only screen and (min-width:768px) { 
      width: 100%;
    }
    @media only screen and (min-width:1234px) { 
      width: 100%;
    }
  }
  .swiper-wrapper {
    .Slider__wrapper {
      width: 350px;
      border-radius: 20px;
      @media only screen and (min-width:859px) { 
        width: 674px;
      }
      @media only screen and (max-width:481px) { 
        width: 300px;
      }
      .Slider__right .Slider__excerpt h1.article-headline {
        font-family: $gobold-bold;
        font-size: 33px;
        color: inherit;
        @media only screen and (min-width:768px) { 
          font-size: 40px;
        }
      }
      .image_container {
        display: flex;
        justify-content: center;
        .banner__image {
          width: 300px;
          @media only screen and (min-width:859px) { 
            width: 490px;
          }
        }
      }
      .Slider__right {
        .Slider__excerpt {
          padding: 50px 0px 30px 0;
          @media only screen and (min-width:859px) { 
            padding: 30px 0px 10px 0;
          }
        }
      }

      button.btn__container {
        background-color: var(--alpine-blue, #8BD1F0);
        font-family: $good-head-pro;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 4px;
        text-transform: uppercase;
        color: var(--deep-purple, #3B2774);
        &::before {
          background-color: var(--alpine-blue, #8BD1F0);
        }
        &:hover {
          background-color: var(--alpine-blue, #8BD1F0);
          background-image: unset;
        }
        a {
          color: var(--deep-purple, #3B2774);
        }
      }
    }
  }
  .swiper-button-next {
    background-color: #fff;
    color: #3B2774;
    top: 94%;
    right: 40%;
    @media only screen and (min-width:481px) { 
      right: 45%;
      top: 95%;
    }
    @media only screen and (min-width:1250px) { 
      right: 47%;
      top: 95%;
    }
    &::after {
      font-size: 17px;
      font-weight: 700;
    }
  }
  .swiper-button-prev {
    background-color: #fff;
    color: #3B2774;
    top: 94%;
    left: 40%;
    @media only screen and (min-width:481px) { 
      left: 45%;
      top: 95%;
    }
    @media only screen and (min-width:1250px) { 
      left: 47%;
      top: 95%;
    }
    &::after {
      font-size: 17px;
      font-weight: 700;
    }
  }
}

.trikot-prizes {
  background-color: #7D69AC;
  span.good-headline-gold {
    font-family: inherit;
  }
  .trikot-promo-container {
    h3 {
      padding-top: 33px;
      @media only screen and (min-width: 481px) { 
        margin-top: 0;
        padding-top: 70px;
      }
    }
  }
}
.prizes-question-faq {
    margin-bottom: 62px;
    margin-top: 73px;
    color:#fff;
    @media only screen and (max-width: 481px) { 
      margin-bottom: 12px;
    }
  h2 {
    font-family: 'Gobold Bold';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 5px;
    @media only screen and (min-width: 481px) { 
      font-size: 40px;
      margin-bottom: 15px;
    }
  }
  p {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Good Headline Pro';
    font-style: normal;
    text-align: center;
    @media only screen and (min-width: 481px) { 
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
    }
  }
  a {
    font-family: 'Good Headline Pro';
    text-decoration: underline;
  }
}

.weltmeister-faqs.tickets-faq.trikot-faq {
  background-color: #7D69AC;
}
#buzzer-faqs.trikot-faq .content-faq {
  background-color: #7D69AC;
  @media only screen and (min-width: 481px) { 
    padding-top: 85px;
  }
  .content-faq__item .item-content {
    @media only screen and (max-width: 481px) { 
      padding-top: 0;
    }
  }
}

.content-holding__heading.closing.weltmeister-end.trikot-end{
  background-color: #7D69AC;
  .content-end__text {
    padding-bottom: 0;
    @media only screen and (max-width: 481px) { 
      padding-top: 50px;
    }
    h1 {
      text-transform: uppercase;
      @media only screen and (min-width: 481px) { 
        font-size: 60px;
        line-height: 90px;
      }
    }
    p {
      font-family: $good-head-pro;
      @media only screen and (min-width: 481px) { 
        max-width: 450px;
      }
    }
    @media only screen and (min-width: 481px) { 
      margin-bottom: 0;
    }
  }
/*   .s-content-end .end-element__item {
    @media only screen and (min-width: 481px) { 
      height: 476px;
    }
  } */

  .s-content-end .end-element__item {
    height: 470px;
    .end-element__copy{
      h3 {
        font-family: $gobold-bold;
        @media only screen and (min-width: 481px) { 
          font-size: 24px;
          line-height: 36px;
        }
      }
      p {
        font-family: $good-head-pro;
        font-family: $good-head-pro;
      }
    }
  }
  .prizes-question-faq {
    margin-top: 0;
  }
}

.content-holding__heading {
  .s-content-end {
    .end-element__item {
      height: 470px;
      @media only screen and (min-width: 481px) { 
        height: 476px;
      }
    }
  }
}

.content-holding__heading.trikot-promo {
  .content-home.endpage-rewe {
    padding: 0;
    .remindMe-container .form__container p {
      @media only screen and (max-width: 481px) { 
        text-align: center; 
      }
    }
  }
}

.milka-variety-wrapper {
  border-width: 7.2px 0px;
  border-style: solid;
  border-color: #DFB660;
  background: linear-gradient(180deg, #7D69AC 0%, #EEE4FC 50%, #D8CCEC 59.9%, #7D69AC 100%);
  .variety-container {
    padding: 80px 0 70px 0;
    max-width: 1228px;
    margin: 0 auto;
    @media only screen and (max-width: 481px) { 
      padding: 0px 40px 30px 40px;
    }
/*     @media only screen and (max-width: 1230px) { 
      padding: 80px 40px 70px 40px;
    } */
    @media only screen and (min-width: 481px) { 
      padding: 160px 40px 160px 40px;
    }
    h2 {
      font-family: 'Gobold Bold';
      font-style: normal;
      font-weight: 400;
      font-size: 57.6px;
      line-height: 86.4px;
      text-align: center;
      text-transform: uppercase;
      color: #F9C46E;
      padding-bottom: 30px;
      display: none;
      @media only screen and (max-width: 481px) { 
        display: block;
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 10px;
        padding-top: 20px;
      }
    }
  }
}

#main-participation.weltmeister-participation.tickets-participation.trikot-part {
  .content-participation__login .buzzer-form #frm_login .form-container {
    h2 {
      font-family: $good-head-pro-black;
      font-weight: 900;
    }
    h3 {
      font-family: $good-head-pro;
    }
  }
  #participant-info.buzzer-form {
    h3 {
      font-family: $good-head-pro;
    }
    #dob-container .form-container .input-container .custom-inputs {
      @media only screen and (min-width: 481px) { 
        padding-left: 30px;
      }
    }
    input {
      &::-webkit-input-placeholder { /* Edge */
        font-family: $good-head-pro;
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: $good-head-pro;
      }
      
      &::placeholder {
        font-family: $good-head-pro;
      }
    }
  }
  .content-swiper-container h3{
    span {
      font-family: $good-head-pro-bold;
      text-transform: uppercase;
    }
  }
  .chooseTeam--team-container {
    .team{
      padding: 25px 0 25px 0;
      &:hover {
        background-color: #DDDAED;
      }
      &.active {
        background-color: #DDDAED;
        border: 4px solid #3B2772;
        border-radius: 9.69307px;
      }
      p {
        font-family: $good-head-pro-medium ;
        text-transform: uppercase;
      }
    }
  }
  .campaign-consent {
    @media only screen and (min-width: 481px) { 
      width: 100%;
      max-width: 980px;
    }
    .text-content {
      line-height: 24px;
    }
  }
  .content-participation__form {
    p.special {
      font-family: $good-head-pro;
      @media only screen and (max-width: 481px) { 
        line-height: 24px;
      }
    }
    .campaign-consent #name-terms {
      @media only screen and (max-width: 481px) { 
        margin-top: 50px;
      }
    }
    .campaign-consent #name-member {
      @media only screen and (max-width: 481px) { 
        margin: 40px 0px 40px 0px;
      }
    }
  }
  .btn__container {
    @media only screen and (min-width: 481px) { 
      width: 432px;
    }
  }
}

.variety__wrapper {
  width: 100%;
  margin: 100% auto;
  position: relative;
  @media only screen and (min-width: 481px) { 
    margin: 70px 0;
  }
  .card__container_normal, .card__container {
    border-radius: 20px;
    font-size: 40px;
    @media only screen and (max-width:481px) { 
      margin: 0 auto;
      /* margin-left: 20px;
      margin-right: 20px; */
    }
    @media only screen and (min-width:481px) { 
      margin-bottom: 20px;
    }
    .card_title {
      margin: 30px 0;
    }
    h1.article-headline {
      font-family: $gobold-bold;
      font-size: 33px;
      color: #fff;
      @media only screen and (min-width:768px) { 
        font-size: 40px;
      }
    }
    .card__image {
      img {
        max-width: 372px;
        @media only screen and (max-width:481px) { 
          max-width: 300px;
        }
      }
    }
  }
  .card__container_normal {
    width: 456px;
    height: 385px;
    @media only screen and (max-width:481px) { 
      margin: 0 20px;
    }
  }
  .card__container {
    width: 456px;
    height: 385px;
    margin: 0 20px;
    @media only screen and (max-width:481px) { 
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @media only screen and (min-width:481px) { 
      margin-bottom: 20px;
    }
  }
  
  button.btn__container {
    background-color: var(--alpine-blue, #8BD1F0);
    font-family: $good-head-pro;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: var(--deep-purple, #3B2774);
    &::before {
      background-color: var(--alpine-blue, #8BD1F0);
    }
    &:hover {
      background-color: var(--alpine-blue, #8BD1F0);
      background-image: unset;
    }
    a {
      color: var(--deep-purple, #3B2774);
    }
  }
}

.variety__wrapper {
  position: relative;
  height: 100%;
  margin: 50px 0 0 0;
    
    .owl-stage{
      .owl-item{
        padding-top: 55px;

        @media only screen and (max-width: 480px){
          padding-top: 45px;
        }
      }
      .active{
        padding-top: 0;
      }
      .center{
        .background{
          display: inline-block;
          position: absolute;
          right: 0;
          width: 17%;
          height: 17%;
          background-image: url(/resources/imagesBundesliga/trikot-promo/slider/center-item.png);
          background-size: cover;
          background-attachment: fixed;
          background-repeat: no-repeat;
          background-position: right top;
          z-index: 100;

          @media only screen and (min-width: 1024px){
            width: 15%;
            height: 15%;
          }
        }
      }
      .active:hover{
        .front{
          display: none;
        }
        .back{
          display: block;
        }
      }
    }
    
    .sorte{
      right: 0;
      top: -204px;

      .back{
        display: none;
      }
    }

    .owl-nav{
      position: relative;
      bottom: 180px;
      width: 100px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;

      span{
        font-size: 40px;
      }
    }
}

.under_content{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 38%;
  z-index: 1;
  background-color: transparent;
  background-position: center 20px;
  background-repeat: no-repeat;
  background-image: url(/resources/imagesBundesliga/trikot-promo/slider/under.png);
  background-size: cover;

  @media only screen and (max-width: 374px){
    height: 45%;
  }
}

.nav_under{
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 14%;

  @media only screen and (max-width: 768px){
    bottom: 12%;
  }

  @media only screen and (max-width: 480px){
    bottom: 10%;
  }

  @media only screen and (max-width: 375px){
    bottom: 6%;
  }

  z-index: 2;

  div{
    width: 50px;
    height: 50px;
    margin: auto 15px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px){
      margin: auto 10px;
      width: 40px;
      height: 40px;
    }

    @media only screen and (max-width: 480px){
      width: 30px;
      height: 30px;
    }

    span{
      margin-top: 2px;
      font-size: 50px;

      @media only screen and (max-width: 768px){
        margin-top: 1px;
        font-size: 40px;
      }

      @media only screen and (max-width: 480px){
        margin-top: 1px;
        font-size: 30px;
      }
    }

    .left__button{
      margin-right: 5px;

      @media only screen and (max-width: 768px){
        margin-right: 4px;
      }

      @media only screen and (max-width: 480px){
        margin-right: 3px;
      }
    }

    .right__button{
      margin-left: 5px;

      @media only screen and (max-width: 768px){
        margin-right: 4px;
      }

      @media only screen and (max-width: 480px){
        margin-right: 3px;
      }
    }
  }
}