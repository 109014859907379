#home-page-23{
background-color: $step-color;

.fancenter-home-page-23{
    width: 100%;
    margin: 30px auto -10px auto;
    padding-bottom: 34px;
    .s-content-title{
        .paragraph__v1 {
            @media screen and (min-width: 375px) {
                max-width: 560px;
            }

        }

        .paragraph-two__v1 {
            margin-top: -10px;
        }
    }
    .btn__container{
        background-color: #3B2774;
        color:$white;
        padding: 10px 60px 8px 60px;
        font-size: 20px;
        font-family: $good-head-pro;
        font-weight: bold;
    }
               
        p{
            color: $white;
        }
}
}


