.shop-card {
    &__content {
        &--top-left {
            justify-content: flex-start;

            .shop-card {
                &__footer {
                    width: 50%;
                }
            }
        }
    }
    &__content.centered {
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }
}