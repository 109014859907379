.banner {
    &__video {
        width: 100%;
        max-height: 100vh;
        overflow-y: hidden;

        img {
            width: 100%;
        }
    }
}

.video-container {
    width: 100%;
    display: flex;
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;

    @media only screen and (min-width: 731px) and (max-width: 991px) {
        max-height: 375px;
    }

    @media only screen and (max-width: 730px) {
        flex-direction: column;
        max-height: 100%;
    }

    &__play{
        width: 80%;
        &__image-left{
            width: 90%;

            @media only screen and (max-width: 730px) {
                width: auto;
                min-height: 223vw;
                position: relative;
            }

            .bannerleft{
                width: 100%;
                height: auto;
                min-height: 100%;
    
                .image-banner{
                    width: 100%;
                    height: auto;
                }
            }
        }

        @media only screen and (max-width: 730px) {
            width: auto;
            min-height: 202vw;
        }
  
        .video-content{
            width: auto;
            height: 100%;
            min-height: 100%;

            .video-react{
                .video-react-control-bar{
                    width: fit-content !important;

                    @media only screen and (max-width: 730px) {
                        margin-bottom: 10vw;
                    }
                    .video-react-play-control.video-react-control.video-react-button.video-react-playing{
                        display: none;
                    }
                    .video-react-progress-control.video-react-control{
                        display: none;
                    }
                    .video-react-current-time.video-react-time-control.video-react-control{
                        display: none;
                    }
                    .video-react-duration.video-react-time-control.video-react-control{
                        display: none;
                    }
                    .video-react-time-control.video-react-time-divider{
                        display: none;
                    }
                    .video-react-icon-fullscreen.video-react-fullscreen-control.video-react-control.video-react-button.video-react-icon{
                        display: none;
                    }
                }
            }
            .player-banner{
                width: auto;
                max-width: 100%;
                height: 100%;

                @media only screen and (max-width: 730px) {
                    width: 100%;
                    height: 78vw;
                    position: relative;
                    
                    .video-react-video{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: scale(1.8);
                        transform-origin: center center;
                    }
                }
            }
        }

        .bannerleft{
            width: auto;
            height: 100%;
            min-height: 100%;

            .image-banner{
                width: auto;
                height: 100%;
            }
        }
    }

    &__image.image-left{
        @media only screen and (min-width: 730px) {
            width: 23%;
        }

        @media only screen and (max-width: 730px) {
            top: 40%;
        }

        @media only screen and (min-width: 1620px) {
            width: 40% !important;
        }

        .image-overlay{    
            @media only screen and (min-width: 1620px) {
                left: 50% !important;
            }
        }

        .text-overlay{      
            @media only screen and (min-width: 731px) {    
                margin-right: 20vw;
                width: 30vw;
                padding-top: 2%;
            }
            
            @media only screen and (min-width: 1620px) {
                margin-top: 25px;
                width: 50%;
                margin-right: 10vw;
                padding-top: 1%;
            }
        }
    }

    &__image{
        width: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (max-width: 730px) {
            position: absolute;
            width: 100%;
            height: auto;
            top: 34%;
        }
  
        .image-overlay {
          position: absolute;
          width: auto;
          height: 100%;
          right: 0;
          margin: 0;
          box-sizing: border-box;
          line-height: 1.5;
          text-align: center;

          @media only screen and (min-width: 1620px) {
              left: 63%;
          }

          @media only screen and (min-width: 731px) and (max-width: 991px) {
              height: 100%;
              width: auto;
          }

          @media only screen and (max-width: 730px) {
            width: 100%;
            height: auto;
            object-fit: contain;
          }

          &:last-child{
            margin-bottom: 0;
          }

          h1{
            font-size: 32pt;
            font-weight: bold;
            text-shadow: 0 0 0.3em #000;
            margin: 0 0 1em;
          }
        }

        .text-overlay{
            position: absolute;
            max-height: 100%;

            .title{
                text-align: center;
                font-family: $gobold-bold;
                font-weight: 400;
                font-size: 60px;
                line-height: 90px;
                color: #E8CF73;
            }

            .paragraph{
                font-family: $good-head-pro;
                font-weight: 400;
                font-size: 24px;
                line-height: 33.6px;
                color: white;
                text-align: center;
            }
            @media only screen and (min-width: 1620px) {
             max-width: 523px;
             margin-left: 17px;
                .title{
                    font-size: 60px;
                }
                .paragraph{
                    font-size: 22px;
                }
            }
            @media only screen and (min-width: 1024px) and (max-width: 1619px) {
                padding-right: 15px;
                min-width: 419px;
                .title{
 
                    line-height: 107%;
                    font-size: calc(32px + (54 - 44) * ((100vw - 1024px) / (1619 - 1024)));
                    // padding-right: 71px;
                    margin-top: 26px;
                    margin-bottom: 10px;
                }
                .paragraph{
                    font-size: 19px;
                    line-height: 132%;
                    min-width: 312px;
                    font-size: calc(16px + (22 - 18) * ((100vw - 1024px) / (1619 - 1024)));
                    // padding: 0 50px 0 0px;
                    margin: 0 35px;
                }
            }
            @media only screen and (min-width: 914px) and (max-width: 1023px) {
                min-width: 419px;
                .title{
  
                    line-height: 107%;
                    font-size: calc(24px + (32 - 24) * ((100vw - 731px) / (1023 - 731)));
                    padding: 10px 60px 0 50px;    
                }
                .paragraph{

                    line-height: 121%;
                    min-width: 316px;
                    font-size: calc(12px + (17 - 12) * ((100vw - 730px) / (1023 - 730)));
                     padding: 0 65px 0 45px; 
            //         margin: 0 auto;
                }
            }
            @media only screen and (min-width: 815px) and (max-width: 913px) {
                min-width: 419px;
                .title{
  
                    line-height: 107%;
                    font-size: calc(24px + (32 - 24) * ((100vw - 731px) / (1023 - 731)));
                    padding: 10px 60px 0 50px;    
                }
                .paragraph{

                    line-height: 121%;
                    min-width: 316px;
                    font-size: calc(12px + (17 - 12) * ((100vw - 730px) / (1023 - 730)));
                     padding: 0 83px 0 65px; 
            //         margin: 0 auto;
                }
            }
            @media only screen and (min-width: 731px) and (max-width: 814px) {
                min-width: 419px;
                .title{
  
                    line-height: 107%;
                    font-size: calc(24px + (32 - 24) * ((100vw - 731px) / (1023 - 731)));
                    padding: 10px 60px 0 50px;    
                }
                .paragraph{

                    line-height: 121%;
                    min-width: 316px;
                    font-size: calc(12px + (17 - 12) * ((100vw - 730px) / (1023 - 730)));
                     padding: 0 98px 0 80px; 
            //         margin: 0 auto;
                }
            }
                @media only screen and (min-width: 481px) and (max-width: 730px) {
                bottom: -110px;
                .title {
                        font-size: calc(24px + (30 - 24) * ((100vw - 481px) / (731 - 481)));
                        line-height: 37px;
                    }
                                .paragraph {
                                    padding: 0 2%;
                    line-height: 31.6px;
                    text-align: center;
                    font-size: calc(18px + (22 - 18) * ((100vw - 481px) / (731 - 481)));
                    }
                    }
                    
                    @media only screen and (min-width: 351px) and (max-width: 480px) {
                        bottom: -65px;
                    
                        .title {
                            font-size: calc(24px + (30 - 24) * ((100vw - 481px) / (480 - 351)));
                            line-height: 37px;
                        }
                    
                        .paragraph {
                            padding: 0 2%;
                            line-height: 28.6px;
                            text-align: center;
                            font-size: calc(18px + (22 - 18) * ((100vw - 351px) / (480 - 351)));
                        }
                    }
                    
                    @media only screen and (min-width: 280px) and (max-width: 350px) {
                        bottom: -50px;
                    
                        .title {
                            font-size: calc(18px + (20 - 18) * ((100vw - 280px) / (350 - 280)));
                            line-height: 37px;
                        }
                    
                        .paragraph {
                            padding: 0 2%;
                            line-height: 23.6px;
                            text-align: center;
                            font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (731 - 280)));
                }
            }
            
        }

    }

    &__under{
        display: flex;
        width: 100%;

        img{
            @media only screen and (max-width: 730px) {
                display: none;
            }
        }
    }
}