$purple: rgb(121, 21, 121);
$light-purple: #afa6d2;
$x-light-purple: #d0cae7;
$extra-light-purple: #dddaee;

$grey: rgb(160, 160, 160);
$light-grey: rgb(230, 230, 230);
$light-purple: #9585B7;

$white: #fff;
$redish-white: #f7f4f4;

$light-blue: #5c8bcb;
$headline-shadow : #044B9A;

$hr-grey: rgba(126, 105, 172, 0.2);
$black-grey: #636363;
$dark-grey: #545454;
$light-grey: #EEEEEE;

$amazon-yellow: #F4D17C;


//! BRAND COLORS
$milka-lilac: #684FA3; 
$mid-lilac: #AFA6D2;
$mid-dark-lilac: #3B2772;
$mid-dark-lil: #7D6BAA;
$dark-lilac: #3b2774; 
$dark-lil: #3C2772;
$dark-lila: #513C79;
$dark-milka: #3c2c72;
$dark-milka-header: #271c43;
$light-lilac: #DDDAEE;
$alpine-sky-light: #C9E8FB;
$alpine-sky: #93D1F4;
$alpine-sky-active: #50a5d5;
$alpine-sky-dark: rgb(27, 168, 250);
$alpine-meadow: #AABE24;
$alpine-meadow-light: #DBF493;
$natural: #F7F4F4;
$dark-natural: #efe9e9;
$alpine-yellow: #D6C531;
$alpine-red: #D43939;
$alpine-blue: #3977D4;
$btn-red: #CF1316;
$btn-tuc: #FFD300;
$btn-orange: #F39101;
$btn-milka-puruple:#8D7CB6;
//Error color
$error-red: #D93333;
$succes-green: #AABD23;

//Biscuits color
$pink: #e50071;
$input-background: rgba(76, 53, 131, 0.1);;
$article-head: #ACA1CE;
$article-footer: #564389;
$step-color:#7D69AC;
//heading colors
$heading_dark-lilac:#3B2772;
$wheat: #DDC6A2;
$dark-lilac-annoucement-background:#3B2774;

//card-validation color
$card-validation-purple:#E7E4F2;