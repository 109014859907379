.campaign-mid {
    padding: 50px 0 ;
    @include breakpoint(medium) {
        padding: 100px 0 50px 100px;
    }

    h2 {
        font-family: $recoleta-medium;
        font-size: 30px;
        line-height: 30px;
        padding: 0 20px;
        margin-bottom: 20px;
        
        @include breakpoint(medium) {
            text-align: left;
            margin-bottom: 30px;
            font-size: 40px;
            line-height: 42px;
            padding: 0;
        }
    }

    ul {
        list-style: disc;
        list-style-position: outside;
        padding: 30px;

        @include breakpoint(medium) {
            padding: 0;
        }
        
        li {
            margin: 10px 0;
            @include breakpoint(medium) {
                margin: 20px 0;
            }
        }
    }

    p, li {
        font-size: 20px;
        line-height: 22px;
        
        @include breakpoint(medium) {
            font-size: 25px;
            line-height: 28px;
        }
    }

    p{  
        @include breakpoint(medium) {
            font-size: 20px;
            margin-bottom: 30px;
            text-align: left;
        }
    }

    button {
        width: 80%;
        margin: 0 auto;
        margin-top: 10px;
        
        @include breakpoint(medium) {
            margin: 0;
            margin-top: 20px;
        }
    }

    &.no-padding-left {
        padding: 10px 0 ;

        @include breakpoint(medium) {
            padding: 100px 50px;
        }

        @include breakpoint(small-only) {
            img {
                padding-top: 30px;
            }
        }
    }

    &.padding-top-70 {
        @include breakpoint(small-only) {
            padding-top: 70px;
        }
    }

    .padding-top-100 {
        @include breakpoint(small-only) {
            padding-top: 100px;
        }
    }
}

#main {
    #intro-page {
        button {
            font-weight: bold;
            margin: 20px 0;
            width: 100%;
        }
    }
}