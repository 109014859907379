
$img-scale: $grid-transition;

.article-card {
    &__container {
        position: relative;
        width: auto;
        height: 100%;
        min-height: 150px;
        
        @include hover {
            .article-card__image img {
                transform: scale($img-scale);
            }
        }
    }

    &__image {
        height: 100%;
        overflow: hidden;
        picture {
            width: 100%;
        }

        img {
            transition: $grid-transition-speed;
            position: relative;
            width: 100%;
            height: 100%;
            @include object-fit('cover');
        }
    }

    &__content {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }

    &__content.white {
        color: $white;

        .article-card {
            &__tag {
                color: $white;
            }
            &__title {
                color: $white;
            }
            &__link {
                color: $white;

                &:before {
                    background-color: $white;
                }
            }
        }
    }

    &__content.top {
        color: $white;
        padding-bottom: 70px;

        @include breakpoint(medium){
            padding-bottom: 50%;
        }
        @include breakpoint(large){
            padding-bottom: 40%;
        }

        .article-card {
            &__tag {
                color: $white;
            }
            &__title {
                color: $white;
            }
            &__link {
                color: $white;

                &:before {
                    background-color: $white;
                }
            }
        }
    }

    &__content.bottom {
        color: $white;
        padding-top: 70px;

        @include breakpoint(medium){
            padding-top: 40%;
        }
        @include breakpoint(large){
            padding-top: 30%;
        }

        .article-card {
            &__tag {
                color: $white;
            }
            &__title {
                color: $white;
            }
            &__link {
                color: $white;

                &:before {
                    background-color: $white;
                }
            }
        }
    }

    &__tag {
        color: $dark-lilac;
        font-family: $soft-alt-medium;
        font-size: 14px;

        @include breakpoint(x-large) {
            font-size: 16px;
        }
    }

    &__title {
        color: $dark-lilac;
        font-family: $recoleta-medium;
        width: 80%;
        text-align: center;
        font-size: 24px;
        line-height: 1.25;
        margin-top: 10px;
        
        @include breakpoint(large) {
            font-size: 30px;
        }

        @include breakpoint(x-large) {
            font-size: 35px;
        }
    }

    &__link {
        position: relative;
        color: $dark-lilac;
        font-family: $soft-alt-medium;
        font-size: 16px;
        margin-top: 5px;
        letter-spacing: 4.5px;
        padding-bottom: 10px;

        @include breakpoint(large) {
            margin-top: 10px;
            font-size: 18px;
        }

        &:before {
            content: "";
            transition: .5s;
            position: absolute;
            bottom: 0;
            left: 10%;
            height: 1px;
            width: 80%;
            background-color: $dark-lilac;
        }

        @include hover {
            &:before {
                left: 0;
                width: 100%;
            }
        }
    }
}

@import "../../05_STATE/grid-components/article-card";