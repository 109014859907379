#hamburger-m{
    position: absolute;
    right: 97px;
}

@-webkit-keyframes navigationFadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-moz-keyframes navigationFadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-o-keyframes navigationFadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes navigationFadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

.header-md{
    &__container{
        .partner-logo, picture{
            display: block;
            margin: 0 auto;
        }
        .header__menu{
            width: 33px;
            height: 33px;
        }
        .menu-line {
            height: 3px;
            border-radius: 3px;
        }

        @media only screen and (max-width: 768px) {
            height: 54px;
            .header-md__logo{
                left: 14px;
                span{
                    font-size: 30px !important;
                }
            }

            .header__menu {
                height: 15px;
                width: 28px;
            }
            .menu-line {
                height: 2px;
                border-radius: 2px;
            }

            .menu-line:nth-child(2){
                top: 7px;
            }

            .menu-line:nth-child(3) {
                top: 14px;
            }

            #hamburger-m{
                right: 16px;
            }
            
        }
    }
    &__logo{
        position: absolute;
        left: 39px;
    }   
}

.header-menu{
    top: 0;
    left: 0;
    height: 100em;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 99999;
    
    &--open{
        display: block;
        

        .header-menu-navigations{
            opacity: 1;
            animation: navigationFadeIn 0.5s 0s;
        }
    }
    &-navigations {
        opacity: 0;
        position: absolute;
        width: 280px;
        min-height: min-content;
        right: 0;
        top: 0;
        background-color: #3B2772;
        padding: 25px;
        padding-top: 102px;

        &__close{
            position: absolute;
            right: 32px;
            top: 32px;
            cursor: pointer;
        }
        li{
            font-family: $soft-alt;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: 2.61538px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-bottom: 20px;
            a.active{
                font-family: $soft-alt-medium;
                font-weight: bold;
            }
        }
    }
}
@media only screen and (min-width: 1025px) {
    .image__container .form-button {
        justify-content: flex-end;
        bottom: 5%;
        right: 11%;
    }
   
}
@media screen and (max-width: 1024px) {
    .image__container .form-button.form-button-main.weltmeister.buzzer-game {
            bottom: 2%;
        }
}


.profile-logo__container{
    position: absolute;
    right: 63px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    @media only screen and (min-width: 769px) {
        right: 142px;
        width: 32px;
        height: 32px;
        top: 31px;
    }
    @media only screen and (min-width: 993px) {
        right: 72px;
    }
    @media only screen and (min-width: 1241px) {
        right: 147px;
    }
    img {
        width: 100%;
    }
}