.heimtrikot{
    .container_main, .container_confirmation {
        .container_title{
            .title{
                font-size: 40px;
            }
            .description{
                font-size: 20px;
                margin-bottom: 80px;
                @media (max-width: 425px){
                    font-size: 16px;
                }
            }
    
        @media (max-width: 425px) {
            .title {
                font-size:24px;
                margin-bottom: 25px;
                max-width: 360px;
            }
            .description { 
                margin-bottom: 51px;
            }
        }
        }
    
        .bannerBtn {
            position: absolute;
            z-index: 2;
            margin-top: -125px;
            width: 100%;
            height: 50px;
            text-align: center;
            cursor: pointer;
    
            @media (max-width: 767px) {
                margin-top: -98px;
                height: 50px;
            }

            @media (min-width:768px) and (max-width:1023px) {
                margin-top: -180px;
                height: 80px;                
            }

            @media (min-width:1024px) and (max-width:1299px) {
                margin-top: -57px;
                height: 30px;                
            }

            @media (min-width:1300px) and (max-width:1699px) {
                margin-top: -85px;                
            }
            
            @media (min-width: 1700px) {
                margin-top: -117px;  
                height: 65px;              
            }

        }
    }
}
