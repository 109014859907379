.campaign-prize {

    @include breakpoint(medium) {
        margin-bottom: 100px;
    }

    img {
        width: auto;
        @include breakpoint(medium) {
            max-height: 346px;
            max-width: 460px;
        }
    }

    .grid-6-m, .grid-5-m {
        display: flex;
        justify-content: center;
    }

    .grid-6-m {
        align-items: center;
    }

    .grid-5-m {
        flex-direction: column;

        h6 {
            @include breakpoint(medium) {
                font-weight: bold;
                font-size: 28px;
                line-height: 31px;
                margin-bottom: 15px;
            }
        }
    }

    &-footer {

        p {
            margin-top: 15px;
        }

        button {
            margin: 35px auto;
        }
    }
}