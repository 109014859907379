.order {
    &-0 {
        @include breakpoint(small-only) {
            order: -1;
        }
    }
    &-s-l {
        @include breakpoint(small-only) {
            order: 9;
        }
    }
}