.fancenter__steps {
    counter-reset: number;
    width: 100%;
    background: rgba(175, 166, 210, 0.8);
    padding: 9px 16px;
    //margin: 15px 0;
    display: flex;
    overflow: scroll;
    justify-content: center;
    
    @include breakpoint(medium) {
        padding: 15px 0;
        justify-content: center;
        overflow: hidden;
    }

    div {
        counter-increment: number;
        min-width: 120px;
        margin: 0 15px;
        text-align: center;
        position: relative;
        padding-top: 30px;
        
        @include breakpoint(large) {
            text-align: left;
            padding-top: 0;
            min-width: unset;
            margin: 0 25px;
        }
        @include breakpoint(small-only){
            min-width: 80px;
        }

        span {
            color: $white;
            font-size: 16px;
            line-height: 26px;

            @include breakpoint(medium) {
                font-size: 24px;
                line-height: 26px;
                font-family: $gobold-bold;
                display: flex;
            align-items: center;
            }
            @include breakpoint(small-only){
                font-size: 15px;
            }

            &::before {
                content: counter(number);
                position: absolute;
                top: 0;
                left: calc(50% - 13px);

                display: block;
                width: 26px;
                height: 26px;
                
                font-size: 19.5px;
                line-height: 26px;
                text-align: center;
                
                border: 2px solid $white;
                border-radius: 50%;
                font-family: $gobold-bold;

                @include breakpoint(large) {
                    display: inline-block;

                    position: relative;
                    left: 0;
                    margin-right: 5px;
                }
                @include breakpoint(small-only){
                    font-size: 16px;
                }
            }
        }

        &:not(:last-child) {
            span {
                @include breakpoint(large) {
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: -40px;

                        display: inline-block;
                        width: 30px;
                        height: 2.5px;
                        margin-left: 10px;
                        background-color: $white;
                    }
                }
            }
        }

        &.in-progress {
            span {
                color: $dark-lilac;
                &::before {
                    color: $white;
                    background-color: $dark-lilac;
                    border-color: $dark-lilac;
                }
            }
        }

        &.complete {
            span {
                color: $white !important;
                &::before {
                    content: '\e019';
                    font-size: 15px;
                    font-family: 'icomoon' !important; 
                    background-color: $step-color;
                    border:1px solid $step-color;
                }
            }
        }
    }
}