.nutrition-facts {
    &--emphasized {
        font-size: 18px !important;

        @include breakpoint(medium) {
            font-size: 20px !important;
        }

        strong {
            font-weight: bold;
        }
    }
    &__td {
        &--emphasized {
            font-family: $soft-alt-medium !important;
        }
    }
}