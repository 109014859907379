.selection-jersey {
    label{
        font-family: "Intervogue Soft Alt Regular";
        display: block;
        color:#3B2774;
        font-size: 20px;
        margin-bottom: 20px;
        text-align: center;
        @media only screen and (min-width:768px){
            margin-bottom: 40px;
            font-size: 27px;
        }
    }

    &__size-container {
        display: block;
        width: 315px;
        height: 275px;
        margin: auto;

        @include breakpoint(medium) {
            width: 390px;
        }

        p {
            text-align: center;
        }

        & > div {
            padding: 20px 0;
            margin-top: 50px;
            border-radius: 14px;
            background-color: RGBA(125,107,170,0.2);

            @include breakpoint(medium) {
                padding: 30px 0;
            }
        }

        .size-boxes{
            width: 90%;
            margin: 0 auto;

            button {
                border: none;
                background-color: $white;
                color: $dark-lilac;
                font-size: 27px;
                width: calc(100%/3);
                height: 75px;
                cursor: pointer;

                display: inline-block;
                margin-left: 0;
                margin-right: 0;

                &:first-child {
                    border-radius: 13px 0 0 13px;
                    border-right: 1px solid $dark-lilac;
                }
                &:last-child {
                    border-left: 1px solid $dark-lilac;
                    border-radius: 0 13px 13px 0;
                }

                &.out-stock {
                    background-color: rgba(216, 216, 216, 0.2);
                    color: rgba(59, 39, 116, 0.2);
                    cursor: default;
                }
                &.selected {
                    background-color: rgba(216, 216, 216, 0.8);
                }
            }
        }
    }
}

.jersey-size-selection{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    margin-bottom: 50px;
    max-width: 1175px;

    .jersey-image, .jersey-sizes, .jersey-sizes--link, .jersey-sizes--submit{
        width: 50%;
        @media only screen and(max-width:849px) {
            width: 100%;
        }
    }
    .jersey-image{
        .jersey-season{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }
    
    .jersey-sizes--link{
        text-align: center;
        a{
            text-decoration: underline;
        }
    }

    .selection-jersey__size-container{
        margin-bottom: 25px;
        height: auto;
        label{
            margin-bottom: 40px;
            font-size: 27px;
            display: block;
            text-align: center;
        }
    }

    .jersey-sizes--submit{
        p{
            text-align: center;
            max-width: 350px;
            margin: 0 auto;
            @media only screen and(max-width:849px) {
                width: 90%;
            }
        }

        .btn--red{
            font-family: $soft-alt-regular;
            width: 243px !important;
            font-size: 20px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 5px;
            text-transform: uppercase;
            font-weight: normal;
        }
        .btn--secondary-lilac{
            width: 300px;
        }
    }

    .size-not-available{
        margin-bottom: 25px !important;
    }
}

.jersey-size-selection{
    &__container {
        max-width: 1175px;
        margin: auto;
        padding:0 10px 50px;

        @include breakpoint(large) {
            padding: 0 50px 120px;
        }
        
        &__top {
            margin: auto;
            margin-bottom: 10px;
            max-width: 840px;
            text-align: center;
            padding: 0 10px 10px;
            
            @include breakpoint(large) {
                margin-bottom: 40px;
                padding: 0 30px 30px;
            }
    
            &__title {
                font-family: $gobold-bold;
                font-size: 40px;
                font-weight: 400;
                line-height: 56px;
                @media only screen and(max-width:481px) {
                    font-size: 24px;
                }
            }
    
            &__text {
                margin-top: 30px;
                font-family: $good-head-pro;
                font-size: 24px;
                font-weight: 400;
                line-height: 33.6px;
                @media only screen and(max-width:481px) {
                    font-size: 20px;
                }
            }
            .contenu {
                display: flex;
                flex-direction: column;
                @media only screen and(min-width:720px) {
                    flex-direction: row;
                    justify-content: center;    
                    position: relative;
                }
                .zuruck-button {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    @media only screen and(min-width:720px) {
                        margin-bottom: 0;
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }
}

.size-selection {
    &__container{
        background-color: $white !important;
    }
}

