.price-component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 550px;

    .title{
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Gobold Bold";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;
        margin-top: 20px !important;
    }

    .texte{
        color: #FFF !important;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Good Headline Pro";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding-inline: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .price-component{
        width: 100%;
    }
}