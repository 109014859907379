.banner {
    &__container {
        display: flex;
        justify-content: center;
        // height: 90vh;

        // @include breakpoint(medium) {
        //     height: 100vh;
        // }
    }

    &__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 100%;
        width: 100%;

        padding: 0 10%;

        text-align: center;
    }

    &__title {
        text-transform: uppercase;
    }

    &__text {
        margin-top: 15px;
        text-transform: uppercase;
        font-size: 20px;

        @include breakpoint(medium) {
            font-size: 23px;
        }

        @include breakpoint(large) {
            font-size: 25px;
        }
    }

    &__btn {
        .btn__container {
            margin-top: 15px;

            @include breakpoint(medium) {
                margin-top: 20px;
            }
        }
    }

    &-video {
        &__container {
            // background-color: #271c43;
            background-color: #2d204b;
            height: 55vh;

            @include breakpoint(large) {
                height: 100vh;
            }

            .video {
                &__player {
                    z-index: 0;

                    top: 60px;
                    @include breakpoint(medium) {
                        width: 80%;
                        height: auto;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.image-caption {
    width: 214px;
    height: 214px;
    position: absolute;
    border-radius: 50%;
    left: 40px;
    bottom: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media only screen and (max-width: 320px) { 
        &.sm {
            bottom: 70px !important;
        }

    }

    @media only screen and (max-width: 640px) { 
        transform: scale(0.4) !important;
        left: -50px !important;
        bottom: 94px !important;
    }

    /* iPad Portrait */
    @media only screen and (max-width: 768px) { 
        transform: scale(0.5);
        left: -16px;
        bottom: 46px;
    }

    /* iPad landscape */
    @media only screen and (min-width: 769px) and (max-width: 1200px) { 
        transform: scale(0.5);
        left: -16px;
        bottom: 46px;
    }

    @media only screen and (min-width: 1600px) { 
        left: 95px;
        bottom: 200px;
    }
    
    p {
        font-family: $recoleta-medium;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 34px;  
        color: #FFFFFF;

        &.date-caption {
            font-weight: bold;
        }
    }

    &.orange-bg {
        background-color: #EA6111;
    }

    &.red-bg {
        background-color: #CD1719;
    }

    .image-caption__content {
        transform: rotate(-14deg);
    }
}

.breadcrumb-banner {
    padding-left: 10%;
    
    p {
        padding: 35px 0;
        color:#3B2774;
        text-transform: uppercase;

        span {
            font-weight: bold;
        }
    }
}
.background-separator{
    position:absolute;
    z-index:0;
    bottom:273px;
    @media only screen and (min-width:768px){
        bottom:994px;
    }
    
}

.banner__image {
    z-index: 2;
}

.sommer{
    .banner__image{
        z-index:2;
        background-color: #7D69AC;
    }
}

.image__container {
    .form-button.form-button-main.weltmeister{
        @media only screen and (min-width: 1025px) {
            right: 1%;
        }
        @media only screen and (min-width: 1100px) {
            right: 2vw;
        }
        @media only screen and (min-width: 1200px) {
            right: calc(100% / 22);
        }
        @media only screen and (min-width: 1400px) {
            right: calc(100% / 14);
        }
        @media only screen and (min-width: 1600px) {
            right: calc(100% - 90vw);
        }
/*         @media only screen and (min-width: 2000px) {
            right: 10%;
        } */
        &.buzzer-game {
            @media only screen and (min-width: 1025px) {
                bottom: 8%;
            }
            @media only screen and (min-width: 1200px) {
                right: calc(100% / 15);
            }
        }
        .btn__container {
            border: 2px solid #FFFFFF;
            font-family: $good-head-pro-regular;
            font-weight: 700;
            @media only screen and (min-width: 1025px) {
                margin-right: 0;
            }
        }
    }
}

.image__container {
    .form-button.form-button-main.weltmeister.tickets{
        bottom: 13%;
        @media only screen and (min-width: 1024px) {
            bottom: 21%;
        }
        button {
            background-color: #3B2774;
            font-family: $good-head-pro;
            display: inline-block;
            @media only screen and (max-width: 481px) {
              width: 295px;
            }
            @media only screen and (min-width: 481px) {
              font-size: 22px;
              padding: 8px 40px 8px 40px;
            }
            @media only screen and (min-width: 1024px) {
              width: 378px;
              min-width: unset;
            }
            &::before {
              background-color: #3B2774;
            }
            &:hover {
              background-color: #3B2774;
            }
            span {
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-align: inherit;
                letter-spacing: inherit;
                display: inline-block;
                @media only screen and (max-width: 481px) {
                  display: none;
                }
            }
          }
    }
}