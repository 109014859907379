.filter-mb {
    &__container {
        margin: 0 auto;
        margin-top: 15px;
        width: 90%;
        color: $dark-lilac;
        // background-color: $light-grey;
        border-bottom: 3px double $dark-lilac;

        @include breakpoint(medium-l) {
            display: none;
        }

        @include breakpoint(large) {
            display: none;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        position: relative;

        width: 100%;
        padding: 15px 5%;

        border-top: 1px solid $dark-lilac;

        h2 {
            font-size: 16px;
            font-family: $soft-medium;

            @include breakpoint (medium) {
                font-size: 22px;
            }
        }

        // close Icon
        .filter-mb__icon {   
            position: absolute;
            top: calc(50% - 6px);
            right: 6%;
            box-shadow: 3px 3px $dark-lilac;
            width: 12px;
            height: 12px;

            transform: rotate(-45deg);
        }
    }

    &__icon {
        position: relative;
        height: 20px;
        width: 20px;
    }

    &__category {

        .filter-mb__title{
            flex-direction: column;
            position: relative;

            // Arrow down icon
            .filter-mb__icon {
                position: absolute;
                top: calc(50% - 12px);
                right: 6%;
                box-shadow: 3px 3px $dark-lilac;
                width: 12px;
                height: 12px;

                transform: rotate(45deg);

                &:before {
                    display: none;
                }
        
                &:after {
                    display: none;
                }
            }
        }
        
    }

    &__active {
        margin-top: 5px;
        padding-right: 20px;

        span {
            color: blue;
            font-family: $soft-medium;
            font-size: 16px;
            
            @include breakpoint(medium) {
                font-size: 19px;
            }

            &:after {
                content: ', ';
            }

            &:nth-last-child(1):after {
                content: '';
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 0 5%;

        overflow: hidden;
        max-height: 0px;

        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;
    }

    &__item {
        width: 100%;
        font-family: $soft-regular;
        position: relative;
        margin: 10px 0;
        font-size: 17px;

        @include breakpoint(medium) {
            margin: 10px 0;
            font-size: 20px;
        }

        &:after {
            content: attr(data-filter-count);
            text-align: center;

            position: absolute;
            right: 0;

            min-width: 20px;
            padding: 2px;
            border-radius: 5px;

            // background-color: $light-purple;
        }
    }
}

@import "../../05_STATE/filter/filter-mb";