.reihe{
    background-color: white;
    max-width: 100% !important;
    padding-inline: 100px !important;
    @media (max-width: 767px) {
        padding-inline: 0px !important;
    }

    h2,p,li{
        color: #3b2774;
    }
}