$container-back-color: $btn-background-color;
$container-color: $btn-color;

.btn {
    &__container {
        letter-spacing: 2px;
        position: relative;
        cursor: pointer;
        border: none;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 10px auto;
        padding: 10px 30px 8px 30px;

        background: $container-back-color;
        color: $container-color;
        font-family: $btn-font-family;

        border-radius: 22px;
        transition: .3s;
        font-size: 18px;
        line-height: 15px;

        @include breakpoint (large) {
            padding: 8px 40px 8px 40px;
        }

        span {
            font-family: $soft-medium;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            letter-spacing: 2px;
        }

        &:before {
            left: 0;
        }
    }

    &__icon {
        @include breakpoint(medium) {
            margin: 0 10px;
        }
    }

    &__text {
        text-transform: capitalize;
        letter-spacing: 5px;
        text-align: center;
        transition: 0s;

        @include breakpoint(medium) {
            letter-spacing: initial;
        }

        .icon-customize {
            display: none;
            position: absolute;

            @include breakpoint(large) {
                display: block;
                top: 20%;
                right: 75px;
                font-size: 20px;
            }
        }
    }
}


button.btn__container {
    @include breakpoint(medium) {
        max-width: 400px;
    }
}

@import "../../05_STATE/buttons/btn";