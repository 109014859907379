/* Tickets promo main page */
#main-buzzer.tickets-main {
  .campaign-heading {
    margin: 20px 0 30px;
    @media only screen and (min-width: 481px) {
      margin: 50px 0 80px;
    }
    @media only screen and (min-width: 481px) {
      margin: 62px 0 70px;
    }
    h1 {
      @media only screen and (min-width: 481px) {
        margin-bottom: 27px;
      }
    }
    > p {
      font-size: 24px;
      line-height: 33.6px;
      font-family: $good-head-pro;
      @media only screen and (min-width: 481px) {
        margin-top: 0;
        width: 787px;
      }
    }
  }
  h2 {
    font-family: $gobold-bold;
    font-size: 30px;
    line-height: 42px;
    @media only screen and (min-width: 481px) {
      display:block;
      font-size: 40px;
      line-height: 56px;
      margin-bottom: 40px;
    }
    &.sub-heading {
      display:none;
      @media only screen and (min-width: 481px) {
        display:block;
      }
    }
  }
  #edeka-terms {
    font-family: $good-head-pro;
    font-size: 16px;
    @media only screen and (min-width: 481px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 1024px) {
      margin-bottom: 34px;
      font-size: 16px;
    }
    @media only screen and (max-width: 481px) {
      padding-bottom: 21px;
      margin-top: 20px;
    }
    a {
      font-family: $good-head-pro;
    }
  }
  .s-content-steps.step-tickets-promo {
    .s-content-steps__item:nth-child(1) {
      img {
        @media only screen and (min-width: 481px) {
          min-height: 142px;
        }
      }
    }
    .s-content-steps__item:nth-child(3) {
      @media only screen and (min-width: 481px) {
        margin-top: 0;
      }
      img {
        @media only screen and (min-width: 481px) {
          min-height: 142px;
        }
      }
    }
    .text--wheat {
      color: #e8cf73 !important;
    }
  }
  .ticket-promo-container {
    background: #7d69ac;
    /* padding-top: 44px; */
/*     @media only screen and (min-width: 481px) {
      padding-top: 52px;
    } */
    h3 {
      font-family: $gobold-bold;
      font-size: 30px;
      line-height: 42px;
      color: #fff;
      text-align: center;
      margin-bottom: 32px;
      @media only screen and (min-width: 481px) {
        font-weight: 400;
        margin-top: 32px;
        margin-bottom: 36px;
      }
      @media only screen and (max-width: 481px) {
        padding-top: 41px;
      }
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media only screen and (min-width: 1024px) {
        flex-direction: row;
      }
    }
    .ticket-promo.campaign-footer {
      display: flex;
      justify-content: center;
      padding-top: 44px;
      @media only screen and (min-width: 481px) {
        padding-top: 52px;
      }
      &:nth-child(1){
        .ticket-promo-card {
          @media only screen and (min-width: 1024px) {
            margin-right: 30px;
          }
        }
      }
      &:nth-child(2){
        .ticket-promo-card {
          @media only screen and (min-width: 1024px) {
            margin-left: 30px;
          }
        }
        
      }
      .ticket-promo-card {
        border: 2.7943px solid #ffffff;
        border-radius: 4.19145px;
        width: 90%;
        max-width: 342px;
        height: max-content;
        @media only screen and (min-width: 481px) {
          width: 490px;
          height: 578px;
          border: 4px solid #d3b565;
          border-radius: 10px;
          max-width: unset;
        }
        .ticket-promo__content {
          * {
            color: #fff;
          }
          img {
            width: 100%;
          }

          p {
            font-size: 20px;
            line-height: 30px;
            font-family: $good-head-regular;
            margin: 0 16px;
            margin-top: 20px;
            padding: 0 15px;
            @media only screen and (min-width: 481px) {
              max-width: 508px;
              margin: 0 auto;
              margin-top: 25px;
            }
          }
        }
      }
    }
    .cta-container.bottom {
      margin-top: 0px;
      margin-bottom: 20px;
      @media only screen and (max-width: 481px) {
        margin-top: 25px;
      }
      .btn--secondary-lilac{
        width: 336px;
        font-family: $good-head-pro;
        background-color: #3B2774;
        padding: 8px 17px 8px 17px;
        letter-spacing: 4px;
        @media only screen and (min-width: 481px) {
          font-size: 20px;
        }
        &::before {
          background-color: #3B2774;
        }
        &:hover {
          background-color: #3B2774;
          background-image:unset;
        }
      }
    }
  }
  .step-container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px;
    max-width: 375px;
    background: #3B2774;
    @media only screen and (min-width: 1095px) {
      max-width: 1085px;
      height: 374px;
    }
    @media only screen and (max-width: 481px) {
      background: unset;
      width: 100%;
      padding: 0;
      max-width: unset;
    }
    .step-content {
      border-radius: 4px;
      height: 100%;
      width: 100%;
      background-size: cover;
      display: flex;
      flex-direction: column;
      border: 4px solid #D3B565;
      @media only screen and (min-width: 1095px) {
        flex-direction: row;
        background: url(/resources/imagesBundesliga/tickets-promo/step-bg.png) no-repeat;
      }
      @media only screen and (max-width: 481px) {
        border: none;
      }
      .step-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        padding: 20px 20px;
        height: 350px;
        @media only screen and (min-width: 1095px) {
          width: calc(100%/3);
          height:auto;
        }
        h2 {
          @media only screen and (min-width: 481px) {
            display: none;
          }
        }

        &:first-child {
          @media only screen and (max-width: 1094px) {          
            background-color: #927FBA;
            height: 290px;
          }
          @media only screen and (max-width: 481px) {          
            justify-content: space-around;
            height: 330px;
          }
        }
        &:nth-child(2) {
          @media only screen and (max-width: 1094px) {
            background: url(/resources/imagesBundesliga/tickets-promo/triangle_1.png) no-repeat;
            background-position: top center;
            background-position-y: -2px;
            background-color: #7D69AC;
            @media only screen and (max-width: 481px) {
              height: 300px;
            }
          }
        }
        &:nth-child(3) {
          @media only screen and (max-width: 1094px) {

            background: url(/resources/imagesBundesliga/tickets-promo/triangle.png) no-repeat;
            background-color: #3B2774;
            background-position: top center;
            background-position-y: -2px;
          }
          @media only screen and (max-width: 481px) {
            height: 260px;
          }
        
        
        }
        picture {
          max-width: 240px;
        }
        .step__text {
            display: flex;
            align-items: flex-start;
            /* align-content: flex-start; */
            /* flex-direction: column-reverse; */
            height: 120px;
            @media only screen and (max-width: 481px) {
              height: unset;
            }
        }
        p {
          font-family: $good-head-pro;
          color: #fff;
          font-size: 20px;
          line-height: 30px;
          margin-top: 20px;
        }
      }
    }
  }
  .middle-button-wrapper {
    @media only screen and (max-width: 481px) {
      background-color: #3B2774;
    }
    .cta-container.middle {
      @media only screen and (max-width: 481px) {
        margin-top: 0;
      }
      .btn--secondary-lilac {
        @media only screen and (max-width: 481px) {
          margin-top: 0;
        }
      }
    }
  }
  .ticket-teaser-wrapper {
    display: flex;
    margin: 0 auto;
    max-width: 1082px;
    justify-content: space-between;
    flex-wrap: wrap;
    @media only screen and (max-width: 1080px) {
      justify-content: center;
    }
    @media only screen and (max-width: 481px) {
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 90%;
    }
    
    .ticket-teaser_container {
      background: #3B2774;
      width: 521px;
      height: 619px;
      @media only screen and (max-width: 481px) {
        width: 100%;
        height: auto;
      }
      &:first-child {
        margin-bottom: 30px;
      }
      .ticket-teaser-card {
        padding: 30px 20px;
        .ticket-teaser {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            @media only screen and (max-width: 481px) {
              width: 97%;
            }
          }
          h1 {
            color: #D3B565;
            text-align: center;
            font-family: 'Gobold Bold';
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
            text-transform: uppercase;   
            margin-bottom: 16px;
            @media only screen and (min-width: 481px) {
              width: 500px;
            }
          }
          p {
            color: #FFF;
            text-align: center;
            font-family: "Good Headline Pro";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            max-width: 454px;
          }
          &.primary {
            h1 {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .cta-container.middle {
    margin-top: 40px;
    .btn--secondary-lilac{
      width: 349px;
      font-family: $good-head-pro;
      background-color: #3B2774;
      padding: 8px 17px 8px 17px;
      @media only screen and (min-width: 481px) {
        font-size: 20px;
      }
      &::before {
        background-color: #3B2774;
      }
      &:hover {
        background-color: #3B2774;
        background-image:unset;
      }
    }
  }
}

/* Participation */

#buzzer-participation.tickets-participation {
  .buzzer-form .form-container #salutation {
    align-items: flex-start;
    flex-direction: column;
    @media only screen and (min-width: 481px) {
      flex-direction: column-reverse;
    }
    p {
      font-family: $good-head-regular;
      font-size: 17px;
      line-height: 20px;
      @media only screen and (min-width: 481px) {
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 14px;
      }
      @media only screen and (min-width: 1025px) {
        text-align: left;
        padding-left: 10px;
      }
    }
  }
  .retailer-container__form
    .brand-wrapper
    .form-container
    .input-container
    .custom-inputs
    span.checkmark {
    background-color: #fff;
    &::after {
      color: #3c2774;
    }
  }
  .buzzer-form .form-container .error-email {
    font-size: 16px;
    color: red;
  }
  p.special {
    @media only screen and (max-width: 481px) {
      margin-bottom: 20px;
    }
  }
}

#main-participation.tickets-participation {
  .buzzer-form .form-container #salutation {
    align-items: flex-start;
    flex-direction: column;
    @media only screen and (min-width: 481px) {
      flex-direction: column-reverse;
    }
    p {
      font-family: $good-head-regular;
      font-size: 17px;
      line-height: 20px;
      @media only screen and (min-width: 481px) {
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 14px;
      }
      @media only screen and (min-width: 1025px) {
        text-align: left;
        padding-left: 10px;
      }
    }
  }
  .retailer-container__form
    .brand-wrapper
    .form-container
    .input-container
    .custom-inputs
    span.checkmark {
    background-color: #fff;
    &::after {
      color: #3c2774;
    }
  }
  .buzzer-form .form-container .error-email {
    font-size: 16px;
    color: red;
  }
  p.special {
    @media only screen and (max-width: 481px) {
      margin-bottom: 20px;
    }
  }
}

#buzzer-prizes.tickets-prizes {
  .campaign-prize-header {
    h1 {
      @media only screen and (min-width: 481px) {
        font-size: 60px;
        line-height: 90px;
      }
    }
    p {
      @media only screen and (min-width: 481px) {
        max-width: 566px;
      }
      &.info {
        font-family: $good-head-pro;
      }
    }
  }
  .weltmeister.campaign-footer.tickets {
    .weltmeister-card__content {
      border: 3px solid #d3b565;
    }
    .cta-container {
      .btn__container {
      width: 349px;
      height: 44px;
      background-color: #3b2774;
      font-family: $good-head-pro;
      font-weight: 700;
      font-size: 20px;
      line-height: 15px;
      letter-spacing: 4px;
      text-transform: uppercase;
      padding-top: 5px;
        &:before {
          background-color: #3b2774;
          border: 2px solid #ffffff;
        }
      }
    }

    .info {
      font-size: 20px;
      line-height: 30px;
      margin-top: 24px;
      margin: 0;
      margin-top: 24px;
      font-family: $good-head-pro;
      @media only screen and (min-width: 481px) {
        max-width: 387px;
        font-size: 20px;
        line-height: 30px;
        margin: 0 auto;
        margin-top: 22px;
      }
    }
    .legal-text {
      font-size: 16px;
      line-height: 24px;
      margin-top: 17px;
      margin: 0;
      margin-top: 24px;
      font-family: $good-head-pro;
    }
  }
  .modal-content {
    .form-input__container {
      * {
        text-align: left;
      }
    }
    .btn__container {
      border: 2px solid #fff;
      font-family: $good-head-pro;
      font-size: 20px;
      font-weight: bold;
      line-height: 15px;
      padding: 10px 17px 8px 17px;
      background-color: #7d69ac;
      margin-top: unset;
      &::before {
      background-color: #7d69ac;
      }
    }
  }
  .ticket-promo-container {
    h3 {
      font-family: $gobold-bold;
      font-size: 30px;
      line-height: 42px;
      color: #fff;
      text-align: center;
      margin-bottom: 44px;
      @media only screen and (min-width: 481px) {
        font-weight: 400;
        margin-top: 32px;
        margin-bottom: 36px;
        font-size: 40px;
      }
      @media only screen and (max-width: 481px) {
        padding-top: 41px;
      }
    }
    .ticket-teaser-wrapper {
      display: flex;
      margin: 0 auto;
      max-width: 1082px;
      justify-content: space-between;
      flex-wrap: wrap;
      @media only screen and (max-width: 1080px) {
        justify-content: center;
      }
      @media only screen and (max-width: 481px) {
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 90%;
      }
      
      .ticket-teaser_container {
        background: #3B2774;
        width: 521px;
        height: max-content;
        @media only screen and (max-width: 481px) {
          width: 100%;
          height: auto;
        }
        &:first-child {
          margin-bottom: 30px;
        }
        .ticket-teaser-card {
          padding: 30px 20px;
          .ticket-teaser {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              @media only screen and (max-width: 481px) {
                width: 97%;
              }
            }
            h1 {
              color: #D3B565;
              text-align: center;
              font-family: 'Gobold Bold';
              font-size: 30px;
              font-style: normal;
              font-weight: 400;
              line-height: 45px;
              text-transform: uppercase;   
              margin-bottom: 16px;
              @media only screen and (min-width: 481px) {
                width: 500px;
              }
            }
            p {
              color: #FFF;
              text-align: center;
              font-family: "Good Headline Pro";
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
              max-width: 454px;
            }
            &.primary {
              h1 {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.content-holding__heading.closing.product-wrapper.weltmeister-product.ticket-product {
  .content-end__text {
    margin-bottom: 20px;
    @media only screen and (min-width: 481px) {
      margin-bottom: 40px;
    }
  }
  .chocolate {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    @media only screen and (min-width: 481px) {
      margin-bottom: 70px;
    }
    img {
      @media only screen and (max-width: 481px) {
        width: 100%;
      }
    }
  }
  .s-content-end {
    .end-element__item {
      display: flex;
      width: 345px;
      @media only screen and (min-width: 1024px) {
        width: 100%;
        max-width: 981px;
      }
      @media only screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        margin-bottom: 41px;
      }
      .end-element__copy {
        max-width: 340px;
        @media only screen and (min-width: 1024px) {
          margin: 60px;
          padding: 0;
        }
        img {
          @media only screen and (min-width: 1024px) {
            border-radius: initial;
          }
        }
        h3 {
          font-family: $gobold-bold;
          text-transform: inherit;
          @media only screen and (min-width: 481px) {
            font-size: 24px;
            line-height: 34px;
          }
        }
        p {
          @media only screen and (min-width: 481px) {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 32px;
          }
        }
        .btn__container.btn--secondary-lilac {
          position: relative;
          left: 0;
          margin: 0 auto;
          font-family: $good-head-regular !important;
          font-weight: 700;
          font-size: 20px;
          line-height: 15px;
          letter-spacing: 4px;
          text-transform: uppercase;
          a {
            font-family: $good-head-regular !important;
          }
          @media only screen and (min-width: 1024px) {
            width: 340px;
          }
        }
      }
    }
  }
}

#buzzer-faqs.tickets-faq {
  ul.indented {
    margin-left: 21px;
  }
}

.content-holding__heading.q3-announment.trikot-holding {
  background-color: #7D69AC;
  .campaign-heading {
    max-width: 783px;
  }
  .campaign-heading h1 {
    text-transform: uppercase;
  }
  .campaign-heading p {
    font-family: $good-head-pro;
  }
  .remindMe-container {
    @media only screen and (max-width: 481px) {
      margin-bottom: 50px;
    }
  }
}

.tickets-promo-emd{
  background-color: #7d69ac;
  .content-end__text {
    padding-top: 70px;
    color: #FFF;
    text-align: center;
    @media only screen and (max-width: 481px) {
      padding-top: 30px;
    }
    h1 {
      font-family: $gobold-bold;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      margin-bottom: 20px;
      @media only screen and (max-width: 481px) {
        font-size: 30px;
        line-height: 45px;
      }
    }
    p {
      font-family: $good-head-pro;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 33.6px */
    }
  }
  .s-content-end {
    @media only screen and (min-width: 481px) {
      padding:0 0 30px 0;
    }
  }
  .s-content-end .end-element {
    max-width: unset;
  }
  .s-content-end .end-element__item {
    width: 380px;
    @media only screen and (min-width: 759px) {
      height: 620px;
    }
    @media only screen and (max-width: 759px) {
      height: 570px;
    }
    &:first-child {
      @media only screen and (max-width: 759px) {
        height: 620px;
      }
    }
  }
  .s-content-end .end-element__item:nth-child(2),
  .s-content-end .end-element__item:nth-child(3) {
    margin-left: 0;
    @media only screen and (min-width: 797px) {
      margin-left: 20px;
    }
  }
  .s-content-end .end-element .end-element__item .end-element__copy {
    h3 {
      font-family: $gobold-bold;
      text-transform: inherit;
      font-size: 24px;
      line-height: 34px;
    }
    p {
      font-family: $good-head-regular;
      font-size: 20px;
      line-height: 30px;
    }
  }
}