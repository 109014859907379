.section-text-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:15px;

    &__text{
        width: 100% !important;
        font-weight: 400;
        font-size: 30px !important;
        color:$white;
        text-align: center;
        text-transform: uppercase;
        font-family: $gobold-bold !important;
        line-height: 42px;

    }
    button{
        margin:30px auto 0 auto !important;
        width: 25% !important;
        font-family: $good-head-pro;
        font-size: 20px;
        min-width: 200px;
    }

    @include breakpoint(small-only){
        &__text{
            font-size: 24px !important;
        }
    }
}