.buzzer-mobile2023 {
    background-color: white;
    border-radius: 10px;
    padding: 20px 60px;
        
    @media only screen and (max-width: 480px) {
        padding: 20px 25px;
    }

    @media only screen and (min-width: 768px ) and (max-width: 991px) {
        padding: 20px 50px;
    }
    margin: 50px 5% 90px;

    span {
        font-size: 24px;
        line-height: 33.6px;
        font-weight: 400;
        font-family: $good-head-pro;
        
        @media only screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 24px;
        }

        &.separator {
            display: block;
            height: 2px;
            width: 5%;
            min-width: 20px;
            background-color: $dark-lilac;
            margin: 0 30px;

            @media only screen and (min-width: 768px ) and (max-width: 991px) {
                margin: 0 10px;
            }
        }

        &.checkmark[data-checked] {
            height: 29px;
            width: 29px;
            background: #FFFFFF;
            border: 1.5px solid $dark-lilac;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            margin-right: 25px;
            transition: 0s;
        }

        &.checkmark[data-checked="true"] {
            background: #FFFFFF;
            border: 10px solid $dark-lilac;
        }
    }

    div {
        display: flex;
        align-items: center;
        align-self: flex-start;
    }

    img {
        width: auto;
        height: 86px;
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        &.date-mobile {
            display: none;

            @media only screen and (max-width: 727px) {
                display: flex;
                width: 100%;
                justify-content: center;
                margin: 5px auto;
            }
        }
        &.date {
            width: 40%;

            @media only screen and (min-width: 768px ) and (max-width: 991px) {
                width: 30%;
            }
    
            @media only screen and (max-width: 727px) {
                width: 25%;
            }
        
            @media only screen and (max-width: 480px) {
                width: 20%;
            }

            .date-match{
                display: flex;
    
                @media only screen and (max-width: 727px) {
                    display: none;
                }
            }
        }
        &.match {
            width: 60%;
            justify-content: center;

            @media only screen and (min-width: 768px ) and (max-width: 991px) {
                width: 70%;
            }
    
            @media only screen and (max-width: 727px) {
                width: 75%;
            }

            div {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    div {
        &.date {
            width: 40%;
        }
    }

    .match-container {
        flex-wrap: wrap;
        padding: 10px 0;

        hr.separator {
            flex-basis: 100%;
            opacity: 0.5;
            border: 0.5px solid #8D7EB5;
            margin: 5px 0;
        }

        .team-container {
            justify-content: center;

            .team-container__home, .team-container__away{
                display: flex;
                flex-direction: column;
            }

            .team-container__home{
                @media only screen and (min-width: 768px) {
                    flex-direction: row-reverse;
                }

                .team-home{
                    @media only screen and (min-width: 768px) {
                        align-self: center;
                    }
                }
            }

            .team-container__away{
                @media only screen and (min-width: 768px) {
                    flex-direction: row;
                }

                .team-away{
                    @media only screen and (min-width: 768px) {
                        align-self: center;
                    }
                }
            }
        }

        .team-home, .team-away {
            justify-content: center;

            span {
                text-align: center;
            }
        }

        &.out-stock {
            .match {
                pointer-events: none;
                opacity: 0.5;
            }

            div.date {
                p {
                    background-color: white;
                    padding: 20px 10px;
                    border-radius: 13px;
                    width: 80%;
                    font-weight: bold;
                    color: #3B2772;
                    margin-left: 0;
                }
            }
        }
    }
}