/* This style sheet contains new fonts Good Headline Pro and Gobold Bold on Account*/
/* Account start up */
.main-content.main-content--white.account-layout {
    .account {
        .form-container {
            @media only screen and (max-width: 854px) {
                width: 100%;
            }
            form#frm_login {
                width: 100%;
            }
        }
        .input-container.half-width {
            @media only screen and (min-width: 1200px) {
                width: 587px;
                padding-right: 0;
                padding-left: 0;
            }
        }
        h2.title {
            font-family: $gobold-bold;
            font-size: 30px;
            line-height: 42px;
            padding-bottom: 0;
            @media only screen and (min-width: 481px) {
                font-size: 40px;
                line-height: 56px;
            }
        }
        p.subtitle {
            font-family: $good-head-regular;
            margin: 0 auto;
            font-size: 24px;
            line-height: 34px;
            max-width: 343px;
            margin-top: 16px;
            padding-bottom: 10px;
            @media only screen and (min-width: 481px) {
                max-width: 358px;
                padding-bottom: 20px;
            }
        }
        .form-button {
            padding-bottom: 28px;
            @media only screen and (min-width: 481px) {
                padding-bottom: 45px;
            }
            .btn__container {
                font-family: $good-head-regular;
                letter-spacing: 4px;
                text-transform: uppercase;
                @media only screen and (min-width: 481px) {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 15px;
                    width: 377px;
                }
                &.small-btn {
                    @media only screen and (min-width: 481px) {
                        width: 270px;
                    }
                }
            }
        }
        .input-container.space-left{
            @media only screen and (max-width: 854px) {
                padding-top: 37px;
            }
            @media only screen and (min-width: 1200px) {
                width: 587px;
                padding-right: 0;
                padding-left: 0;
            }
            h2.title {
                max-width: 428px;
                margin: 0 auto;
            }
            p.subtitle {
                font-family: $good-head-regular;
                margin: 0 auto;
                font-size: 24px;
                line-height: 34px;
                max-width: 376px;
                margin-top: 16px;
                padding-bottom: 0;
                @media only screen and (min-width: 481px) {
                    max-width: 358px;
                    padding-bottom: 20px;
                }
            }
            .input-container {
                @media only screen and (min-width: 481px) {
                    max-width: 392px;
                    margin: 0 auto;
                }
                p.required-field {
                   font-family: $good-head-regular; 
                }
                .form-input__container {
                    .form-input__input {
                        &::placeholder {
                            font-family: $good-head-regular; 
                        }
                    }
                }
            }
            .linkPassword {
                a {
                    font-family: $good-head-regular; 
                }
            }
        }
    }
}

/* Password change */
.password-change-email_layout {
    .profile_detail.password_change_email {
        .account {
            h2.title {
                font-family: $gobold-bold;
                font-size: 24px;
                line-height: 14px;
                @media only screen and (min-width: 481px) {
                    font-size: 40px;
                    line-height: 56px;
                }
            }
            p {
                font-family:$good-head-regular;
                font-size: 20px;
                line-height: 30px;
                @media only screen and (min-width: 481px) {
                    max-width: 367px;
                    margin: 0 auto;
                }
            }
            p + .input-container {
                @media only screen and (min-width: 481px) {
                    max-width: 389px;
                    margin: 0 auto;
                }
                p {
                    padding-left: 0;
                }
            }
            .form-input__input {
                &::placeholder {
                    font-family: $good-head-regular; 
                }
            }
            .form-button {
                padding-top: 18px;
                @media only screen and (min-width: 481px) {
                    padding-top: 33px;
                }
                .btn__container {
                    @media only screen and (min-width: 481px) {
                        width: 392px;
                    }
                    .btn__text {
                        font-family:$good-head-regular;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 15px;
                        letter-spacing: 4px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

/* Registration page */
.main-content.main-content--white.register-layout {
    .register {
        .s-content-title {
            @media only screen and (min-width: 481px) {
                max-width: 587px;
                margin: 0 auto;
            }
            @media only screen and (min-width: 1200px) {
                padding: 0;
            }
            h1 {
                font-family: $gobold-bold;
                font-size: 30px;
                line-height: 42px;
                @media only screen and (min-width: 481px) {
                    font-size: 60px;
                    line-height: 90px;
                }
            }
            p{
                &:first-of-type {
                    font-family:$good-head-regular;
                    font-size: 24px;
                    line-height: 34px;
                    @media only screen and (min-width: 481px) {
                        padding-bottom: 59px;
                    }
                }
            }
        }
        .register {
            .s-content-partication {
                .title-data {
                    font-family: $gobold-bold;
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: 400;
                    @media only screen and (min-width: 481px) {
                        padding-top: 70px;
                    }
                }
                .form-container .input-container .form-input__input {
                    &::placeholder {
                        font-family:$good-head-regular;
                    }
                }
                .form-input__label {
                    font-family:$good-head-regular;
                }
                .spaceForm {
                    @media only screen and (max-width: 481px) {
                        padding-bottom: 23px;
                    }
                }

                .account.spaceTeamButtom {
                    @media only screen and (min-width: 481px) {
                        padding-bottom: 40px;
                    }
                    .chooseTeam {
                        @media only screen and (max-width: 481px) {
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                        h2.heading {
                            font-family: $gobold-bold;
                            font-size: 30px;
                            line-height: 42px;
                            font-weight: 400;
                            padding-bottom: 20px;
                            @media only screen and (min-width: 481px) {
                                font-size: 40px;
                                line-height: 56px;
                                padding-top: 70px;                          
                                padding-bottom: 30px;
                            }
                        }
                    }
                    .form-container.profile {
                        .choose_team {
                            padding-top: 30px;
                            @media only screen and (min-width: 481px) { 
                                padding-top: 50px; 
                            }
                            @media only screen and (min-width: 676px) { 
                                width: 457px; 
                            }
                            .heading-choose_team {
                                font-family: $gobold-bold;
                                font-size: 30px;
                                line-height: 42px;
                                font-weight: 400;
                                @media only screen and (min-width: 481px) {
                                    font-size: 40px;
                                    line-height: 56px;
                                }
                            }
                            p.img-type {
                                font-family:$good-head-regular;
                                font-size: 20px;
                                line-height: 30px;
                                padding-top: 6px;
                                margin-bottom: 19px;
                                @media only screen and (min-width: 481px) {
                                    font-size: 24px;
                                    line-height: 34px;
                                    padding-top: 15px;
                                }
                            }
                            .checkbox_register {
                                margin-top: 22px;
                                @media only screen and (min-width: 481px) {
                                    margin-top: 26px;
                                }
                                .form-container {
                                    .btn__container {
                                        background: #7D69AC;
                                        border-radius: 22px;
                                        &.pictureUpload {
                                            text-decoration: none;
                                        }
                                        .desktop-only {
                                            font-family:$good-head-regular;
                                            font-size: 18px;
                                            line-height: 15px;
                                            letter-spacing: 4px;
                                            text-transform: uppercase;
                                            font-weight: 700;
                                            text-decoration: none;
                                        }
                                    }
                                    .input-container.zero_width{
                                        @media only screen and (min-width: 481px) {
                                            max-width: 406px;
                                        }
                                        .text-content {
                                            font-family:$good-head-regular;
                                            font-size: 16px;
                                            line-height: 24px;
                                            padding-left: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .chooseTeam--team-container .team p {
                        font-family:$good-head-regular;
                    }
                }

                .s-content-partication.s-content-newsletter {
                    @media only screen and (min-width: 481px) {
                        max-width: 529px;
                    }
                    .text-content {
                        font-family:$good-head-regular;
                        font-size: 16px;
                        line-height: 24px;
                        padding-left: 12px;
                        font-weight: 400;
                    }
                    .btn__container {
                        font-family: $good-head-regular;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 15px;
                        text-align: center;
                        letter-spacing: 4px;
                        text-transform: uppercase;
                        margin-bottom: 40px;
                        @media only screen and (min-width: 481px) {
                            width: 377px;
                            margin-bottom: 70px;
                        }
                    }
                }
            }
        }
    }
}

/* Account Information */
main.main-content.main-content--white {
    &.account-info_layout, &.login-detail_layout, &.reset-password_layout {
        .profile_detail {
            &.account_upgrade {
                #frm_updateProfile {
                    @media only screen and (min-width: 768px) {
                        margin: 0 0 22px;
                    }
                }
                .content-upload-receipt.form-line.row {
                    @media only screen and (min-width: 481px) {
                        max-width: 1500px;
                    }
                }
                .milka_de_account {
                    h1 {
                        font-family: $gobold-bold;
                        font-size: 30px;
                        line-height: 42px;

                        @media only screen and (min-width: 481px) {
                            font-size: 60px;
                            line-height: 90px;
                        }
                    }

                    p {
                        font-family: $good-head-regular;
                        font-size: 24px;
                        line-height: 34px;

                        @media only screen and (max-width: 920px) {
                            max-width: 650px;
                            margin: 0 auto;
                        }
                    }

                    ol {

                        @media only screen and (max-width: 920px) {
                            max-width: 650px;
                            margin: 0 auto;
                        }

                        li {
                            font-family: $good-head-regular;
                            font-size: 24px;
                            line-height: 34px;
                            font-weight: 400;
                        }
                    }
                }
            }
            .left {
                .navigationAccount {
                    li {
                        font-family: $good-head-pro;
                        font-size: 16px;
                        line-height: 24px;
                        @media only screen and (min-width: 481px) {
                            max-width: 290px;
                        }
                        a {
                            font-family: $good-head-pro;
                            font-size: 16px;
                            line-height: 24px;
                        }
                        &:first-of-type {
                            font-family: $gobold-bold;
                            @media only screen and (min-width: 481px) {
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }
                    }
                }
                .navigationAccount-sm {
                    .left-arrow {
                        font-family: $good-head-regular;
                        font-weight: 900;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                    }
                }
                button.btnLogout.logout-lg {
                    width: 110px;
                    margin-top: 34px;
                    .btn__logout {
                        font-family: $good-head-regular;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
            }
            
            .logout-sm {
                .btn__logout {
                    font-family: $good-head-regular;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
            }
            .right {
                h2 {
                    font-family: $gobold-bold;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 42px;
                    @media only screen and (min-width: 481px) {
                        padding-bottom: 39px;
                    }
                }
                .register .form-container .input-container .form-input__input {
                    &::placeholder {
                        font-family: $good-head-regular; 
                    }
                }
                .space-bottom {
                    p {
                        font-family: $gobold-bold;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 34px;
                    }
                    p.hint {
                        font-family: $good-head-regular; 
                        font-size: 16px;
                        line-height: 24px;
                        padding-bottom: 3px;
                    }
                }
                .notice-regardlessTeam {
                    #text-underTeam {
                        font-family: $good-head-regular; 
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .choose_team {
                    @media only screen and (min-width: 481px) {
                        min-width: 373px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                    }
                    span.desktop-only {
                        font-family: $good-head-regular;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .profile_picture .img-type {
                        .heading-choose_team {
                            font-family: $good-head-regular;
                            @media only screen and (min-width: 481px) {
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }
                        p:last-child {
                            font-family: $good-head-regular;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .zero_width .text-content {
                        font-family: $good-head-regular;
                        font-size: 16px;
                        line-height: 24px;
                        padding-left: 9px;
                        max-width: 321px;
                    }
                }
                #frm_updateProfile .form-button .btn__container {
                    font-family: $good-head-regular;
                    width: 345px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 15px;
                    text-align: center;
                    letter-spacing: 4px;
                    text-transform: uppercase;
                    @media only screen and (min-width: 481px) {
                        width: 381px;
                    }
                }
                .input-container.resetPassword {
                    p {
                        font-family: $good-head-pro;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 30px;
                        &.small {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .form-button {
                        @media only screen and (min-width: 481px) {
                            margin-top: 45px;
                        }
                        button {
                            padding-left: 0;
                            .btn__container {
                                max-width: 442px;
                                span {
                                    font-family: $good-head-regular;
                                    font-weight: 700;
                                    font-size: 20px;
                                    line-height: 15px;
                                    text-align: center;
                                    letter-spacing: 4px;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.account .input-container .form-input__input {
    &::placeholder {
        font-family: $good-head-regular;
    }
}

.main-content.account-confirmation_layout {
    .s-content-title {
        padding-bottom: 12px;
        @media only screen and (min-width: 481px) {
            padding-bottom: 0;
        }
        h1 {
            font-family: $gobold-bold;
            font-size: 30px;
            line-height: 42px;
            @media only screen and (min-width: 481px) {
                font-size: 60px;
                line-height: 90px;
                max-width: 699px;
                margin: 0 auto;
                margin-bottom: 33px;
                color: #fff;
            }
        }
    }
    .confirmation_account {
        max-height: unset;
        height: auto;
        @media only screen and (min-width: 481px) { 
            max-height: unset;
            height: auto;
        }
    }
    .confirmation_account-text {
        img {
            @media only screen and (min-width: 481px) {
                width: 182px;
            }
        }
        .s-content-title {
            @media only screen and (min-width: 481px) {
                max-width: 665px;
                margin: 0 auto;
            }
            p {
                font-family: $good-head-regular;
                font-size: 20px;
                line-height: 30px;
                @media only screen and (min-width: 481px) {
                    font-size: 24px;
                    line-height: 34px;
                }
            }
        }
    }
}