@import "./buzzer-heading";
@import "./buzzer-mid";
@import "./buzzer-footer";
@import "./buzzer-form";
@import "./buzzer-code";
@import "./buzzer-consent";
@import "./buzzer-game";
@import "./buzzer-prize";
@import "./buzzer-pages";
@import "./buzzer-specific";

.separator {
    width: 80%;
    margin: 0 auto;
}