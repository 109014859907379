$font-size:44px;
$font-size-sm:22px;

@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}
@mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
}

.variety-wrapper-trikot{
    border-width: 2px 0px;
    border-style: solid;
    border-color: #DFB660;
    background: linear-gradient(180deg, #7D69AC 0%, #EEE4FC 50%, #D8CCEC 59.9%, #7D69AC 100%);
}

.gamification-q3{
    
    &__game{
        background-size: 100vw auto;
        background-position-y: 30%;

        &__text{
            font-size: $font-size;
            font-family: $good-head-pro-blackitalic;
            background: -webkit-linear-gradient(91.51deg, #FFFFFF , #D5D0ED);
            line-height: 150%;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
            text-align: center;
            position: absolute;
            top: 4%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 1164px;
             @include animate(popoutin, 1.5s, ease, infinite);
            @media screen and (min-width: 768px) and (max-width: 1024px){
                font-size: 35px;
                min-width: 600px;
            }
            @media screen and (max-width:767px){
                top: 4%;    
                font-size: $font-size-sm;
                min-width: 348px;
                @include animate(popoutin, 1.5s, ease, infinite);
            }
            @media  screen and (max-width:375px) {
                font-size: $font-size-sm;
                min-width: 292px;
                @include animate(popoutin, 1.5s,ease, infinite);
            }
        }

        &__door{
            width: 100%;
        }

        &__clickable{
            width: 80%;
            background-color: #fff;
            opacity: .0;
            position: absolute;
            top: 42%;
            transform: translate(-50%, -50%);
            left: 50%;
            height: 120vh;
            cursor: pointer;

            @media screen and (max-width:768px){
                top: 30%;
                width: 100%;
                height: 82vh;
            }
        }

    }
       &__prize-details{
        background-color: $step-color;
        .s-content-title {
                text-align: center;
                font-family: $gobold-bold;
                margin: 0 auto;
        
                @media only screen and (max-width: 481px) {
                    width: 100%;
                }
                
                @media only screen and (min-width: 481px) {
                    padding-top: 0;
                }
        
                .heading__v1,
                .paragraph__v1 {
                    display: block;
                    margin: 0 auto;
                    color: $white;
                }
        
                .paragraph__v1 {
                    width: 65%;
                    max-width: 900px;
        
                    @media only screen and (max-width: 481px) {
                        width: 99%;
                    }
                }
        
                .heading__v1 {
                    padding:30px 0 20px 0;
                    font-family: $gobold-bold !important;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 90px;
                    text-align: center;
                    word-break: break-word;
                    max-height: 251px;
                    font-size: 40px;
                    color:$white;
                    /* iPad Portrait */
                    @media only screen and (max-width: 768px) {
                        line-height: 40px;
                    }
        
                    /* mobile Portrait */
                    @media only screen and (max-width: 481px) {
                        font-size: 30px;
                        line-height: 36px;
                        letter-spacing: 0.4375px;
                        padding-bottom: 30px;
                    }

                    @media only screen and (min-width: 481px) {
                        line-height: 60px;
                        padding: 60px 0 17px 0;
                    }
        
                    @include breakpoint(large) {
                        //transform: scale(1.2);
                        font-size: 40px;
                        width: 1040px;
                    }
        
                    @include breakpoint(small) {
                        min-width: 331px;
                    }
        
        
                }

                .varietyTeaser2__heading{
                    font-size: 60px;
                    line-height: 90px;
                    font-weight: 400;

                    @media only screen and (max-width: 991px) {
                        font-size: 40px;
                        line-height: 50px;
                    }

                    @media only screen and (max-width: 481px) {
                        font-size: 30px;
                        line-height: 40px;
                    }
                }

                .varietyTeaser2__paragraph{
                    font-size: 40px;
                    line-height: 60px;
                    font-weight: 400;
                    font-family: $gobold-bold !important;
                    color: #fff;

                    @media only screen and (max-width: 991px) {
                        font-size: 30px;
                        line-height: 40px;
                    }

                    @media only screen and (max-width: 481px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
        
        
                .paragraph__v1 {
                    padding-bottom: 35px;
                    font-size: 24px;
                    line-height: 26px !important;
                    font-family: $good-head-pro !important;
        
                    /* mobile Portrait */
                    @media only screen and (max-width: 481px) {
                        line-height: 20px;
                        padding-top: 0px;
                    }
                    @media only screen and (min-width: 481px) {
                        max-width: 585px;
                        padding-bottom: 75px;
                    }
        
                    @include breakpoint(small) {
                        line-height: 33px !important;
                    }
                }
                span.yellow{
                    color:#E8CF73;
                    display: unset;
                }
        
            }
            .s-content-end {
                padding: 0;
                margin-bottom: 0;
                h1 {
                    font-family: $gobold-bold;
                }
    
                p {
                    font-family: $good-head-regular;
                    color: #fff;
                    font-size: 24px;
                    line-height: 34px;
                    text-align: center;
    
                    @media only screen and (max-width: 480px) {
                        padding-bottom: 30px;
                    }
    
                    @media only screen and (min-width: 480px) {
                        padding-bottom: 30px;
                        max-width: 480px;
                        margin: 0 auto;
                        margin-bottom: 50px;
                        text-align: center;
                    }
                }
    
                .end-element {
                   
                    background-color: #7D69AC;
                    padding: 0 20px;
                    padding-top: 40px;
                    margin: 0 auto;

                    @include breakpoint(large){
                        max-width: 1500px;
                        padding: 0 50px;
                    }
    
                    
    
                    p {
                        color: #3B2772;
                        text-align: left;
                        padding-bottom: 0px;
                    }
                    &__item:nth-child(2),
                    &__item:nth-child(3){
                            margin-left: 3%;
                    }
    
                    .end-element-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 50px auto;
                        max-width: 1175px;
                        justify-content: center;
    
                        .end-element__copy {
                            h3 {
                                font-family: $gobold-bold;
                                font-size: 20px;
                                line-height: 28px;
                                font-weight: bold;
                            }
    
                            p {
                                font-family: $good-head-regular;
                                font-size: 20px;
                                line-height: 26px;
                            }
    
                            .btn__container {
                                font-family: $good-head-pro;
                                font-size: 19px;
                                font-weight: bold;
                                line-height: 15px;
                                padding: 10px 17px 8px 17px;
                            }
                        }
                    }
                }
            }
       }

    .error_message {
        text-align: center;
        font-family: "Good Headline Pro";
        font-size: 24px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 4%;
        left: 50%;
        color: red;
        width: 100%;
        @media only screen and (max-width: 481px) {
            font-size: 18px;
            line-height: 19px;
            top: 6%;
        }
    }
}
 @include keyframes(popoutin) {
    0% {         }
    50% {  transform:translate(-50%, -50%) scale(0.7);   }
    100% {     }
 }
