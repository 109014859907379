.buzzer-predictions {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0s;
    background-repeat: repeat-x;
    margin: 25px 0 45px;

    @include breakpoint(large) {
        margin: 45px 0;
    }

    div {
        background: #FFFFFF;
        border-radius: 100px;
        width: 100%;
        max-width: 530px;
        max-height: 55px;
        margin: 10px auto;
        padding: 0 20px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(medium) {
            padding: 12px 20px;
            max-height: 85px;
            margin: 20px auto;
        }

        span {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
        }

        img {
            max-height: 55px;
            height: 100%;
            width: auto;

            @include breakpoint(medium) {
                max-height: 85px;
            }
        }
    }

    div[data-index] {
        transition: 0s;
        &.selected {
            background: linear-gradient(0deg, #DDDAEE, #DDDAEE);
            border: 2px solid #3B2772;
        }
    }

    &[data-prediction-count="1"] {
        background-image: url('/resources/imagesBundesliga/buzzer/predictions/1-sm.png');
        height: 139px;
        
        @include breakpoint(medium) {
            background-image: url('/resources/imagesBundesliga/buzzer/predictions/1.png');
            height: 287px;
        }
    }

    &[data-prediction-count="2"] {
        background-image: url('/resources/imagesBundesliga/buzzer/predictions/2-sm.png');
        height: 208px;
        
        @include breakpoint(medium) {
            background-image: url('/resources/imagesBundesliga/buzzer/predictions/2.png');
            height: 365px;
        }
    }

    &[data-prediction-count="3"] {
        background-image: url('/resources/imagesBundesliga/buzzer/predictions/3-sm.png');
        height: 280px;
        
        @include breakpoint(medium) {
            background-image: url('/resources/imagesBundesliga/buzzer/predictions/3.png');
            height: 456px;
        }
    }
}