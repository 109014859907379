.container_holding-reihe{
    .remindMe-container{
        padding-right: 0px;
        padding-top: 20px;
        padding-bottom: 10px;

        @media (max-width: 767px) {
            padding-right: 25px;
        }

        .normal{
            margin-inline: 87px;
            width: 320px;
            background: var(--Milka-Lilac, #7D69AC);

            @media (max-width: 767px) {
                margin-inline: 0px;        
            }
        }
    }

    .paragraphe {
        margin-top: 50px;
    }

    .taille {
        width: 95%;
        max-width: 100% !important; 
        margin-inline: 10px;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
    
    .checkmark::after{
    left: 2px;
        top: 5px !important;
    }
    
}