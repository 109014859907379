.heritage-card {
    &__container {
        display: flex;
        flex-direction: column;
        background-color: $natural;
        height: 100%;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 1;
        width: 100%;
        text-align: center;
        padding-top: 21px;

        @include breakpoint(large) {
            padding-top: 41px;
        }
    }

    &__tag {
        color: $dark-lilac;
        font-family: $soft-alt-medium;
        font-size: 14px;

        @include breakpoint(x-large) {
            font-size: 16px;
        }
    }

    &__title {
        font-family: $recoleta-medium;
        color: $dark-lilac;

        @include breakpoint(large) {
            width: 35%;
            font-size: 30px;
        }
    }

    &__illustration {
        margin-top: 8px;
        margin-bottom: 8px;
        height: 65%;
        flex-grow: 1;
        overflow: hidden;

        picture{
            width: 100%;
            height: 65%;

            @include breakpoint(large) {
                height: 100%;
            }
        }
    }

    &__img {
        width: auto;
        height: 100%;
    }
}