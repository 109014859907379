.campaign-consent {
    padding: 20px;

    &.buzzer-consent{
        @include breakpoint(small-only){
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    @include breakpoint(medium) {
        width: 60%;
        margin: 0 auto;
    }

    .text-content {
        font-size: 17px;
        line-height: 20px;

        @include breakpoint(medium) {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .form-container {
        @include breakpoint(medium) {
            padding: 15px 0;
        }
    }

    #passWordReg {
        h4 {
            font-family: $recoleta;
            text-align: center;
            font-size: 28px;
        }
    }

    input, select {
        background-color: rgba(76, 53, 131, 0.1);
    }
}