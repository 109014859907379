@import "./screen";

.micro-globus {
    
    &__title-container{
        padding: 100px 0px 100px 0px;
        background-color: #7d69ac;

        @include globus-screen(mobile-only) {    
            padding-top: 50px;
            padding-bottom: 50px;
        }

        h1, h2 {
            font-family: $recoleta-medium;
        }
        h1 {
            font-size: 60px;
            line-height: 65px;
            width: 90%;
            max-width: 842px;//1067px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 20px;
            padding-top: 0;

            @include globus-screen(small-only) {
                font-size: 30px;
                line-height: 36px;
                letter-spacing: 0.4375px;
            }
        }

        h2 {
            font-size: 40px;
            line-height: 46px;
            width: 90%;
            max-width: 650px;
            text-align: center;
            margin: 0 auto;

            @include globus-screen(small-only) {
                font-size: 22px;
                line-height: 28px;
            }
        }
        p {
            text-align: center;
            font-family: $recol-regular;
            max-width: 745px;
            margin: 0 auto;
            font-size: 25px;
            line-height: 32px;
            width: 90%;

            strong{
                font-weight: bold;
                font-family: $recoleta-medium;
            }
            
            @include globus-screen(small-only) {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
    &__title-container * {
        color: #fff;
    }

    @media only screen and (min-width: 1024px){
        .image__container .form-button {
            right: 0;
            width: 71%;
            justify-content: center;
            
            button {
                width: 100%;
                @media only screen and (max-width: 1399px){
                    min-width: unset;
                }
            }
        }
    }

    .image__container .form-button {
        bottom: 8%;
        @include globus-screen(mobile-only) {
            bottom: 4%;
        }
    }

    .card-container__card__tertiary{
        margin-top: 0;
    }

    .step-container{
        &--terms-link {
            margin-top: 40px !important;
            @include globus-screen(desktop-only) {
                max-width: 815px;
                line-height: 31px;
            }
        }
        .btn--secondary-lilac {
            margin-top: 100px;
            @include globus-screen(mobile-only) {
                margin-top: 0px;
            }
        }
    }

    .s-content-steps__item {
        max-width: 300px;

        &.first {
            p {
                max-width: 182px;
            }
        }
        &.second {
            p {
                max-width: 175px;
            }
        }
        &.third {
            p {
                max-width: 233px;
            }
        }
    }

    .card-container {
        &__card {
            padding-top: 100px;
            padding-bottom: 100px;

            @include globus-screen(mobile-only) {
                padding-top: 50px;
                padding-bottom: 50px;
            }

            &__tertiary {
                max-width: 1175px;

                .text-container {
                    padding-bottom: 0;
                    @include globus-screen(mobile-only) {
                        margin-bottom: 50px;
                    }
                    /*p {
                        margin: 0 auto;
                        margin-top: 20px;
                        width: 90%;
                    }*/
                }
            }
        }
    }
}

@import "./participation";
@import "./confirmation";

