#main-buzzer {
    .campaign-heading {
        h2, h4 {
            @include breakpoint(small-only) {
                margin-bottom: 40px;
            }
        }

        h2 {
            br {
                @include breakpoint(small-only) {
                    display: none;
                }
            }
        }

    }

    .campaign-footer {
        .special {
            @include breakpoint(small-only) {
                font-family: $soft;
            }
        }

        h3 {
            width: 90%;
            margin: 0 auto 20px;
        }

        .btn--anchor {
            margin-top: 5px;
        }
    }
}

#buzzer-participation {
    .campaign-heading {
        h4 {
            &:first-of-type {
                @include breakpoint(small-only) {
                    margin: 30px 0;
                }

                @include breakpoint(medium) {
                    font-size: 40px;
                }
            }

            &:last-of-type {
                @include breakpoint(small-only) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    #frm_participation {
        @include breakpoint(small-only) {
            margin-bottom: 100px;
        }
    }
}

#main-participation {
    .campaign-heading {
        h4 {
            &:first-of-type {
                @include breakpoint(small-only) {
                    margin: 30px 0;
                }

                @include breakpoint(medium) {
                    font-size: 40px;
                }
            }

            &:last-of-type {
                @include breakpoint(small-only) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    #frm_participation {
        @include breakpoint(small-only) {
            margin-bottom: 100px;
        }
    }
}

#game-buzzer {
    .campaign-heading {
        @include breakpoint(medium) {
            margin-bottom: 50px;
        }
        h4 {
            @include breakpoint(medium) {
                margin-top: 20px;
            }
        }
    }
}

#buzzer-prizes {
    .campaign-heading {
        h3 {
            @include breakpoint(small-only) {
                width: 90%;
                margin: 20px auto;

                br {
                    display: none;
                }
            }
        }
    }

    .campaign-mid {
        @include breakpoint(small-only) {
            padding-top: 0;
        }

        .campaign-prize {
            @include breakpoint(small-only) {
                margin: 80px 0;
            }

            img {
                @include breakpoint(small-only) {
                    width: 90%;
                    height: auto;
                }

                &.smaller {
                    @include breakpoint(small-only) {
                        width: 50%;
                    }
                }
            }

            h6 {
                @include breakpoint(small-only) {
                    font-weight: bold;
                    width: 100%;
                    text-align: center;
                    margin: 30px 0 15px;
                    font-size: 28px;
                }
            }

            p {
                @include breakpoint(small-only) {
                    font-size: 20px;
                }
            }
        }
    }
}

#frm_user_details_comp {
    .information {
        display: block;
        padding: 10px;
        text-align: center;
    }
}

.buzzer-banner-netto {
    @include breakpoint(large) {
        bottom: 15% !important;
    }
}

.buzzer-banner-kaufland {
    @include breakpoint(large) {
        bottom: 15% !important;
    }
}

.banner__container {
    .form-button {
        button {
            background-color: #7D69AC;
        }
        button:hover{
            background-color: #6c5c92;
        }
    }
}

#buzzer-kaufland {
    .campaign-footer {
        .campaign-mid {
            @include breakpoint(medium) {
                padding:0 50px 50px;
            }

            .text {
                @include breakpoint(large) {
                    padding-top: 100px;
                }
            }
        }
    }
}

#Covid-Details {
    padding-top: 100px;
}

.team-season {
    position: relative;
    span {
        background-color: $error-red;
        color: white;
        position: absolute;

        font-size: 18px;
        line-height: 20px;
        text-align: center;
        
        display: flex;
        align-items: center;
        width: 85px;
        height: 85px;
        
        border-radius: 50%;
        box-shadow: 0px 1.2763px 1.2763px rgba(0, 0, 0, 0.25);
        transform: rotate(-10deg);
    }
}