.campaign-game {
    margin-bottom: 30px;
    
    @include breakpoint(medium) {
        margin-bottom: 50px;
    }

    h4 {
        font-size: 22px;
        line-height: 28px;
        font-family: $recoleta;
        text-align: center;
        padding: 0 35px;
        position: relative;
        margin-bottom: 27px;
        
        @include breakpoint(medium) {
            font-size: 30px;
            line-height: 32px;
            padding: 0 15%;
        }

        strong {
            font-weight: bold;
            font-family: inherit;
        }

        &[data-heading] {
            br {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }
        }

        &[data-heading]::before {
            content: attr(data-heading);
            height: 27px;
            width: 27px;
            line-height: 27px;
            background-color: $dark-lilac;
            color: $white;
            border-radius: 50%;

            position: absolute;
            top: 0;
            left: 5px;
        
            @include breakpoint(medium) {
                position: relative;
                display: inline-block;
                height: 40px;
                width: 40px;
                line-height: 41px;
                left: -5%;
            }
        }

        &[data-heading="2"]{
            position: relative;

            @include breakpoint(large) {
                max-width: 1500px;
                margin: 0 auto;
                
                &::after {
                    content: url('/resources/imagesBundesliga/buzzer/arrow.png');
                    position: absolute;
                    bottom: -250px;
                    left: 25%;
                }
            }
        }
        &[data-heading="3"]{
            margin: 0 auto;
            @include breakpoint(medium) {
                width: 70%;
            }
        }
    }

    h5 {
        font-size: 22px;
        line-height: 28px;
        margin: 30px 0 40px;
        width: 100%;
        text-align: center;

        @include breakpoint(medium) {
            margin: 40px 0 70px;
        }
    }

    p {
        width: 90%;
        display: block;
        margin: 20px auto;
        text-align: center;
        font-size: 20px;
        
        @include breakpoint(medium) {
            width: 45%;
        }
    }

    .buzzer-container{
        display: flex;
        flex-direction: column;
        
        @include breakpoint(large) {
            flex-direction: row;
            align-items: center;
        }
    
        @include breakpoint(medium) {
            width: 80%;
            margin: 0 auto;
        }
        @include breakpoint(large) {
            width: 40%;
            margin: 0 auto;
            margin-right: calc(30% + 70px);
        }

        @include breakpoint(x-large) {
            margin-right: 30%;
        }

        & > picture {
            cursor: pointer;
    
            .buzzer {
                position: relative;
                z-index: 4;

                @include breakpoint(large) {
                    max-width: 590px;
                }
            }

        }
        .buzzer-label {
            order: 2;

            @include breakpoint(large) {
                order: 0;
            }

            img {
                width: 80%;
                margin: 10px auto 30px;
            }
        }
    }

    .buzzer-gif {
        display: block;
        position: relative;
        z-index: 4;
        // width: 100%;

        // @include breakpoint(medium) {
        //     width: 80%;
        //     margin: 0 auto;
        // }
        @include breakpoint(large) {
            width: 90%;
            margin: 0 auto;
            max-width: 590px;
        }
    }

    button.tablet-up {
        max-width: unset;
        width: 540px;
    }

    button.mobile {
        width: 90%;
    }
}