.thank-you-content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 80px;
    background-color: #7D69AC;

    @media only screen and (min-width: 768px) {
        .thank-you{
            display: grid;
            justify-content: center;
            grid-template-columns: auto auto auto;
            align-items: baseline;
            column-gap: 30px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .thank-you-content {

        .thank-you {
            display: grid;
            justify-content: center;
            grid-template-rows: auto auto auto;
            align-items: baseline;
            row-gap: 30px;
        }
    }
}