.s-content-steps {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    padding: 0 15px;
    text-align: center;
    justify-content: center;
    //margin:50px;
   

    &__step {
        width: 236px;
        margin: 0 auto;

        @include breakpoint(large) {
            max-height: 200px !important;
        }

        p {
            font-family: $good-head-pro;
            font-style: normal;
            font-size: 20px !important;
            line-height: 30px;
            text-align: center;
            letter-spacing: -0.928384px;
            color: #3b2772;

            @include breakpoint(small-only) {
                font-size: 22px !important;
                line-height: 26px;
            }

            strong {
                font-weight: bold;
            }

            a {
                text-decoration: underline;
            }
        }

        
    }

    &__copy {

        &__span{
            font-weight: bold;
            color:#E8CF73;
        }
        width: 220px;
        margin: 0 auto;
        margin-top: 15px;

        &.third {
            width: 264px;

            @include breakpoint(small-only) {
                width: 209px;
            }
        }
    }

    h2 {
        @include breakpoint(small-only) {
            padding-top: 0;
        }
    }
    picture img{
        margin: 0 auto;
    }
        @include breakpoint(small-only) {
            &>div {
                margin: 15px auto !important;
            }
        }
}