/* Réinitialisation CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Styles pour .textTickets */
.textTickets_ {
    color: white;
    font-size: 30px;
    text-align: center;
    font-family: "Good Headline Pro Bold", sans-serif;
    /* Ajoutez la police avec le bon poids */
    font-weight: 700;
}

.t64 {
    font-size: 40px;
}

.containerAnimation {
    width: 260px;
    height: 260px;
    position: relative;
    margin: auto;
    margin-top: 100px;
    z-index: 2;
}

.ticketHaut,
.ticketBas {
    animation: slideLeftToRight 5s linear infinite;
    margin: auto;
    height: 50px;
    background-color: #c01515;
    margin: auto;
    position: absolute;
    z-index: 3;
    transform: skewY(-6deg);
    box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.ticketHaut {
    width: 70px;
    top: -31px;

}

.rectifHaut {
    width: 220px;
}

.ticketBas {
    top: 240px;
    width: 321px;
    height: auto;
}

.rectifBas {
    width: 220px;
    /* animation: slideLeftToRightPhase2 5s linear infinite; */
    margin-left: 118px;
}

.zoomFadingElement {
    animation: growAnimation 5s infinite;
    width: 450px;
    height: 450px;
    opacity: 0.1;
    transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;
    margin: auto;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.zoomFadingElementExeption {
    animation: growAnimationExeption 5s infinite;
    width: 450px;
    height: 450px;
    opacity: 0.1;
    transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;
    margin: auto;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    position: absolute;
}

/* Style pour la bordure blanche */
.containerBulle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 86px;
    margin-left: 80px;
}

.whiteBorderBox,
.solidWhiteBox {
    width: 12px;
    height: 12px;
    margin: 3px;
    border-radius: 50%;
}

.whiteBorderBox {
    border: 2.5px solid white;
}

.solidWhiteBox {
    background-color: white;
}

/* Animation  */
@keyframes growAnimation {
    0% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }

    25% {
        width: 350px;
        height: 350px;
        opacity: 1;
    }

    75% {
        width: 350px;
        height: 350px;
        opacity: 0.9;
    }

    100% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }
}

@keyframes growAnimationExeption {
    0% {
        width: 300px;
        height: 280px;
        opacity: 0;
    }

    25% {
        width: 350px;
        height: 330px;
        opacity: 1;
    }

    75% {
        width: 350px;
        height: 330px;
        opacity: 0.9;
    }

    100% {
        width: 300px;
        height: 280px;
        opacity: 0;
    }
}

@keyframes slideLeftToRight {
    0% {
        left: -20px;
        opacity: 0;
    }

    25% {
        left: 8px;
        opacity: 1;

    }

    75% {
        left: 8px;
        opacity: 0.9;
    }

    100% {
        left: 50px;
        opacity: 0;
    }
}