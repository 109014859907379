.mosaique {
    &-row {
        &--small {
            @include breakpoint(large) {
                height: 397px;
            }
        }
        &--medium {
            @include breakpoint(large) {
                height: 484px;
            }
        }
        &--large {
            @include breakpoint(large) {
                height: 575px;
            }
        }
    }

    &__title {
        &--big {
            font-size: 60px;
        }
    }

    &__container {
        &--centered {
            background-color: transparent;

            .mosaique {
                &__title {
                    font-family: $recoleta;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 45px;
                    text-align: center;
                    color: $dark-lilac;
    
                    @include breakpoint(medium) {
                        font-size: 60px;
                        line-height: 65px;
                    }
                }
            }
        }
        &--bg {
            background-color: transparent;
            background-image: url($local-images+'/Shapes/Textur-Milka-Logo.svg');
            background-size: 100%;
            background-position: bottom;
            background-repeat: no-repeat;

            .mosaique {
                &__title {
                    color: $dark-lilac;
                }
            }
        }
    }
}