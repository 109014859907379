.btn-simple{
        
        border: none;
        outline: none;
        background-color: $dark-lilac;
        padding: 2px 20px;
        position: relative;
        border-radius: 8px;
        letter-spacing: 0.7px;
        color: $white;
        font-size: 18px;
        cursor: pointer;
        min-width: 120px;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 2px solid $dark-lilac;
        height: 48px;
        border-radius: 50px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-right: 15px;

        @include breakpoint(x-small) {
                padding:2px 16px;
                font-size: 16px;
        }
}

.no-uppercase{
    text-transform: none !important;
}