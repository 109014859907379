
input {
    font-family: $soft-alt-regular;
    font-size: 16px;
}

p {
    font-family: $p-font-family;
    font-size: 14px;

    @include breakpoint(medium) {
        font-size: 16px;
    }

    @include breakpoint(large) {
        font-size: 18px;
    }
}

h1 {
    font-family: $h1-font-family;
    font-size: 30px;
    line-height: 1.25em;

    @include breakpoint(medium) {
        font-size: 32px;
    }

    @include breakpoint(medium-l) {
        font-size: 40px;
        line-height: 1.25em;
    }

    @include breakpoint(large) {
        font-size: 48px;
        line-height: 1.05em;
    }
}

h2 {
    font-family: $h2-font-family;
    font-size: 24px;
    line-height: 1.154em;

    @include breakpoint(medium) {
        font-size: 26px;
    }

    @include breakpoint(medium-l) {
        font-size: 32px;
        line-height: 1.25em;
    }

    @include breakpoint(large) {
        font-size: 36px;
    }
}

h3 {
    font-family: $h3-font-family;
    font-size: 20px;
    line-height: 1.14em;

    @include breakpoint(medium) {
        font-size: 22px;
        line-height: 1.25em;
    }

    @include breakpoint(medium-l) {
        font-size: 24px;
    }

    @include breakpoint(large) {
        font-size: 28px;
    }
}

h4 {
    font-family: $h4-font-family;
    font-size: 18px;
    line-height: 1.22em;

    @include breakpoint(medium-l) {
        line-height: 1.22em;
    }
}

ul, ol {
    list-style-position: inside;
}

.shadow {
    text-shadow: 0px 4px 50px $headline-shadow;
}
.lilac-shadow {
    text-shadow: 0px 4px 50px $milka-lilac;
}
.dark-lilac-shadow {
    text-shadow: 0px 4px 50px $dark-lilac;
}

.no-decoration {
    text-decoration: none !important;
}

.recoleta {
    font-family: $recoleta;
}
.recoleta-medium {
    font-family: $recoleta-medium !important;
}
.good-headline-pro {
    font-family: $good-head-pro !important;
}
.gobold-bold {
    font-family: $gobold-bold !important;
}

.lilac {
    color: $dark-lilac !important;
    * {
        color: $dark-lilac !important;
    }
}

.text--wheat {
    color: $wheat !important;
}