.weltmeister-game{
  background: #7D69AC;
    &__bottom{
        margin-top: 50px;
        .q4-game {
            max-width: 1175px;
            /* max-height: 376px; */
            background: #3B2774;
            border: 6px solid #E8CF73;
            border-radius: 14px;
            justify-content: center;
            padding: 50px 0 0;
            margin: 0 30px;
            margin-bottom: 31px;
            @media only screen and (min-width: 1167px) {
            margin: 0 auto;
            margin-bottom: 31px;
            }
            @media only screen and (max-width: 481px) {
              height: max-content;
              max-width: 375px;
              margin: 0 auto 24px;
              padding: 30px 20px 20px;
            }
            .trikot-promo-card {
              display: flex;
              justify-content: space-around;
              flex-wrap: wrap-reverse;
              .trikot-promo__content {
                display: flex;
                flex-direction: column;
                max-width: 444px;
                max-height: 296px;
                align-items: center;
                gap: 8px;
                img {
                  @media only screen and (max-width: 481px) {
                    width: 186px;
                  }
                }
                h4 {
                  font-family: 'Gobold Bold';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 36px;
                  text-align: center;
                  text-transform: uppercase;
                  color: #FFFFFF;
                  /* max-width: 340px; */
                  @media only screen and (max-width: 481px) {
                    max-width: 297px;
                  }
                }
                p {
                  font-family: 'Good Headline Pro';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 30px;
                  text-align: center;
                  color: #FFFFFF;
                  @media only screen and (max-width: 481px) {
                    max-width: 270px;
                  }
                }
              }
            }
            .player-wrapper{
                .player-wrapper__content{
                    .winInstitution{
                        height: 261px;
                    }
                }
            }
          }
          .s-content-end{
            .end-element .end-element__item{
                    height: 480px;
            }
          }
    }
}
  