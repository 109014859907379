.sommer_end{
    .title-end{
        padding-top:40px ;
        background-color: #7D69AC;

        .titre{
            font-size: 60px;
            text-align: center;
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Gobold Bold";
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: uppercase;
            margin-bottom: 24px;
        }

        .text-end{
            font-size: 24px;
            text-align: center;
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Gobold Bold";
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: 767px) {
    .sommer_end{
        .title-end{
            .titre{
                font-size: 30px;
            }

            .text-end{
                font-family: "Good Headline Pro";
                text-transform: none;
            }
        }

        .thanks{
            margin-top: -1px;
        }
    }
}