$background-color: $search-layer-back;

.search {
    &__overlay {
        
        display: none;
        position: fixed;
        top: 0;
        z-index: 10;
        background: black;
        opacity: 0.3;
        height: 100vh;
        width: 100%;
    }

    &__container {
        
        // background-color: $background-color;
        width: 100%;
        max-width: 95%;
        display: flex;
        justify-content: center;
        display: none;

        background-color: $white;
        border-radius: 10px;
        right: 0;
        margin: 0 45px 0 auto;
        z-index: 50;

        &:after {
            content: "";
            position: absolute;
            right: 17px;
            top: -15px;
            border-top: none;
            border-right: 15px solid transparent;
            border-left: 15px solid transparent;
            border-bottom: 15px solid $white;
            @include breakpoint (large) {
                right: 69px;
            }
        }

        @include breakpoint(medium) {
            display: flex;
            position: fixed;
            top: -2000px;
            padding: 15px 100px;

            transition: 0.5s;
        }

        @include breakpoint(large) {
            padding: 40px 0 15px;
        }

        @include breakpoint(start-large) {
            max-width: 1336px;
        }

        .btn__container {
            // padding-top: 8px; 
            // padding-bottom: 8px; 

            .icon-overlay-close {

                &::before {
                    font-size: 18px;
                }
            }
        }
    }

    &__content {
        
        font-family: $soft-alt;

        @include breakpoint(large) {
            width: 100%;
            max-width: 1171px;
            padding: 0 15px;
        }
    }

    &__result {
        
        margin-top: 25px;
        @include breakpoint(large) {
            max-height: 400px;
        }
        @include breakpoint(start-large) {
            max-height: 600px;
        }
        overflow-y: scroll;
        
        &-products {
            
            justify-content: space-around;

            .product__card {
                max-width: 253px;
            }

            .search-result__article-list {
                padding-right : 15px;
            }
        }

        &::-webkit-scrollbar {
            width: 5px;
            margin-left: 15px;
        }
        
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    &-result {
        &__list {
            
            justify-content: center;
            margin: 30px 0 35px;
        }
        &__more {
            
            // width: 400px;
            .btn__container {
                // padding: 13px 20px;
                background: $alpine-sky;
                color: $dark-lilac;

                font-family: $soft-alt;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;

                text-align: center;
                letter-spacing: 5px;
            }
        }
    }
}

.js-search__result {
    
    display: none;
}

@import "../../05_STATE/search/search-layer";