.buzzer-form {
    position: relative;
    margin: 50px 0;
    .btn__container {
        font-family: $good-head-pro-bold;
      }
    
      .details {
        display: flex;
        justify-content: center;
        align-items: center;
        .card-tableau {
          @include breakpoint(large) {
            margin-right: 20px;
          }
          .btn-icon {
            background-color: #fff !important;
          }
        }
    
        .match-card {
          @include breakpoint(large) {
            margin-left: 20px;
          }
        }
        .matchcard__container {
          @include breakpoint(small-only){
            margin-top: 50px;
          }
          .titre-matchcard {
            display: flex;
            color: $white !important;
            font-family: "Good Headline Pro";
            font-size: 20px;
            font-weight: bold;
            margin: 0 0 22px 0;
            justify-content: center;
          }
          .match-card {
            @include breakpoint(large) {
              height: 339px;
            }
            .match-card__bottom {
              .match-card__cta {
                display: none;
              }
            }
          }
        }
        .cardbutton-details {
          .btn-icon__fill {
            background-color: white !important;
            .btn-icon__text {
              color: $step-color;
            }
            @include breakpoint(small-only) {
              margin: 0 auto 33px auto;
            }
          }
          .btn-icon__border {
            border-color:#fff;
            position: absolute;
            @media only screen and (min-width: 1026px) {
              margin-left: 90px;
              top: -329px;
            }
            @media only screen and (min-width: 746px) and  (max-width: 1026px) {
              top: -310px;
              left: 20px;
            }
            @media only screen and (min-width: 630px) and  (max-width: 746px){
              top: -372px;
              left: 20px;
            }
            @media only screen and (min-width: 481px) and  (max-width: 630px) {
              top: -400px;
              left: 20px;
            }
            @media only screen and (min-width: 200px) and  (max-width: 480px) {
              top: -316px;
              left: 50%;
              transform: translate(-50%, 0);
            }
            .btn-icon__text {
              color:#fff;
            }
          }
        }
        @media (max-width: 1025px) {
          flex-direction: column;
        }
      }
      
      .card__validation {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .card-validation {
          background-color: #afa6d2;
          max-width: 992px;
          width: 100%;
          @media (max-width: 1025px) {
            width: 80%;
          }
          @media (max-width: 481px) {
            width: 94%;
          }
          .btn-simple {
            font-family: $good-head-pro-bold;
            padding: 14px 74px 14px 74px !important;
            @media (max-width: 1025px) {
              font-size: 18px;
            }
    
            @include breakpoint(small-only) {
              font-size: 20px;
              white-space: normal;
              padding: 25px !important;
              line-height: 19px;
              width: 100%;
            }
          }
        }
      }

      .display-desktop {
        display: flex;

        @media (max-width: 767px) {
          display: none;
        }
      }

      .display-mobile {
        display: none;

        @media (max-width: 767px) {
          display: flex;
        }
      }

    @include breakpoint(large) {
        margin: 100px 0 75px;
    }

    * {
        color: $mid-dark-lilac;
    }

    h2, h3 {
        text-align: center;
    }

    h2 {
        font-family: $good-head-pro-bold;
        @include breakpoint(large) {
            font-size: 40px;
            line-height: 46px;
        }
    }

    h3 {
        font-family: $good-head-pro;
        margin: 15px 0 43px;
        @include breakpoint(medium-down){
          margin: 15px 0 10px;
        }

        @include breakpoint(large) {
            // font-size: 30px;
            font-size: 24px;
            line-height: 32px;
        }
    }

    .part-sub{
      @include breakpoint(large) {
        font-size: 24px;
      }
    }

    h6 {
        font-size: 18px;
        line-height: 24px;

        @include breakpoint(large) {
            font-size: 20px;
            line-height: 26px;
        }

        &.centered {
            // width: 75%;
            margin: 0 auto;
            
            @include breakpoint(medium) {
                width: 75%;
            }
            & + .input-container {
                margin-bottom: 40px;
                @include breakpoint(medium) {
                    margin-bottom: 44px;
                    width: 75%;
                }
            }
        }
    }

    input, select {
        font-family: $good-head-pro;
        /* background-color: rgba(76, 53, 131, 0.1); */
    }
    #name-terms {
        margin-top: 30px !important;
      }

    .form-container {
        flex-direction: column;
        margin: 0 auto;
        width: 90%;

        @include breakpoint(large) {
            width: 40%;
        }

        .input-container, .form-input__container, .link-container, button {
            margin: 18px auto;
/*             @include breakpoint(large) {
                width: 65%;
                margin: 12px auto;
            } */
        }

        .input-container {
            .form-input__container {
                width: 100%;
                margin: 0;
            }
            .custom-inputs {
                .text-content {
                  font-family: $good-head-pro;
                }
              }
        }

        .link-container {
            padding-left: 30px;
            margin: 0px auto 20px;

            @include breakpoint(large) {
                margin: 0px auto 10px;
            }

            a {
                text-decoration: underline;
                font-family: $good-head-pro;
            }
        }

        
        #salutation {
            width: 100%;
            flex-wrap: wrap;
            align-items: flex-end;

            .form-anrede{
              font-family: $good-head-pro-regular !important;
            }

            .form-input__container {
                min-width: 155px;
                max-width: 196px;
                margin-left: 0;

                @include breakpoint(large) {
                    padding: 0 10px;
                }
            }

            p {
                min-width: 100vw;
                margin-bottom: 18px;

                @include breakpoint(large) {
                    min-width: unset;
                    order: 9;
                    width: 240px;
                    margin-bottom: 0;
                    position: relative;
                    bottom: 10px;
                    left: 15px;
                }
            }
        }

        #dob-container {
            order: 9;

            @include breakpoint(large) {
                order: unset;
            }
        }

        @include breakpoint (large) {
            #firstName, #lastName, 
            #email-container, 
            #dob-container, 
            #address-container, 
            #country-container,
            #dob-container, 
            #telephone-container {
                width: 50%;

                .form-input__container {
                    width: 100%;
                    padding: 0 10px;
                }
            }
    
            #houseNumber, #zipCode {
                width: 24%;

                .form-input__container {
                    width: 100%;
                    padding: 0 10px;
                }
            }
    
            #street, #city-container { 
                width: 26%;

                .form-input__container {
                    width: 100%;
                    padding: 0 10px;
                }
            }
        }

        .email-error-participation{
          position: relative;
          left: 10px;
        }
    }

    .under-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        padding: 0 20px;
        @media (max-width: 480px) {
          margin-bottom: 30px;
        }
        .email-label,
        .email-value {
          color: $white;
          font-size: 24px;
        }
        .email-value {
          font-family: $good-head-pro-bold;
        }
      }

    #userDetails {
        margin: 0 auto;
        width: 90%;
        
        @include breakpoint(large) {
            flex-direction: row;
            flex-wrap: wrap;
            width: 65%;
            // border: 1px solid black;
        }
    }

    .trickots {
        max-width: 600px !important;
        flex-direction: column !important;
        margin: 0 auto !important;
        .formulaire {
          /* max-width: 484px; */
          width: 100% !important;
          .form-input {
            &__container {
              input,
              label {
                font-family: $good-head-pro !important;
              }
            }
          }
        }
      }

    #companionUserDetails {
        margin: 0 auto;
        width: 90%;
        
        @include breakpoint(large) {
            flex-direction: row;
            flex-wrap: wrap;
            width: 45%;
        }

        #street {
            @include breakpoint(large) {
                width: 75%;
            }
        }

        #houseNumber {
            @include breakpoint(large) {
                width: 25%;
            }
        }

        #email-container, #telephone-container {
            width: 100%;
        }

        #zipCode {
            @include breakpoint(large) {
                width: 25%;
            }
        }

        #dob-container {
            @include breakpoint(large) {
                width: 35%;
            }
        }

        #address-container {
            @include breakpoint(large) {
                width: 65%;
            }
        }

        #city-container, #street2-container {
            @include breakpoint(large) {
                width: 75%;
            }
        }

    }

    .text-regardlessTeam {
        background-color: $mid-dark-lilac;
        color: white;
    }
}