.card-validation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $card-validation-purple;
  max-width: 790px;
  overflow: hidden;
  height: 104px;
  border-radius: 24px;
  padding: 15px;
  @media only screen and (max-width: 481px) {
    flex-direction: column;
    height: max-content;
    padding: 28px;
  }

  .btn-simple {
    color: #fff !important;
    text-transform: uppercase;
    margin: 0;
    width: auto;
    padding: 15px 50px 14px;
    display: flex;
    align-items: center;
    letter-spacing: 4px;
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;
    display: flex;
      justify-content: center;
      font-family: $good-head-pro ;

    @include breakpoint(small-only) {
        font-size: 20px;
        white-space: normal;
        padding: 20px !important;
        line-height: 19px;
        width:230px;
      }
  }

  &__text {
    color: $mid-dark-lilac;
    // font-weight: bold;
    margin-top: 0 !important;
    font-size: 24px !important;
    font-family: $good-head-pro-bold !important;
    font-family: $good-head-pro-bold !important;

    @include breakpoint(small-only) {
      font-size: 24px;
    }
    @media only screen and (max-width: 481px) {
      flex-direction: column;
      margin-bottom: 18px!important;
    }
  }
  & > * {
    margin: 0 1.25rem;
  }
}
