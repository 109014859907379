.container_firstContent {
    padding-top: 5em;
    width: 75%;
    height: auto;
    flex-shrink: 0;
    // background-color: black;
    margin: auto;
    display: flex;

    .first {
        margin: 0;
        width: 75%;
        // width: 765px;
        // background-color: aqua;
        text-align: left !important;
        padding-right: 80px;

        .titleP {
            color: #FFF !important;

            text-align: left !important;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Gobold Bold";
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            /* 60px */
            text-transform: uppercase;
        }

        .description {
            text-align: left;
            color: #FFF !important;

            font-feature-settings: 'clig' off, 'liga' off;
            /* H5 - Expressive Body Copy */
            font-family: "Good Headline Pro";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            padding-right: 6%;
            /* 33.6px */
        }

        .bold {
            color: #FFF;

            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Good Headline Pro";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            /* 33.6px */
        }

        .mini {
            vertical-align: middle;
            margin-right: 5px;
        }


    }

    .second {
        width: 25%;

        // background-color: red;
        .firstCircle {
            /* This is the base style for the .firstCircle element */
            width: 310px;
            margin-left: 20px;

            // refa kely 
            @media (max-width: 1399px) {
                margin-left: -30px;
            }
        }



    }

    /* Add Flexbox */
    @media (max-width: 768px) {
        text-align: center;

        .second,
        .first {
            order: 1;
            width: 100%;
            text-align: center !important;
            margin: auto;
            padding: 0;

            .titleP {
                text-align: center !important;
                font-size: 30px;
                padding: 15px;
            }

            .description {
                padding: 15px;
                text-align: center;
            }

            .nocenter {
                text-align: left !important;
            }
        }

        .second {
            order: 0;
            width: 100%;
            text-align: center !important;
            margin: auto;
            margin-left: -42px;

            .firstCircle {
                margin: 0;
            }
        }

        width: 100%;
        padding: 5px;
        flex-direction: column;
        align-items: center;
    }
}