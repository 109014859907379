.category-anchor {
    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 15px;
        padding: 15px 0;

        border-top: 3px double;
        border-bottom: 3px double;
        border-color: black;
    }

    &__item {
        letter-spacing: 5px;
        text-transform: uppercase;
        font-family: $soft-alt-medium;
        
        @include breakpoint(large) {
            padding: 10px 50px;
            font-size: 18px;;
        }
    }
}

@import "../../05_STATE/category-anchors/category-anchors.scss";