.video-card {
    &__play {
        &--hide {
            display: none;
        }
    }

    &__text {
        &--top {
            top: 0;
            padding-top: 25px;
        }
    }

    &--hide {
        opacity: -1;
    }
}