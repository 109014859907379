.image {
    &__container {
        position: relative;
        height: 100%;
        overflow: hidden;
        width: 100%;
        // z-index: -2;
    }

    &__img {
        z-index: -1;
        width: 100%;
        height: 100%;

        img {
            transition: 0s;

            height: auto;
            width: 100%;
            position: relative;

            // height: 100%;
            // @include object-fit('cover');

            @include breakpoint(small-only) {
                height: 100%;
                @include object-fit('cover');
            }
        }
    }

    &__text {
        color: $white;
        // height: 100%;
        width: 100%;
        position: absolute;
        top: 25%;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: flex-start;
        line-height: 1em;

        // padding-top: 30%;

        // @include breakpoint(medium) {
        //     justify-content: flex-start;
        //     padding-top: 400px;
        // }
    }

    &__title {
        font-size: 30px;
        text-align: center;
        font-family: $recol-regular;

        @include breakpoint(medium) {
            font-size: 50px;
        }

        @include breakpoint(large) {
            font-size: 54px;
        }

        span {
            font-size: 54.4px;
            text-align: center;
            font-family: $recoleta-medium;
    
            @include breakpoint(medium) {
                font-size: 70px;
            }
    
            @include breakpoint(large) {
                font-size: 75px;
            }
        }
    }
    &__subtitle {
        text-align: center;
        font-family: $recoleta-medium;
        font-size: 24px;
        padding: 0 10px;

        @include breakpoint(small) {
            font-size: 40px;
        }

        @include breakpoint(medium) {
            font-size: 70px;
        }

        @include breakpoint(large) {
            font-size: 75px;
        }
    }
}

@import "../../05_STATE/units/image";