.custom-radio-image {
    &__container {
        position: relative;
        margin-top: 15px;
        padding-left: 35px;
    }
    
    &__label {
        line-height: 19px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 9px;

            width: 20px;
            height: 20px;

            border: 1px solid $dark-lilac;
            box-sizing: border-box;
            border-radius: 100px;

            font-size: 15px;
            color: $dark-lilac;
        }

        &_content {
            font-family: $soft-alt;
            &--option {
                display: flex;
                align-items: center;
                // justify-content: space-between;

                &-image {
                    max-width: 60px;
                }

                &-name {
                    font-style: normal;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: left;
                    padding-left: 15px;
                    cursor: pointer;
                }
            }
            &--details {
                color: $dark-lilac;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    &__input:checked + &__label:before {
        border: 7px solid $dark-lilac;
    }

    &__input {
        display: none;
    }

}