.heimtrikot{
  .profile{
  
    background-position: center;
    background-repeat: no-repeat;
    // margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: normal;
  }
  
  .background {
    position: absolute;
  }
  .carousel{
    border: none !important;
    height: 350px;
    position: relative;
    overflow: visible;
  }
  
  .carousel {
    position: relative;
    width: 100%;
    height: 400px;
    -webkit-perspective: 500px;
    perspective: 2000px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    margin-left: 25px;
  }
  
  .carousel.carousel-slider {
    top: 0;
    left: 0;
  }
  .carousel.carousel-slider .carousel-fixed-item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 1;
  }
  .carousel.carousel-slider .carousel-fixed-item.with-indicators {
    bottom: 68px;
  }
  .carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .carousel.carousel-slider .carousel-item h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  .carousel.carousel-slider .carousel-item p {
    font-size: 15px;
  }
  .carousel .carousel-item {
    visibility: hidden;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .carousel .carousel-item>img {
    width: 100%}
  .carousel .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }
  .carousel .indicators .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 24px 4px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    border-radius: 50%}
  .carousel .indicators .indicator-item.active {
    background-color: #fff;
  }
  .carousel.scrolling .carousel-item .materialboxed, .carousel .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }
  .carousel-item {
      opacity: 1 !important;
      margin: -100px 0;
  
    }
    .carousel .carousel-item>img {
        width: 50%;  
        transform: scale(2.5);
    }
  .carousel .carousel-item {
    left:-13px;
    top: 200px;
    opacity: 0 !important;
    &.active {
      opacity: 1 !important;
    }
  
    &.active-left, &.active-right {
      opacity: 1 !important; // Slightly smaller than the active image
      margin-top: -130px;
    }
  
    
  }
  .carousel .carousel-item.active >img{
    transform: scale(3.5) ;
    transition: transform 0.5s ease ;
    position: relative;
    top: -30px;
  
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
  
    .profile {
        margin-top: -10px;
    }
  
    
  }
  
  
  @media only screen and (max-width: 767px) {
  
  .heimtrikot{

    .profile {
        background-image: url(/resources/imagesBundesliga/reihe/kits-carousel/background1-mobile.png);
        position: relative;
        background-size: contain;
        margin-top: -85px;
        width: auto;
  
        .carousel {
          perspective: 200px;
          position: relative;
          transform-origin: 0 50%;
          transform-style:preserve-3d;
          width: 100%;
          height: 40vh;
      }
  
      .carousel .carousel-item>img {
        width: 45vw;
        transform: scale(1);
        // height: 200px;
        // transform: scale(1.2) translate(50%, 50%);
    }
  
    .carousel .carousel-item {
        left: -10px;
        top: 120px;
        display: flex;
        align-items: center;
        margin: -90px 0;
        
    }
    .carousel .carousel-item.active >img{
        top: -20px;
        left: 10px;
    }
  }
    }
  
   
  
    
  
    .background {
      display: none;
        // display: flex;
        // position: absolute;
        // flex-direction: column;
        // width: 100%;
    }
  } 
  
  @media only screen and (max-width: 420px) {
    .profile {
      .carousel {
        perspective: 280px;
        
    }
  }
  }
}
