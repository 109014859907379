.stepcontentheimtrikot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #231141;

    h1{
        color: #FFF !important;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Gobold Bold";
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;
        margin-block: 24px;
    }

    .para{
        @media (max-width: 1000px) {
            width: 100%;
        }

        width: 1063px;
        margin-block: 20px;
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Good Headline Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .txt{
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Good Headline Pro";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-block: 20px;

        a{
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Good Headline Pro";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-decoration-line: underline;
        }
        a:hover{
            cursor: pointer;
        }

    }

    .step_content{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
        width: fit-content;
        border-radius: 25px;

        .step{
            display: flex;
            flex-direction: column;
            height: 308px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            p{
                color: #FFF;
                text-align: center;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: "Good Headline Pro";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                a{
                    text-decoration-line: underline;
                }

                .date {
                    color: #FFF !important;
                    font-family: "Good Headline Pro Bold";
                }
            } 

            img{
                margin-bottom: 27px;
            }
        }

        .one {
            background-color: #7D69AC;
            width: 340px;
            padding-inline: 32px;
            border-radius: 25px 25px 25px 25px;
        }

        .two {
            background-color: #AA9CCC;
            width: 340px;
            padding-inline: 32px;
            border-radius: 25px 25px 25px 25px;
            position: relative;
        }
        .two:after {
            width: 60px;
            height: 140px;
            content: '';
            clip-path: polygon(0 0, 0% 100%, 99% 49%);
            background-color: #7D69AC;
            position: absolute;
            top: 26%;
            left: 0px;
        }

        .three {
            background-color: #FFF;
            padding-inline: 32px;
            width: 340px;
            border-radius: 25px 25px 25px 25px;
            position: relative;
            p{
                color: var(--Milka-Lilac, #7D69AC);
            }
        }
        .three:after {
            width: 60px;
            height: 140px;
            content: '';
            clip-path: polygon(0 0, 0% 100%, 99% 49%);
            background-color: #AA9CCC;
            position: absolute;
            top: 26%;
            left: 0px;
        }
    }
}

@media (min-width:768px) and (max-width: 1024px) {
    .stepcontentheimtrikot{

        .step_content{
            flex-direction: column;

            .step {
                height: fit-content;
            }

            .one {
                padding-bottom: 40px;
                width: 500px;
            }

            .two {
                padding-top: 80px;
                padding-bottom: 40px;
                width: 500px;
            }

            .two:after {
                width: 140px;
                height: 60px;
                content: '';
                clip-path: polygon(50% 100%, 0 0, 100% 0);                
                background-color: #7D69AD;
                position: absolute;
                top: 0px;
                left: 36%;
            }

            .three {
                padding-top: 80px;
                padding-bottom: 40px;
                width: 500px;
            }

            .three:after {
                width: 140px;
                height: 60px;
                content: '';
                clip-path: polygon(50% 100%, 0 0, 100% 0);                
                background-color: #AA9CCC;
                position: absolute;
                top: 0px;
                left: 36%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .stepcontentheimtrikot{
        padding-inline: 20px;

        h1 {
            text-align: center;
            font-size: 30px;
            font-weight: 400;
            line-height: 150%;
            background-color: #231141;
            width: 100%;
            margin-block: 0px;
            padding-block: 24px;
            z-index: 2;
        }

        .para{
            width:100%;
            padding-inline: 21px;
        }

        .txt{
            font-size: 16px;

            span{
                font-size: 16px;
            }
        }

        .step_content {
            flex-direction: column;
            width: 100%;
            box-shadow: none;

            .step{

                p{
                    .date {
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-family: "Good Headline Pro Bold";
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%;
                    }
                }
            }

            .one {
                padding-inline: 50px;
                border-radius: 25px;
                width: 100%;
                height: fit-content;
                padding-block: 40px;
            }

            .two {
                padding-inline: 50px;
                margin-left: 0px;
                width: 100%;
                height: fit-content;
                padding-bottom: 20px;
                padding-top: 80px;
            }
            .two:after {
                width: 140px;
                height: 60px;
                content: '';
                clip-path: polygon(50% 100%, 0 0, 100% 0);                
                background-color: #7D69AD;
                position: absolute;
                top: 0px;
                left: 32%;
            }

            .three {
                margin-left: 0px;
                padding-inline: 50px;
                width: 100%;
                border-radius: 25px;
                height: fit-content;
                padding-top: 80px;
                padding-bottom: 20px;
            }
            .three:after {
                width: 140px;
                height: 60px;
                content: '';
                clip-path: polygon(50% 100%, 0 0, 100% 0);
                background-color: #AA9CCC;
                position: absolute;
                top: 0px;
                left: 32%;
            }
        }
    }
}