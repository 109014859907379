.sizeCard {
  display: flex;
  background-color: #e7e5f2;
  border-radius: 24px;
  padding: 35px;
  max-width: 879px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  
  
  @include breakpoint(large) {
    flex-direction: row;
    align-items: unset;
  }
  @media only screen and(max-width:768px) {
    max-width: 359px;
    padding: 22px;
  } 
  
  &__left, &__right {
    width: 100%;

    @include breakpoint(large) {
      width: 50%;
    }
  }

  &__right{
    padding: 0;
    
    @include breakpoint(large) {
      padding: 20px 0 20px 30px;
    }

    .match-list__top__club__top {
      margin-bottom: 10px;
      text-align: center;

      @include breakpoint(large) {
        text-align: unset;
      }
      @media only screen and(max-width:768px) {
        text-align: left;
      }
    }

    .match-list__top__club__bottom {
      margin-bottom: 0;
      flex-direction: row;
      justify-content: center;
      
      @include breakpoint(large) {
        margin-bottom: 29px;
        justify-content: unset;
      }
      @media only screen and(max-width:768px) {
        justify-content: left;
      }
    }

    .match-list__top__club {
      text-align: unset;
    }

    .sizeCard__right__button {
      display: flex;
      justify-content: center;

      @include breakpoint(large) {
        display: block;
      }
      @media only screen and(max-width:768px) {
        justify-content: left;
      }
    }
  }

  &__left {
    &__cta {
      button {
        color: $white;
        cursor: pointer;
        font-family:  $good-head-pro;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 15px;
        letter-spacing: 4px;
        font-weight: 700;
        padding: 0 20px !important;
        width: 100% !important;
        max-width: 314px;
        @media only screen and(max-width:768px) {
          margin-top: 0!important;
        }
      }
    }

    &__choices {
          max-width: 314px;
          margin: auto;

          .size-boxes{
            margin: 0 auto;

            button {
                border: none;
                background: none;
                color: $dark-lilac;
                font-size: 27px;
                width: calc(100%/3);
                height: 44px;
                cursor: pointer;

                display: inline-block;
                margin-left: 0;
                margin-right: 0;

                border: 2px solid #7d69ac66;
                @media only screen and(max-width:768px) {
                  margin-top: 0!important;
                }

                &:first-child {
                    border-radius: 24px 0 0 24px;
                    border-right: 1px solid #7d69ac66;
                }
                &:last-child {
                    border-left: 1px solid #7d69ac66;
                    border-radius: 0 24px 24px 0;
                }

                &.out-stock {
                    background-color: rgba(216, 216, 216, 0.2);
                    color: rgba(59, 39, 116, 0.2);
                    cursor: default;
                }
                &.selected {
                    background-color: rgba(216, 216, 216, 0.8);
                }
            }

            button.selected{
              background-color: #3B2772;
              color:#fff;
            }
        }
    }
  }

}