.card_thank-you{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 374px;
    background-color: #fff;
    border-radius: 10px;
    border: 4px solid #fff;
    overflow: hidden;

    .text{
        padding-inline: 10px;
        .title{
            text-align: left;
            color: #3B2772;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Gobold Bold";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: uppercase;
            padding-top: 20px;
            padding-bottom: 10px;
        }

        .paragraphe{
            text-align: left;
            color: #3B2772;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: "Good Headline Pro";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }

        button{
            margin-block:25px;
            width: 100%;
            text-decoration: none;
        }
    }

}

@media only screen and (max-width: 767px) {
    .card_thank-you {
        width: 353px;

        button{
            padding-inline: 20px;
        }
    }
}