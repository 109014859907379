.slick {
    &-slide {
        outline: none;

        &:focus {
            outline: none;
        }
    }

    &-dots {
        & li {
            & button {

                &:before {
                    border: 2px solid $dark-lilac;
                    height: 8px;
                    width: 8px;

                    border-radius: 50%;
                    content: "";

                    @include breakpoint (medium) {
                        border: 1px solid $dark-lilac;
                        height: 10px;
                        width: 10px;
                    }
                }
            }

            &.slick-active {
                
                & button {

                    &:before {
                        background-color: $dark-lilac;
                    }
                }
            }
        }
    }
}