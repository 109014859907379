@import "./buzzer-heading";
@import "./buzzer-footersocial";
@import "./buzzer-footer";
@import "./_buzzer-overview-slider";

main {
  margin-top: 102px;
  @media only screen and (max-width: 768px) {
    margin-top: 54px;
  }
  &.main-content.main-content--dark-lilac {
    background-image: none;
    background-color: #3b2772;
  }
}
.remindMe-container {
  margin-bottom: 140px;
  border-radius: 10px;
  @media only screen and (max-width: 481px) {
    margin-bottom: 60px;
  }
     .custom-inputs .text-content {
       font-family: $good-head-regular !important;
       font-size: 16px;
       line-height: 23px;
      font-weight: 500;
     }
    .form__container{
      
      p{
        font-family: $good-head-regular;
        font-weight: 400;
        font-size: 20px;
      }
    }
  
    .btn__container{
      font-family: $good-head-pro !important;
      font-weight: bold;
    }
  
}

@media only screen and (max-width: 650px) {
  .remindMe-container .img__container {
    order: 10;
  }
}

.content-holding__heading .campaign-heading {
 
  margin-top: 100px;
  margin-bottom: 100px;

  @include breakpoint(small-only) {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  p {
    font-family: $recol;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 32px;
    text-align: center;

    @include breakpoint(small-only) {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.content-home h1 {
  font-size: 48px;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 100px;

  @media only screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 49px;
    max-width: 263px;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 25px;
  }
}

.content-home #edeka-terms {
  @media only screen and (min-width: 1024px) {
    font-size: 24px;
  }
}
.container.medium-copy.terms {
  width: 90%;
  margin: 0 auto;
}

.card-container {
  background-color: #7d69ac;
  &__card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    padding-top: 50px;
    @include breakpoint(small-only) {
      padding-top: 0px;
      padding-bottom: 50px;
    }

    &__primary,
    &__secondary {
      border: 6px solid #f1cc74;
      border-radius: 10px;
      min-height: 528px;
      background-color: #3b2772;
      margin-top: 50px;
      padding-bottom: 50px;

      @include breakpoint(small-only) {
        margin-top: 50px;
        min-height: 480px;
      }
      h3 {
        margin-top: 25px !important;
      }
      h3,
      p {
        color: #fff;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        margin: 0 auto;
        margin-top: 15px;

        @include breakpoint(small-only) {
          margin-top: 25px;
        }
      }
    }

    &__primary {
      width: 60%;
      max-width: 820.46px;
      margin-right: 40px;

      @media only screen and (max-width: 1319px) {
        width: 100%;
        margin-right: 0px;
      }
      img {
        width: 100%;
      }
      h3 {
        font-family: $recoleta-medium;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 46px;
        text-align: center;
        width: 90%;
        margin: 0 auto;

        @include breakpoint(small-only) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      p {
        width: 80%;
      }
    }

    &__secondary {
      width: 40%;
      max-width: 468px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      img {
        margin: 0 auto;
        margin-top: 25px;
        width: 100%;
      }
      h3 {
        font-family: $recoleta-medium;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 54px;
        text-align: center;

        @include breakpoint(small-only) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      p {
        width: 90%;
      }
    }

    &__tertiary {
      width: 100%;
      max-width: 1329px;
      background: #3b2772;
      border: 6px solid #e5e5e5;
      border-radius: 10px;
      margin-top: 50px;

      display: flex;

      @media only screen and (max-width: 768px) {
        display: block;
      }

      .image-container {
        padding: 20px;
        img {
          display: block;
          margin: 0 auto;
        }
      }
      .text-container {
        padding: 20px;
        padding-top: 30px;
        h3 {
          font-family: $recoleta-medium;
          text-decoration-line: underline;
          font-size: 40px;
          line-height: 26px;
          text-align: center;
          color: #fff;

          @include breakpoint(small-only) {
            font-size: 24px;
            line-height: 32px;
          }
        }
        p {
          font-family: $soft-alt;
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          margin-top: 20px;
          color: #fff;
        }
      }
    }
  }
}

#buzzer-faqs .prizeRewe,
#buzzer-prizes .prizeRewe,
#main-buzzer .prizeRewe {
  padding: 0 !important;
  margin: 0;
  padding-top: 0 !important;
  padding-bottom: 100px !important;
  background-image: none;
  background-color: #7d69ac;

  &-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;

    &__primary,
    &__secondary {
      border: 6px solid #f1cc74;
      border-radius: 10px;
      min-height: 528px;
      background-color: #3b2772;
      margin-top: 100px;
      padding-bottom: 50px;

      @include breakpoint(small-only) {
        margin-top: 50px;
        min-height: 480px;
      }
      h3 {
        margin-top: 25px !important;
      }
      h3,
      p {
        color: #fff;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        margin: 0 auto;

        @include breakpoint(small-only) {
          margin-top: 25px;
        }
      }
    }

    &__primary {
      width: 60%;
      max-width: 686px;
      margin-right: 100px;

      @media only screen and (max-width: 1226px) {
        margin-right: 50px;
      }

      @media only screen and (max-width: 1152px) {
        margin-right: 25px;
      }

      @media only screen and (max-width: 1052px) {
        width: 100%;
        margin-right: 0;
      }
      img {
        width: 100%;
      }
      h3 {
        font-family: $recoleta-medium;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 46px;
        text-align: center;
        width: 90%;

        @include breakpoint(small-only) {
          font-size: 25px;
          line-height: 32px;
        }
      }
      p {
          @media only screen and (min-width: 1202px) {
            width: 70%;
            }
        width: 70%;
        min-width: 276px;
      }
    }

    &__secondary {
      width: 40%;
      max-width: 391px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      img {
        margin: 0 auto;
        margin-top: 25px;
      }
      h3 {
        font-family: $recoleta-medium;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 32px;
        text-align: center;

        @include breakpoint(small-only) {
          font-size: 25px;
          line-height: 32px;
        }
      }
      p {
        width: 90%;
      }
    }
  }

  .btn--lilac {
    background: #3b2772;
    border: 1px solid #ffffff;
    padding: 10px 60px 8px 60px;
    color: #fff;
    @include breakpoint(small-only) {
      padding: 10px 30px 8px 30px !important;
      line-height: 22px;
      width: 90% !important;
      height: auto;
    }
  }

  .content-separator {
    padding-top: 50px;
    h2 {
      font-family: $recoleta-medium;
      max-width: 665px;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 46px;
      text-align: center;
      color: #fff;

      @include breakpoint(small-only) {
        font-size: 30px;
        line-height: 41px;
        margin-bottom: 50px;
      }
    }

    h3.special {
      max-width: 835px;
      font-family: $recol;
      font-size: 25px;
      line-height: 32px;
      color: #ffffff;
      text-align: center;
      width: 90%;
      margin: 0 auto;
    }
  }
}

.campaign-prize-footer {
  p {
    font-size: 20px;
    line-height: 26px;
  }
}
#buzzer-prizes {
  .campaign-footer {
    @include breakpoint(small-only) {
      padding: 14px 10px 40px;
    }
  }
  .prizeRewe {
    @include breakpoint(small-only) {
      padding-bottom: 15px !important;
    }
  }
}
#buzzer-faqs {
  .faq-v1 {
    background-color: #7d69ac !important;

    .content-faq {
      background-color: #7d69ac !important;

      &__title {
        color: #fff;
        max-width: 783px;
        padding: 0 80px 30px 80px;
        h5 {
          font-family: $gobold-bold;
          font-size: 30px;
        }
      }

      &__item {
        h6 {
          font-family: $good-head-pro-bold;
        }
        border-top: 2px solid #ffffff;
        color: #fff;

        p {
          color: #fff;
        }
        ul li {
          font-family: $good-head-pro;
        }
        .content-faq__img.shown{
          top: 26px;
        }
        .item-content {
          padding: 0 0;
        }

        &-last {
          display: flex;
          max-width: 783px;
          margin: 0 auto;
          border-top: 2px solid #ffffff;
          p {
            font-size: 20px;
            font-family: $good-head-pro;
            color: #fff;
            padding-top: 26px;
            line-height: 30px;
          }
          .Verbraucherservice {
            font-size: 20px;
            font-family: $good-head-pro;
            text-decoration: underline;
          }
        }
      }
      &#tickets {
        .item-content.show {
          p {
            max-width: 590px;
          }
        }
      }
      &#trikots {
        margin-bottom:0;
        .item-content.show {
          p {
            max-width: 590px;
          }
        }
      }
    }
  }
  .content-faq {
    background-color: #3b2772;

    &__item {
      border-top: 2px solid #ffffff;
      color: #fff;
      p {
        color: #fff;
      }
    }
    &__item:last-child {
      border-bottom: 2px solid #ffffff;
    }
  }
}

.s-content-end {
  @include breakpoint(small-only) {
    margin: 40px auto;
  }

  h1 {
    font-size: 60px;
    line-height: 65px;
    @include breakpoint(small-only) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  h2 {
    font-family: $recol;
    font-size: 40px;
    line-height: 46px;
    max-width: 848px;
    margin: 0 auto;

    @include breakpoint(small-only) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

#game-buzzer,
#buzzer-confirmation {
  margin-top: 0;
  .content-home {
    margin-bottom: 0;
    padding: 0;
  }
  .content-game {
    position: relative;
    &__copy {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      @media only screen and (max-width: 1023px) {
        position: initial;
        height: auto;
        background-color: #000;
      }
    }
    &__text {
      width: 60%;
      float: right;
      overflow-wrap: break-word;
      margin-top: 15%;

      @media only screen and (max-width: 1139px) {
        margin-top: 15%;
      }

      @media only screen and (max-width: 1023px) {
        width: 100%;
        float: none;
        margin-top: 0;
        padding-bottom: 100px;
        padding-top: 50px;
      }

      h1 {
        padding-top: 0;
        margin: 0 auto;
        font-family: $soft-alt;
        font-style: normal;
        font-weight: bold;
        font-size: 54px;
        line-height: 65px;
        text-align: center;
        text-transform: uppercase;
        color: #f1cc74;
        max-width: 654px;
      }
      h2 {
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 25px;
        font-family: $soft-alt;
        font-style: normal;
        font-weight: bold;
        font-size: 37.9182px;
        line-height: 46px;
        text-align: center;
        color: #ffffff;
      }
      p {
        margin: 0 auto;
        font-family: $soft-alt;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        max-width: 536px;
      }

      @media only screen and (max-width: 1199px) {
        h1 {
          font-size: 35px;
        }
        h2 {
          font-size: 25px;
        }
        p {
          font-size: 20px;
        }
      }

      @include breakpoint(small-only) {
        h1 {
          font-size: 24px;
          line-height: 29px;
          max-width: 350px;
        }

        h2 {
          font-size: 20.834px;
          line-height: 25px;
          max-width: 250px;
        }
        p {
          font-size: 13.1867px;
          line-height: 16px;
          max-width: 350px;
        }
      }
    }
  }
}

.content-participation {
  &__login {
    background-color: #fff;
    max-width: 601px;
    margin: 0 auto;
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 25px;
    margin-bottom: 100px;
    margin-top: 100px;

    @include breakpoint(small-only) {
      margin-bottom: 0;
      margin-top: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .buzzer-form {
      margin: 0;
      #frm_login .form-container{
          h3{
           @include breakpoint(large){
            font-size: 24px;
          }
          font-size: 20px;
          }
      }
    }

    .form-container {
      width: 100%;
      .input-container {
        max-width: 391px;
        @include breakpoint(small-only) {
          width: 90%;
        }
      }
      .link-container {
        width: 70%;
        @include breakpoint(small-only) {
          width: 90%;
        }
      }
      .btn--secondary-lilac {
        @include breakpoint(small-only) {
          width: 90%;
        }
      }
    }
  }

  &__form {
    max-width: none;
    border-radius: 0;
    background-color: #3b2772;

    #participant-info {
      @include breakpoint(small-only) {
        margin-bottom: 20px;
      }
    }
    .buzzer-form {
      @include breakpoint(small-only) {
        margin-top: 0;
      }

      h2,
      h3,
      p {
        color: #ffffff;
      }

      .form-container .date-error-participation{
        margin-top: 15px;
      }
    }
    #text-underTeam {
      text-align: left;
      @include breakpoint(small-only) {
        width: 90%;
      }
    }
    #phone {
      order: 9;
    }
    #dob-container.desktop {
      .form-container {
        max-width: 450px;
      }
      .checkmark {
        right: 0;
        left: auto;
      }
      .custom-inputs {
        padding-right: 30px;
        padding-left: 0;
        .text-content {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    #dob-container {
      order: 8;
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
        margin-top: 60px ;
      }
      .form-container {
        width: 100%;
      }
      .input-container {
        width: 95%;
        @include breakpoint(small-only) {
          width: 100%;
        }
      }
      label {
        @include breakpoint(small-only) {
          left: 3px !important;
        }
      }
      .custom-inputs {
        @include breakpoint(small-only) {
          padding-left: 22px;
        }

        .text-content {
          color: #fff;
          font-size: 16px;
          line-height: 22px;
        }
        .checkmark {
          color: #fff;
          background-color: #fff;
        }
      }
    }

    .team-retailer__form {
      display: block;

      .team-container__form,
      .retailer-container__form {
        width: 100%;
        float: none;
        max-width: 393px;
        margin: 0 auto;
        margin-top: 70px;
        margin-bottom: 20px;
      }
      .team-container__form {
        @include breakpoint(small-only) {
          margin-bottom: 50px;
        }
      }
      .retailer-container__form {
        @include breakpoint(small-only) {
          margin-top: 40px;
        }
      }
    }

    .campaign-consent {
      .text-content,
      .buzzer-tcs {
        color: #fff;
        font-family: $good-head-pro;
      }
      .checkmark:after{
      
        color: #3B2772;
      }
      .checkmark {
        border: 1px solid #fff;
        background-color: #fff;
      }
    }
    .campaign-consent{
      #name-member {
        @include breakpoint(small-only){
          margin:12px 0px 22px 0px; 
        }
        .input-container .custom-inputs .checkmark {
          @include breakpoint(medium) {
            top: -5px
          }
        }
        }
    }

    .btn--secondary-lilac {
      border: 1px solid #ffffff;
    }

    .campaign-form-receipt {
      padding-bottom: 60px;

      @include breakpoint(small-only) {
        width: 90%;
        margin: 0 auto;
      }

      h3 {
        font-size: 40px;
        line-height: 32px;

        @include breakpoint(small-only) {
          font-size: 30px;
          line-height: 32px;
        }
      }
      img {
        margin: 0 auto;
        display: block;
      }
      .info-receipt {
        font-size: 16px;
        line-height: 20px;
        max-width: 308px;
        margin: 0 auto;
        text-align: center;

        // @media only screen and (max-width: 768px) {
        //     display: none;
        // }
      }

      #file_to_upload {
        display: none;
      }
      .btn--secondary-lilac {
        span {
          color: #fff;
        }
      }
    }
  }
}

#buzzer-participation {
  margin-top: 100px;
  @include breakpoint(small-only) {
    margin-top: 0;
  }

  .buzzer-tcs,
  #moreInfo {
    color: #fff;
    &.buzzer-info{
      position: relative;
      left: 55px;
      bottom: 20px;
      font-family: $good-head-pro;
    }
  }

  p.special {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 150px;
    letter-spacing: normal;
    a {
      text-decoration: underline;
      font-family: $good-head-pro;
    }
    font-family: $good-head-pro;
    @include breakpoint(small-only){
      margin-bottom: 20px;
    }
  }

  .chooseTeam {
    background-color: #fff;
    max-width: none;
   

    .content-swiper {
      display: flex;
      max-width: 1440px;
      &-image{
        position: relative;
        top: 4px;
        width: 40%;
        @include breakpoint(medium-down){
          width: 50%;
        }
        img{
          position: absolute;
          bottom: 0px;
          max-height: 670px;
          @include breakpoint(medium-down){
            max-height: 400px;
            bottom: 4px;
          }
          @include breakpoint(custom-medium-swiper){
            max-height: 120%;
          }
        }
      }
      @include breakpoint(start-large)
        {
          justify-content: space-around;
          margin: 0 50px;
        }
      &-container{
        width: 60%;
        h3{
          visibility: hidden;
          span{
            font-weight: bold;
            font-family: $good-head-pro-bold;
            text-transform: uppercase;
          }
        }
      }
    }

    h2.heading {
      padding-bottom: 50px;
      padding-top: 50px;
      font-family: $gobold-bold;
      @include breakpoint(small-only){
        padding-bottom: 20px;
      }
    }

    &--team-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
   
      @include breakpoint(medium-swiper){
          flex-direction: column;
            align-items: center;
      }
      .team {
        padding: 20px 5px;
        width: 170px;
        @include breakpoint(medium-swiper){
          width: 112px;
        }
        border-radius: 10px;
        margin: 5px;
        cursor: pointer;

        &-logo {
          margin-bottom: 20px;
          img {
            height: 90px;
            width: auto;
            display: block;
            margin: 0 auto;
          }
        }
        p {
          font-family: $soft-alt;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
        }
      }
      .team.active,
      .team:hover {
        background-color: #dddaed;
        text-decoration: none;
        p {
          //color: #fff;
        }
      }
      .team.active {
        border: 4px solid #3b2772;
        p{
          font-family:$good-head-pro;
        }
      }
    }
    #favorite-team-error {
      color: #d93333;
      display: none;
      text-align: center;
      margin-top: 50px;
    }
    .swiper-container {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      padding-bottom: 50px;
      @include breakpoint(medium-swiper){
        padding-bottom: 0px;
      }
    }
    .swiper-pagination {
      display: none;
    }
  }

  .chooseTeam * {
    color: #3b2772;
  }
  .js-file-success {
    color: #fff;
  }
  .info-receipt.notvalid {
    color: #d93333;
  }
}
#buzzer-participation .content-participation__form{
  form {
    .buzzer-form{
      h2{
        font-family:$gobold-bold;
      }
      .form-container .input-container p{
        font-family:$good-head-pro;
      }
    }
    .team-retailer__form{
      .chooseTeam{
        .content-swiper{
          .content-swiper-container{
            .team{
              p{
                font-family: $good-head-pro;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  input:label{
    font-family:  $good-head-pro;
  }
  }
}

#buzzer-participation .content-participation__login{
  .buzzer-form .form-container .input-container .form-input__container{
    .form-input__label{
      font-family: $good-head-pro-regular;
    }
    .form-input__input{
      &::placeholder{
        font-family: $good-head-pro-regular;
      }
      font-family: $good-head-pro-regular;
    }
  }
}

#main-participation {
  margin-top: 0;
  @include breakpoint(small-only) {
    margin-top: 0;
  }

  .buzzer-tcs,
  #moreInfo {
    color: #fff;
  }

  p.special {
    max-width: 882px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 150px;
    a {
      text-decoration: underline;
    }
  }

  .chooseTeam {
    background-color: #fff;
    max-width: none;
    padding-bottom: 50px;

    h2.heading {
      padding-bottom: 50px;
      padding-top: 50px;
      font-family: $gobold-bold;
    }

    &--team-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      .team {
        padding: 5px;
        width: 175px;
        border-radius: 10px;
        margin: 5px;
        cursor: pointer;

        @include breakpoint(small-only) {
          width: 130px;
        }

        &-logo {
          margin-bottom: 20px;
          img {
            height: 90px;
            width: auto;
            display: block;
            margin: 0 auto;
          }
        }
        p {
          font-family: $soft-alt;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
        }
      }
      .team.active,
      .team:hover {
        background-color: #c9c9c9;
        text-decoration: none;
        p {
          //color: #fff;
        }
      }
    }
    #favorite-team-error {
      color: #d93333;
      display: none;
      text-align: center;
      margin-top: 50px;
    }
    .swiper-container {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }
    .swiper-pagination {
      display: none;
    }
  }

  .chooseTeam * {
    color: #3b2772;
  }
  .js-file-success {
    color: #fff;
  }
  .info-receipt.notvalid {
    color: #d93333;
  }
}
#passWordReg {
  h4 {
    color: #fff;
  }
  .form-input__input {
    border: 1px solid #ffffff;
    background-color: #fff;
  }
  .form-input__input.notvalid {
    border: 1px solid #d93333;
  }
}

.team-retailer__form .retailer-container__form h6.centered {
  color: #fff;
  width: 96%;
  font-family: $good-head-pro-bold;

  
}
.team-retailer__form .retailer-container__form{
  .centered.gekauft{
    @include breakpoint(large) {
        margin-top: 80px;
      }
  }
}

.team-retailer__form .team-container__form h2.heading {
  font-family: $gobold-bold;
  font-size: 40px;
  line-height: 46px;

  @include breakpoint(small-only) {
    font-size: 30px;
    line-height: 34px;
  }
}

#buzzer-participation .space-bottom .full-width {
  width: 100%;
}

#main-participation .space-bottom .full-width {
  width: 100%;
}

.buzzer-form .text-regardlessTeam {
  background-color: #7d69ac;
}

.buzzer-form h6.centered + .input-container {
  width: 100%;
}

#buzzer-participation {
  hr.separator {
    border: none;
    height: 1px;
    background-color: #afa6d2;
  }
  #salutation {
    .form-input__dropdown:after {
      right: 26px;
    }
  }
}

#main-participation {
  hr.separator {
    border: none;
    height: 1px;
    background-color: #afa6d2;
  }
  #salutation {
    .form-input__dropdown:after {
      right: 26px;
    }
  }
}

.main-content--secondary-lilac {
  background-color: #7d69ac;
}

#game-buzzer {
  .content-home {
    position: relative;
    #c-animation {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }

  #confetti-canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 450px;
  }
  .gif-animation-container {
    display: none;
  }

  .content-game.loser {
    .content-game__text {
      margin-top: 15%;

      h1 {
        font-size: 35px;
        line-height: 40px;
      }
      h2 {
        font-size: 30px;
        line-height: 30px;
        max-width: 400px;
      }
      p {
        font-size: 20px;
      }

      @media only screen and (max-width: 1199px) {
        margin-top: 2%;
        h1 {
          font-size: 35px;
        }
        h2 {
          font-size: 25px;
        }
        p {
          font-size: 20px;
        }
      }

      @media only screen and (max-width: 1023px) {
        margin-top: 0;
      }

      @include breakpoint(small-only) {
        h1 {
          font-size: 24px;
          line-height: 29px;
          max-width: 350px;
        }

        h2 {
          font-size: 20.834px;
          line-height: 25px;
          max-width: 250px;
        }
        p {
          font-size: 13.1867px;
          line-height: 16px;
          max-width: 350px;
        }
      }
    }
  }
  .chooseMatch-section{
    margin-top: -65px;
    background-color: $step-color;
    .s-content-title{
      margin-top: 65px;
      padding-top: 43px;
      color: #ffffff;
      .heading__v1 {
          padding-bottom: 18px;
          @media only screen and (min-width: 1025px) {
            max-width: 597px;
          }
      }
      .paragraph__v1{
        @media only screen and (min-width: 1025px) {
          max-width: 580px;
        }
        @media only screen and  (max-width: 481px) {
          font-size: 20px;
          max-width: 580px;
        }
      }
    }   
  }
  .listMatch-section{
    .buzzer-desktop{
      background-color: white;
      .match-container{
        span{
          font-family: $good-head-pro;
          font-weight: 400;
          font-size: 24px;
          line-height: 33.6px;
        }
        .date{
          width: 30%;
        }
        .match{
          width: 70%;
          .team-container{
            .separator{
              width: 80%;
              margin: 0 auto;
            }
          }
        }
      }
    }
    .mobile{
      // @media only screen and (max-width: 1024px) {
        display: block !important;
      // }
    }
  }
  .listMatch-bouton{
    display: flex;
    justify-content: center;
    margin: -60px auto 60px auto;

    &__disabled{
      opacity: 0.5;
      pointer-events: none !important;
    }
    .btn-icon__fill{
      width: 230px;
      height: 44px;
      background-color: #7D69AC;
      text-align: center;
      justify-content: center;

      .btn-icon__text{
        font-family: $good-head-pro;
        font-weight: 700;
        font-size: 20px;
        line-height: 15px;
        letter-spacing: 4px;
        text-transform: uppercase;
      }
    }
    .btn-icon__border{
      border: 2px solid white;
    }
  }
}

.buzzer-choose{
  background-color: #7D69AC;
}

.content-holding__heading {
  background-color: $dark-lilac-annoucement-background ;
  .campaign-heading {
    h2 {
      font-size: 30px;
      line-height: 32px;
      max-width: 764px;
      margin: 0 auto;
      @media only screen and (min-width: 481px) {
        font-size: 60px;
        line-height: 65px;
        max-width: 1028px;
      }
    }
    .content-holding__p {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 0;
      font-family: "Intervogue Soft Alt Regular";
    }
    p {
      font-size: 20px;
      line-height: 26px;
      font-family: $soft-alt;
      @media only screen and (min-width: 481px) {
        font-family: $recoleta;
        font-size: 25px;
        line-height: 32px;
        max-width: 832px;
      }
    }

    @include breakpoint(small-only) {
      h2 {
        font-size: 30px;
        line-height: 32px;
      }
      .content-holding__p {
        line-height: 32px;
      }
    }
  }
  .content-home{
    .spaceTile-intropage{
      .s-content-title{
        .heading__v1 {
          margin-top: 25px;
            @include breakpoint(large) {
                width: 706px;
              }

          }
        .paragraph__v1{
          margin: 0px auto 38px auto;
          @include breakpoint(large) {
            width: 784px;
            }
        }
        .paragraph-two__v1 {
          margin: 0px auto 38px auto;
          @include breakpoint(large) {
              width: 783px;
            }
        }
      }
    }
  }
}

.remindMe-container .checkmark {
  height: 28px;
  width: 28px;
  border-radius: 9px;
}
.remindMe-container .form-container {
  @media only screen and (min-width: 481px) {
    width: 95%;
    margin-bottom: 45px;
  }
}

.buzzer-netto.kaufland {
  background-color: unset;
}

.kaufland {
  @media only screen and (min-width: 768px) {
    .content-home {
      padding: 0;
    }
  }
  .campaign-heading {
    margin-top: 0;
    padding: 0;
  }
  .title-container {
    background-color: #7d69ac;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
    @include breakpoint(small-only) {
      padding-top: 50px;
    }
    h2 {
      font-size: 40px;
      line-height: 46px;
      max-width: 669px;
      margin-bottom: 0px;
      .tablet-up {
        margin-bottom: -3px;
        @include breakpoint(small-only) {
          display: inline-block !important;
        }
      }

      @include breakpoint(small-only) {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 0px;
        max-width: 312px;
      }
    }
    h4 {
      font-family: $soft-alt-thin;
      font-size: 20px;
      line-height: 26px;
    }
  }
  .step-container {
    padding-top: 100px;
    padding-bottom: 100px;

    @include breakpoint(small-only) {
      padding-bottom: 25px;
      padding-top: 25px;
    }
    .step-title {
      font-size: 48px;
      line-height: 32px;
      font-family: $recoleta-medium;
      margin: 0 auto;
      margin-bottom: 60px;
      @include breakpoint(small-only) {
        font-size: 36px;
        line-height: 49px;
        margin-bottom: 25px;
        max-width: 263px;
      }
    }

    .btn--secondary-lilac {
      margin-top: 50px;
    }
    .s-content-steps__item {
      p {
        margin-top: 15px;
        width: 85%;
      }
    }
    &--terms-link {
      font-size: 24px;
      line-height: 22px;
      text-align: center;
      margin: 0 auto;
      margin-top: 25px;
      width: 90% !important;
      @include breakpoint(small-only) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  .prizeRewe {
    background-image: none;
    background-color: #3b2772;

    h2 {
      max-width: 665px;
      font-size: 40px;
      line-height: 46px;
    }
    h3 {
      font-size: 25px;
      line-height: 32px;
      max-width: 835px;

      @include breakpoint(small-only) {
        max-width: 317px;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
    .terms-link {
      margin-top: 50px;
      font-family: $soft-alt;
      font-size: 24px;
      line-height: 22px;

      @include breakpoint(small-only) {
        line-height: 29px;
        margin-top: 20px;
      }
    }
    .terms-faq {
      margin-top: 20px;
      margin-bottom: 50px;
      font-family: $soft-alt;
      font-size: 20px;
      line-height: 22px;
      @include breakpoint(small-only) {
        margin-bottom: 50px;
      }
    }
    .terms-title {
      font-family: $recoleta-medium;
      font-size: 40px;
      line-height: 46px;
      margin-bottom: 25px;
      @include breakpoint(small-only) {
        font-size: 30px;
      }
    }
    .terms-subtitle {
      font-family: $soft-alt-medium;
      font-size: 24px;
      line-height: 29px;
      max-width: 826px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px;
      @include breakpoint(small-only) {
        font-size: 20px;
        line-height: 24px;
      }
      a {
        text-decoration: underline;
      }
    }
    .instruction {
      font-family: $soft-alt;
      font-size: 16px;
      line-height: 20px;
      max-width: 882px;
      text-align: left;
      margin-bottom: 15px;
      @include breakpoint(small-only) {
        text-align: center;
      }
    }
  }

  .prizeRewe * {
    color: #fff;
  }

  .card-container {
    &__card {
      .card {
        margin: 100px auto;
        margin-bottom: 0;
        background-color: #3b2772;
        border: 5.45166px solid #f1cc74;
        border-radius: 9.08609px;
        max-width: 960.4px;
        display: flex;

        @media only screen and (max-width: 768px) {
          display: block;
        }
        h3 {
          font-family: $recoleta-medium;
          font-size: 32.7099px;
          line-height: 44px;
          color: #fff;

          @include breakpoint(small-only) {
            font-size: 36px;
            line-height: 49px;
            max-width: 292px;
            margin: 0 auto;
            text-align: center;
          }
        }
        p {
          font-family: $soft-alt;
          font-size: 18.1722px;
          line-height: 24px;
          color: #fff;
          max-width: 464.3px;
          margin-top: 10px;
          @include breakpoint(small-only) {
            text-align: center;
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
      .card--image {
        width: 50%;
        img {
          width: 100%;
        }
      }
      .card--text {
        margin-top: 20%;
        width: 50%;
        padding-right: 20px;
        padding-left: 20px;
      }
      @media only screen and (max-width: 768px) {
        .card--image {
          width: 100%;
        }
        .card--text {
          width: 90%;
          margin: 0 auto;
          padding-right: 0px;
          padding-bottom: 50px;
          padding-left: 0;
        }
      }
    }
  }

  .s-content-end {
    h1 {
      font-size: 40px;
      line-height: 46px;
      max-width: 848px;

      @include breakpoint(small-only) {
        font-size: 30px;
        line-height: 32px;
      }
    }
  }
}

.remindMe-container .email-sent {
  @media only screen and (min-width: 769px) {
    margin-top: -40px;
  }
  margin: 0;
  max-width: 451px;
  text-align: left;
}

.rewe {
  .campaign-heading,
  .title-container {
    .tablet-up {
      width: auto !important;
      margin-bottom: -5px !important;
      margin-top: 0 !important;
    }
  }
  .card-container {
    &__card {
      &__primary {
        img {
          max-width: 659px;
          display: block;
          margin: 0 auto;
        }
      }
      &__secondary {
        img {
          max-width: 286px;
          display: block;
          margin: 0 auto;
        }

        h3 {
          font-size: 30px;
          line-height: 32px;

          @include breakpoint(small-only) {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }
  .prizeRewe {
    .instruction {
      text-align: center;
    }
    .instruction.link {
      margin: 0 auto;
    }
  }
}
.penny {
  .title-container {
    h2 {
      max-width: 341px;
      @media only screen and (min-width: 481px) {
        font-size: 60px;
        line-height: 65px;
        max-width: 821px;
      }
      .tablet-up {
        margin-bottom: -15px;
        width: 100px;
        // @include breakpoint(small-only) {
        //   margin-bottom: -14px;
        // }
      }
    }
    h4 {
      font-family: $soft;
      font-size: 20px;
      line-height: 26px;
      margin: 16px auto 10px !important;
      @media only screen and (min-width: 481px) {
        font-family: $recol;
        font-size: 25px;
        line-height: 32px;
        max-width: 784px;
        padding: 0;
        margin: 23px auto 40px !important;
      }
    }
  }
  .step-container {
    .step-title {
      @media only screen and (max-width: 481px) {
        max-width: 433px;
        margin-top: 20px;
        margin-bottom: 0;
      }
      @media only screen and (min-width: 481px) {
        font-size: 40px;
      }
    }
    .s-content-steps__item {
      picture img {
        width: auto;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        @media only screen and (min-width: 481px) {
          max-width: 194px;
        }
      }
      &.fourth {
        @media only screen and (min-width: 481px) {
          max-width: 190px;
        }
      }
    }
    .btn__container {
      border: 1px solid #ffffff;
    }
    .step-container--terms-link {
      font-size: 20px;
      margin-top: 25px;
      @media only screen and (max-width: 481px) {
        margin-bottom: 30px;
      }
    }
  }
  .card-container {
    &__card__primary {
      h3 {
        max-width: 544px;

        @include breakpoint(small-only) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    &__card__secondary {
      img {
        display: block;
        margin: 0 auto;
        width: auto;
        margin-top: 25px;
      }
      h3 {
        font-size: 30px;
        line-height: 32px;
        max-width: 328px;
        margin: 0 auto;
        @include breakpoint(small-only) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
  .prizeRewe {
    .instruction {
      text-align: center;
    }
  }
  .prizeRewe.campaign-footer {
    background-color: #7d69ac;
    .btn__container {
      border: 1px solid #ffffff;
      border-radius: 22px;
      @media only screen and (max-width: 481px) {
        height: 90px;
        margin-top: 100px;
      }

      &::before {
        background-color: #3b2772;
      }
      &:hover {
        background-image: none;
        background-color: #3b2772;
      }
      a {
        text-transform: uppercase;
        line-height: 24px;
      }
      @media only screen and (min-width: 481px) {
        padding: 10px 50px 8px 50px;
      }
      @media only screen and (min-width: 1025px) {
        padding: 10px 50px 8px 50px;
      }
    }
  }
}

.netto {
  .title-container {
    h2 {
      .tablet-up {
        margin-bottom: -32px;
        @include breakpoint(small-only) {
          margin-bottom: -6px;
          width: 57px;
        }
      }
    }
  }
}

.remindMe-container .email-sent {
  text-align: left;
  max-width: 451px;
  margin: 0;
  margin-top: -50px;
  margin-left: 20px;

  @media only screen and (max-width: 662px) {
    margin-top: 0px;
  }
}

.email-buzzer23{
  margin-top: auto !important;
}

.remindMe-container.sent {
  min-height: 400px;
}

/* all-action overview*/
.all-action-page {
  background-color: #7d69ac;

  .campaign-heading {
    margin: 0px 0 35px;
    /* padding-top: 48px; */
    @media only screen and (min-width: 481px) {
      margin: 0px 0 57px;
      padding: 0;
      /* padding-top: 80px; */
    }
    h2 {
      font-family: $gobold-bold;
      font-size: 30px;
      line-height: 45px;
      font-weight: 400;
      @media only screen and (min-width: 481px) {
        font-size: 40px;
        line-height: 56px;
      }
    }
  }

  .promo-content {
    display: flex;
    max-width: 1060px;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 120px;
    @media only screen and (max-width: 979px) {
      justify-content: center;
    }
    @media only screen and (min-width: 481px) {
      padding-top: 130px;
    }
  }
  .promo__wrapper {
    margin-inline:20px;
    display: flex;
    /* margin: 0 auto; */
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 481px) {
      max-width: 1080px;
      justify-content: center;
    }
/*     @media only screen and (min-width: 1000px) {
      flex-direction: row;
    } */
    .promo-title {
      margin: 0px 0 35px;
      @media only screen and (min-width: 481px) {
        margin: 0px 0 26px;
        padding: 0;
      }
      h2 {
        font-family: $gobold-bold;
        font-size: 30px;
        line-height: 45px;
        font-weight: 400;
        @media only screen and (min-width: 481px) {
          font-size: 40px;
          line-height: 56px;
        }
      }
    }
    .promo__container {
      color: #fff;
      border-radius: 6px;
      margin-bottom: 42px;
      width: 343px;
      // height: 470px;
      @media only screen and (min-width: 481px) {
        width: 490px;
        // height: 630px;
        margin-bottom: 90px;
      }
      @media only screen and (max-width: 513px) and (min-width: 481px) {
        width: 90%;
        // height: 650px;
      }
      .win-price__content {
        padding: 0;
        text-align: center;
        margin: 0 13px;
        @media only screen and (min-width: 481px) {
          padding: 0;
          margin: 0 22px;
        }

        .teaser-link {
          font-family: $good-head-regular;
          font-size: 20px;
          line-height: 15px;
          button {
            margin-top: -115px !important;

            @media (max-width: 767px) {
              margin-top: -90px !important;
            }
          }
        }

        .teaser-text{
          @media (max-width: 767px) {
            font-size: 30px;
          }
          color: #FFF;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: "Gobold Bold";
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-transform: uppercase;
          
        }

        .teaser-date{
          @media (max-width: 767px) {
            font-size: 50px;
          }
          color: #FFF;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: "Gobold Bold";
          font-size: 60px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          text-transform: uppercase;
        }

        h2 {
          font-family: $gobold-bold;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 13px;
          @media only screen and (min-width: 481px) {
            margin-bottom: 10px;
            line-height: 42px;
            font-size: 30px;
          }
        }
        P {
          font-family: $good-head-regular;
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 21px;
          @media only screen and (min-width: 481px) {
            margin-bottom: 25px;
          }
        }
        .btn__container.btn--secondary-dark {
          background: #7d69ac;
          border: 2px solid #ffffff;
          border-radius: 22px;
          letter-spacing: 4px;
          text-transform: uppercase;
          width: 278px;
          height: 44px;
          margin-top: 24px;
          @media only screen and (min-width: 481px) {
            width: 349px;
            height: 44px;
            margin-top: 20px;
          }
          &::before {
            background-color: unset;
          }
          a {
            font-family: $good-head-regular;
            font-size: 20px;
            line-height: 15px;
          }
        }
        
      }
    }
  }

  .football-section {
    border-bottom: 4px solid #f1cc74;
    padding: 50px 0;

    @media (min-width: 992px) {
      padding: 100px 0;
    }

    &__card {
      margin: 0 auto;
      background-color: #ffffff;
      width: 90%;
      border-radius: 6px;
      padding: 5px;
      color: #3b2772;
      max-width: 345px;
      display: flex;
      flex-flow: column-reverse wrap;

      @media (min-width: 992px) {
        max-width: 981px;
        min-height: 365px;
        display: flex;
        flex-flow: row nowrap;
      }

      &__text {
        width: 100%;
        padding: 5px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 992px) {
          width: 50%;
          padding: 30px;
        }

        &__wrapper {
          max-width: 342px;
        }

        &__title {
          font-family: $gobold-bold;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;
        }

        &__description {
          font-family: $good-head-regular;
          margin-top: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
        }

        .btn__container {
          font-family: $good-head-regular;
          width: 100%;
          display: block;
          margin-top: 24px;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 15px;
        }
      }

      &__image {
        width: 100%;
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media (min-width: 992px) {
          width: 50%;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
.overview-header__container {
  /*   background-image: url(/resources/imagesBundesliga/intropage/overview-header.png);
  background-size: cover;
  display: flex;
  align-items: center; */
  position: relative;
}
.overview-header__container + .banner__container .image__container {
  display: none;
  @media only screen and (max-width: 480px) {
    padding-bottom: 0;
  }
}
.overview-header__img {
  @media only screen and (min-width: 1024px) {
    border-bottom: 4px solid #f1cc74;
  }
  .banner__image {
    width: 100%;
  }
}
.overview-header__content {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: #fff;
  text-align: center;
  width: 342px;
  @media only screen and (min-width: 481px) {
    width: 80%;
  }
  @media only screen and (min-width: 800px) {
    width: 100%;
  }
}
.overview-header__title {
  font-family: "Good Head W01 Bold";
  //color: #f1cc74;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 6px;
  line-height: 48px;
  @media only screen and (min-width: 481px) {
    font-size: 72px;
    line-height: 56px;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 820px) {
    margin-bottom: 35px;
  }
  @media only screen and (min-width: 1440px) {
    font-size: 5vw;
  }
}
.overview-header__subtitle {
  max-width: 706px;
  font-family: "Good Head W01 Medium";
  font-size: 20px;
  line-height: 30px;
  @media only screen and (min-width: 481px) {
    margin: 0 auto;
    font-size: 24px;
    line-height: 1.2;
    max-width: 786px;
  }
  @media only screen and (min-width: 1440px) {
    font-size: 1.6vw;
  }
}

.win-price__wrapper {
  background-color: #7d69ac;
  /* height: 542px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  @media only screen and (max-width: 980px) {
    height: auto;
  }
}
.win-price__container {
  display: flex;
  width: 100%;
  /* max-width: 1230px; */
  justify-content: space-between;
  margin: 0 auto;
  @media only screen and (max-width: 980px) {
    flex-flow: column;
    /* padding: 10% 0; */
  }
  @media only screen and (max-width: 480px) {
    padding: 0 0 10%;
  }
  .win-price__picture {
    /* border-bottom: 4px solid #f1cc74; */
    @media only screen and (max-width: 480px) {
      border-top: none;
    }
    @media only screen and (min-width: 768px) {
      /* border: 4px solid #000; */
      border-radius: 8px;
    }
    @media only screen and (min-width: 1400px) {
      width: 60%;
    }

    /*     @media only screen and (min-width:800px) and (max-width: 980px) {
      width: 65%;
      margin: 0 auto;
      margin-top: 33px;
    }  */
    img {
      width: 100%;
      display: block;
    }
  }
  .win-price__content {
    /* max-width: 526px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Recoleta Regular";

    align-content: center;
    align-items: center;
    margin: 0 auto;
    /*     @media only screen and (min-width: 981px) { 
      margin-left: 10px;
      margin-right: 100px;
    } */
    @media only screen and (max-width: 980px) {
      /* width: 30%; */
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 481px) {
      margin-top: 20px;
      margin-bottom: 0;
    }
    @media only screen and (min-width: 980px) {
    }
    @media only screen and (min-width: 980px) and (max-width: 1400px) {
      margin-left: 0;
      margin-right: 30px;
    }
    h2,
    p {
      text-align: center;
      font-family: $gobold-bold;
      color: #fff;
      @media only screen and (max-width: 980px) {
        text-align: center;
      }
    }
    h2 {
      font-size: 40px;
      line-height: 88px;
      @media only screen and (max-width: 480px) {
        font-size: 30px;
        line-height: 42px;
        /* max-width: 267px; */
        margin: 0 auto;
        margin-bottom: 8px;
      }
    }
    p {
      font-family: $good-head-regular;
      font-size: 24px;
      line-height: 34px;
      max-width: 454px;
      @media only screen and (max-width: 480px) {
        font-size: 19px;
        line-height: 28px;
        max-width: 361px;
        margin: 0 auto;
      }
    }
    .btn__container {
      margin: 0 auto;
      margin-top: 20px;
      width: 400px;
      font-family: $good-head-regular;
      font-size: 19px;
      font-weight: bold;
      background-color: #3b2772;
      letter-spacing: 4px;
      padding: 10px 17px 8px 17px;
      @media only screen and (max-width: 480px) {
        width: 310px;
        margin: 0 auto;
        margin-top: 30px;
        font-size: 19px;
      }
      &::before {
        background-color: #3b2772;
      }
    }
  }
}

.milka-bg {
  background-image: url(/resources/imagesBundesliga/intropage/bg-milka-icon.png);
  background-size: 280%;
  @media only screen and (min-width: 481px) {
    background-size: cover;
  }
}
.milka-member {
  border-top: 4px solid #f1cc74;
  border-bottom: 4px solid #f1cc74;
  display: flex;
  @media screen and (max-width: 850px) {
    flex-flow: column;
  }
  .member-item__wrapper {
    flex: 1;
    align-self: center;
    @media only screen and (min-width: 850px) {
      align-self: flex-end;
    }
    &:first-child {
      .member__item {
        @media only screen and (max-width: 481px) {
          margin-bottom: 70px;
          margin-top: 20px;
        }
      }
      .btn__container {
        letter-spacing: 4px;
        font-family: $good-head-regular;
        font-size: 19px;
        font-weight: bold;
        line-height: 15px;
        padding: 10px 17px 8px 17px;
      }
    }
  }
  .member__item {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 70px;
    margin-top: 70px;
    color: #fff;
    @media only screen and (max-width: 481px) {
      margin-bottom: 60px;
      margin-top: 50px;
    }
    @media only screen and (min-width: 850px) {
      width: 70%;
    }
  }
  .member__item h2 {
    font-family: $gobold-bold;
    font-size: 30px;
    margin-top: 23px;
    margin-bottom: 14px;
    @media only screen and (min-width: 481px) {
      font-size: 40px;
      line-height: 56px;
    }
  }
  .member__item p {
    font-family: $good-head-regular;
    font-size: 18px;
    line-height: 1.3;
    @media only screen and (min-width: 481px) {
      font-size: 22px;
    }
  }
  .member__image {
    width: 236px;
    margin: 0 auto;
    &.member__image-secondary {
      @media only screen and (min-width: 481px) {
        width: 100%;
        max-width: 444px;
      }
      @media only screen and (min-width: 1441px) {
        width: auto;
      }
    }
  }
  .seperator {
    border: 2px solid #f1cc74;
  }
  .btn__container {
    font-family: $good-head-regular;
    margin-top: 20px;
    width: 310px;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 4px;
    padding: 10px 17px 8px 17px;
    text-transform: uppercase;
    width: 350px;
    @media only screen and (min-width: 481px) {
      font-size: 19px;
      letter-spacing: 4px;
      margin-top: 30px;
      min-width: max-content;
      padding: 10px 17px 8px 17px;
    }
    @media only screen and (min-width: 481px) and (max-width: 850px) {
      width: 379px;
    }
    @media only screen and (min-width: 1200px) {
      width: 379px;
    }
    background-color: #3b2772;
    &::before {
      background-color: #3b2772;
    }
  }
}

.overview-fairplay-banner {
  padding-bottom: 0;
  background-color: #7d69ac;
  @media only screen and (max-width: 480px) {
    padding-bottom: 20px;
  }
  .image__container {
    @media only screen and (min-width: 480px) {
      padding: 7% 14%;
    }
    @media only screen and (max-width: 480px) {
      padding: 5% 6%;
    }
    .form-button.form-button-main {
      @media only screen and (min-width: 1025px) {
        bottom: 2%;
        right: 0%;
      }
      @media only screen and (max-width: 1025px) {
        display: none;
      }
      @media only screen and (min-width: 1300px) {
        bottom: 2%;
        right: 3%;
      }
      /*       button{
        @media only screen and (min-width: 1200px) {
          min-width: 364px;
        }
      } */
    }
  }
  .btn__container.btn--secondary-dark {
    display: none;
    font-family: $good-head-regular;
    font-size: 19px;
    font-weight: bold;
    line-height: 15px;
    padding: 10px 17px 8px 17px;
    letter-spacing: 4px;
    @media only screen and (max-width: 480px) {
      display: block;
      width: 350px;
      margin-bottom: 0;
    }
    &::before {
      background-color: #3b2772;
    }
  }
}

.footer__container--links {
  a {
    text-decoration: none !important;
    font-family: $good-head-pro-bold;
    font-size: 18px;
    line-height: 21.6px;
    letter-spacing: 3px;
  }
}

.footer__legal {
  a {
    font-family: $good-head-pro-bold !important;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1px;
  }
}

.footer__copyright{
  h4{
    font-family: $good-head-pro;
    font-weight: 400;
    line-height: 24px;
  }
}

.overview-spacer {
  height: 170px;
  background-color: #7d69ac;
  border-top: 3px solid #f1cc74;
  border-bottom: 3px solid #f1cc74;
  @media only screen and (min-width: 480px) {
    height: 233px;
  }
  .btn__container {
    margin-top: 20px;
    width: 310px;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 5px;
    padding: 10px 15px 8px 15px;
    @media only screen and (min-width: 481px) {
      margin-top: 30px;
    }
    @media only screen and (min-width: 481px) {
      display: none;
    }

    background-color: #3b2772;
    &::before {
      background-color: #3b2772;
    }
  }
}

#buzzer-video {
  width: 100%;
  height: auto;
  z-index: 10;
  cursor: pointer;
  display: block;
}
.content-game__copy {
  cursor: pointer;
  .content-game__text {
    cursor: default;
  }
}

#buzzer-error {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #7d69ac;
}
.container.medium-copy.terms {
  padding-top: 100px;
  @include breakpoint(small-only) {
    padding-top: 50px;
  }
}

.image__container.terms {
  @include breakpoint(small-only) {
    padding-bottom: 0px;
  }
}

.edeka {
  .title-container h2 .tablet-up {
    margin-bottom: -7px;
  }
}

.rewe-banner {
  position: absolute;
  z-index: 5;
  width: 40%;
  height: 100%;
  bottom: 0;
  right: 0;
  text-align: center;
  @media only screen and (max-width: 1023px) {
    display: none !important;
  }

  p {
    font-family: $recol;
    color: #3b2774;
    font-weight: 400;
    font-size: 19.841px;
    line-height: 27px;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 85%;
    a {
      text-decoration: underline;
    }
  }
}
.rewe-banner.mobile {
  display: block !important;
  position: absolute;
  width: 100%;
  height: auto;
  @media only screen and (min-width: 1024px) {
    display: none !important;
  }
  p {
    margin-top: 0;
    margin-bottom: 12%;
    @include breakpoint(small-only) {
      font-size: 13.1638px;
      line-height: 18px;
    }
  }
}
#buzzer-error {
  .s-content-title {
    h1 {
      @include breakpoint(small-only) {
        font-size: 25px;
      }
    }
  }
}
.s-content-steps__item {
  p {
    strong {
      font-weight: bold !important;
      font-family: $soft-alt-bold !important;
      span {
        font-weight: bold !important;
        font-family: $soft-alt-bold !important;
      }
    }
  }
}
.terms {
  .s-content-title {
    h1 {
      @include breakpoint(small-only) {
        font-size: 25px;
      }
    }
  }
}
.partner-logo__container {
  display: inline-flex;
  margin: 0 auto;

  @keyframes fade-in {
      from {
        opacity: 0;
      }
  
      to {
        opacity: 1;
      }
    }
  
  
    .fading {
      animation: fade-in 1.5s ease-in-out;
    }

  .active{
    display: block;
  }
  .not-active{
    display: none;
  }
  &.Q4-header-logo {
    a {
      display: flex;
      align-items: center;
    }
    picture {
      display: flex;
      align-items: center;
      img.partner-logo.first {
        @media only screen and (max-width: 939px) {
          margin-right: 9px;
        }
      }
      &.milka-logo {
        margin-left: 20px;
        @media only screen and (max-width: 939px) {
          display: none;
        }
        @media only screen and (max-width: 768px) {
          display: flex;
        }
        @media only screen and (max-width: 536px) {
          display: none;
        }
      }
      &.oreo-logo {
        margin-right: 30px;
        @media only screen and (max-width: 939px) {
          display: none;
        }
        @media only screen and (max-width: 768px) {
          display: flex;
        }
        @media only screen and (max-width: 536px) {
          display: none;
        }
        img {
          @media only screen and (max-width: 768px) {
            width: 39px;
          }
        }
      }
    }
  }
}

.globus .card-container__card__primary {
  margin-right: 0;
}

#frm_user_details_ticket {
  .custom-inputs {
    .text-content,
    a,
    .checkmark:after {
      color: #fff !important;
    }
    .checkmark {
      border: 1px solid #fff;
    }
    &.notvalid .checkmark {
      border: 1px solid #d93333;
    }
  }
  span.information {
    color: #fff;
  }
}
.buzzer-jersey div button {
  width: 80%;
}

.overview-slider-wrapper {
  position: relative;
  background-color: #7d69ac;
  color: #fff;
  border-top: 4px solid #f1cc74;
  @media only screen and (max-width: 481px) {
    padding: 50px 0 30px 0;
  }
  @media only screen and (min-width: 481px) {
    padding: 80px 90px;
  }
  h2 {
    font-size: 25px;
    font-family: $gobold-bold;
    text-align: center;
    padding-bottom: 30px;
    color: #fff;
    @media only screen and (min-width: 481px) {
      font-size: 40px;
      line-height: 46px;
      padding-bottom: 50px;
    }
  }
  .swiper-container {
    position: initial;
    @media only screen and (max-width: 481px) {
      width: 260px;
    }
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      width: 350px;
    }
    @media only screen and (min-width: 1234px) {
      width: 1060px;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;
    top: 35%;
    background-color: #3b2772;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    z-index: 1;
    @media only screen and (min-width: 450px) {
      top: 46%;
    }
    /*     @media only screen and (min-width: 620px) {
      top: 30%;
    }
    @media only screen and (min-width: 768px) {
      top: 40%;
    }
    @media only screen and (min-width: 1280px) {
      top: 50%;
    } */
    &:after {
      font-size: 16px;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  .swiper-button-next {
    right: 30px;
    @media only screen and (min-width: 481px) {
      right: 30px;
    }
    /*     @media only screen and (min-width: 1249px) {
      display: none;
    } */
    &:after {
      padding-left: 3px;
    }
  }
  .swiper-button-prev {
    left: 20px;
    @media only screen and (min-width: 481px) {
      left: 30px;
    }
    /*     @media only screen and (min-width: 1249px) {
      display: none;
    } */
    &:after {
      padding-right: 3px;
    }
  }
  .swiper-pagination.swiper-pagination-bullets {
    top: 193px;
    bottom: 0;
    z-index: 2;
    display: none;
    .swiper-pagination-bullet {
      background: #fff;
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      background-color: #3b2772;
      border-radius: 8px;
      max-width: 336px;
      &.swiper-slide-active {
        z-index: 999;
        display: flex;
        flex-flow: row wrap;
        @media only screen and (min-width: 1200px) {
          display: unset;
          flex-flow: unset;
        }
      }
    }
    .Slider__left__wrapper__video {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      max-width: 100%;
    }

    //For fading effect
    /*     .swiper-slide:not(.swiper-slide-active) {
      opacity: 0 !important;
      z-index: 0 !important;
    } */

    .Slider__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: start;
      height: 438px;
      max-height: 519px;
      @media (min-width: 1280px) {
        flex-direction: row;
      }
      @media (max-width: 481px) {
        height: 369px;
      }
      .Slider__left {
        position: relative;
        flex: 1 1 0;
        align-items: center;
        width: 100%;
        @media (min-width: 1280px) {
          margin: 3.75rem 3.125rem 3.75rem 7.8125rem;
        }
        .Slider__left__wrapper {
          position: relative;
          padding-top: 56.25%;
          overflow: hidden;
          line-height: 0;
          .Slider__left__wrapper__picture {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
          }
          .Slider__left__wrapper__video {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            max-width: 100%;
          }
          .Slider__left__wrapper__overlay {
            font-size: 3.4375rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            padding: 0;
            margin: 0;
            font-family: inherit;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            &:before {
              /* opacity: 0.5; */
              display: inline-flex;
              content: "";
              /*               background-image: url($local-images + "/icons/triangle-right.svg");
              background-size: 250px 210px;
              height: 200px;
              width: 260px; */
              background-image: url("/resources/imagesBundesliga/fairplay/polygon.png");
              background-repeat: no-repeat;
              background-position: 10px 14px;
              height: 90px;
              background-size: 54px 70px;
              width: 76px;
              @media (min-width: 768px) {
                background-position: 26px 26px;
                background-size: initial;
                height: 139px;
                width: 118px;
              }
            }
          }
        }
      }
      .Slider__right {
        /* padding: 40px; */
        flex: 1 1 0;
        /* align-self: center; */
        width: 100%;
        color: #fff;
        height: 519px;
        max-height: 519px;
        @media (max-width: 481px) {
          height: max-content;
          max-height: max-content;
        }
        @media (min-width: 1280px) {
          /* padding: 60px; */
          padding-top: 0;
          /* margin: 6.25rem 7.8125rem 1.25rem 0; */
        }
        @media (min-width: 1400px) {
          width: 75rem;
          padding-right: 0;
          padding-left: 0;
        }
        .image__container {
          /* max-height: 168px; */
          max-height: 136px;
          max-width: 248px;
          border-top-right-radius: 8px;
          cursor: pointer;
          /* background: #000;
          opacity: 0.9; */
          @media (min-width: 480px) {
            /* max-height: 233px; */
            max-height: 186px;
            max-width: 336px;
          }
          &::before {
            /* opacity: 0.5; */
            display: inline-flex;
            content: "";
            background-image: url("/resources/imagesBundesliga/intropage/caroussel/preview_play.png");
            background-repeat: no-repeat;
            background-position: center;
            height: 56px;
            background-size: 56px 56px;
            width: 56px;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(40%, -41%);
            @media only screen and (min-width: 768px) {
              background-position: center;
              background-size: initial;
              height: 56px;
              width: 56px;
              transform: translate(50%, -50%);
            }
          }
          .banner__image {
            width: 248px;
            @media (min-width: 480px) {
              width: 336px;
            }
          }
          .Slider__left__wrapper__overlay {
            font-size: 3.4375rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            padding: 0;
            margin: 0;
            font-family: inherit;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            &:before {
              /* opacity: 0.5; */
              display: inline-flex;
              content: "";
              /*               background-image: url($local-images + "/icons/triangle-right.svg");
              background-size: 250px 210px;
              height: 200px;
              width: 260px; */
              background-image: url("/resources/imagesBundesliga/fairplay/polygon.png");
              background-repeat: no-repeat;
              background-position: 10px 14px;
              height: 90px;
              background-size: 54px 70px;
              width: 76px;
              @media (min-width: 768px) {
                background-position: 26px 26px;
                background-size: initial;
                height: 139px;
                width: 118px;
              }
            }
          }
        }

        .Slider__excerpt {
          color: #fff;
          padding: 40px;
          padding-top: 20px;
          @media (max-width: 481px) {
            padding: 30px;
            padding-top: 20px;
            padding-bottom: 50px;
          }
          &:first-child {
            margin-top: 0;
          }
          .article-headline {
            font-family: $good-head-regular;
            color: #fff;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            @media (max-width: 481px) {
              /* max-width: 160px; */
              margin: 0 auto;
            }
            @media (min-width: 481px) {
              font-size: 18px;
              line-height: 28px;
            }
            span {
              font-family: "Good Head W01 Bold";
              font-weight: bold;
            }
          }
          p {
            position: relative;
            display: block;
            text-align: right;
            margin: 0;
            font-family: DFL, sans-serif;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 700;
            color: #fff;
            letter-spacing: 1.1px;
            padding-top: 15px;
            @media (min-width: 1024px) {
              font-size: 2rem;
            }
            @media (min-width: 1440px) {
              font-size: 2.25rem;
            }
          }
          p.headline {
            text-transform: uppercase;
            padding-top: 0;
            text-align: initial;
            padding-bottom: 15px;
            font-size: 29 / 16 * 1rem;
          }
          .Slider__excerpt :first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.penny {
  .content-holding__heading .campaign-heading {
    p {
      font-family: $recol;
      @media only screen and (max-width: 480px) {
        line-height: 32px;
      }
    }
  }
  .remindMe-container {
    @media only screen and (max-width: 480px) {
      margin-bottom: 160px;
    }
    p {
      @media only screen and (max-width: 480px) {
        text-align: center;
        font-size: 20px;
        margin-bottom: 29px;
      }
    }
    .form-container {
      @media only screen and (max-width: 480px) {
        margin-bottom: 60px;
      }
    }
  }
}

#buzzer-participation.penny-participation {
  .penny-participation {
    .s-content-steps {
      margin-bottom: 85px;
    }
    .s-content-steps__item {
      min-width: 250px;
      @media only screen and (max-width: 481px) {
        padding-bottom: 0;
      }
      picture img {
        width: auto;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        margin: 0 auto;
        margin-top: 30px;
        max-width: 194px;
      }
      &.fourth {
        min-width: 260px;
        @media only screen and (min-width: 481px) {
          max-width: 190px;
        }
        p {
          @media only screen and (max-width: 481px) {
            max-width: 260px;
          }
        }
      }
    }
    .btn__container {
      @media only screen and (max-width: 481px) {
        margin-top: 0px;
      }
    }
  }

  .content-participation__login {
    @media only screen and (max-width: 481px) {
      margin: 0 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .form-container {
      h2 {
        @media only screen and (max-width: 481px) {
          font-size: 28px;
        }
      }
      h3 {
        @media only screen and (max-width: 481px) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .content-participation__form {
    @media only screen and (max-width: 481px) {
      margin-top: 60px;
    }
    #participant-info.buzzer-form {
      @media only screen and (max-width: 481px) {
        margin-bottom: 30px;
      }

      @media only screen and (min-width: 1025px) {
        margin: 100px 0 155px;
      }

      h2 {
        @media only screen and (max-width: 481px) {
          font-size: 30px;
          line-height: 32px;
        }
      }
      h3 {
        @media only screen and (max-width: 481px) {
          font-family: $recol;
          font-size: 22px;
          max-width: 344px;
          line-height: 32px;
          margin: 0 auto;
          margin-top: 20px;
          margin-bottom: 46px;
        }
      }
    }

    .team-retailer__form .campaign-form-receipt h3 {
      @media only screen and (max-width: 481px) {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 29px;
      }
    }

    .retailer-container__form .form-container {
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .campaign-form-receipt {
      @media only screen and (max-width: 481px) {
        padding-bottom: 30px;
      }
    }
    .campaign-form-receipt .info-receipt {
      @media only screen and (max-width: 481px) {
        margin-top: 14px;
      }
    }

    .campaign-form-receipt .btn__container span {
      letter-spacing: 5px;
      font-size: 20px;
    }

    .campaign-consent {
      @media only screen and (min-width: 481px) {
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
      }
      
    }
    .campaign-consent .form-container {
      margin-bottom: 6px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 0;
        padding-bottom: 10px;
      }
    }
    .campaign-consent .form-container #error-terms {
      @media only screen and (max-width: 481px) {
        margin-top: 10px;
      }
    }
    .campaign-consent #moreInfo {
      margin-left: 50px;
    }

    p.special {
      @media only screen and (max-width: 481px) {
        margin-bottom: 40px;
      }
    }

    #dob-container {
      .form-container {
        @media only screen and (min-width: 481px) {
          max-width: initial;
        }
        .input-container {
          @media only screen and (min-width: 481px) {
            margin-top: 12px;
            margin-bottom: 0;
          }
          .custom-inputs {
            @media only screen and (min-width: 481px) {
              padding-right: 0;
              padding-left: 50px;
              padding-top: 4px;
            }
            .checkmark {
              @media only screen and (min-width: 481px) {
                right: auto;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
  h6.centered {
    width: 100%;
    @media only screen and (max-width: 481px) {
      font-size: 22px;
    }
    &:last-of-type {
      margin-bottom: 30px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 20px;
      }
    }
  }

  #name-brand,
  #name-product-min-price {
    .input-container {
      width: 100%;
      margin-bottom: 20px;
    }
    .text-content {
      color: #fff;
      font-size: 15px;
      line-height: 19px;
    }
    .checkmark {
      color: #fff;
      border: 1px solid #fff;
      &:after {
        color: #fff;
      }
    }
  }
  #name-brand {
    .text-content {
      padding-left: 10px;
    }
    .checkmark {
      top: -7px;
    }
  }
}

#main-participation.penny-participation {
  .penny-participation {
    .s-content-steps {
      margin-bottom: 85px;
    }
    .s-content-steps__item {
      min-width: 250px;
      @media only screen and (max-width: 481px) {
        padding-bottom: 0;
      }
      picture img {
        width: auto;
      }
      p {
        font-size: 20px;
        line-height: 24px;
        margin: 0 auto;
        margin-top: 30px;
        max-width: 194px;
      }
      &.fourth {
        min-width: 260px;
        @media only screen and (min-width: 481px) {
          max-width: 190px;
        }
        p {
          @media only screen and (max-width: 481px) {
            max-width: 260px;
          }
        }
      }
    }
    .btn__container {
      @media only screen and (max-width: 481px) {
        margin-top: 0px;
      }
    }
  }

  .content-participation__login {
    @media only screen and (max-width: 481px) {
      margin: 0 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .form-container {
      h2 {
        @media only screen and (max-width: 481px) {
          font-size: 28px;
        }
      }
      h3 {
        @media only screen and (max-width: 481px) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .content-participation__form {
    @media only screen and (max-width: 481px) {
      margin-top: 60px;
    }
    #participant-info.buzzer-form {
      @media only screen and (max-width: 481px) {
        margin-bottom: 30px;
      }

      @media only screen and (min-width: 1025px) {
        margin: 100px 0 155px;
      }

      h2 {
        @media only screen and (max-width: 481px) {
          font-size: 30px;
          line-height: 32px;
        }
      }
      h3 {
        @media only screen and (max-width: 481px) {
          font-family: $recol;
          font-size: 22px;
          max-width: 344px;
          line-height: 32px;
          margin: 0 auto;
          margin-top: 20px;
          margin-bottom: 46px;
        }
      }
    }

    .team-retailer__form .campaign-form-receipt h3 {
      @media only screen and (max-width: 481px) {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 29px;
      }
    }

    .retailer-container__form .form-container {
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .campaign-form-receipt {
      @media only screen and (max-width: 481px) {
        padding-bottom: 30px;
      }
    }
    .campaign-form-receipt .info-receipt {
      @media only screen and (max-width: 481px) {
        margin-top: 14px;
      }
    }

    .campaign-form-receipt .btn__container span {
      letter-spacing: 5px;
      font-size: 20px;
    }

    .campaign-consent {
      @media only screen and (min-width: 481px) {
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
      }
    }
    .campaign-consent .form-container {
      margin-bottom: 6px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 0;
        padding-bottom: 10px;
      }
    }
    .campaign-consent .form-container #error-terms {
      @media only screen and (max-width: 481px) {
        margin-top: 10px;
      }
    }
    .campaign-consent #moreInfo {
      margin-left: 50px;
    }

    p.special {
      @media only screen and (max-width: 481px) {
        margin-bottom: 40px;
      }
    }

    #dob-container {
      .form-container {
        @media only screen and (min-width: 481px) {
          max-width: initial;
        }
        .input-container {
          @media only screen and (min-width: 481px) {
            margin-top: 12px;
            margin-bottom: 0;
          }
          .custom-inputs {
            @media only screen and (min-width: 481px) {
              padding-right: 0;
              padding-left: 50px;
              padding-top: 4px;
            }
            .checkmark {
              @media only screen and (min-width: 481px) {
                right: auto;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
  h6.centered {
    width: 100%;
    @media only screen and (max-width: 481px) {
      font-size: 22px;
    }
    &:last-of-type {
      margin-bottom: 30px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 20px;
      }
    }
  }

  #name-brand,
  #name-product-min-price {
    .input-container {
      width: 100%;
      margin-bottom: 20px;
    }
    .text-content {
      color: #fff;
      font-size: 15px;
      line-height: 19px;
    }
    .checkmark {
      color: #fff;
      border: 1px solid #fff;
      &:after {
        color: #fff;
      }
    }
  }
  #name-brand {
    .text-content {
      padding-left: 10px;
    }
    .checkmark {
      top: -7px;
    }
  }
}

#buzzer-faqs.penny-faqs {
  .content-faq {
    padding: 35px 25px 50px 25px;
    @media only screen and (max-width: 481px) {
      margin-bottom: 0;
    }
    .content-faq__item {
      @media only screen and (max-width: 481px) {
        padding-bottom: 0;
      }
    }
  }
  .prizeRewe {
    @media only screen and (max-width: 481px) {
      padding-bottom: 40px !important;
    }
  }
  .content-separator {
    margin-top: 0;
  }
  .content-separator h2 {
    @media only screen and (max-width: 481px) {
      max-width: 300px;
      margin-bottom: 25px;
    }
  }
}

.penny-terms__container {
  margin-top: 51px;
  @media only screen and (min-width: 481px) {
    margin-top: 120px;
  }
  * {
    color: #fff;
  }
  .s-content-copy {
    padding: 0 20px;
    @media only screen and (min-width: 481px) {
      padding: 0 100px;
    }
    @media only screen and (max-width: 481px) {
      padding-bottom: 70px;
    }
  }
  .s-content-copy__item {
    width: initial;
    padding-bottom: 5px;
    p {
      font-size: 14px;
      @media only screen and (min-width: 481px) {
        font-size: 20px;
      }
    }
    .headings {
      font-size: 14px;
      @media only screen and (min-width: 481px) {
        font-size: 20px;
      }
      &.title {
        padding-bottom: 45px;
        @media only screen and (min-width: 481px) {
          padding-bottom: 15px;
        }
      }
      &.sub {
        line-height: 26px;
        font-weight: normal;
        margin-bottom: 33px;
      }
      &.mob {
        @media only screen and (max-width: 481px) {
          padding-bottom: 0;
          margin-bottom: 13px;
        }
      }
    }
  }
}

.penny-confirmation {
  .confirmation-wrapper {
    margin-top: 60px;
    @media only screen and (min-width: 481px) {
      margin-top: 100px;
    }
    .confirmation-text {
      max-width: 845px;
      h1 {
        font-family: "Recoleta";
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 14px;
        @media only screen and (min-width: 481px) {
          font-size: 60px;
          line-height: 65px;
        }
      }
      p {
        font-family: "Recoleta";
        font-size: 22px;
        line-height: 28px;
        @media only screen and (min-width: 481px) {
          font-size: 40px;
          line-height: 46px;
        }
      }
    }
  }
}
#main-buzzer{
  .content-home.endpage-rewe{
    padding: 0px;
    .cta-container {
      @include breakpoint(large){
          padding-top: 160px;
        }
      .btn__container {
        font-family: $good-head-pro !important;
        font-weight: bold;
      }
    .text-section{
    
        margin: 0 auto;
        font-family: $good-head-pro;
        line-height: 36px;
        margin-bottom: 24px;
        @include breakpoint(start-medium){
            max-width: 598px;
            
        }
        @include breakpoint(small-only){
          padding: 16px;
        }
    }
  }
      .edeka-terms{
        font-family: $good-head-pro;
        font-size: 18px;
      }
      
        .s-content-title{
          height: 138px;
         .heading__v1{


          @include breakpoint(large) {
            font-size: 40px !important;
          }
        
      }
    }
    .mitmachen-heading{
    
        .text-section-heading{
          &__paragraph{
            font-size: 20px;
          }
          @media only screen and (max-width: 481px) {
              width: 100%; 
             padding: 15px 10px;
            }
        }
       
        
    
    }
    .spaceTile-intropage.campaign-heading{
      .heading__v1{
        @media only screen and (max-width: 481px) {
          font-size: 30px;
          padding-top: 15px;
        }
        @media only screen and (max-width: 768px)  {
          .s-content-title .heading__v1{
            font-size: 30px;
          }
        }
      }
    }
  }
  .prizeRewe.campaign-footer{
    .s-content-title{
      width: 100% !important;
      padding-bottom: 41px;
      .heading__v1{
         color:white !important;
         padding-top: 50px;  
          width: auto;

      @media only screen and (max-width: 768px) {
          font-size: 30px;
        }
         @include breakpoint(large){
          width:625px ;
          
         }
        @media only screen and (max-width: 481px) {
          .s-content-title {
           width: 100%; 
        }
      }
            @include breakpoint(small) {
              min-width: unset;
            }
      }
    }
    .prizeRewe-card{
      
      @include breakpoint(large){
          margin-top: -87px;  
      }
      @media screen  and (max-width:992px) and (min-width:480px) {
        margin-top:-150px;
      }
      @media screen and(max-width:480px) {
        margin-top: -85px;
      }

      &__primary,
      &__secondary{
        h3{
          font-family: $gobold-bold;
          font-size: 24px;
        }
        p{
          font-family: $good-head-pro;
          font-size: 20px;
        }
      }
      &__primary{
        max-width: 670px;
        @media screen and (min-width:992px) { 
          margin-right: 42px;
        }
        
      }
      &__secondary{
        max-width: 423px;
      }
     
    }
  }
}
#game-buzzer-page {
@mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
  }

  @mixin keyframes($name) {
    @keyframes #{$name} {
      @content;
    }
  }
  background: #7d69ac;
  background: linear-gradient(180deg, #7d69ac 72%, #655198 88%);
  .buzzer-title-section {
    margin-top: -65px;

    .s-content-title {
      margin-top: 65px;
      padding-top: 43px;
      color: #ffffff;

      .heading__v1 {
       

        padding-bottom: 20px; 
        @media only screen and (min-width: 1025px) {
               padding-bottom: 20px; 
               max-width: 90vw;
        }
        @media screen and (min-width: 375px) {
          margin: 0 auto;
          min-width: unset;
      }
      }

      .paragraph__v1 {
        @media only screen and (min-width: 1025px) {
          max-width: 580px;
        }
        @media only screen and (max-width: 481px) {
          font-size: 20px;
        }
      }

    }
    
  }
  .buzzer-game-section {
   
    // align-items: flex-end;
    // padding: 0 30px;
    
    @include breakpoint(large){
      margin: -155px auto 31px auto;
      display: flex;
      justify-content: center;
      width: 90%;
      max-width: 1250px;
      padding-bottom: 60px;
    }
        // margin: 0 auto;
        // max-width: 940px;
        min-width: 302px;
        // width: 60%;
        margin-top: 70px;
        width: 100%;

     
    &__buzzer-man{
           
        @include keyframes(fade) {
            0% {
              opacity: 1;
            }
        
            50% {
              opacity: 0.5;
            }
        
            100% {
              opacity: 0;
            }
          }
      @include breakpoint(large) {

        flex:0 0 40%;
      .rudi {
          position: absolute;
          width: 100%;
          max-width: 425px;
          cursor: pointer;
          min-width: 392px;
          bottom: 129px;
        }
      
          .drucke {
            max-width: 242px;
              position: relative;
              display: block;
              left: 102px;
              top: 420px;
              right: 0;
              width: 25vw;
              min-width: 140px;
          }
          .drucke-not-visible {
            visibility: hidden;
            max-width: 242px;
              position: relative;
              display: block;
              left: 102px;
              top: 425px;
              right: 0;
              width: 25vw;
              min-width: 140px;
          }

        .drucke-hide {
          max-width: 242px;
            position: relative;
            display: block;
            left: 102px;
          top: 425px;
            right: 0;
            width: 25vw;
            min-width: 140px;
          @include animate(fade, 1s, linear, 1);
        }
      }
      @media screen  and (max-width: 1024px) {
        flex: 0 0 100%;
        max-height: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgb(125, 105, 172);
        background: linear-gradient(180deg, rgba(125, 105, 172, 1) 60%, rgba(101, 81, 152, 1) 94%);
        .rudi {
          position: relative;
            width: 100%;
            cursor: pointer;
            width: auto;
            height: 350px;
          }
          .drucke-hide {
            max-width: 139px;
            position: relative;
            display: block;
            left: 6px;
            bottom: 238px;
            right: 0;
            width: 100%;
            @include animate(fade, 1s, linear, 1);
          }
          .drucke {
          max-width: 139px;
            position: relative;
            display: block;
            left: 6px;
            bottom: 238px;
            right: 0;
            width: 100%;
          }
          .drucke-not-visible {
            visibility: hidden;
            max-width: 139px;
            position: relative;
            display: block;
            left: 6px;
            bottom: 238px;
            right: 0;
            width: 100%;
          }
      } 
      

    }
    &__game-only{
      flex:0 0 100%;
      @include breakpoint(large) {
        flex:0 0 60%;
        padding-top: 188px;
      }
      @media screen and (max-width: 1024px) {
      // margin: -2px 0px;
        margin: -2px 0px 27px 0px;
      min-width: 310px;
      display: flex;
      flex-direction: column;
      align-items: center;
      }
      
      &> .buzzer-generated-score,
      .buzzer-generated-score.generated,
      .buzzer-generated-score.select,
      .buzzer-generated-score.selected
      {
        margin: 20px 0;
        justify-content: end;
        @media screen and (max-width: 1024px) {
          margin:7px 0;
        }
      }
      &>.buzzer-game-button-section {
        margin: 17px 0 0 0;
        justify-content: end;
      }
      &>.buzzer-game-text-section{
        margin:10px 0 0 0;
        justify-content: end; 
      }
     
      .buzzer-game-button-section {
        display: flex;
        max-width: 520px;
        font-family: $good-head-pro;

        .buzzer-game-button-section__number {
          &__arrow{         
                width: 50px;
                @media screen and (max-width: 1024px){
                  width: 33px;
                    position: relative;
                    top: 10px;
                }
          }
        }

        .buzzer-game-button-section__details {
          max-width: 420px;
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          min-width: 312px;
          flex-wrap: wrap;
            @media screen and (max-width: 1024px) {
              max-width: 316px;
            }

          .btn__container.btn--lilac{
            height: 48px;
            width: 97%;
            background-color: $step-color;
            border:2px solid $white;
            text-transform: uppercase;
            font-family: $good-head-pro;
            font-size: 20px ;
            letter-spacing: 4px;
            line-height: 15px;
            font-weight: bold;
            @media screen and (max-width: 1024px) {
              height: 44px;
              padding: 8px 0px 8px 0px;
              }
          }
          .btn__container.btn--lilac.disabled{
              border: 2px solid #C7C7C7;
              color:#C7C7C7;
          }
         
            &-img img{
              max-width: 364px;
              @media screen and (max-width: 1024px) {
                  max-width: 321px;
                }
              @include keyframes(fadeinTwo) {
                  0% {
                    opacity: 0.2;
                  }
              
                  50% {
                    opacity: 0.7;
                  }
              
                  100% {
                    opacity: 1;
                  }
                }
              
                @include animate(fadeinTwo, 0.5s, ease-in, 1);
            }
          &-img-not-visible img {
            visibility: hidden;
            @include breakpoint(large) {
              max-width: 364px;
            }

            @media screen and (max-width: 1024px) {
              max-width: 321px;
            }
          }
          .buzzer-error{
            margin:10px 0;
          }
        }
      }
      .buzzer-game-text-section {
        display: flex;
        max-width: 520px;
        font-family: $good-head-pro;

        .buzzer-game-button-section__number {
        }

        .buzzer-game-button-section__details {
          max-width: 420px;
          width: 82%;
          display: flex;
          align-items: center;
          justify-content: space-around;  
          @media screen and (max-width: 1024px) {
            max-width: 312px;
            width: 100%;
            min-width: 310px;

          }
            .buzzer-game-text-inner-section{
              position: relative;
              left: 30px;
              color:$white;
              font-family: $good-head-pro;
              line-height: 24px;
              font-size: 16px;
             
             @media screen and (max-width: 1024px) {
              text-align: center;
              left: 10px;
             }
                
            }
            &-img img{
              @include breakpoint(large){
                  max-width: 364px;
              }
                @media screen and (max-width: 1024px) {
                  max-width: 321px;
                }
            }
            &-img-not-visible img{
              visibility: hidden;
              @include breakpoint(large){
                  max-width: 364px;
              }
                @media screen and (max-width: 1024px) {
                  max-width: 321px;
                }
            }
    

        }
      }
          
    }
        &__game-only.before-selected{
      // background: url(/resources/imagesBundesliga/buzzer_23/buzzer/all_arrow.gif) no-repeat;
      // background-position-y: 39%;
      //   background-size: 58px auto;
          @media screen and (max-width: 1024px) {
              // margin: -89px 0 0 -56px;
              margin:0 auto;
              // background-position-y: 1%;
              // background-size: 45px auto;
              // background-position-x: 25%;
            }
        }
        &__game-only.on-select{
      background: url(/resources/imagesBundesliga/buzzer_23/buzzer/all_arrow.gif) no-repeat;
      background-position-y: 53%;
        background-size: 58px auto;
          @media screen and (max-width: 1024px) {
            margin: auto;
            background-position-y: 18%;
            background-size: 37px auto;
            background-position-x: 2%;
            min-width: 325px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 390px;
            padding-bottom: 35px;
            }
        }
  }
  .buzzer-under-section{
    .image-under{
      height: 9px;
    }
  }
}

/* FAQ page */
#buzzer-faqs.buzzer-game .content-faq {
  max-width: unset;
  border-radius: unset;
  margin-bottom: 0;
  padding: 50px 100px 80px 100px;
  @media only screen and (max-width: 580px) {
    padding: 35px 25px 50px 25px;
  }
  .content-faq__item {
    * {
      font-family: $good-head-pro;
    }
    padding-bottom: 0px;
  }
}

#buzzer-prizes.buzzer-game {
  .prizeRewe {
    .campaign-heading {
      margin: 0;
      padding-top: 50px;
      h2 {
        font-family: $gobold-bold;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 45px;
        width: 100%;
        @media only screen and (min-width: 481px) {
          font-size: 60px;
          line-height: 90px;
          max-width: 783px;
        }
      }
    }
    .prizeRewe-card {
      .prizeRewe-card__primary {
        h3 {
          font-family: $gobold-bold;
        }
        p {
           font-family: $good-head-pro;
        }
      }
      .prizeRewe-card__secondary {
        @media only screen and (min-width: 481px) {
          width: auto;
          max-width: min-content;
        }
        
        .sub-prize{
          width: 90% !important;
        }
        h3 {
          font-family: $gobold-bold;
        }
        p {
           font-family: $good-head-pro;
        }
        img {
          width: 100%;
          margin-top: 0;
          @media only screen and (min-width: 481px) {
            width: auto;
          }
        }
      }
    }
  }
}

/* buzzer product */ 
.content-holding__heading.closing.product-wrapper.weltmeister-product.ticket-product.prize-buzzer {
  .content-end__text {
    margin-top: 0;
    padding-top: 41px;
    @media only screen and (min-width: 481px) {
      padding-top: 71px;
    }
                p {
                  font-family: $good-head-pro;
                  line-height: 140%;
                }
                }
        
                .chocolate {
                  display: flex;
                  justify-content: center;
                  margin-bottom: 40px;
        
                  @media only screen and (min-width: 481px) {
                    margin-bottom: 70px;
                    margin-top: -85px;
                  }
        
                  img {
                    @media only screen and (max-width: 481px) {
                      width: 100%;
                    }
                  }
                        }
  .s-content-end {
    + .s-content-end {
      padding-top: 0;
      .end-element__item {
        @media only screen and (max-width: 481px) {
          flex-direction: column;
        }
      }
    }
    .end-element__item .end-element__copy {
      margin-bottom: 0;
      p {
        @media only screen and (max-width: 481px) {
          margin-bottom: 32px !important;
        }
      }
    }
  }
}

/* Terms */
.buzzer-terms {
  background-color: #3b2772;
  .terms-container {
    * {
      color: #fff;
    }
    .s-content-copy {
      padding-top: 85px;
      @media only screen and (max-width: 768px) {
        padding: 0 12px;
        padding-top: 45px;
      }
    }
    .s-content-copy .s-content-copy__item {
      @media only screen and (min-width: 768px) {
        width: 760px;
      }
      p {
        font-family: 'Good Headline Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        padding-bottom: 30px;

        &.headings {
          font-family: $gobold-bold;
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 45px;
          padding-bottom: 17px;
          @media only screen and (min-width: 481px) {
            font-size: 40px;
            line-height: 60px;
            padding-bottom: 10px;
          }
          &.sub{
            @media only screen and (min-width: 481px) {
              font-size: 30px;
            }
            &.small {
              @media only screen and (min-width: 481px) {
                font-size: 24px;
              }
            }
          }
          &.first {
            font-size: 24px;
            line-height: 36px;
            @media only screen and (min-width: 481px) {
              font-size: 30px;
              line-height: 45px;
            }
          }
        }
      }
      ul {
        &.disc {
          list-style: inside;
        }
        li {
          font-family: 'Good Headline Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          width: unset;
          padding-bottom: 2px;
          padding-left: 15px;
          @media only screen and (min-width: 768px) {
          width: unset;
          }
        }
      }
    }
  }
}

#main-buzzer {
  .campaign-heading {
    @media only screen and (min-width: 481px) {
      margin-top:0
    }
  }
  .buzzerGame {
    .s-content-steps{
      button {
        background-color: none;
        background: none;
        border: none;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        cursor: pointer;
        padding: 0;
        text-decoration: underline;
      }
    }
  }
}

.buzzer23-main{
  * {
      letter-spacing: normal;
    }
  .modal-v1.penny .modal-content .btn__container{
    font-family: $good-head-pro;
    font-weight: 700;
    font-size: 22px;
    line-height: 15px;
    letter-spacing: 5px;
  }

.buzzer23-container .s-content-steps .s-content-steps__step {

  .s-content-steps__copy.first,
  .s-content-steps__copy.second,
  .s-content-steps__copy.third {
    span {
      font-family: $good-head-pro;
    }
  }
}

.s-content-steps__copy.third {
      width: 190px;
      
  }

  .buzzer23-container{
    .s-content-title{
      @media only screen and (max-width: 480px) {
        height: 70px !important;
      }
    }
    .cta-container{
      .text-section{
        font-weight: 400;
        line-height: 27px !important;
      }
      .edeka-terms{
        @media only screen and (max-width: 480px) {
          padding: 0 5px !important;
          
        }
      }
    }
  }
}

/* End */
.content-holding__heading.closing {
  .content-end__text {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 30px;
    padding-bottom: 31px;
    @media only screen and (min-width: 481px) {
      margin-top: 0;
      padding-top: 80px;
      padding-bottom: 91px;
    }
    h2 {
      font-family: $gobold-bold;
      font-style: normal;
      font-size: 30px;
      line-height: 45px;
      @media only screen and (min-width: 481px) {
        font-weight: 400;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 20px;
      }
    }
    p {
      color: #fff;
      font-family: $good-head-pro;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
    }
  }
}

 // Set the desired increase percentage

.newsletter-sticky {
  position: fixed;
  width: 320px;
  height: 66px;
  right: 0;
  /* top: 65vh; */
  top: unset!important;
  background: #FFFFFF;
  border-radius: 50px 0px 0px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin-top: 40px;
  /* @media only screen and (min-width: 481px) and (max-width:819px) {
    top: 80vh!important; 
  }
  @media only screen and (min-width: 820px) and (max-width:1005px) {
    top: 45vh !important; 
  }

  @media screen 
  and (min-device-width: 1500px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    top: 77vh!important;
  }

  @media screen 
    and (min-device-width: 1500px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (min-resolution: 192dpi) { 
      top: 83vh!important;
  }
  @media only screen and (min-width: 1600px) {
    top:  591px!important; 
  }
  @media only screen and (min-width: 1700px) {
    top:  607px!important; 
  }
  @media only screen and (min-width: 1875px) {
    top:  655px!important; 
  }
  @media only screen and (min-width: 2071px) {
    top:  709px !important
  }
  @media only screen and (min-width: 2265px) {
    top:  779px !important
  } */
  h2 {
    font-family: 'Good Headline Pro Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #3B2774;
    margin-left: 11px;
    margin-right: 20px;
  }
}

.overview-header__content {
  @media only screen and (min-width: 481px) and (max-width: 780px) {
    width: 100%;
  }
  .overview-header__title {
    @media only screen and (min-width: 481px) and (max-width: 780px) {
      font-size: 32px;
      margin-bottom: 0px;
    }
  }
  .overview-header__subtitle {
    @media only screen and (min-width: 481px) and (max-width: 780px) {
      font-size: 19px;
    }
  }
}