.announcement-banner {
    &__container {
        width: 100%;
        padding: 50px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        // background-color: $mid-lilac; // TODO: Remove & Replace with img
        background-image: url($local-images + '/shapes/hell-Lila-Background.png');;
        // background-size: 100%;
        // background-image: url($milka-small-texture);
        background-size: cover;
        background-repeat: no-repeat;

        .btn {
            &__container {
            color: $dark-lilac;
                font-family: $soft-medium;
                font-size: 14px;

                @include breakpoint(medium) {
                    font-size: 17px;
                }

                @include breakpoint(large) {
                    font-size: 20px;
                }
            }

            &__text {
                font-weight: 600;
            }
        }

        .social-media {
            margin: 0 20px;
        }
    }

    &__text {
        font-family: $recol;
        color: $white;
        margin-right: 15px;
        margin-bottom: 15px;
        font-size: 20px;
        text-align: center;
        margin-bottom: 15px;

        @include breakpoint(medium) {
            margin-bottom: 0px;
            font-size: 25px;
        }

        @include breakpoint(large) {
            font-size: 30px;
        }

        a {
            font-family: inherit;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__social-link {
        font-size: 40px;
        margin: 0 10px;

        @include breakpoint(medium) {
            margin: 0 20px;
        }

        .icon {
            &-facebook {
                &::before {
                    color: $white;
                }
            }

            &-instagram {
                &::before {
                    color: $white;
                }
            }

            &-youtube {
                .icon {
                    &-boundary {
                        &::before {
                            color: $white;
                        }
                    }

                    &-play {
                        &::before {
                            color: $mid-lilac;
                        }
                    }
                }
            }
        }
    }
}