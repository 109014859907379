.heimtrikot{
    .container_Affiche{
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: normal;
        // width: 80%;
    
        .container_cardAffiche {
            text-align: center;
            margin-inline: 25px;
    
            .cardImage {
                .srcimage {
                    img{
                        width:fit-content ;
                    }
                }
            }
    
            .step2 {
                margin-top: -192px;
                .srcimage {
                    img {
                        width: 100%;
                    }
                }
            }
            
            .cardText, .cardText1 {
                
    
                h3 {
                    color: var(--Fresh-White, #FFF);
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: "Gobold Bold";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    text-transform: uppercase;
                    margin-bottom: 30px;
                }
    
                p {
                    color: #FFF;
                    text-align: center;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: "Good Headline Pro";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    margin-bottom: 80px;
                    max-width: 783px;
                    // width: 783px;
                    // height: 83px;
    
                    .para{
                        display: block;
                        font-size: 16px;
                    }
                }
            }
    
            .cardText {
                margin-block: 40px;
            }
    
            .cardText1 {
                margin-block: -4px;
                margin-top: 40px;
            }
        }
            
    }
    
    @media (min-width: 768px) and (max-width: 1024px) {
        .container_Affiche {
            flex-direction: column;
    
            .container_cardAffiche {
                margin-inline: auto;
            }
        }
    
        .profile {
            margin-top: -10px;
        }
    
        
    }
    
    @media only screen and (max-width: 767px) {
        .container_Affiche {
            flex-direction: column;
            width: 100%;
    
            .container_cardAffiche{
                .step2 {
                    margin-top: 0px;
                }
    
                .cardImage{
                    .srcimage {
                        img{
                            width: 100%;
                        }
                    }
                }
            
                .cardText, .cardText1 {
                    h3 {
                        font-size: 24px;
                        text-align: center ;
                    }
                }
    
                .cardText1 {
                    margin-block: 0;
                }
            
            }
    
        }
    }
    
        
}
