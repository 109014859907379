.heimtrikot {
    
    @media (max-width: 767px) {
        width: 100%;
    }
    ._btnreihe {

        width: 357px;
        height: 44px;
        
        margin-top: 8px;
        display: inline-flex;
        // padding: 15px 74px 14px 74px;
        justify-content: center;
        align-items: center;
        @media (max-width: 767px) {
            // padding: 15px 50px 14px 50px;
            width: 100%;
        }
        
        border-radius: 53px;
        border: 2px solid var(--Fresh-White, #7D69AC);
        background: var(--Deep-Purple, #7D69AC);
    
        .content_Btn {
            color: var(--Fresh-White, #FFF);
            text-align: center;
            font-family: "Good Headline Pro";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 15px;
            /* 75% */
            letter-spacing: 4px;
            text-transform: uppercase;
        }
    }
    ._btnreihe:hover{
        cursor: pointer;
    }

}
