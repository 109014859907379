#main-buzzer.doppelt-main {
    .campaign-heading {
        padding-top: 30px;
        @media only screen and (min-width: 481px){
            margin: 40px 0 70px;
        }
    }
    #edeka-terms {
        @media only screen and (min-width: 1024px){
            margin-bottom: 92px;
        }
        a {
            font-family: 'Good Headline Pro';
        }
    }
    
    .white-title {
        font-family: 'Gobold Bold';
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px; /* 60px */
        text-transform: uppercase;
        text-align: center;
        color: #fff;
    }
}
.charity-icon {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
    img {
        width: 164.877px;
        height: 137px;
    }
}
.player-wrapper {
    display: flex;
    margin: 0 auto;
    margin-bottom: 80px;
    justify-content: space-between;
    @media only screen and (min-width: 481px){
        max-width: 922px;
    }
    @media only screen and (max-width: 939px){
        flex-direction: column;
        align-items: center;
    }
    .player-wrapper__content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        color: #fff;
        max-width: 444px;
        @media only screen and (max-width: 939px){
            max-width: 340px;
        }
        img {
            @media only screen and (max-width: 481px){
                width:229px;
            }
        }
        h4 {
            font-family: 'Gobold Bold';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            text-transform: uppercase;
            margin-top: 30px;
        }
        p {
            font-family: 'Good Headline Pro';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            margin: 8px 0 25px 0;
        }
        &:last-child {
            @media only screen and (max-width: 939px){
               margin-top: 60px;
            }
        }
    }
}
.charity {
    .player-wrapper {
        margin-bottom: 40px;
    }
}

#main-buzzer.doppelt-main, #buzzer-prizes.doppelt-prizes{
    .trikot-promo-container {
        h3 {
            margin-top: 32px;
            @media only screen and (min-width: 481px){
                font-size: 60px;
                margin-bottom: 62px;
                margin-top: 20px;
            }
        }
        
        .trikot-promo-container-wrapper {
            max-width: 912px;
            margin: 0 auto;
            margin-bottom: 44px;
            @media only screen and (min-width: 1024px){
                padding: 32px 12px;
            }
            @media only screen and (max-width: 481px){
                height: max-content;
                max-width: 352px;
            }
            &.last {
                margin-bottom: 74px;
                @media only screen and (max-width: 481px){
                    margin-bottom: 24px;
                }
            }
            .trikot-promo-card.reverse {
                @media only screen and (min-width: 804px){
                    flex-direction: row-reverse;
                }
            }
            .trikot-promo__content {
                @media only screen and (min-width: 864px){
                    justify-content: center;
                }
                @media only screen and (max-width: 481px){
                    max-height: unset;
                }
                img {
                    @media only screen and (max-width: 481px){
                        width: 243px;
                        padding-bottom: 20px;
                    }
                }
                h4 {
                    @media only screen and (max-width: 481px){
                        max-width: 267px;
                    }
                }
            }
        }
    }
}

#main-buzzer.doppelt-main{
    .trikot-promo-container{
        h3 {
            @media only screen and (min-width: 481px){
                font-size: 40px;
                line-height: 60px;
                margin-bottom: 44px;
            }
        }
    }
}

.gewinnen-background {
    .image__container .form-button.form-button-main.weltmeister.Doppelt {
        @media only screen and (max-width: 481px){
            bottom: 4%;
        }
        @media only screen and (min-width: 481px) and (max-width: 1023px){
            bottom: 4%;
        }
/*         @media only screen and (min-width: 1100px){
            left: calc(60vw + 20px);
        }
        @media only screen and (min-width: 1200px){
            left: calc(62vw + 20px);
        }
        @media only screen and (min-width: 1400px){
            left: calc(63vw + 20px);
        }
        @media only screen and (min-width: 1400px){
            left: 64vw;
        } */
    }
}

/* PARTICIPATION */
#main-participation.doppelt-part {
    .content-participation__form .chooseTeam {
        .sub-heading {
            max-width:795px;
            margin: 0 auto;
            @media only screen and (max-width: 481px){
                max-width: 345px;
                line-height: 28px;
                padding: 11px 0 10px 0;
            }
        }
        h2.heading {
            @media only screen and (max-width: 481px){
                line-height: 40px;
            }
        }
    }
    .chooseTeam {
        @media only screen and (max-width: 481px){
            padding-bottom: 0;
        }
    }
}

/* prize page */
#buzzer-prizes.doppelt-prizes {
    .cta-container {
        .btn__container {
        width: 349px;
        height: 44px;
        background-color: #3b2774;
        font-family: $good-head-regular;
        font-weight: 700;
        font-size: 20px;
        line-height: 15px;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding: 0 25px;
        padding-top: 5px;
          &:before {
            background-color: #3b2774;
            border: 2px solid #ffffff;
          }
        }
      }
}

.consumer-service {
    max-width: 783px;
    margin: 0 auto;
    margin-top:40px;
    p {
        color:#fff;
        font-family: Good Headline Pro;
        font-size: 20px;
        line-height: 30px;
    }
}

.content-holding__heading.closing.weltmeister-end.trikot-end.doppelt-end {
    .content-end__text p {
        @media only screen and (min-width: 481px) {
            max-width: 560px;
        }
    }
}

.buzzer-terms {
   .s-content-copy__item ul li::before {
        content: none;
    } 
    .s-content-copy .s-content-copy__item ul li:not(.no-list)::before {
        content: none;
    }
}

#buzzer-prizes.doppelt-prizes .trikot-promo-container h3 {
    @media only screen and (min-width: 481px) {
        font-size: 40px;
        line-height: 60px;
        padding-top: 50px;
    } 
}