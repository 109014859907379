.category-anchor {
    &__item {
        
        &--active {
            background-color: $natural;
            color: $milka-lilac !important;
            border-radius: 20px;
            font-size: 20px;
        }
    }
}