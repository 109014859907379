$soft-thin:'Intervogue Soft Thin';
$soft-alt-black:'Intervogue Soft Alt Black';
$soft-alt-regular:'Intervogue Soft Alt Regular';
$soft-regular:'Intervogue Soft Regular';
$soft-alt-bold:'Intervogue Soft Alt Bold';
$soft-alt: "Intervogue Soft Alt Regular";
$soft-alt-thin:'Intervogue Soft Alt Thin';
$soft-medium:'Intervogue Soft Medium';
$soft-alt-medium:'Intervogue Soft Alt Medium';
$soft-medium-o:'Intervogue Soft Medium Oblique';
$soft: "Intervogue Soft";