.btn-icon {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
    
    &__border {
        border: 2px solid rgba(175, 166, 210, 0.3);
        padding: 8px 16px;
        border-radius: 24px;
    }
    
    &__color {
        background: #02C751;

        &__dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        &__none {
            display: none;
        }
        
        &__disabled{
            background: #F55D53;
        }
    }
    
    &__text {
        color: #7D69AC;
        font-family: $good-head-regular;
        font-weight: 700;
        font-size: 16px;
        white-space: nowrap;

        img {
            margin-right: 5px;
        }
    }
    

    &__fill {
        background-color: #3B2774;
        border: none;
        
        &:hover{
            background-color: #301f5e;
            cursor: pointer;
        }

        .btn-icon__text {
            color: $white;  
        }
    }

    &__custom{
        color: red;
    }
}