.campaign-code {
    background-image: url($local-images + "/Pages/Participation/Code/bg_sm.png");
    background-repeat: repeat-x;
    padding: 0 7%;
    min-height: 405px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    
    @include breakpoint(large) {
        align-items: flex-start;
        // background-image: url($local-images + "/Pages/Participation/Code/bg.png");
        // min-height: 485px;
        padding-left: 15%;
    }



    * {
        color: white;
        text-align: center;
    }

    h4 {
        font-family: $recoleta;
        font-size: 26px;
        line-height: 25px;

        @include breakpoint(large) {
            font-size: 40px;
            line-height: 46px;
            width: 778px;
        }
    }

    p {
        font-size: 20px;
        line-height: 26px;
        margin: 10px 0;

        @include breakpoint(large) {
            font-size: 20px;
            line-height: 26px;
            width: 778px;
        }

        &#info {
            font-size: 12px;
            line-height: 14px;
            margin-top: 0;
            @include breakpoint(large) {
                width: 150px;
                position: absolute;
                right: -490px;
                top: -35px;
                text-align: left;
            }
        }
    }

    input {
        display: block;
        margin: 0 auto;
        &.campaign-code-input {
            background: #FFFFFF;
            color: rgba(0, 0, 0, 0.4);
            border-radius: 35.5px;
            border: none;
            font-weight: bold;
            font-size: 28px;
            line-height: 37px;

            width: 100%;

            @include breakpoint(medium) {
                width: 70%;
            }

            @include breakpoint(large) {
                width: 778px;
                font-size: 28px;
                line-height: 37px;
                padding: 17px 0;
                margin-top: 37px;
            }

            &.notvalid {
                color: $error-red;
                border: 4px solid $alpine-yellow;
                box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.3);

                &::placeholder {
                    color: $error-red;
                }
            }
        }
    }

    &-container {
        @include breakpoint(large) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        img {
            max-height: 223px;
            width: auto;
            margin: 0 auto;

            @include breakpoint(large) {
                position: absolute;
                // left: -50px;
                // top: -25px;
                right: -350px;
                top: -110px;
                width: 400px;
                order: 9
            }
        }

        input {
            @include breakpoint(large) {
                margin-right: 0;
            }
        }
    }
}