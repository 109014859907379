.micro-globus {
    &--confirmation{
        .micro-globus{
            &__content-title {
                position: relative;
                background-color: #7d69ac;
            }
            &__title-container {
                padding-bottom: 0;
                margin-bottom: -100px;

                @media (min-width : 1350px){
                    margin-bottom: -250px;
                }
                @media (max-width : 1023px){
                    margin-bottom: 0px;
                }
                
                /*position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: unset;

                @media only screen and (max-width: 1023px) {
                    position: unset;
                }*/
            }
        }
    }
    
}