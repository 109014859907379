.titre-card {
    display: flex;
    color: $white !important;
    font-family: "Good Headline Pro";
    font-weight: bold;
    font-size: 20px;
    margin: 0 0 22px 0;
    justify-content: center;

    @include breakpoint(small-only) {

            div:first-child{
                margin:0 auto;
            }
        }
    
}

.card-tableau {
    width: 575px;
    height: 339px;
    left: 230px;
    top: 964px;
    border:2px solid transparent;
    background: #FFFFFF;
    border-radius: 12px;
    
    @include breakpoint(small-only){
        width: 92%;
        margin: 0 auto;
        height: auto;
    }

    .text__container {
        margin-top: 25px;

        .texte {
            display:block;
            font-size: 18px;
            margin:15px;
            font-family: "Good Headline Pro";
            display:flex;
            justify-content: space-between;
            &__span1{
                font-family: $good-head-pro;
                color:#3B2774;
                text-align:right;
                padding-left: 20PX;
                @include breakpoint(small-only){
                    padding-left: 0;
                }
            }
            &__span2{
                font-family:  $good-head-pro-bold;
                color:#3B2774;
                display:block;
                width:390px;
                /* float:right; */
                

                @include breakpoint(small-only){
                    width:270px;
                }
            }
        }

        .tiret {
            color:#AFA6D2;
            width:495px;
            @include breakpoint(small-only){
                width:280px;
            }
        }
    }

    .texte {
        display:block;
        font-size: 18px;
        margin:15px;
        font-family: "Good Headline Pro";
        &.info {
            margin-top:30px;
        }
        @include breakpoint(small-only){
            font-size:14px;
        }
        &__span1{
            font-family: $good-head-pro;
            color:#3B2774;
            text-align:right;
            padding-left: 20PX;
        }
        &__span2{
            font-family: $good-head-pro-bold;
            color:#3B2774;
            display:block;
            width:390px;
            /* float:right; */
            @include breakpoint(small-only){
                width:200px;
            }
        }
        
        &__checkedbtn {
            text-align: right;
            padding-left: 20px;

            &.notvalid {
                .btncheck {
                    border: 1px solid $error-red;
                }

                .texte__textcheck {
                    color: $error-red;
                }
            }
        }

        &__textcheck {
            color: #3B2774;
            font-size: 16px;
            float: right;
            margin-top: 16px;
            top: 20px;
            margin-right: 80px;
            word-wrap: break-word;
            text-align: start;
            
            padding-left: 12px;
            @include breakpoint(small-only){
                top: -23px;
                font-size: 16px;
                width:auto;
                text-align:start;
                padding:5px;
                position: relative;
                left: 10px;
                text-align:start;
                padding:5px;
                margin-right: 0;
                margin-top:20px;
                line-height: 24px;
            }
        }

        &__paragraphe {
            display: flex;
            color:#3B2774;
            padding-left: 20PX;
            font-size: 16px;
            top:20px;
           /*  position: relative; */
            margin-right: 10px;
        
            @include breakpoint(small-only){
                font-size:16px;
                line-height:24px;
                text-align:start;
                /* position: relative; */
                text-align:start;
                padding:0;
                left:19px;
                //margin-left:20px;
            }
        }

        &__checkedbtn {
            display: flex;
            overflow-wrap: break-word;
            text-align:right;
            padding-left: 20px;
            @include breakpoint(small-only){
                padding-left: 0;
            }
        }

        .btncheck {
            width:25px;
            height:40px;
            border-radius:9px;
            top:25px;
            /* position: relative; */

            @include breakpoint(small-only){
                top:0;
                width: 18px;
                height: 28px;
            }
        }

        .btncheck :before {
            background-color:#AFA6D2;
        }
    }
    
}