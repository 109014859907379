.video {
    &__container {
        position: relative;
        height: 100%;
        overflow: hidden;
        width: 100%;
    }

    &__player {
        z-index: -1;
        position: absolute;
        top: 0;

        width: 100%;
        height: 100%;

        video {
            width: 100%;
        }
    }

    &__text {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}