$active-filter-color: $dark-lilac;

.filter-active {
    &__container {
        display: none;
        // background-color: $light-grey;
        // background-image: url($local-images+'/Shapes/master-paper-texture_grey.jpg');
        background-color: rgba(250, 233, 210, 0.2);
        padding: 5px;
        margin-bottom: 15px;

        .filter-d__item {
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            border-bottom: 2px solid $dark-lilac;
            padding-bottom: 5px;

            @include breakpoint(large) {
                border: none;
                padding-bottom: 0;
            }
            
            span:nth-child(1) {
                position: relative;
                min-width: 20px;
                min-height: 20px;
                margin-right: 15px;

                &::after {
                    top: 50%;
                    content: " ";
                    width: 100%;
                    height: 2px;
                    background-color: $active-filter-color;
                    position: absolute;
                    transform: rotate(45deg);
                }

                &::before {
                    top: 50%;
                    content: " ";
                    width: 100%;
                    height: 2px;
                    background-color: $active-filter-color;
                    position: absolute;
                    transform: rotate(-45deg);
                }
            }

            span:nth-last-child(1) {
                color: $active-filter-color;
                text-align: left;
                font-family: $soft-alt-medium;
                background-color: transparent;
                border-radius: 0;
                padding: 0;
            }
        }
    }

    &__title {
        font-family: $soft-alt-medium;
        color: $active-filter-color;
        font-size: 16px;
        border-bottom: 2px solid $dark-lilac;
        padding-bottom: 10px;

        @include breakpoint(medium) {
            font-size: 20px;
        }

        @include breakpoint(large) {
            padding-bottom: 0;
            border: none;
            font-size: 22px;
        }
    }
}