.fancenter-layout {
  .image__container {
    @media only screen and (max-width: 480px) {
      padding-bottom: 0px;
    }
    .image__img{
      background-color: #fff;
    }
    .form-button{
      &.fancenter-banner-button{
        @media only screen and (max-width: 481px) {
          bottom: 5%;
        }
        @media only screen and (min-width: 481px) and (max-width: 980px) {
          bottom: 3%;
        }
        @media only screen and (min-width: 1025px) {
          bottom: 31%;
          right: 11%;
        }
        button{
          width: 232px;
          height: 40px;      
          font-size: 17px;
          text-transform: uppercase;
          padding: 10px 14px 8px 16px;
          @media only screen and (min-width: 481px) {
            min-width: 306px;
            height: 42px;
          }
        }
      }
      
    }
  }
  .separator{
    display: none;
  }
}
.main-fancenter{
  .image__img{
    @media only screen and (max-width: 481px) {
      padding-bottom: 100px;
    }
  }
}
.buzzer-jersey{
  .fancenter-main{
    .campaign-heading {
      > p {
        max-width: 902px;
      }
      .lilac.recoleta-medium {
        margin: 0 auto;
        display: none;
        @media only screen and (min-width: 481px) {
          display: block;
          margin-top: 0 !important;
          padding-top: 90px;
          padding-bottom: 50px;
        }
      }
    }
  }
}
.buzzer-jersey .code-entry-wrapper{
  background-image: url('/resources/imagesBundesliga/fanCenter/konfetti-code-entry-sm.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
  padding-top: 30px;
  padding-bottom: 30px;
  @media only screen and (min-width: 481px) {
    background-position: 50% 50%;
    background-image: url('/resources/imagesBundesliga/fanCenter/konfetti-code-entry.png');
    padding-top: 90px;
    padding-bottom: 100px;
  }
  .campaign-heading{
    .lilac.recoleta-medium{
        margin-top: 0;
        @media only screen and (min-width: 481px) {
          width: 100%;
          max-width: 952px;
        }
    }
  }
  .form__container{
    margin-bottom:0!important;
    padding: 0;
    .btn__container.btn--red{
      width: 243px;
    }
  }
}

.buzzer-jersey.user-details-wrapper{
  .campaign-heading {
    @media only screen and (min-width: 768px) {
      margin-bottom: 0;
    }
    .lilac.recoleta-medium{
      max-width: 670px;
      margin: 0 auto;
      margin-top: 70px;
    }
    p.lilac {
      @media only screen and (min-width: 481px) {
        max-width: 680px;
      }
    }
    h3 {
      @media only screen and (max-width: 480px) {
        font-size: 30px;
        max-width: 85%;
        br {
          display: none;
        }
      }
    }
  }
  #frm_user_details_ticket {
    .form-container{
      &#name-terms{
        width: 90%;
        max-width: 600px;
        @media only screen and (min-width: 1025px) {
          width: 65%;
        }
        .input-container{
          width: 100%;
          margin: 0 auto;
          @media only screen and (min-width: 481px) {
            padding: 0 10px;
          }
        
        }
      }
    }
    #salutation .form-input__container {
      max-width: 175px;
    }
    .form-input__dropdown:after {
      right: 29px;
    }
    .custom-inputs {
      .text-content {
        font-size: 15px;
        @media only screen and (max-width: 481px) {
          padding-left: 9px;
        }
      }
      .checkmark {
      background-color: #fff;
      width: 28px;
      height: 28px;
      border-radius: 8px;
      &::after{
        color: #3b2772!important;
        left: 4px;
        top: 7px;
        font-size: 13px;
      }
    }
    }
    p.good-headline-pro {
      @media only screen and (min-width: 1025px) {
        padding: 0 10px;
      }
    }
  }
  .buzzer-form {
    @media only screen and (min-width: 1025px) {
      margin-top: 0;
    }
  }
}

.buzzer-jersey.lottery{
  .spaceTile-intropage{
    margin-bottom: 50px;
    @media only screen and (min-width: 481px) {
      margin-bottom: 100px;
    }
  }
}

.fancenter-main {
  .ticket-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding-top: 40px;
    @media only screen and (min-width: 481px) {
      padding-top: 0;
    }
    @media only screen and (min-width: 481px) and (max-width: 885px) {
      justify-content: center;
    }
    @media only screen and (min-width: 1030px){
      width: 830px;
    }
    @media only screen and (min-width: 1300px){
      width: 1230px;
    }

    .ticket-card {
      flex: 1 1 391px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 391px;
      background-color: #3b2772;
      border: 5px solid #f1cc74;
      border-radius: 7px;
      padding: 40px 0;
      margin: 20px 0;
      @media only screen and (min-width: 481px) {
        height: 528px;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
      }

      img {
        display: block;
        margin: 0 auto;
      }
      h2 {
        font-size: 30px;
        margin: 0 30px;
        margin-top: 16px;
        line-height: 32px;
      }
    }
  }
}

.buzzer-jersey {
  background-color: #7d69ac;
  color: #fff;
  .campaign-heading {
    padding: 0;
    margin-top: 0;
    .lilac {
      color: #fff !important;
      font-family: "Intervogue Soft Regular";
      font-size: 20px;
      a {
        font-family: "Intervogue Soft Regular";
        font-weight: normal;
      }
      * {
        color: #fff !important;
      }
      &.recoleta-medium {
        color: #fff !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 1px;
        margin-top: 40px;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 40px;
        @media only screen and (min-width: 481px) {
          max-width: unset;
          font-size: 34px;
          margin-top: 70px;
        }
        &.ticket {
          @media only screen and (max-width: 481px) {
            font-size: 30px;
            max-width: 390px;
          }
        }
      }
    }
    p.lilac {
      max-width: 340px;
      @media only screen and (min-width: 481px) {
        width: 100%;
        max-width: 680px;
      }
    }
  }
  .campaign-game {
    background-color: #fff;
    h4 {
      color: #fff;
      @media only screen and (min-width: 481px) {
        font-size: 37px;
      }
    }
    p {
      color: #fff;
      margin-bottom: 30px;
      @media only screen and (min-width: 481px) {
        margin-bottom: 40px;
      }
    }
    .buzzer-mobile {
      background: #afa6d2;
    }
    .buzzer-desktop {
      background: #afa6d2;
      .match-container {
        hr.separator {
          display: none;
        }
      }
    }
    .btn--red {
      background-color: #3b2772;
      letter-spacing: 4px;
    }
  }
  #Covid-Details {
    padding-top: 0;
    max-width: 820px;
    margin: 0 auto;
    .back--red {
      padding: 16px;
      width: 90%;
      background-color: #3b2772;
      @media only screen and (min-width: 481px) {
        padding: 36px;
      }
      p {
        line-height: 26px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  #frm_code_submit,
  #frm_user_details_ticket {
    .small-input-container {
      p {
        color: #fff;
        padding-left: 30px;
        @media only screen and (min-width: 1025px) {
          font-size: 16px;
        }
      }
    }
    .btn__container.btn--red {
      font-family: "Intervogue Soft Thin";
      letter-spacing: 4px;
      background-color: #3b2772;
    }
  }
  /* User-details/ticket */
  #frm_user_details_ticket {
    .form-container {
      p {
        color: #fff;
        /* padding-left: 30px; */
        @media only screen and (min-width: 1025px) {
          font-size: 16px;
          white-space:nowrap;
        }
        &.good-headline-pro {
          @media only screen and (max-width:481px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      input,
      select {
        background-color: $white;
      }
    }
    .email-label, .email-value {
      @media only screen and (max-width:481px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .terms-link {
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
    width: 90% !important;
    color: #fff;

    @include breakpoint(small-only){
      font-size: 18px;
      line-height: 22px;
    }
    a{
      color: #fff; 
      text-decoration: underline;
    }
  }

  .chooseTeam {
    background-color: #fff;
    max-width: none;
    padding-bottom: 50px;
    margin-bottom: 49px;
    @media only screen and (min-width: 481px){
      margin-bottom: 35px;
    }
    @media only screen and (min-width: 1025px){
      &.buzzer-form {
        margin: 75px 0 44px;
      }
    }
    
    h2.heading {
      padding-bottom: 50px;
      padding-top: 50px;
    }

    &--team-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;

      @media screen and (max-width: 767px){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }


      .team {
        padding: 5px;
        width: 175px;
        border-radius: 10px;
        margin: 5px;
        cursor: pointer;

        @include breakpoint(small-only) {
          width: 130px;
        }

        &-logo {
          margin-bottom: 20px;
          img {
            max-height: 118px;
            width: auto;
            display: block;
            margin: 0 auto;
            
          }
        }
        p {
          font-family: $soft-alt;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
        }
      }
      .team.active,
      .team:hover {
        background-color: #c9c9c9;
        text-decoration: none;
        p {
          //color: #fff;
        }
      }
      .not-avalaible{
        opacity: 0.5;
        color: rgba(59, 39, 116, 0.2);
        pointer-events: none;

        .card-team__not-avalaible{
          font-family: $good-head-pro;
          font-size: 16px;
          line-height: 20.8px;
          text-align: center;
          font-weight: 700;
          display: flex;
          padding-top: 24px;
          ul {list-style: none}
          li {
                @include breakpoint(small-only) {
                  padding-right: 3px;
                }
              }
          li::before {
            content: "•";
            font-size: 50px;
            color: red;
            display: inline-block;
            margin-left: -0.2em;
            
            @include breakpoint(small-only) {
              width: 1px;
            }
          }
        }
      }
    }
    #favorite-team-error {
      color: #d93333;
      display: none;
      text-align: center;
      margin-top: 50px;
    }
    .swiper-container {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }
    .swiper-pagination {
      display: none;
    }

  .chooseTeam * {
    color: #3b2772;
  }

  #frm_team_selection{
    button.btn__container {
      background: #3b2772;
      letter-spacing: 4px;
      @media only screen and (min-width: 481px){
        max-width: 243px;
      }
      &::before, &:hover{
        background: #3b2772;
      }
    }
  }
}
}

.fancenter-main-footer{
  background-color: #3b2772;
  background-image: url(/resources/imagesBundesliga/fanCenter/Konfetti-sm.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  @media only screen and (min-width: 481px) {
    background-image: url(/resources/imagesBundesliga/fanCenter/_Konfetti.png);
  }
  @media only screen and (min-width: 1160px) {
    font-size: 16px;
  }
  h2, h3{
    color: #fff;
  }
  h2{
    max-width: initial;
  }
  h3{
    max-width: 710px;
  }
  .btn__container{
    width: 363px;
    text-transform: uppercase;
    background-color: #7D69AC;
    font-weight: 400;
    &:hover{
      background-image: none;
    }
  }
}

.jersey-size-selection{
  margin-bottom: 70px;
  @media only screen and (max-width: 481px) {
    margin-bottom: 80px;
  }
  .mob-heading {
    @media only screen and (min-width: 481px) {
      display: none;
    }
    h2 {
      font-family: 'Recoleta';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      padding-bottom: 10px;
    }
    p {
      font-family: 'Intervogue Soft';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #fff;
      max-width: 344px;
      margin: 0 auto;
      padding-bottom: 40px;
    }
  }
  .team-season {
    display: flex;
    background-color: #E5E1EE;
    max-width: 211px;
    margin: 0 auto;
    height: 203px;
    border-radius: 10px;
    @media only screen and (min-width: 1160px) {
      height: 350px;
      max-width: 363px;
    }
    #jerseyImg{
      width: 90px;
      @media only screen and (min-width: 1160px) {
        width: auto;
      }
    }
  }
  .lilac{
    color:#fff!important;
  }
  .jersey-sizes{
    .lilac{
      color:#fff!important;
      font-family: $recoleta-medium;
      font-weight: 700;
      margin-bottom: 20px;
      @media only screen and (max-width: 481px) {
        display: none;
      }
    }
    .selection-jersey__size-container{
      width: initial;
      margin-bottom: 0;
      @media only screen and (min-width: 481px) {
        width: 390px;
      }
      p {
        color:#fff;
        line-height: 26px;
        @media only screen and (max-width: 481px) {
          font-size: 20px;
          display: none;
        }
      }
      label{
        color:#fff;
        margin-bottom: 16px;
        @media only screen and (max-width: 481px) {
          font-size: 20px;
        }
      }
      .size-boxes{
        button.selected{
          background-color: #3B2772;
          color:#fff;
        }
      }
      & > div {
        margin-top: 20px;
      }
    }
  }
  .jersey-sizes--link{
    .lilac.link{
      a{
        font-size: 20px;
        color:#fff!important;
        @media only screen and (min-width: 481px) {
          font-size: 16px;
        }
      }
    }
  }
  .jersey-sizes--submit{
    .buzzer-error{
      color: #fff!important;
    }
    p{
      color:#fff;
      @media only screen and (max-width: 481px) {
        font-size: 20px;
      }
    }
    .btn--secondary-lilac{
      background-color: #3B2772;
      &::before{
        background-color: #3B2772;
      }
      &:hover{
        background-image:none;
      }
    }
    .btn--red{
      background-color: #3B2772;
      &:hover{
        background-image:none;
      }
    }
  }
}

.fanCenter.jersey-confirmation {
  background-color: #7D69AC;
  #fancenter-confirmation{
    @media only screen and (min-width: 481px) {
      padding-top: 83px;
    }
    .s-content-title {
      padding: 0;
      @media only screen and (min-width: 481px) {
        margin: 0 auto;
      }
      h1.heading_dark-lilac {
        font-size: 30px;
        line-height: 36px;
        max-width: 330px;
        margin: 0 auto;
        padding-top: 43px;
        font-weight: 700;
        @media only screen and (min-width: 481px) {
          max-width: 939px;
          font-size: 40px;
          line-height: 46px;
          margin: 0 auto;
          padding-top: 0;
        }
        
      }
      p.heading_dark-lilac.space {
        font-size: 22px;
        line-height: 28px;
        padding: 0;
        max-width: 340px;
        margin: 0 auto;
        padding-bottom: 29px;
        @media only screen and (min-width: 481px) {
          font-size: 30px;
          line-height: 32px;
          max-width: 939px;
          padding-bottom: 50px;
        }
      }
    }
  }
  .s-content-end {
    margin: 0 auto;
    .end-element {
      margin-top: 0;
      .end-element__item {
        height: 480px;
        @media only screen and (min-width: 731px) {
          height: 460px;
        }
        @media only screen and (max-width: 731px) {
          max-width: 380px;
        }
      }
    }
  }
  .btn-simple {
    @media only screen and (min-width: 481px)  {
      margin-top: 30px;
      width: 290px!important;
      letter-spacing: 4px;
    }
  }
}