.carousel-banner {
    &__container {
        width: 100%;
        height: 100%;

        display: flex;
        
        .slick {
            &-track {
                height: 100%;

                .product {
                    &__card {
                        height: 100%;
                        display: flex !important;
                    }

                    &__image {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &-slide {
                display: flex;
                align-items: center;
                justify-content: center;

                transition: 0.2s;
                opacity: 0;

                & > div {
                    height: 100%;
                }
            }

            &-current {
                opacity: 1;
            }
        }
    }
}