.overview-slider-wrapper {
    .popup-overlay{
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      opacity: 1;
      z-index: 9;
    }
    .popup-caroussel {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%,-50%);
      //background: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      @media (max-width: 768px) {
        width: 90%;
      }
  
      .caroussel-content {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 768px) {
          width: 810px;
        }
        .Slider__left__wrapper__picture {
          width: 100%;
        }
        .Slider__left__wrapper__video {
          max-width: 90%;
          @media (min-width: 768px) {
            max-width: 934px;
          }
        }
        .Slider__left__wrapper__overlay {
          font-size: 3.4375rem;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          padding: 0;
          margin: 0;
          font-family: inherit;
          cursor: pointer;
          background-color: transparent;
          border: 0;
          border-radius: 0;
          &:before {
            /* opacity: 0.5; */
            display: inline-flex;
            content: "";
            /*               background-image: url($local-images + "/icons/triangle-right.svg");
            background-size: 250px 210px;
            height: 200px;
            width: 260px; */
            background-image: url("/resources/imagesBundesliga/intropage/caroussel/preview_play.png");
            background-repeat: no-repeat;
            background-position: 10px 14px;
            height: 90px;
            background-size: 60px 60px;
            width: 76px;
            @media (min-width: 768px) {
              background-position: center;
              background-size: initial;
              height: 122px;
              width: 122px;
            }
          }
        }
      }
    }
  }