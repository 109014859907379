$font-location: '/resources/fonts/Good/';

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=6262a60c-6902-40eb-b6cc-73a06ffa5332&fontids=5558165,5561073,5561574,5561583");

@font-face {
    font-family:"Good Head W01 Regular";
    src:url($font-location + '5558165/35e6a1bb-9b64-4c61-ae0d-5d45639c6cd4.woff2') format('woff2'),
    url($font-location + '5558165/e926e74a-8be2-4314-9a38-780a32274cbb.woff') format('woff');
}
@font-face{
    font-family:"Good Head W01 Bold";
    src:url($font-location + "5561073/5dbbe228-1db1-4354-8256-9a47fd494c2d.woff2") format("woff2"),
    url($font-location + "5561073/317675ce-cdb1-4bf1-b345-9da712bd52f4.woff") format("woff");
}
@font-face{
    font-family:"Good Head W01 Medium";
    src:url($font-location + "5561574/7e5b05e8-d245-40f0-8dcd-9e5f16109adf.woff2") format("woff2"),
    url($font-location + "5561574/88f46fb8-3d88-4bc9-9233-49328356564a.woff") format("woff");
}
@font-face{
    font-family:"Good Head W01 Black";
    src:url($font-location + "5561583/82816efc-cb31-4eaf-9494-4a8a3e7bd157.woff2") format("woff2"),
    url($font-location + "5561583/f5140e31-fe26-49d9-bd7e-e6781478f2b3.woff") format("woff");
}
@font-face{
    font-family:"Good Headline Pro";
    src:url($font-location + "HeadlinePro/GoodHeadlinePro.woff2") format("woff2"),
    url($font-location + "HeadlinePro/GoodHeadlinePro.woff") format("woff");
}
@font-face{
    font-family:"Good Headline Pro Bold";
    src:url($font-location + "HeadlinePro/GoodHeadlinePro-Bold.woff2") format("woff2"),
    url($font-location + "HeadlinePro/GoodHeadlinePro-Bold.woff") format("woff");
}
@font-face{
    font-family:"Good Headline Pro Regular";
    src:url($font-location + "HeadlinePro/GoodHeadlinePro.woff2") format("woff2"),
    url($font-location + "HeadlinePro/GoodHeadlinePro.woff") format("woff");
}
@font-face {
    font-family:"Good Headline Pro Black";
    src:url($font-location + "HeadlinePro/GoodHeadlinePro-Black.woff2") format("woff2"),
    url($font-location + "HeadlinePro/GoodHeadlinePro-Black.woff") format("woff");
}
@font-face{
    font-family:"Good Headline Pro BlackItalic";
    src:url($font-location + "HeadlinePro/GoodHeadlinePro-BlackItalic.woff2") format("woff2"),
    url($font-location + "HeadlinePro/GoodHeadlinePro-BlackItalic.woff") format("woff");
}
@font-face{
    font-family:"Good Headline Pro CondBlack";
    src:url($font-location + "HeadlinePro/GoodHeadlinePro-CondBlack.woff2") format("woff2"),
    url($font-location + "HeadlinePro/GoodHeadlinePro-CondBlack.woff") format("woff");
}
@font-face{
    font-family:"Good Headline Pro Medium";
    src:url($font-location + "HeadlinePro/GoodHeadlinePro-Medium.woff2") format("woff2"),
    url($font-location + "HeadlinePro/GoodHeadlinePro-Medium.woff") format("woff");
}
