.s-content-steps {
    counter-reset: number;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 35px;

    @include breakpoint(large) {
        margin: 0 auto;
        width: 80%;
    }

    &.no-steps {
        .s-content-steps__item {
            &::before {
                display: none;
            }
        }
    }

    &__item {
        counter-increment: number;
        box-sizing: border-box;
        width: 50%;
        padding: 25px 17px;
        position: relative;
        min-width: 300px;

        @include breakpoint(large) {
            width: auto;
            flex: 1;
            padding: 0 25px;
        }

        
        &::after {
            content:"";
            background-size: 38px 40px;
            background-image: url('/resources/imagesBundesliga/kaufland/icons/arrow_right.png');
            position: absolute;
            display: inline-block;
            width: 38px; 
            height: 40px;
            right: -22px;
            top: 20%;

            @media screen and (max-width: 599px) {
                background-image: url('/resources/imagesBundesliga/kaufland/icons/arrow_bottom.png');
                display: block;
                position: relative;
                margin: 0px auto;
                margin-top: 30px;
                right: unset;
                top: unset;
            }
        }

        &::before {
            content: counter(number);
            background-color: $white;
            border-radius: 50%;
            display: block;
            width: 25px;
            height: 25px;
            color: $dark-lilac;
            font-size: 21px;
            line-height: 25px;
            text-align: center;

            position: relative;
            top: 15px;

            @include breakpoint(large) {
                display: none;
            }
        }

        @include breakpoint (large) {
            &:nth-child(even) {
                &::after {
                    display: inline-block;
                }
            }
        }

        &:last-of-type {
            &::after {
                display: none !important;
            }
        }

        img {
            margin: 0 auto;
            display: block;
            max-height: 190px;
            @include breakpoint(large) {
                width: auto;
            }
        }

        p {
            font-family: $soft;
            font-size: 16px;
            line-height: 18px;

            @include breakpoint(medium) {
                font-size: 20px;
                line-height: 22px;
            }

            span {
                font-weight: 700;
            }
        }
    }
}