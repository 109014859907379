.s-content-title {
    text-align: center;
    font-family: $gobold-bold;
    color: #3b2774;
    margin: 0 auto;

    @media only screen and (max-width: 481px) {
        width: 91%;
    }

    .heading__v1,
    .subheading__v1,
    .paragraph__v1,
    .paragraph-two__v1 {
        display: block;
        margin: 0 auto;
    }

    .subheading__v1,
    .paragraph__v1,
    .paragraph-two__v1 {
        width: 91%;

        @media only screen and (max-width: 481px) {
            width: 99%;
        }
    }

        .heading__v1 {
        padding-bottom: 100px;
        font-family: $gobold-bold !important;
        font-style: normal;
        font-weight: 400;
        line-height: 90px;
        text-align: center;
        word-break: break-word;
        max-height: 251px;
        font-size: 30px;
        
        /* iPad Portrait */
        @media only screen and (max-width: 768px) {
            font-size: 40px;
            line-height: 40px;
        }
        
        /* mobile Portrait */
        @media only screen and (max-width: 481px) {
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 0.4375px;
            padding-bottom: 30px;
        }
        
        @include breakpoint(large) {
            font-size: 60px;
            width: 1040px;
        }
        @include breakpoint(small){
            min-width: 331px;
        }


    }

    .subheading__v1 {
        padding-bottom: 35px;
        font-family: $gobold-bold;
        font-size: 24px;
        line-height: 32px;


        /* iPad Portrait */
        @media only screen and (max-width: 768px) {
            font-size: 28px;
            line-height: 28px;
        }

        /* mobile Portrait */
        @media only screen and (max-width: 481px) {
            font-size: 25px;
            line-height: 28px;
            padding-bottom: 0px;
        }


    }

    .paragraph__v1 {
        padding-bottom: 35px;
        font-size: 24px ;
        line-height: 26px !important;
        font-family: $good-head-pro !important;
        /* mobile Portrait */
        @media only screen and (max-width: 481px) {
            font-size: 17px;
            line-height: 20px;
            padding-top: 0px;
        }
        @include breakpoint(small){
            line-height: 33px !important;
        }
    }
    .paragraph-two__v1 {
        padding-bottom: 35px;
        font-size: 24px ;
        line-height: 26px !important;
        font-family: $good-head-pro !important;

        /* mobile Portrait */
        @media only screen and (max-width: 481px) {
            font-size: 17px;
            line-height: 20px;
            padding-top: 0px;
        }
        @include breakpoint(small) {
            line-height: 33px !important;
        }
    }
}