.match-list {
    background-color: $white;
    padding: 10px;
    display: flex;
    justify-content: center;
    
    @include breakpoint(large) {
        padding: 80px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;
        max-width: 1190px;

    }

    &__display {
        @include breakpoint(large) {
            width: 1190px;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        color: $mid-dark-lilac;
        flex-direction: column-reverse;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
        
        @include breakpoint(large) {
            flex-direction: row;
            align-items: unset;
            width: 100%;
        }

        &__buttons {
            display: flex;
            gap: 16px;
        }

        &__club {
            text-align: center;

            @include breakpoint(large) {
                text-align: unset;
            }

            &__selected, &__bottom {
                display: flex;
                align-items: center;
                gap: 10px;

                &__image {
                    img {
                        height: 35px;
                    }
                }

                &__name {
                    font-size: 24px;
                    font-weight: 700;
                    font-family: $good-head-pro-bold;
                }
            }
            
            &__top {
                font-weight: 400;
                font-size: 20px;
                font-family: $good-head-regular;
            }

            &__bottom {
                flex-direction: column;

                @include breakpoint(large) {
                    flex-direction: row;
                }
            }

            &__button{
                margin-top: -14px;
                margin-left: 20px;
            }
        }
    }

    &__cta {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        &-mobile {
            display: flex;
            justify-content: center;
            padding: 30px;

            @include breakpoint(large) {
                display: none;
            }
        }
    }

    &__detail {
        margin-top: -85px;

        .match-list__display {
            @include breakpoint(large) {
                display: flex;
                justify-content: center;
            }
        }
    }


    .match-detail{
        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            margin-bottom: 40px;
            margin-top: 30px;

            @include breakpoint(large) {
                margin-top: 0;
            }

            @media only screen and (max-width: 481px) {
                flex-direction: column;
                margin-bottom: 120px;
            }

            &__title {
                color: $mid-dark-lilac;
                font-size: 30px;
                font-family: $gobold-bold;
                margin-left: 20px;
                width: 78%;
                
                @include breakpoint(large) {
                    font-size: 40px;
                    margin-left: 0;
                }
                @media only screen and (max-width: 481px) {
                    margin-top: 20px;
                    text-align: center;
                    width: 100%;
                    margin-left: 0;
                }
            }
        }

        &__bottom {
            margin-top: 54px;
            @media only screen and (max-width: 481px) {
                margin-bottom: 30px;
            }
            p {
                margin-top: 25px;
                color:  $mid-dark-lilac;
                text-align: center;
                font-family: $good-head-regular;
                font-size: 16px;
            }
        }
    }
}

#match-list__button {
    &::after {
        left: 42%
    }
}