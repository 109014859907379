.radio-label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    /* Adjust as needed */
    cursor: pointer;
    font-size: 16px;
    /* Adjust as needed */
    color: white !important;
    padding: 7px 10px 10px 45px;
}

.radio-label::before {
    content: "";
    display: inline-block;
    width: 28px;
    /* Adjust as needed */
    height: 27px;
    /* Adjust as needed */
    position: absolute;
    left: 0;
    top: 0;
    // border: 2px solid #000;
    /* Adjust border style and color as needed */
    border-radius: 8px;
    background-color: #fff;
    /* Adjust border radius to make it look like a square */
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+.radio-label::before {
    content: '\2713';
    /* Unicode check mark symbol */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    /* Adjust as needed */
    color: rgb(0, 0, 0);
    /* Color of the check mark */
}

#checkmarkMilka {
    position: absolute;
    z-index: 2;
    margin-left: 3px;
    margin-top: 3px;
}