.buzzer {
    &-faq {
        .content-faq {
            max-width: unset;
        }
        .s-content-steps__item {
            margin: 10px 25%;

            @include breakpoint(large) {
                margin: 0;
                flex: unset;
            }
            &::after {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }
        }
    }

    &-netto {

        .campaign-heading {
            h4 {
                @include breakpoint(large) {
                    margin-top: 60px;
                    margin-bottom: 60px;
                }
            }

            p {
                width: 100%;
            }

            .s-content-steps__item {
                p {
                    margin: 0 auto;
                }
            }
        }

        .campaign-mid {
            h3 {
                font-family: $soft-alt-medium;
                width: auto;
                
                @include breakpoint(medium) {
                    font-size: 28px;
                    line-height: 31px;
                    text-align: left;
                    margin: 15px 0;
                }
            }
        }

        p {
            a {
                text-decoration: underline;
                font-weight: bold;
            }
        }

        #instructions {
            text-align: left;
            margin-bottom: 100px;
        }
    }

    &-tcs {
        font-size: 15px;
        line-height: 19px;
        a {
            text-decoration: underline;
        }
    }

    &-error {
        color: $error-red !important;
        text-align: center;
        font-family: $recoleta-medium;
        margin: 30px 0;
        @include breakpoint(small-only){
            margin: 10px 0;
        }
    }

    &-jersey {
        .form__container {
            background: rgba(125, 107, 170, 0.2);
            border-radius: 10px;
            padding: 30px 25px;
            margin-bottom: 100px !important;

            @include breakpoint(medium) {
                margin-bottom: 150px !important;
            }

            &.no-bottom {
                margin-bottom: 50px !important;
            }

            & > * {
                margin: 12px auto;
            }

            input, select {
                background-color: $white;
            }

            [type="submit"] {
                margin: 30px auto 0;
            }
        }
        #frm_code_submit {
            .form__container {
                width: 90%;
                margin: 0 auto;

                @include breakpoint(medium) {
                    width: 35%;
                }
            }
        }

        div {
            button {
                display: block;
                width: 80%;
                margin: 20px auto;
                padding: 0;

                @include breakpoint(large) {
                    width: 250px;
                }
            }
        }

        #frm_gift_choice {
            .selection-options {
                width: 90%;
                margin: 0 auto;

                @include breakpoint(large) {
                    width: 30%;
                }

                label {
                    background-color: rgba(125, 107, 170, 0.2);;
                    border-radius: 13px;
                    margin: 20px 0;
                    padding: 20px 16px;
                    padding-left: 35px;
                    text-align: left;

                    @include breakpoint(medium) {
                        text-align: center;
                        padding-left: 0;
                    }

                    .radiobtn {
                        left: 10px;
                        top: calc(50% - 10px);

                        @include breakpoint(medium) {
                            left: 20px;
                        }
                    }
                }
            }
        }

        #frm_team_selection {
            text-align: center;
            margin: 0 auto;
            margin-bottom: 83px;
            
            #team-logo {
                margin-bottom: 50px;
            }

            button {
                width: 243px;
            }

            .space-bottom {
                .btn-bottom {
                    margin: 0;
                }
            }

            &.ticket {
                .space-bottom {
                    padding-bottom: 00;
                }
            }

            p {
                font-size: 20px;
                @include breakpoint(medium) {
                    font-size: 25px;
                }
            }

            .team-season {
                span {
                    right: 0;
                    transform: rotate(10deg);
                }
            }
        }

        #frm_jersey_size, #frm_user_details, #frm_gift_choice {
            button[type="submit"] {
                width: 60%;
                margin-top: 50px;
                
                @include breakpoint(medium) {
                    width: 15%;
                }
            }
        }

        #name-terms {
            margin-top: 50px;
            margin-bottom: 50px;
            @include breakpoint(medium) {
                margin-top: 65px;
                margin-bottom: 60px;
                width: 100%;
            }
        }

        #frm_user_details_comp, #frm_user_details_ticket {
            #name-terms {
                div {
                    @include breakpoint(medium) {
                        width: 40%;
                    }
                }
            }
        }

        &#confirmation {
            h2 {
                margin-bottom: 20px;
            }

            h4 {
                margin-top: 0;
            }

/*             p {
                @include breakpoint(medium) {
                    width: 70%;
                    margin: 0 auto;
                }
            } */

            hr.separator {
                margin: 50px auto;

                @include breakpoint(medium) {
                    margin: 80px auto 100px;
                }
            }

            img#ticket-img {
                width: 90%;
                margin: 0 auto;
                display: block;

                @include breakpoint(medium) {
                    width: 60%;
                }
            }

            img#milka-products {
                width: 90%;
                margin: 0 auto;
                display: block;

                @include breakpoint(medium) {
                    width: 60%;
                }
            }
        }

        #winner-name {
            font-family: inherit;
            text-transform: capitalize;
        }
    }
}