.price{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    h1{
        margin-bottom: 40px;
        color: #FFF !important;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Gobold Bold";
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;
    }

    .price-banner{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        background-color: #3b2774;
        border: solid 5px #F1CC74;
        border-radius: 220px;
        width: 65em;
        height: 385px;
        margin-bottom: 50px;

        @media only screen and (min-width: 768px){
            .imagebanner {
                padding-top: 50px;
                height: 126%;
            }
        }
        

        .text-content{
            display: flex;
            justify-content: center;
            // align-items: center;
            flex-direction: column;

            .title{
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: "Gobold Bold";
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-transform: uppercase;
                text-align: left;
                padding-left: 30px;
            }

            .text{
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: "Good Headline Pro";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-align: left;
                padding-left: 30px;
                padding-right: 100px;
            }

            .expli{
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: "Good Headline Pro";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-align: left;
                padding-left: 30px;
                padding-right: 100px;
            }
        }
    }

    .price-content{
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto;
        align-items: baseline;
        row-gap: 60px;
        column-gap: 50px;
        margin-bottom: 35px;
    }
}

@media only screen and (max-width: 767px) {
    .price{
        margin-top: 40px;

        h1{            
            font-size: 30px;
            padding-inline: 65px;
        }

        .price-banner{
           flex-direction: column;
           height: fit-content;
           width: 85%;

            .imagebanner {
                width: 100%;
                margin-top: -5px;
            }

           .text-content{
                margin-bottom: 60px;
                margin-top: 10px;
                align-items: center;

                .title{
                    text-align: center;
                    font-size: 24px;
                    padding-left: 0px;
                }

                .text{
                    text-align: center;
                    padding-inline: 30px;
                    font-family: "Good Headline Pro";
                    font-size: 20px;
                }

                .expli{
                    text-align: center;
                    padding-inline: 30px;
                    padding-block: 15px;
                    font-size: 13px;
                }
           }
        }

        .price-content{
            display: flex;
            flex-direction: column;
            width: fit-content;
        }
    }

}