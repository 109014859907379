.video-card {
    &__container {
        position: relative;
        overflow: hidden;
        min-height: 200px;
        background-color: $milka-lilac;

        @include breakpoint(medium) {
            height: 100%;
        }

        iframe {
            width: 100%;
            height: 100%;
            min-height: 200px;
    
            @include breakpoint(medium) {
                min-height: 320px;
                width: 100%;
            }

            @include breakpoint(start-medium-tablet) {
                min-height: 520px;
            }

            @include breakpoint(large) {
                min-height: 575px;
            }
        }
    }

    &__video {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        &-poster {
            width: 100%;
            height: auto;
        }

        picture {
        width: 100%;
        height: 100%;
        }
    }

    &__play {
        position: absolute;
        cursor: pointer;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 85px;
        width: 85px;
        left: calc(50% - 52px);
        top: calc(50% - 52px);
        background-color: rgba($color: $white, $alpha: 0.5);
        border-radius: 50%;

        @include hover {
            background-color: rgba($color: $white, $alpha: 1);
        }

        @include breakpoint(medium) {
            height: 105px;
            width: 105px;
        }
    }

    &__play-icon {
        margin-left: 10px;
    }

    &__text {
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        width: 90%;

        padding-bottom: 25px; 

        @include breakpoint(large) {
            margin: initial;
            width: 100%;
            text-align: left;
            padding-left: 35px;
        }
    }

    &__title {
        color: $white;
        font-family: $recoleta-medium;
        font-size: 25px;

        @include breakpoint(large) {
            font-size: 30px;
        }
    }

    &__subtitle {
        color: $white;
        font-family: $recol-regular;
        font-size: 25px;

        @include breakpoint(large) {
            font-size: 30px;
        }
    }
}

@import "../../05_STATE/grid-components/video-card";