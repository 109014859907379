#buzzer-participation.micro-globus{
    margin-top: 0;
    .micro-globus{
        &__title-container {
            h1{
                margin-bottom: 50px;
            }

            @include globus-screen(mobile-only) {
                padding-bottom: 5px;
            }
        }
    }
    .team-retailer__form {
        margin-top: 80px;
        .input-container{
            width: 100%;
        }

        @include globus-screen(small-only) {
            margin-top: 40px;
            .retailer-container__form{
                margin-bottom: 40px;
            }
        }
    }
    
    #participant-info{
        @include globus-screen(small-only) {
            margin-bottom: 50px;
        }
    }

    h2{
        margin-bottom: 70px;
        @include globus-screen(small-only) {
            margin-bottom: 40px;
        }
    }

    @media only screen and (min-width: 1025px) {
        .buzzer-form {
            margin: 50px auto 75px;
        }
    }

    p.special {
        @include globus-screen(small-only) {
            margin-bottom: 100px;
        }
    }

    #dob-container {
        order: unset !important;
    }
}

#main-participation.micro-globus{
    margin-top: 0;
    .micro-globus{
        &__title-container {
            h1{
                margin-bottom: 50px;
            }

            @include globus-screen(mobile-only) {
                padding-bottom: 5px;
            }
        }
    }
    .team-retailer__form {
        margin-top: 80px;
        .input-container{
            width: 100%;
        }

        @include globus-screen(small-only) {
            margin-top: 40px;
            .retailer-container__form{
                margin-bottom: 40px;
            }
        }
    }
    
    #participant-info{
        @include globus-screen(small-only) {
            margin-bottom: 50px;
        }
    }

    h2{
        margin-bottom: 70px;
        @include globus-screen(small-only) {
            margin-bottom: 40px;
        }
    }

    @media only screen and (min-width: 1025px) {
        .buzzer-form {
            margin: 50px auto 75px;
        }
    }

    p.special {
        @include globus-screen(small-only) {
            margin-bottom: 100px;
        }
    }

    #dob-container {
        order: unset !important;
    }
}

.micro-globus {
    &__user-details {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        max-width: 1000px;
        margin: 0 auto;
    }
    &__inputs-container {
        width: 48%;
        margin: 0 auto;
        position: relative;

        &.secondary{
            padding-top: 64px;
        }

        .input-container {
            width: 100% !important;
            margin: 0 !important;
            margin-top: 12px !important;
            margin-bottom: 12px !important;
            display: inline-block;
        }

        #salutation{
            .form-input__container{
                width: 40% !important;
            }
        }

        #pflichtfelder{
            position: absolute;
            right: 0;
            top: 35px;
        }

        #street {
            width: 50% !important;
        }
        #houseNumber{
            width: 45% !important;
            margin-left: 5% !important;
        }
        #zipCode{
            width: 45% !important;
            margin-right: 5% !important;
        }
        #city-container{
            width: 50% !important;
        }
        #dob-container {
            margin: 0px !important;
            margin-top: 0px !important;
            margin-bottom: 18px  !important;
        }
    }
}
