.header-animated {
    @keyframes fadeInLogo {
        0% { opacity: 0;}
        100% { opacity: 1;}
    }
    
    @-moz-keyframes fadeInLogo {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-webkit-keyframes fadeInLogo {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-o-keyframes fadeInLogo {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-ms-keyframes fadeInLogo {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes fadeOut {
        0% { opacity: 1;}
        100% { opacity: 0;}
    }

    @-moz-keyframes fadeOut {
        0% { opacity: 1;}
        100% { opacity: 0;}
    }
    
    @-webkit-keyframes fadeOut {
        0% { opacity: 1;}
        100% { opacity: 0;}
    }
    
    @-o-keyframes fadeOut {
        0% { opacity: 1;}
        100% { opacity: 0;}
    }
    
    @-ms-keyframes fadeOut {
        0% { opacity: 1;}
        100% { opacity: 0;}
    }

    @keyframes zoomIn {
        0% { transform: scale(1);}
        100% { transform: scale(1.2);}
    }

    @-moz-keyframes zoomIn {
        0% { transform: scale(1);}
        100% { transform: scale(1.2);}
    }
    
    @-webkit-keyframes zoomIn {
        0% { transform: scale(1);}
        100% { transform: scale(1.2);}
    }
    
    @-o-keyframes zoomIn {
        0% { transform: scale(1);}
        100% { transform: scale(1.2);}
    }
    
    @-ms-keyframes zoomIn {
        0% { transform: scale(1);}
        100% { transform: scale(1.2);}
    }

    .partner-logo__container {
        @media screen and (min-width: 992px) {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .partner-logo {
            width: 98px;
            height: 27px;
            @media screen and (min-width: 769px) {
                width: 197px;
                height: 54px;
            }
        }
        .reihe-logo {
            @media screen and (min-width: 769px) {
                width: 100%;
                height: 56px;
                fill: none;
            }
        }
    }

    &__left, &__right {
        display: none;

        @media screen and (min-width: 992px) {
            height: 100%;
            width: 100%;
            display: block;  
        }
    }

    &__left {
        direction: rtl;
        padding-right: 0px;
    }

    &__right {
        padding-left: 0px;
    }

    &__logo-content {
        position: relative;
        width: 100%;
        height: 100%;

        .logos-grouped {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            &__item {
                width: auto;
                height: unset;
                max-height: 50px;
                opacity: 1;
                margin: 0 15px 0 15px;
                @media screen and (min-width: 992px) and (max-width: 1240px) {
                    margin: 0 10px 0 10px;
                }
            }

            &.active {
                opacity: 1;
                animation: fadeInLogo 2s;
                -webkit-animation: fadeInLogo 2s;
                -moz-animation: fadeInLogo 2s;
                -o-animation: fadeInLogo 2s;
                -ms-animation: fadeInLogo 2s;
            }

            &.not-active {
                animation: fadeOut 1s;
                -webkit-animation: fadeOut 1s;
                -moz-animation: fadeOut 1s;
                -o-animation: fadeOut 1s;
                -ms-animation: fadeOut 1s;

                .logos-grouped__item {
                    animation: zoomIn 1s;
                    -webkit-animation: zoomIn 1s;
                    -moz-animation: zoomIn 1s;
                    -o-animation: zoomIn 1s;
                    -ms-animation: zoomIn 1s;
                }                
            }
        }
    }

    #hamburger-m {
        z-index: 1;
        @media screen and (min-width: 992px) and (max-width: 1240px) {
            right: 25px;
        }
    }

    .header-md__logo {
        @media screen and (min-width: 992px) and (max-width: 1240px) {
            left: 10px;

            .icon-milka-logo {
                font-size: 30px !important;
            }
        }
    }
    .header-logo{
        position: absolute;
        z-index: 1;
    }

    .oreo {
        @media screen and (min-width: 769px){
            left: 125px;
        }
        left: 60px;
    }
}