.buzzer-generated-score{
    display: flex;
    gap: 30px;
    max-width: 520px;
    font-family: $good-head-pro;
    @media screen and (max-width: 1024px) {
        gap:15px;
    }
    
    .buzzer-generated-score__number{
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: $white;
        p{
            color:#3B2774;
            font-size: 42.26px;
            font-family: $good-head-pro;
            font-weight: 900;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1024px) {
            max-width: 44px;
            min-width: 44px;
            max-height: 44px;

            p {
                font-size: 31px;
            }
        }
    }
    .buzzer-generated-score__details{
        max-width: 420px;
        height: 60px;
        width: 70%;
        background-color: #69559B;
        border: 7px dashed #fff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 34px;
        min-width: 243px;
        @media screen and (max-width: 1024px) {
            max-width: 243px;
            max-height: 44px;
        }
       
    }
}
.buzzer-generated-score.generated{
    cursor: pointer;
    display: flex;
    gap: 30px;
    max-width: 520px;
    font-family: $good-head-pro;

    @media screen and (max-width: 1024px) {
            gap: 15px;
        }

    .buzzer-generated-score__number{
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: $white;
        p{
            color:#3B2774;
            font-size: 42.26px;
            font-family: $good-head-pro;
            font-weight: 900;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1024px) {
            max-width: 44px;
            min-width: 44px;
            max-height: 44px;

            p {
                font-size: 31px;
            }
        }
    }
    .buzzer-generated-score__details{
        max-width: 420px;
        height: 60px;
        width: 70%;
        background-color: $white;
        border: 7px dashed $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 34px;
        padding: 0 7px;
        min-width: 243px;
        &__team{
            max-width: 48px;
            padding-top: 5px;
            img{
                width: 48px;
                @media screen and (max-width: 1024px) {
                    width: 33px;
                }
            }
        }
        &__match-score{
            p{
                font-size: 36px;
                line-height: 36px;
                font-weight: 900;
                color: #3B2774;
                font-family: $good-head-pro;
            }
        }
        @media screen and (max-width: 1024px) {
             max-width: 243px;
            max-height: 44px;
        }

    }
}

.buzzer-generated-score.select{
    cursor: pointer;
    display: flex;
    gap: 30px;
    max-width: 520px;
    font-family: $good-head-pro;

    @media screen and (max-width: 1024px) {
            gap: 15px;
        }
    // background: url(/resources/imagesBundesliga/buzzer_23/buzzer/arrow-selected-score.gif);
    // background-repeat: no-repeat;
    // background-size: 50px auto;
    // .buzzer-generated-score__arrow{
    //     img{
    //         width: 50px;
 
    //     }
    // }
    @media screen and (max-width: 1024px) {
        background-size: 30px auto;
    }

    .buzzer-generated-score__number{
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: $white;
        p{
            color:#3B2774;
            font-size: 42.26px;
            font-family: $good-head-pro;
            font-weight: 900;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1024px) {
            max-width: 44px;
            min-width: 44px;
            max-height: 44px;

            p {
                font-size: 31px;
            }
        }
    }
    .buzzer-generated-score__details{
        max-width: 420px;
        height: 60px;
        width: 70%;
        background-color: $white;
        border: 7px dashed $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 34px;
        padding: 0 7px;
        min-width: 243px;
        &__team{
            max-width: 48px;
            padding-top: 5px;
            img{
                width: 48px;
                @media screen and (max-width: 1024px) {
                        width: 33px;
                }
            }
        }
        &__match-score{
            p{
                font-size: 36px;
                line-height: 36px;
                font-weight: 900;
                color: #3B2774;
                font-family: $good-head-pro;
            }
        }
        @media screen and (max-width: 1024px) {
            max-width: 243px;
            max-height: 44px;
        }

    }
}
.buzzer-generated-score.selected{
    display: flex;
    gap: 30px;
    max-width: 520px;
    font-family: $good-head-pro;

    @media screen and (max-width: 1024px) {
        gap: 15px;
    }
 
    .buzzer-generated-score__number{
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: $white;
        p{
            color:#3B2774;
            font-size: 42.26px;
            font-family: $good-head-pro;
            font-weight: 900;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1024px) {
            max-width: 44px;
            min-width: 44px;
            max-height: 44px;
            p {
                font-size: 31px;
            }
        }
    }
    .buzzer-generated-score__details{
        max-width: 420px;
        height: 60px;
        width: 70%;
        background-color: #CAC1E2;
        border: 5px solid #2ECE09;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 34px;
        padding: 0 7px;
        min-width: 243px;
        &__team{
            max-width: 48px;
            padding-top: 5px;
            img{
                width: 48px;
            @media screen and (max-width: 1024px) {
                    width: 33px;
            }
            }
        }
        &__match-score{
            p{
                font-size: 36px;
                line-height: 36px;
                font-weight: 900;
                color: #3B2774;
                font-family: $good-head-pro;
            }
        }
    @media screen and (max-width: 1024px) {
    max-width: 243px;
    max-height: 44px;
    }

    }
}