.intropage_fairplay {
  .navigation {
    background-color: rgba(59, 39, 114, 0.2);
  }
}
.fairplay-text {
  padding: 0;
  @media only screen and (min-width: 768px) {
    padding-top: 40px;
  }
  .s-content-title {
    padding-top: 0px;
    .heading_dark-lilac {
      font-family: "Intervogue Soft Alt Bold";
      font-size: 35px;
      padding-bottom: 14px;
      @media only screen and (min-width: 768px) {
        font-size: 50px;
        padding-bottom: 30px;
      }
    }
    #endpage-Text {
      font-family: "Intervogue Soft Alt Regular";
      max-width: 739px;
      margin: 0 auto;
      font-size: 20px;
      @media only screen and (min-width: 768px) {
        font-size: 26px;
      }
    }
  }
}

.fairplay-slider-wrapper {
  background-color: #d20515;
  color: #fff;
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;
    top: 20%;
    @media only screen and (min-width: 450px) {
      top: 23%;
    }
    @media only screen and (min-width: 620px) {
      top: 30%;
    }
    @media only screen and (min-width: 768px) {
      top: 40%;
    }
    @media only screen and (min-width: 1280px) {
      top: 50%;
    }
  }
  .swiper-pagination.swiper-pagination-bullets {
    top: 193px;
    bottom: 0;
    @media only screen and (min-width: 450px) {
      display: none;
    }
    .swiper-pagination-bullet {
      background: #fff;
    }
  }

  .swiper-wrapper {
    .swiper-slide.swiper-slide-active {
      z-index: 999;
      display: flex;
      flex-flow: row wrap;
      @media only screen and (min-width: 1200px) {
        display: unset;
        flex-flow: unset;
      }
    }

    //For fading effect
    .swiper-slide:not(.swiper-slide-active) {
      opacity: 0 !important;
      z-index: 0 !important;
    }

    .Slider__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      @media (min-width: 1280px) {
        flex-direction: row;
      }
      .Slider__left {
        position: relative;
        flex: 1 1 0;
        align-items: center;
        width: 100%;
        @media (min-width: 1280px) {
          margin: 3.75rem 3.125rem 3.75rem 7.8125rem;
        }
        .Slider__left__wrapper {
          position: relative;
          padding-top: 56.25%;
          overflow: hidden;
          line-height: 0;
          .Slider__left__wrapper__picture {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
          }
          .Slider__left__wrapper__video {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            max-width: 100%;
          }
          .Slider__left__wrapper__overlay {
            font-size: 3.4375rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            padding: 0;
            margin: 0;
            font-family: inherit;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            &:before {
              /* opacity: 0.5; */
              display: inline-flex;
              content: "";
              /*               background-image: url($local-images + "/icons/triangle-right.svg");
              background-size: 250px 210px;
              height: 200px;
              width: 260px; */
              background-image: url("/resources/imagesBundesliga/fairplay/polygon.png");
              background-repeat: no-repeat;
              background-position: 10px 14px;
              height: 90px;
              background-size: 54px 70px;
              width: 76px;
              @media (min-width: 768px) {
                background-position: 26px 26px;
                background-size: initial;
                height: 139px;
                width: 118px;
              }
            }
          }
        }
      }
      .Slider__right {
        padding: 40px;
        flex: 1 1 0;
        align-self: center;
        width: 100%;
        color: #fff;
        @media (min-width: 1280px) {
          padding: 60px;
          padding-top: 0;
          margin: 6.25rem 7.8125rem 1.25rem 0;
        }
        @media (min-width: 1400px) {
          width: 75rem;
          padding-right: 0;
          padding-left: 0;
        }

        .Slider__excerpt {
          color: #fff;
          &:first-child {
            margin-top: 0;
          }
          .article-headline {
            font-family: "Intervogue Soft";
            color: #fff;
            font-size: 19px;
            line-height: 28px;
            @media (min-width: 768px) {
              font-size: 20px;
            }
          }
          p {
            position: relative;
            display: block;
            text-align: right;
            margin: 0;
            font-family: DFL, sans-serif;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 700;
            color: #fff;
            letter-spacing: 1.1px;
            padding-top: 15px;
            @media (min-width: 1024px) {
              font-size: 2rem;
            }
            @media (min-width: 1440px) {
              font-size: 2.25rem;
            }
          }
          p.headline {
            text-transform: uppercase;
            padding-top: 0;
            text-align: initial;
            padding-bottom: 15px;
            font-size: 29 / 16 * 1rem;
          }
          .Slider__excerpt :first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.GridFairplay {
  padding-bottom: 40px;

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: 100px;
  }
  @media (min-width: 1024px) {
    padding-bottom: 130px;
  }
  .GridFairplay__wrapper {
    display: flex;
    flex-wrap: wrap;
    & > * {
      position: relative;
      flex-grow: 1;
      width: 100%;
      max-width: 100%;
      padding-top: 100%;
      margin-top: 0;
      @media (min-width: 1024px) {
        position: relative;
        flex-grow: 1;
        width: 50%;
        max-width: 50%;
        padding-top: 50%;
        margin-top: 0;
      }
      @media (min-width: 1280px) {
        position: relative;
        flex-grow: 1;
        width: 33.33333%;
        max-width: 33.33333%;
        padding-top: 33.33333%;
        margin-top: 0;
      }
    }
    & > :nth-child(-n + 1) {
      margin-top: 0;
    }
    & > :not(:nth-of-type(1n + 1)),
    & > :nth-of-type(1n + 1) {
      margin-left: 0;
    }
    & > :nth-child(-n + 2) {
      @media (min-width: 1024px) {
        margin-top: 0;
      }
    }
    & > :not(:nth-of-type(odd)),
    & > :nth-of-type(odd) {
      @media (min-width: 1024px) {
        margin-left: 0;
      }
    }
    & > :nth-child(-n + 3) {
      @media (min-width: 1280px) {
        margin-top: 0;
      }
    }
    & > :not(:nth-of-type(3n + 1)),
    & > :nth-of-type(3n + 1) {
      margin-left: 0;
    }
    .GridFairplay__itemwrapper {
      position: relative;
      flex-grow: 1;
      background-color: #fff;
      &:last-child {
        @media (max-width: 450px) {
          padding-bottom: 40px;
        }
      }
      &.GridFairplay__itemwrapper--text {
        @media (max-width: 1023px) {
          padding-top: unset;
        }
      }
      .GridFairplay__contentText {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 2.5rem;
        background-color: #fff;
        &:hover {
          .GridFairplay__corner-icon-left {
            transform: translate(5px, 5px);
          }
          .GridFairplay__corner-icon-right {
            transform: translate(-5px, -5px);
          }
        }
        @media (min-width: 1024px) {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 3.125rem;
          margin-bottom: 0;
        }
        @media (min-width: 1440px) {
          top: 50%;
          right: 50%;
          transform: translate(0%,-50%);
          width: 100%;
          height: 460px;
        }
        .GridFairplay__text {
          font-size: 0.875rem;
          line-height: 1.57143em;
          color: #000;
          position: relative;
          max-height: calc(100% - 4.375rem);
          padding: 1.5625rem;
          overflow: hidden;
          text-align: center;
          div {
            color: #000;
          }
          @media (min-width: 768px) {
            font-size: 1.125rem;
            max-height: calc(100% - 6rem);
            padding: 1.875rem;
          }
          .GridFairplay__corner-icon-left {
            font-family: iconfont, Arial, sans-serif !important;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            line-height: 1;
            text-transform: none;
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            font-size: 1.9375rem;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            position: absolute;
            top: 0;
            left: 0;
            &:hover {
              transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
            }
          }
          [class*=" icon-"]:before,
          [class^="icon-"]:before {
            font-family: iconfont, Arial, sans-serif !important;
            font-size: util-rem(16);
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            line-height: 1;
            text-transform: none;
            speak: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          .icon-quote-left:before {
            content: url(/resources/imagesBundesliga/fairplay/quote_frame_left.png);
          }
        }
        .GridFairplay__corner-icon-right {
          font-family: iconfont, Arial, sans-serif !important;
          font-size: 1.9375rem;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          line-height: 1;
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          position: absolute;
          right: 0;
          bottom: 0;
          &:hover {
            transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
          }
        }
        [class*=" icon-"]:before,
        [class^="icon-"]:before {
          font-family: iconfont, Arial, sans-serif !important;
          font-size: util-rem(16);
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          line-height: 1;
          text-transform: none;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        .icon-quote-right:before {
          content: url(/resources/imagesBundesliga/fairplay/quote_frame_right.png);
        }
        .GridFairplay__name {
          font-family: "Intervogue Soft Alt Bold";
          font-size: 1.75rem;
          font-weight: 600;
          line-height: 1.35714em;
          color: #000;
          text-transform: uppercase;
          margin-top: 1.25rem;
          text-align: center;
          @media (min-width: 1280px) {
            font-size: 2.25rem;
          }
        }
      }
      .GridFairplay__contentImage {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        picture {
          font-size: 0;
          &:hover {
            img {
              transform: scale(1.05);
              &:hover {
                transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
              }
            }
          }
          img {
            width: 100%;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          }
        }
      }
    }
  }
}
.fairplay{
  .image__container{
    @media only screen and (max-width: 480px) {
      padding-bottom: 0;
    }
  }
}