// * Allow footer to remain at the bottom of page

body {
    font-family: $soft-alt-regular;
    position: relative;

    @include breakpoint(medium) {
        min-height: 100vh;

        // @media not all and (min-resolution:.001dpcm) {
        //     @supports (-webkit-appearance:none) {
        //         padding-bottom: 99vh;
        //     }
        // }
    }

    // @include breakpoint(large) {
    //     padding-bottom: 104vh;
    // }
    
    // @include breakpoint(x-large) {
    //     padding-bottom: 71vh;
    // }

    * {
        font-family: $soft-alt-regular;
    }

    strong {
        font-weight: bold;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a {
        &:focus {
            outline: none;
        }
    }
}

main {
    display: block;
    & > .container:first-child {
        padding-top: 150px;
    }

    &.buzzer23 {
        background-color: #3B2772;
    }
    &.main-content {
        background-image: url($local-images+'/pages/bg-pattern/bg-paper-lilac2.png');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }//for overview page
    &.overview-content{
        background-image: url($local-images+'/pages/bg-pattern/bg-paper-lilac.png');
        background-repeat: repeat;
    }
    &.main-content--white{
        background-image: url($local-images+'/pages/bg-pattern/Master Paper Texture_White.png');
        background-repeat: repeat;
        -webkit-background-size: contain;

        * {
            color: $dark-lilac;
        }
    }
    &.main-content--blue{
        background-image: url($local-images+'/pages/bg-pattern/bg-paper-lilac2.png');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }

    .back--red {
        background-color: $error-red;
        border-radius: 13px;
        color: $white !important;
        padding: 30px;
        width: 80%;
        margin: 50px auto;
        
        @include breakpoint(large) {
            width: 50%;
        }

        p {
            text-align: center;
            color: inherit;
            font-size: 20px;
            line-height: 22px;
        }
    }
}

footer {
    background-color: $redish-white ;

    width: 100%;

    @include breakpoint(large) {
        position: relative;
        bottom: 0;
        // min-height: 105vh;
        max-height: 105vh;
    }

    @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance:none) {
            max-height: 120vh;
        }
    }
}

* {
    transition: 0.2s;
}

main {
    overflow: hidden;
}

picture {
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.desktop {
    display: none !important;
    @include breakpoint(large) {
        display: block !important;
    }
}

.mobile {
    @include breakpoint(medium) {
        display: none !important;
    }
}
.tablet {
    @include breakpoint(large) {
        display: none !important;
    }

    &-up {
        @include breakpoint(small-only) {
            display: none !important;
        }
    }
}


.bg-white {
    background-image: url($local-images + "/Pages/bg-pattern/bg-paper-white.jpg");

    * {
        color:$dark-lilac;
    }
}